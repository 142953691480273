import React, {Component} from 'react';
import {Tab} from 'semantic-ui-react';
import SortTable from './SortTable';
import store from '../redux/store';
import p from "../permissionsConstants";

const {playersGameTablesTab, playersTransactionsTab, playersSessions, playerTickets} = p;

const dataForTabs = {
    gameTables: {
        title: null,
        cols: ['Table ID', 'Player ID Host', 'Player ID Guest', 'Winner ID', 'Bet Amount', 'Win Amount', 'Game Type', 'Game Kind', 'Start Date', 'End Date'],
        keys: ['id', 'player_id_host', 'player_id_guest', 'player_id_winner', 'props_amount', 'win_amount', 'scene_title', 'gameType', 'created_at', 'finished_at'],
        remoute_keys: ['id', 'remoute_hostId', 'remoute_guestId', 'remoute_winnerId', 'props_amount', 'win_amount', 'scene_title', 'gameType', 'created_at', 'finished_at'],
        type: 'gameTables',
        message: 'No games yet',
        classname: null,
        showFilter: true,
        pageName: 'games'
    },
    playerTransactions: {
        title: null,
        cols: ['Transaction ID', 'AB Transaction ID', 'Amount', 'Game Type', 'Game', 'Table ID', 'Transaction Type', 'Status',
            'Start Date', 'Player IP'],
        keys: ['transaction_id', 'op_trx_id', 'transaction_amount', 'table_type', 'game', 'table_id', 'transaction_type', 'status',
            'transaction_create_at', 'player_ip'],
        remoute_keys: ['transaction_id', 'op_trx_id', 'transaction_amount', 'table_type', 'game', 'table_id', 'transaction_type', 'status',
            'transaction_create_at', 'player_ip'],
        type: 'playerTransactions',
        message: 'No Transactions',
        classname: null,
        showFilter: true,
        pageName: 'games'
    },

    session: {
        title: null,
        cols: ['ID', 'Device', 'Created At', 'Finished At', 'Status'],
        keys: ['id', 'device', 'created_at', 'finished_at', 'session_status'],
        remoute_keys: ['id', 'device', 'created_at', 'finished_at', 'session_status'],
        type: 'playerSessions',
        message: 'No player sessions',
        classname: null,
        showFilter: true,
        pageName: 'sessions'

    },
    ticket: {
        title: null,
        cols: ['Ticket ID', 'Amount', 'Created At', 'Expired At', 'Bet Type'],
        keys: ['op_trx_id', 'value', 'created_at', 'expired_at',  'type'],
        remoute_keys:  ['op_trx_id', 'value', 'created_at', 'expired_at',  'type'],
        type: 'playerTickets',
        message: 'No ticket for this date range',
        classname: null,
        showFilter: true,
        pageName: 'games'

    },

};

class PlayerTabs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            color: 'blue',
            permission: store.getState().userReducer['permissions'],
            playerId: props.id
        };

        this.panes = [
            {
                menuItem: 'Game Tables',
                render: () => (
                    <Tab.Pane attached={false}>
                        <SortTable data={dataForTabs.gameTables} params={{playerId: this.state.playerId}}/>
                    </Tab.Pane>
                ),
            },
            {
                menuItem: 'Transactions',
                render: () => (
                    <Tab.Pane attached={false}>
                        <SortTable data={dataForTabs.playerTransactions} params={{playerId: this.state.playerId}}/>
                    </Tab.Pane>
                ),
            },
            // {
            //     menuItem: 'Sessions',
            //     render: () => (
            //         <Tab.Pane attached={false}>
            //             <SortTable data={dataForTabs.session} params={{playerId: this.state.playerId}}/>
            //         </Tab.Pane>
            //     ),
            // },
            {
                menuItem: 'Tickets',
                render: () => (
                    <Tab.Pane attached={false}>
                        <SortTable data={dataForTabs.ticket} params={{playerId: this.state.playerId}}/>
                    </Tab.Pane>
                ),
            },

        ];
        this.prepareData();

    }

    componentDidUpdate(nextProps) {
        if (nextProps.id !== this.state.playerId) {
            this.setState({playerId: nextProps.id})
        }
    }

    getIndex = (arr, name) => {
        let i = null;
        for (const [index, el] of arr.entries()) {
            if (el.menuItem === name) {
                i = index;
                break;
            }
        }
        return i
    }
    prepareData = () => {
        const {permission} = this.state;
        if (!permission[playersGameTablesTab]) {
            const index = this.getIndex(this.panes, 'Game Tables')
            this.panes.splice(index, 1)
        }
        if (!permission[playersTransactionsTab]) {
            const index = this.getIndex(this.panes, 'Transactions')
            this.panes.splice(index, 1)
        }
        if (!permission[playersSessions]) {
            const index = this.getIndex(this.panes, 'Sessions')
            this.panes.splice(index, 1)
        }
        if (!permission[playerTickets]) {
            const index = this.getIndex(this.panes, 'Tickets')
            this.panes.splice(index, 1)
        }
    }

    render() {
        const {color} = this.state;
        return (
            <>
                {this.panes.length ?
                    <Tab
                        menu={{color, inverted: true, attached: false, tabular: false}}
                        panes={this.panes}
                        style={{marginTop: '3%', minHeight: '400px'}}
                    /> : null}
            </>
        );
    }
}

export default PlayerTabs;
