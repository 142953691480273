import {MAIN_TYPES, OTHER_TYPES, USERS_TYPES} from '../actionTypes';
import {createAction} from "../../helpers/general";

const {  OPEN_OR_CLOSE } = OTHER_TYPES;
const { RESET_REQUEST_MESSAGE } = USERS_TYPES;
const { LOADER_SHOW} = MAIN_TYPES;

export function resetRequestMessage(value) {
    return (dispatch) => {
        dispatch({ type: RESET_REQUEST_MESSAGE, value: value });
    };
}
export function leftDropDownMenuControl(value) {
    return (dispatch) => {
        dispatch({ type: OPEN_OR_CLOSE, value: value });
    };
}
export function resetLoader() {
    return (dispatch) => {
        dispatch(createAction(LOADER_SHOW))
    };
}
