import React, {Component, useEffect, useRef} from 'react';
import {Input, Button, Modal} from 'semantic-ui-react';
import {createNewTournament} from '../redux/actions/tournamentsAction';
import {connect} from 'react-redux';
import {TimeInput} from '@trendmicro/react-datepicker';

import '@trendmicro/react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import DatePick from './Datapiker/DataPicker';
import {arrClass} from "./Datapiker/general";

class ModalExampleDimmer extends Component {
    constructor(props) {
        super(props);
        this.refTo = React.createRef();
        this.refFrom = React.createRef();
    }
    state = {
        minPlayers: '',
        maxPlayers: '',
        roundState: '',
        buyIn: '',

        open: true,
        dimmer: 'blurring',

        createDateFrom: moment().format('00-00-0000'),
        createTimeFrom: moment().format('00:00:00'),

        createDateTo: moment().format('00-00-0000'),
        createTimeTo: moment().format('00:00:00'),

        scheduleDateFrom: moment().format('00-00-0000'),
        scheduleTimeFrom: moment().format('00:00:00'),

        scheduleDateTo: moment().format('00-00-0000'),
        scheduleTimeTo: moment().format('00:00:00'),

        error: {errName: '', errPass: ''},
        tournamentName: '',
        tournamentId: '',
    };

    search = () => {
        const {
            minPlayers,
            maxPlayers,
            createDateFrom,
            createTimeFrom,
            createDateTo,
            createTimeTo,
            scheduleDateFrom,
            scheduleDateTo,
            scheduleTimeFrom,
            scheduleTimeTo,
            tournamentName,
            tournamentId,
            roundState,
            buyIn,
        } = this.state;
        const {makeSearch} = this.props;
        const data = {
            createdAtFrom:
                createDateFrom === '00-00-0000' ? '' : createDateFrom + ' ' + createTimeFrom,
            createdAtTo: createDateTo === '00-00-0000' ? '' : createDateTo + ' ' + createTimeTo,
            scheduledAtFrom:
                scheduleDateFrom === '00-00-0000' ? '' : scheduleDateFrom + ' ' + scheduleTimeFrom,
            scheduledAtTo:
                scheduleDateTo === '00-00-0000' ? '' : scheduleDateTo + ' ' + scheduleTimeTo,

            minPlayers: minPlayers ? +minPlayers : '',
            maxPlayers: maxPlayers ? +maxPlayers : '',
            showPicker: '',
            name: tournamentName,
            id: tournamentId,
            round: roundState,
            buyIn,
        };
        makeSearch(data);
    };

    getMinPlayers = (e) => {
        this.setState({
            minPlayers: e.target.value,
        });
    };
    getMaxPlayers = (e) => {
        this.setState({
            maxPlayers: e.target.value,
        });
    };

    getDate = (type, value, label) => {
        let prop = type + 'Date' + label;
        this.setState({
            [prop]: value,
        });
    };
    getTime = (type, value, label) => {
        let prop = type + 'Time' + label;
        this.setState({
            [prop]: value,
        });
    };
    openPicker = (e) => {
        this.setState({
            showPicker: e,
        });
    };
    getState = (e, stateName) => {
        this.setState({
            [stateName]: e.target.value,
        });
    };
    closeFromDatePicker = (type, value, label) => {
        let prop = type + 'Date' + label;
        this.setState({
            [prop]: value,
            showPicker: '',
        });
    };
    handleClick = (e) => {
        const {showPicker} = this.state;
       // console.log({a:!!(this.refFrom.current && this.refTo.current ), showPicker }
        if ((showPicker === 'from' || showPicker === 'to'  || showPicker === 'schedule-from' || showPicker === 'schedule-to')
            && !!(this.refFrom.current && this.refTo.current ) && !arrClass.includes(e.target.className)) {
           // console.log(e.target, "e.handleClick")
            this.setState({
                showPicker: '',
            });
        }

    };

    componentDidMount() {
        console.log("componentDidMount")
        document.addEventListener('click', this.handleClick);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClick);
    }

    render() {
        const {
            open,
            dimmer,
            minPlayers,
            maxPlayers,
            createDateFrom,
            createTimeFrom,
            createDateTo,
            createTimeTo,
            scheduleDateFrom,
            scheduleDateTo,
            scheduleTimeFrom,
            scheduleTimeTo,
            tournamentName,
            tournamentId,
            showPicker,
            roundState,
            buyIn,
        } = this.state;
        const {close, title, requestMessage} = this.props;

        return (
            <div>
                <Modal dimmer={dimmer} open={open} onClose={close}>
                    <Modal.Header>{title}</Modal.Header>
                    <Modal.Content>
                        <div className="input-box">
                            <div>
                                <Input
                                    label="ID:"
                                    value={tournamentId}
                                    onChange={(e) => this.getState(e, 'tournamentId')}
                                    type="number"
                                />
                            </div>
                            <div>
                                <Input
                                    label="Name:"
                                    value={tournamentName}
                                    onChange={(e) => this.getState(e, 'tournamentName')}
                                    type="text"
                                />
                            </div>
                        </div>

                        <div className="input-box-search-tour">
                            <div className="margin-12">
                                <label>Created-At:</label>
                                <div>
                                    From:
                                    <div className="date-from">
                                        <div className="ui left icon input date">
                                            <input
                                                ref={this.refFrom}
                                                type="text"
                                                size={10}
                                                value={createDateFrom}
                                                onClick={() => this.openPicker('from')}
                                                onChange={() => this.openPicker('from')}
                                            />
                                            <i aria-hidden="true" className="calendar icon"/>
                                        </div>
                                        {showPicker === 'from' && (
                                            <DatePick
                                                close={this.closeFromDatePicker}
                                                value={createDateFrom}
                                                type="create"
                                                label="From"
                                            />
                                        )}
                                        <div className="left">
                                            <TimeInput
                                                value={createTimeFrom}
                                                onChange={(value) =>
                                                    this.getTime('create', value, 'From')
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    To:
                                    <div className="date-from">
                                        <div className="ui left icon input date">
                                            <input
                                                ref={this.refTo}
                                                type="text"
                                                size={10}
                                                value={createDateTo}
                                                onClick={() => this.openPicker('to')}
                                                onChange={() => this.openPicker('to')}
                                            />
                                            <i aria-hidden="true" className="calendar icon"/>
                                        </div>
                                        {showPicker === 'to' && (
                                            <DatePick
                                                close={this.closeFromDatePicker}
                                                value={createDateTo}
                                                type="create"
                                                label="To"
                                            />
                                        )}
                                        <div className="left">
                                            <TimeInput
                                                value={createTimeTo}
                                                onChange={(value) =>
                                                    this.getTime('create', value, 'To')
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="margin-12">
                                <label>Schedule-At:</label>
                                <div>
                                    From:
                                    <div className="date-from">
                                        <div className="ui left icon input date">
                                            <input
                                                ref={this.refFrom}
                                                type="text"
                                                size={10}
                                                value={scheduleDateFrom}
                                                onClick={() => this.openPicker('schedule-from')}
                                                onChange={() => this.openPicker('schedule-from')}
                                            />
                                            <i aria-hidden="true" className="calendar icon"/>
                                        </div>
                                        {showPicker === 'schedule-from' && (
                                            <DatePick
                                                close={this.closeFromDatePicker}
                                                value={scheduleDateFrom}
                                                type="schedule"
                                                label="From"
                                            />
                                        )}
                                        <div className="left">
                                            <TimeInput
                                                value={scheduleTimeFrom}
                                                onChange={(value) =>
                                                    this.getTime('schedule', value, 'From')
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    To:
                                    <div className="date-from">
                                        <div className="ui left icon input date">
                                            <input
                                                ref={this.refTo}
                                                type="text"
                                                size={10}
                                                value={scheduleDateTo}
                                                onClick={() => this.openPicker('schedule-to')}
                                                onChange={() => this.openPicker('schedule-to')}
                                            />
                                            <i aria-hidden="true" className="calendar icon"/>
                                        </div>
                                        {showPicker === 'schedule-to' && (
                                            <DatePick
                                                close={this.closeFromDatePicker}
                                                value={scheduleDateTo}
                                                type="schedule"
                                                label="To"
                                            />
                                        )}
                                        <div className="left">
                                            <TimeInput
                                                value={scheduleTimeTo}
                                                onChange={(value) =>
                                                    this.getTime('schedule', value, 'To')
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="input-box">
                                <div>
                                    <Input
                                        label="Min Players:"
                                        value={minPlayers}
                                        onChange={this.getMinPlayers}
                                        type="number"
                                        min={1}
                                    />
                                </div>
                                <div>
                                    <Input
                                        label="Max Players:"
                                        value={maxPlayers}
                                        onChange={this.getMaxPlayers}
                                        type="number"
                                        min={1}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="input-box">
                            <div>
                                <Input
                                    label="Round:"
                                    value={roundState}
                                    onChange={(e) => this.getState(e, 'roundState')}
                                    type="number"
                                    min={1}
                                />
                            </div>
                            <div>
                                <Input
                                    label="BuyIn:"
                                    value={buyIn}
                                    onChange={(e) => this.getState(e, 'buyIn')}
                                    type="number"
                                    min={1}
                                />
                            </div>
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <div className={requestMessage.error ? 'req-mess err' : 'req-mess'}>
                            {requestMessage.message}
                        </div>
                        <Button color="black" onClick={close}>
                            Cancel
                        </Button>
                        <Button
                            positive
                            icon="checkmark"
                            labelPosition="right"
                            content="Search"
                            onClick={this.search}
                        />
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = ({requestMessage}) => ({
    requestMessage,
});

const mapDispatchToProps = {
    createNewTournament,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalExampleDimmer);
