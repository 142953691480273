import AvatarPlayer from '../reusables/PlayerComponents/AvatarPlayer/AvatarPlayer';
import React from 'react';

export default function PlayerAvatarContainerHR({
    playerId,
    trumper,
    trumpSuit,
    avatarId,
    visualId,
    cloudText,
    isPlayersTurn,
    replayDisconnectTimerText,
}) {
    const chosenTrumpSuit = playerId === trumper.id && trumpSuit;

    return (
        <AvatarPlayer
            {...{
                chosenTrumpSuit,
                cloudText,
                avatarId,
                playerId,
                visualId,
                isPlayersTurn,
                replayDisconnectTimerText,
                withoutTimer: true,
            }}
        />
    );
}
