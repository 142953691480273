import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {getAllSpins, getOneSpinRule, getAllSpinRules} from '../../redux/actions/spinAction';
import {leftDropDownMenuControl} from "../../redux/actions/otherActions";
import './spin.css'
import SpinTable from "../../components/SpinTable";
import OneSpinRule from "./spinRules/OpenOneSpinRule";
import {Loader} from "semantic-ui-react";


const columns = [
    // {key: 1, value: 'id', text: 'ID'},
    // {key: 2, value: 'scheduled_at', text: 'Start Date'},
    {key: 3, value: 'buyin', text: 'Buy In'},
    {key: 4, value: 'prizepool', text: 'Prize Pool'},
    {key: 5, value: 'max', text: 'Max Prize Pool'},
    {key: 6, value: 'count', text: 'Spin Start Count'},
    {key: 7, value: 'created_at', text: 'Last Start Date'},
    {key: 8, value: 'sumBuyIn', text: 'Total Buy In'},
    {key: 9, value: 'sumPrizePool', text: 'Total Prize Pool'}
];

function SpinList({...redux}) {
    const {tableData, leftDropDownMenuControl, getOneSpinRule, getAllSpinRules, data, loader} = redux;
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        getAllSpinRules();
        leftDropDownMenuControl('spin')
    }, []);


    const sorting = () => {

    };

    const openSpin = (id, buyIn, prizePool) => {
        getOneSpinRule({id},buyIn, prizePool);
        setShowModal(true)

    };

    return (
        <div className="spin-content">
            {showModal && data.length? <OneSpinRule close={() => setShowModal(false)} data={data}/> : null}
            <div className="page-title min-width">
                <h2>Spins List</h2>
            </div>
            {!loader ?
                <SpinTable data={tableData}
                           cols={columns}
                           sorting={sorting}
                           count={0}
                           edit={true}
                           editSpin={openSpin}
                /> : <Loader active> Loading ... </Loader>}
        </div>


    );
}

const mapStateToProps = ({allSpinsRulesReducer, totalPagesReducer, oneSpinRuleReducer, loaderReducer}) => ({
    tableData: allSpinsRulesReducer,
    totalPagesReducer,
    data: oneSpinRuleReducer,
    loader: loaderReducer,
});

const mapDispatchToProps = {
    getAllSpins,
    leftDropDownMenuControl,
    getOneSpinRule,
    getAllSpinRules
};
export default connect(mapStateToProps, mapDispatchToProps)(SpinList);