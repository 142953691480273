import React, {Component} from 'react';
import {Modal} from 'semantic-ui-react';
import {createNewTournament} from '../redux/actions/tournamentsAction';
import {connect} from 'react-redux';


import '@trendmicro/react-datepicker/dist/react-datepicker.css';

import EditOrCopyTournament from '../pages/tournament/editOrCopy/EditOrCopyTournament';
import AddPlayersToTournament from '../pages/tournament/addPlayersToTournament/addPlayers';
import OpenTemplate from './OpenTemplateTournament';
import OpenPrizeRuleTemplate from './OpenPrizeRuleTemplate'
import ResList from "../pages/aclManagment/ResList";

class ModalComponentContent extends Component {
    state = {
        open: true,
        dimmer: 'blurring',
    };

    render() {
        const {open, dimmer} = this.state;
        const {close, componentName, title, id, name, resData, groupId, groups} = this.props;

        let showComponent =
            componentName === 'CreateTournament' ? (
                <EditOrCopyTournament close={close} title={title} disable={false}/>
            ) : componentName === 'AddPlayersToTournament' ? (
                <AddPlayersToTournament
                    close={close}
                    title={title}
                    disable={false}
                    tournamentId={id}
                />
            ) : componentName === 'EditorCopyTemplateTournament' ? (
                <OpenTemplate
                    close={close}
                    title={title}
                    disable={false}
                    tournamentId={id}
                />
            ) : componentName === 'CreateTemplateTournament' ? (
                <OpenTemplate
                    close={close}
                    title={title}
                    disable={false}
                    tournamentId={id}
                />
            ) : componentName === 'EditorCopyPrizePoolRule' ? (
                <OpenPrizeRuleTemplate
                    close={close}
                    title={title}
                    disable={false}

                />
            ) : componentName === 'CreatePrizePoolRuleTemplate' ? (
                <OpenPrizeRuleTemplate
                    close={close}
                    title={title}
                    disable={false}

                />
            ) : componentName === 'Add-Resource' ? (
                    <ResList
                        data={resData}
                        groupId={groupId}
                        resNames={Object.keys(resData)}
                        ownResources={groups}
                    />
                )  : null;

        return (
            <div>
                <Modal dimmer={dimmer} open={open} onClose={close} className={'edit-or-copy'}>
                    <Modal.Header>
                        {`${title}  ${name}`}
                    </Modal.Header>
                    <Modal.Content>{showComponent}</Modal.Content>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = ({requestMessage}) => ({
    requestMessage,
});

const mapDispatchToProps = {
    createNewTournament,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalComponentContent);
