import { useEffect, useState } from 'react';

export function useForceUnmount(reason) {
    const [status, setStatus] = useState(true);

    useEffect(() => {
        setStatus(false);
    }, [reason]);

    useEffect(() => {
        !status && setStatus(true);
    }, [status]);

    return [status];
}
