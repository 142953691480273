import React from 'react';
import { avatarImages } from '../../../../../assets';
import ChooseTrump from '../ChoseTrumpCloud/ChoseTrumpCloud';
import { setClass } from '../../../../../helpers/game';
import TimerAvatar from '../../TimerAvatar/TimerAvatar';
import InactiveCards from '../../InactiveCards';
import DotLoader from '../../Opponent/components/DotLoader';

//prettier-ignore
function AvatarPlayer({
    chosenTrumpSuit,
    cloudText,
    avatarId,
    playerId,
    visualId,
    isChooseTrumpCloudVisible,
    withoutTimer,
    isDotLoaderVisible,
    isPlayersTurn,
    replayDisconnectTimerText
}) {

    return (
        <>
            <InactiveCards/>
            <div
            className={`blot-field__user-icon ${
                setClass( isChooseTrumpCloudVisible, 'blot-field__user-control-button')} ${
                setClass(!!chosenTrumpSuit, `blot-field__user-icon--trump blot-field__user-icon--trump-${chosenTrumpSuit}`)} ${
                setClass(!isDotLoaderVisible && !!cloudText, 'blot-field__user-icon--terz')}`
            }
            >
                <div className="blot-field__user-id">ID: {visualId}</div>
                <div className={`blot-field__user-icon-container blot-field__user-icon-container${setClass(isPlayersTurn,'--with-timer')}`}>
                    <img src={avatarImages[avatarId]} alt="user avatar" className="blot-filed__user-icon-img" />
                    {!withoutTimer && <TimerAvatar playerId={playerId}/>}
                    {!!replayDisconnectTimerText && <span className="disconnect-timer">{replayDisconnectTimerText}</span>}
                </div>
                {isChooseTrumpCloudVisible && (
                    <ChooseTrump />
                )}
                {isDotLoaderVisible && <DotLoader />}
                <div className="say-terz">
                    <div className="say-terz__text">{cloudText}</div>
                </div>
            </div>
        </>
    );
}

export default AvatarPlayer;
