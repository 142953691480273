import _ from 'lodash';
import React, {PureComponent} from 'react';
import {Checkbox, Icon, Input, Table, TextArea} from 'semantic-ui-react';


export default class SortableSettingsTable extends PureComponent {
    state = {
        column: null,
        data: this.props.data,
        direction: 'descending',
        changedRowId: null,
        valueForNumber: null,
        valueForString: null,
        valueForJson: null,
        valueForBoolean: '',
    };

    handleSort = (clickedColumn) => () => {
        const {column, direction} = this.state;
        const {sorting} = this.props;

        if (column !== clickedColumn) {
            this.setState({
                column: clickedColumn,
                //	data: _.sortBy(data, [clickedColumn]),
                direction: 'ascending',
            });
        }

        this.setState({
            direction: direction === 'ascending' ? 'descending' : 'ascending',
        });

        sorting(clickedColumn, direction === 'descending' ? 'ASC' : 'DESC');
    };
    saveChanges = (e, type, value, id) => {
        const {save} = this.props;
        const {valueForBoolean, valueForNumber, valueForString, valueForJson} = this.state;
        const arr = [];
        let confValue;
        if (type === 'JSON') {
            confValue = JSON.parse(valueForJson);
        } else {
            confValue =
                type === 'BOOLEAN'
                    ? valueForBoolean
                    : type === 'NUMBER'
                    ? valueForNumber
                    : type === 'STRING'
                        ? valueForString
                        : null;
        }

        arr.push({
            confKey: e,
            confValue,
            id
        });
        save(arr);
    };
    changeCheckBox = (e, id) => {
        this.setState({
            valueForBoolean: !this.state.valueForBoolean,
            changedRowId: id,
        });
    };
    changeCheckBoxFirstTime = (e, id) => {
        this.setState({
            valueForBoolean: !e,
            changedRowId: id,
        });
    };
    changeInput = (e, id) => {
        this.setState({
            valueForNumber: e.target.value,
            changedRowId: id,
        });
    };
    changeStringInput = (e, id) => {
        this.setState({
            valueForString: e.target.value,
            changedRowId: id,
        });
    };
    changeTextArea = (e, data, id) => {
        this.setState({
            valueForJson: data.value,
            changedRowId: id,
        });
    };
    setValue = (value, type, id) => {
        const {
            valueForBoolean,
            valueForNumber,
            valueForString,
            valueForJson,
            changedRowId,
        } = this.state;
        let val;
        switch (type) {
            case 'BOOLEAN': {
                if (valueForBoolean === '' || changedRowId !== id) {
                    val = (
                        <Checkbox
                            toggle
                            checked={value}
                            onClick={() => this.changeCheckBoxFirstTime(value, id)}
                        />
                    );
                } else {
                    val = (
                        <Checkbox
                            toggle
                            checked={valueForBoolean}
                            onClick={(e) => this.changeCheckBox(e, id)}
                        />
                    );
                }
                break;
            }
            case 'NUMBER': {
                val = (
                    <Input
                        size="small"
                        value={valueForNumber && changedRowId === id ? valueForNumber : value}
                        onChange={(e) => this.changeInput(e, id)}
                    />
                );
                break;
            }
            case 'STRING': {
                val = (
                    <Input
                        size="small"
                        value={valueForString && changedRowId === id ? valueForString : value}
                        onChange={(e) => this.changeStringInput(e, id)}
                    />
                );
                break;
            }
            case 'JSON': {
                val = (
                    <TextArea
                        value={
                            valueForJson && changedRowId === id
                                ? valueForJson
                                : JSON.stringify(JSON.parse(value))
                        }
                        style={{maxWidth: '95%'}}
                        onInput={(e, data) => this.changeTextArea(e, data, id)}
                    />
                );
                break;
            }
            default:
                val = null;
        }

        return val;
    };

    render() {
        const {column, direction, changedRowId} = this.state;
        const {data, count} = this.props;

        let i = count;
        return (
            <Table sortable celled fixed size="small">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>No:</Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === 'id' ? direction : null}
                            onClick={this.handleSort('id')}
                        >
                            ID:
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === 'conf_key' ? direction : null}
                            onClick={this.handleSort('conf_key')}
                        >
                            KEY:
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === 'conf_value' ? direction : null}
                            onClick={this.handleSort('conf_value')}
                        >
                            Value:
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === 'conf_path' ? direction : null}
                            onClick={this.handleSort('conf_path')}
                        >
                            Path:
                        </Table.HeaderCell>
                        <Table.HeaderCell>Save:</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {_.map(data, ({id, conf_key, conf_value, conf_type, conf_path}) => {
                        return (
                            <Table.Row key={id}>
                                <Table.Cell>{++i}</Table.Cell>
                                <Table.Cell>{id}</Table.Cell>
                                <Table.Cell>{conf_key}</Table.Cell>
                                <Table.Cell>
                                    <div className="setting-conf-val">
                                        {this.setValue(conf_value, conf_type, id)}
                                    </div>
                                </Table.Cell>
                                <Table.Cell>{conf_path}</Table.Cell>
                                {
                                    conf_key !== 'rake' && id !== 7 ?
                                        <Table.Cell
                                            onClick={
                                                changedRowId === id
                                                    ? () =>
                                                        this.saveChanges(conf_key, conf_type, conf_value, id)
                                                    : null
                                            }
                                            className={changedRowId === id ? 'pointer' : 'pointer disabled own'}
                                        >
                                            <Icon
                                                name="save"
                                                className={changedRowId === id ? null : 'pointer disabled own'}
                                            />
                                            Save{' '}
                                        </Table.Cell> : null
                                }

                            </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>
        );
    }
}
