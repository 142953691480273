import React, { useEffect, useState } from 'react';
import { getAllSettings, updateSetting } from '../../redux/actions/settingsAction';
import { resetRequestMessage } from '../../redux/actions/otherActions';
import { connect } from 'react-redux';
import SortableSettingsTable from '../../components/SortableSettingsTable';
import './setting.css';
import { Button } from 'semantic-ui-react';
import CreateSetting from '../../components/CreateSetting';

function Setting({ getAllSettings, resetRequestMessage, updateSetting, ...redux }) {
    const { settingReducer, requestMessage } = redux;
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        let timeoutId, timeoutId1;
        if (!!requestMessage && !requestMessage.error) {
            timeoutId = setTimeout(() => {
                timeoutId1 = setTimeout(() => {
                    resetRequestMessage('');
                    setOpenModal(false);
                }, 500);
            }, 1000);
        } else {
            getAllSettings({});
        }
        return () => {
            clearTimeout(timeoutId);
            clearTimeout(timeoutId1);
        };
    }, [requestMessage]);
    const save = (data) => {
        updateSetting({ params: data });
        setTimeout(() => {
            resetRequestMessage('');
        }, 1400);
    };

    const sorting = (field, direction) => {
        getAllSettings({ orderBy: field, order: direction });
    };
    const handleOpenModal = () => {
        setOpenModal(true);
    };
    const closeCreateModal = () => {
        setOpenModal(false);
    };

    return (
        <div className="settings-content">
            <div className="page-title">
                <h2>Settings</h2>
                <Button primary className="create-tournament btn belote-green" onClick={handleOpenModal}>
                    Create Settings
                </Button>
            </div>
            {openModal && <CreateSetting close={closeCreateModal} title="Create Setting" />}
            <div className={requestMessage.error ? 'req-mess err set' : 'req-mess set'}>
                {' '}
                {!openModal ? requestMessage.message : ''}
            </div>
            <SortableSettingsTable data={settingReducer} sorting={sorting} save={save} count={0} />
        </div>
    );
}

const mapStateToProps = ({ settingReducer, requestMessage }) => ({
    settingReducer,
    requestMessage,
});

const mapDispatchToProps = {
    getAllSettings,
    updateSetting,
    resetRequestMessage,
};
export default connect(mapStateToProps, mapDispatchToProps)(Setting);
