import React from 'react';
import { useTranslation } from 'react-i18next';
import { getDateFromIso } from '../../../../../../helpers/helpers/general';

function InfoStartDateKnock({ startDate }) {
    const { t } = useTranslation();
    const date = getDateFromIso(startDate);

    return (
        <div className="tour-info__info-row">
            <div className="tour-info__info-cell tour-info__info-cell--left">{t('Start')}</div>
            <div className="tour-info__info-cell tour-info__info-cell--right">
                <span> {`${date.time} | ${date.day} ${t(date.monthText)}`} </span>
            </div>
        </div>
    );
}

export default InfoStartDateKnock;
