import React, { useState, useEffect } from 'react';
import { getStoredPlayer, getTopSidePlayer } from '../../../helpers/game';
import ScoreBoardContainerGeneral from '../reusables/TableMidPart/ScoreBoard/ScoreBoardContainerGeneral';

function ScoreBoardContainerHR(props) {
    const {
        roomId,
        bet,
        till,
        score,
        davePower,
        moveStageScore,
        sceneData,
        scene,
        gameSettings,
        time,
        opponent,
    } = props;
    const [moveScoreData, setMoveScore] = useState({});
    const [matchScoreRows, setMatchScoreRows] = useState([]);
    const playerId = getStoredPlayer().id;
    const opponentId = opponent.id;
    const leftTotalScore = score[score.length - 1][playerId].totalScore;
    const rightTotalScore = score[score.length - 1][opponentId].totalScore;

    const { current } = davePower;
    const powerText = current > 1 ? `${current}x` : '';

    useEffect(() => {
        if (!!score.length) {
            const rows = score
                .slice(1)
                .reverse()
                .map((el) => ({ playerScore: el[playerId], opponentScore: el[opponentId] }));

            setMatchScoreRows(rows);
        }
    }, [score]);

    useEffect(() => {
        if (moveStageScore) {
            const { [playerId]: playerScore, [opponentId]: opponentScore } = moveStageScore;
            setMoveScore({ playerScore, opponentScore });
        } else {
            setMoveScore({});
        }
    }, [moveStageScore]);

    return (
        <ScoreBoardContainerGeneral
            {...{
                till,
                time,
                scene,
                sceneData,
                roomId,
                bet,
                leftTotalScore,
                rightTotalScore,
                gameSettings,
                moveScoreData,
                powerText,
                matchScoreRows,
            }}
        />
    );
}

export default ScoreBoardContainerHR;
