import { createReducer } from '../../helpers/general';
import { OTHER_TYPES } from '../actionTypes';

const { GET_TOTAL_PAGES_COUNT, OPEN_OR_CLOSE, GET_TOTAL_PAGES_COUNT_NEW } = OTHER_TYPES;

export const totalPagesReducer = createReducer(1, (state, { value }) => {
    return {
        [GET_TOTAL_PAGES_COUNT]: value && value.data ? value.data.pageCount : state,
        [GET_TOTAL_PAGES_COUNT_NEW]: value && value.pageCount ? value.pageCount : state,
    };
});
export const menuDropDownControlReducer = createReducer(false, (state, { value }) => {

    return {
        [OPEN_OR_CLOSE]: value !== undefined ? value : state,
    };
});
export default {
    totalPagesReducer,
    menuDropDownControlReducer,
};
