import { createReducer } from '../../helpers/general';
import { PLAYERS_TYPES } from '../actionTypes';

const { SET_PLAYERS, SET_BLACKLIST, UPDATE_BLACKLIST,SET_ONE_PLAYER_BLACKLIST } = PLAYERS_TYPES;

export const playerReducer = createReducer({}, (state, { value }) => {
    return {
        [SET_PLAYERS]: value ? value : state,
        [SET_BLACKLIST]: value,


    };
});
export const onePlayerBlacklistReducer = createReducer({}, (state, { value }) => {
    return {
        [SET_ONE_PLAYER_BLACKLIST]: value?value:state,
        [UPDATE_BLACKLIST]: value?value:state,

    };
});
export const blackListReducer = createReducer([], (state, { value }) => {
    return {
        [SET_BLACKLIST]: value ? value.data : state,

    };
});

export default {
    playerReducer,
    blackListReducer,
    onePlayerBlacklistReducer
};
