import {
    SETTINGS_CARD_APPEARANCE_IDS,
    SETTINGS_FIELD_APPEARANCE_IDS,
    NOTES_COLORS_IDS,
    SETTINGS_AVATAR_IDS,
    SETTINGS_LANGUAGE_IDS,
} from './ids';

const { CARD_BROWN, CARD_GREEN, CARD_PINK } = SETTINGS_CARD_APPEARANCE_IDS;
const { FIELD_BLUE, FIELD_BROWN, FIELD_GREEN } = SETTINGS_FIELD_APPEARANCE_IDS;
const { BLUE, GREEN, NONE, RED, YELLOW } = NOTES_COLORS_IDS;
const { ARM, ENG, RUS } = SETTINGS_LANGUAGE_IDS;
const {
    AVATAR_1,
    AVATAR_2,
    AVATAR_3,
    AVATAR_4,
    AVATAR_5,
    AVATAR_6,
    AVATAR_7,
    AVATAR_8,
    AVATAR_9,
    AVATAR_10,
    AVATAR_11,
    AVATAR_12,
    AVATAR_13,
    AVATAR_14,
    AVATAR_15,
    AVATAR_16,
    AVATAR_17,
    AVATAR_18,
    AVATAR_19,
    AVATAR_20,
} = SETTINGS_AVATAR_IDS;

export const NOTES_COLORS_CLASSES = {
    [NONE]: 'cancel',
    [GREEN]: 'green',
    [RED]: 'red',
    [YELLOW]: 'yellow',
    [BLUE]: 'blue',
};
export const GAME_CARDS_APPEARANCE_CLASSES = {
    [CARD_GREEN]: 'none',
    [CARD_BROWN]: 'card-field--brown',
    [CARD_PINK]: 'card-field--pink',
};
export const GAME_FIELD_APPEARANCE_CLASSES = {
    [FIELD_GREEN]: 'blot-field--green',
    [FIELD_BROWN]: 'blot-field--brown',
    [FIELD_BLUE]: '',
};

export const WINNER_LOSER_POPUP_SCORE_APPEARANCE_CLASSES = {
    [FIELD_BROWN]: 'game-status-popup__score--green',
    [FIELD_GREEN]: 'game-status-popup__score--gray',
    [FIELD_BLUE]: '',
};

export const GAME_POPUP_APPEARANCE_CLASSES = {
    [FIELD_GREEN]: 'waiting-2nd-player__content--green',
    [FIELD_BROWN]: 'waiting-2nd-player__content--brown',
    [FIELD_BLUE]: '',
};

export const SETTINGS_CARD_APPEARANCE_CLASSES = {
    [CARD_GREEN]: 'menu-card__btn-green',
    [CARD_BROWN]: 'menu-card__btn-brown',
    [CARD_PINK]: 'menu-card__btn-pink',
};
export const SETTINGS_FIELD_APPEARANCE_CLASSES = {
    [FIELD_GREEN]: 'menu-table__btn-green',
    [FIELD_BROWN]: 'menu-table__btn-yellow',
    [FIELD_BLUE]: 'menu-table__btn-blue',
};

export const SETTINGS_LANGUAGE_CLASSES = {
    [ARM]: 'menu-flag__armenia',
    [ENG]: 'menu-flag__britain',
    [RUS]: 'menu-flag__russia',
};

export const SETTINGS_AVATAR_CLASSES = {
    [AVATAR_1]: 'menu-avatar__slider-item__btn-avatar1',
    [AVATAR_2]: 'menu-avatar__slider-item__btn-avatar2',
    [AVATAR_3]: 'menu-avatar__slider-item__btn-avatar3',
    [AVATAR_4]: 'menu-avatar__slider-item__btn-avatar4',
    [AVATAR_5]: 'menu-avatar__slider-item__btn-avatar5',
    [AVATAR_6]: 'menu-avatar__slider-item__btn-avatar6',
    [AVATAR_7]: 'menu-avatar__slider-item__btn-avatar7',
    [AVATAR_8]: 'menu-avatar__slider-item__btn-avatar8',
    [AVATAR_9]: 'menu-avatar__slider-item__btn-avatar9',
    [AVATAR_10]: 'menu-avatar__slider-item__btn-avatar10',
    [AVATAR_11]: 'menu-avatar__slider-item__btn-avatar11',
    [AVATAR_12]: 'menu-avatar__slider-item__btn-avatar12',
    [AVATAR_13]: 'menu-avatar__slider-item__btn-avatar13',
    [AVATAR_14]: 'menu-avatar__slider-item__btn-avatar14',
    [AVATAR_15]: 'menu-avatar__slider-item__btn-avatar15',
    [AVATAR_16]: 'menu-avatar__slider-item__btn-avatar16',
    [AVATAR_17]: 'menu-avatar__slider-item__btn-avatar17',
    [AVATAR_18]: 'menu-avatar__slider-item__btn-avatar18',
    [AVATAR_19]: 'menu-avatar__slider-item__btn-avatar19',
    [AVATAR_20]: 'menu-avatar__slider-item__btn-avatar20',
};
