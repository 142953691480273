import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

import {getUserConfigs, getUserIpAddress} from './redux/actions/usersActions';
import {setIpAddress} from './redux/actions/generalAction';
import { getToken } from './configs/server';
import './App.css';
import SignIn from './pages/SignIn';
import MainPage from './pages/mainPage/MainPage';
import MainLoader from './components/loaders/MainLoader';
import { hideGlobalLoader } from './redux/actions/mainActions';
import {NoMatchPage} from "./pages/404/NotFound";
import moment from "moment-timezone";

const RestrictedRoute = ({ component: Component, authValid, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) =>
                authValid ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={{ pathname: '/auth', state: { from: props.location } }} />
                )
            }
        />
    );
};

function App({ getUserConfigs, globalLoader, hideGlobalLoader, authValid, location, setIpAddress,zone}) {
    useEffect(() => {
        const authToken = getToken();
        getUserIpAddress().then((ip)=>{
          setIpAddress(ip);
        }).catch((e)=>{
            console.log(e, "ipAddress-Error")
        });

        if (authToken) {
            getUserConfigs(authToken);
        } else {
            hideGlobalLoader();
        }
    }, []);

    if (location.pathname === '/') {
        return <Redirect to={'/auth'} />;
    }

    return !globalLoader ? (
        <div className="app">

            <Switch>
                <RestrictedRoute path="/admin" authValid={authValid} component={MainPage} />
                <Route path="/auth" component={SignIn} />
                <Route component={() => <NoMatchPage/>} />
            </Switch>
        </div>
    ) : (
        <MainLoader />
    );
}

const mapStateToProps = ({ globalLoaderReducer, authReducer  }) => ({
    globalLoader: globalLoaderReducer,
    authValid: authReducer,

});

const mapDispatchToProps = {
    getUserConfigs,
    hideGlobalLoader: () => hideGlobalLoader,
    setIpAddress

};

export default connect(mapStateToProps, mapDispatchToProps)(App);
