import { List, Icon } from 'semantic-ui-react';
import React from 'react';
import { cutPlayerFromTournament } from '../redux/actions/tournamentsAction';
import { connect } from 'react-redux';
import p from '../permissionsConstants';

const { unregistered } = p;

const PlayersList = ({ id: tournamentId, tournamentStates, ...redux }) => {
    const { cutPlayerFromTournament, adminAddedPlayers, permissions, dataReducer } = redux;
    const deletePlayer = (id) => {
        cutPlayerFromTournament({ playerId: id, tournamentId });
    };
    const isInclude = (player_id) => {
        const filter = dataReducer.filter((el) => el.player_id === player_id)[0];
        return tournamentStates.includes(filter['state']);
    };

    return (
        <List celled>
            {adminAddedPlayers.length ?
                adminAddedPlayers.map((item, key) => {
                    return (
                        <List.Item key={key}>
                            <List.Content className="own">
                                <List.Header>ID: {item.player_id}</List.Header>
                                {permissions && permissions[unregistered]
                                && !isInclude(item.player_id) &&
                                <div className="resource-edit pointer" onClick={() => deletePlayer(item.player_id)}>
                                    <Icon name="delete"/>
                                    Delete
                                </div>}
                            </List.Content>
                        </List.Item>
                    );
                })
                : null}

        </List>
    );
};

const mapStateToProps = ({ adminAddedPlayers, userReducer, tournamentTabsReducer }) => ({
    adminAddedPlayers,
    permissions: userReducer.permissions,
    dataReducer: tournamentTabsReducer,
});
const mapDispatchToProps = {
    cutPlayerFromTournament,
};
export default connect(mapStateToProps, mapDispatchToProps)(PlayersList);

