import React from 'react';
import { useTranslation } from 'react-i18next';
import { getCurrencySymbol } from '../../../../../../helpers/game';

function InfoBuyInKnock({ buyIn }) {
    const { t } = useTranslation();

    return (
        <div className="tour-info__info-row">
            <div className="tour-info__info-cell tour-info__info-cell--left">{t('Buy-in')}</div>
            <div className="tour-info__info-cell tour-info__info-cell--right">
                <span className="light-green">{getCurrencySymbol() + buyIn}</span>
            </div>
        </div>
    );
}

export default InfoBuyInKnock;
