import React, { useState, useRef, useEffect } from 'react';
import { setClass } from '../../../../../helpers/game';
import ThrownCard from './components/ThrownCard';
import PropTypes from 'prop-types';
import { DECK_CARDS_IDS } from '../../../../../constants/game/ids';
import { useTranslation } from 'react-i18next';
import { setGlobalRef } from '../../../../../redux/ducks/globalRefsDucks';
import { connect } from 'react-redux';

Deck.propTypes = {
    trumpCandidateCard: PropTypes.object,
};

Deck.defaultProps = {
    trumpCandidateCard: {},
};

function Deck({ playedCardsIds = [], trumpCandidateCard, cardAppearanceClassName, isCardVisible, ...redux }) {
    const [isThrownCardsBoardActive, setIsThrownCardsBoardActive] = useState(false);
    const { value, suit } = trumpCandidateCard;
    const { setGlobalRef } = redux;
    const { t } = useTranslation();
    const deckRef = useRef(null);

    useEffect(() => {
        setGlobalRef({ deckRef });
    }, [deckRef]);

    return (
        <div className={`blot-field__cards ${setClass(isThrownCardsBoardActive, 'blot-field__cards--throw-cards')}`}>
            <div className={`all-cards ${setClass(!isCardVisible, 'all-cards--with-button')}`}>
                <div className={`all-cards__item card-field `} ref={deckRef} />
                <div
                    className={`all-cards__item card-field card-field--${suit}-${value}`}
                    style={{ transform: isCardVisible && 'rotateY(180deg)', transition: suit ? '0.5s' : '0s' }}
                />
                {!!playedCardsIds.length && (
                    <button className="all-cards__all-cards" onClick={handleAllCardsClick}>
                        {t('All Cards')}
                    </button>
                )}
            </div>
            <div className="throw-cards">
                <button className="throw-cards__button" aria-label="close" onClick={handleThrownCardsBoardCloseClick} />
                <ul className="throw-cards__list">
                    {Object.values(DECK_CARDS_IDS).map((cardId) => (
                        <ThrownCard key={cardId} isMissing={playedCardsIds.includes(cardId)} cardId={cardId} />
                    ))}
                </ul>
            </div>
        </div>
    );

    function handleAllCardsClick() {
        setIsThrownCardsBoardActive(true);
    }

    function handleThrownCardsBoardCloseClick() {
        setIsThrownCardsBoardActive(false);
    }
}

const mapDispatchToProps = {
    setGlobalRef,
};

export default connect(null, mapDispatchToProps)(Deck);
