import React, { Component } from 'react';
import { Select, Input, Button, Modal, TextArea } from 'semantic-ui-react';
import { createSetting } from '../redux/actions/settingsAction';
import { connect } from 'react-redux';

import '@trendmicro/react-datepicker/dist/react-datepicker.css';
import _ from 'lodash';

class ModalExampleDimmer extends Component {
    state = {
        confKey: '',
        confType: '',
        confTypeList: [
            { key: '1', text: 'BOOLEAN', value: 'BOOLEAN' },
            { key: '2', text: 'NUMBER', value: 'NUMBER' },
            { key: '3', text: 'STRING', value: 'STRING' },
            { key: '4', text: 'JSON', value: 'JSON' },
        ],
        confValue: '',
        open: true,
        dimmer: 'blurring',
        confPath: 'SYSTEM',
        error: '',
        textAreaError: '',
    };

    isJson = (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return JSON.parse(str);
    };
    create = () => {
        const { confKey, confValue, confPath, confType, textAreaError } = this.state;
        let dataMustBe;
        let obj = null;
        if (confType !== '' && confKey !== '' && confValue !== '') {
            switch (confType) {
                case 'BOOLEAN': {
                    dataMustBe = ['true', 'false'];
                    if (dataMustBe.includes(confValue)) {
                        obj = {
                            confKey,
                            confType,
                            confPath,
                            confValue: Boolean(confValue),
                        };
                        this.setState({
                            textAreaError: '',
                        });
                    } else {
                        this.setState({
                            textAreaError: 'Please give correct value for choosen type',
                        });
                    }
                    break;
                }
                case 'NUMBER': {
                    if (_.isNumber(+confValue) && !isNaN(confValue)) {
                        obj = {
                            confKey,
                            confType,
                            confPath,
                            confValue,
                        };
                        if (textAreaError !== '') {
                            this.setState({
                                textAreaError: '',
                            });
                        }
                    } else {
                        this.setState({
                            textAreaError: 'Please give correct value for choosen type',
                        });
                    }
                    break;
                }
                case 'STRING': {
                    obj = {
                        confKey,
                        confType,
                        confPath,
                        confValue,
                    };
                    break;
                }
                case 'JSON': {
                    if (this.isJson(confValue) && _.isPlainObject(this.isJson(confValue))) {
                        obj = {
                            confKey,
                            confType,
                            confPath,
                            confValue: this.isJson(confValue),
                        };
                        if (textAreaError !== '') {
                            this.setState({
                                textAreaError: '',
                            });
                        }
                    } else {
                        this.setState({
                            textAreaError: 'Please give correct value for choosen type',
                        });
                    }
                    break;
                }
                default:
            }
            this.props.createSetting(obj);
        } else {
            this.setState({
                textAreaError: 'All fields are required!!',
            });
        }
    };

    getValue = (label, e) => {
        this.setState({
            [label]: e.target.value,
        });
    };
    getconfType = (e, data) => {
        this.setState({
            confType: data.value,
            error: '',
        });
    };

    changeTextArea = (e, data) => {
        const { confType } = this.state;
        if (!confType) {
            this.setState({
                error: 'You should select the type.',
            });
        } else {
            this.setState({
                confValue: data.value,
            });
        }
    };

    render() {
        const {
            open,
            dimmer,
            confKey,
            confValue,
            confTypeList,
            confPath,
            error,
            textAreaError,
        } = this.state;
        const { close, title, requestMessage } = this.props;

        return (
            <div>
                <Modal dimmer={dimmer} open={open} onClose={close}>
                    <Modal.Header>{title}</Modal.Header>
                    <Modal.Content>
                        <div className="input-box-setting">
                            <div className="margin-12">
                                <Input
                                    label="confKey"
                                    value={confKey}
                                    onChange={(e) => this.getValue('confKey', e)}
                                />
                            </div>
                            <div className="margin-12">
                                <Input label="confPath" value={confPath} disabled />
                            </div>
                            <div className="margin-12">
                                <div> ConfTypes:</div>
                                <Select
                                    selectOnBlur={false}
                                    placeholder="confType"
                                    options={confTypeList}
                                    onChange={this.getconfType}
                                />
                                <span className="error-mes acl">{error}</span>
                            </div>
                        </div>
                        <div className="check-box margin-12">
                            <div> ConfValue:</div>
                            <TextArea
                                value={confValue}
                                style={{ width: '100%' }}
                                onInput={this.changeTextArea}
                            />
                            <span className="error-mes acl">{textAreaError}</span>
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <div className={requestMessage.error ? 'req-mess err' : 'req-mess'}>
                            {' '}
                            {requestMessage.message}{' '}
                        </div>
                        <Button color="black" onClick={close}>
                            Cancel
                        </Button>
                        <Button
                            positive
                            icon="checkmark"
                            labelPosition="right"
                            content="Create"
                            onClick={this.create}
                        />
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = ({ requestMessage }) => ({
    requestMessage,
});

const mapDispatchToProps = {
    createSetting,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalExampleDimmer);
