import { createAction, createReducer } from '../../helpers/helpers/redux';

const SET_GLOBAL_REF = 'SET_GLOBAL_REF';
const REMOVE_GLOBAL_REF = 'REMOVE_GLOBAL_REF';

export const setGlobalRef = createAction(SET_GLOBAL_REF);
export const removeGlobalRef = createAction(REMOVE_GLOBAL_REF);

const initialState = {
    blotFieldRef: {},
    wrapperRef: {},
    forCenterGameRef: {},
    deckRef: {},
    headerRef: {},
};

export const globalRefs = createReducer(initialState, (state, { value }) => ({
    [SET_GLOBAL_REF]: () => ({ ...state, ...value }),
}));

export default  {
    globalRefs,
};
