import './game.css';
import React, {useEffect, useState} from 'react';
import SortableGamesTable from '../../components/SortableGamesTable';
import {Checkbox, Confirm, Loader, Pagination, Select} from 'semantic-ui-react';
import {getAllGames, killOneGame, resetGameTable} from '../../redux/actions/gamesAction';
import {resetRequestMessage} from '../../redux/actions/otherActions';
import {connect} from 'react-redux';
import Filter from '../../components/Filter';
import moment from "moment";
import TimeZone from "../../components/TimeZone";

const dateFilter = [
    {key: '1', text: 'Last 24 hours', value: 'Last 24 hours'},
    {key: '7', text: 'Last 7 days', value: 'Last 7 days'},
    {key: '30', text: 'Last 30 days', value: 'Last 30 days'},
    {key: '90', text: 'Last 90 days', value: 'Last 90 days'},
    // {key: '4', text: ' Custom Range', value: '4'},
];
const pickedData = {
    'Last 24 hours': 1,
    'Last 7 days': 7,
    'Last 30 days': 30,
    'Last 90 days': 90
};
const rowItems = [
    // {key: '10', text: '10 rows', value: '10'},
    // {key: '30', text: '20 rows', value: '20'},
    {key: '50', text: '50 rows', value: '50'},
    {key: '100', text: '100 rows', value: '100'},
];

function Game({getAllGames, ...redux}) {
    const {
        gameReducer, totalPagesReducer, requestMessage, resetRequestMessage, killOneGame, resetGameTable,
        toggleType,loader
    } = redux;
    const [sortedField, setSortedField] = useState('');
    const [direction, setDirection] = useState('DESC');
    const [activePage, setActivePage] = useState(1);
    const [startTime, setStartTime] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [timeTo, setTimeTo] = useState(null);
    const [rowCount, setRowCount] = useState(0);
    const [limit, setLimit] = useState(50);
    const [offset, setOffset] = useState(0);
    const [checked, setChecked] = useState(false);
    const [confirmModal, setOpenConfirmModal] = useState(false);
    const [killedTableId, setKilledTableId] = useState(null);
    const [filerDateForSorting, setFilerDateForSorting] = useState({});
    const [pickedDate, setPickedDate] = useState(null);
    const [selected, setSelected] = useState(false);
    const [IsFilter, setIsFilter] = useState(false);

    useEffect(() => {
        getAllGames({
            dateFrom: moment().format('DD-MM-YYYY') + ' ' + moment().format('00:00:00'),
            dateTo: moment().format('DD-MM-YYYY') + ' ' + moment().format('HH:mm:ss'),
            limit, offset: 0, pickedDate: '', priority: 'complete'
        });
        return (() => {
            resetGameTable();
        })
    }, []);
    useEffect(() => {
        let obj = {};
        if (checked && !IsFilter) {
            obj = {
                ...obj,
                dateFrom: '',
                dateTo: '',
                limit, offset: 0, pickedDate: '', priority: 'new'
            }
        } else if (!pickedDate ) {
            obj = {
                ...obj,
                dateFrom: moment().format('DD-MM-YYYY') + ' ' + moment().format('00:00:00'),
                dateTo: moment().format('DD-MM-YYYY') + ' ' + moment().format('HH:mm:ss'),
                ...filerDateForSorting,
                limit, offset: 0, pickedDate: '', priority: 'complete'
            }
        } else if (pickedDate === 'closed' ) {
            obj = {
                ...obj,
                dateFrom: startDate +' ' + startTime,
                dateTo: startDate +' ' + timeTo,
                limit, offset: 0, pickedDate: '', priority: 'complete'
            }
        } else {
            obj = {
                ...obj,
                dateFrom: moment().format('DD-MM-YYYY') + ' ' + moment().format('00:00:00'),
                dateTo: moment().format('DD-MM-YYYY') + ' ' + moment().format('HH:mm:ss'),
                ...filerDateForSorting,
                limit, offset: 0, pickedDate: pickedDate ? pickedData[pickedDate] : '', priority: 'complete'
            };
        }
        getAllGames(obj);

    }, [checked, pickedDate]);

    useEffect(() => {
        let timeoutId;
        if (!!requestMessage && !requestMessage.error) {
            setActivePage(1);
            setRowCount(0);
            getAllGames({
                dateFrom: '',
                dateTo: '',
                limit: 50, offset: 0, pickedDate: '', priority: 'new'
            });
        }
        timeoutId = setTimeout(() => {
            resetRequestMessage('');

        }, 1500);

        return () => {
            clearTimeout(timeoutId);

        };
    }, [requestMessage]);

    const onPageChange = (e, data) => {
        if (checked) {
            filerDateForSorting['dateFrom'] = '';
            filerDateForSorting['dateTo'] = '';
        }
        getAllGames({
            dateFrom: '',
            dateTo: '',
            offset: (data.activePage - 1) * limit,
            orderBy: sortedField,
            order: direction,
            ...filerDateForSorting,
            limit,
            pickedDate: checked ? '' : pickedData[pickedDate],
            priority: checked ? 'new' : 'complete'
        });
        setOffset((data.activePage - 1) * limit);
        setActivePage(data.activePage);
        setRowCount(() => data.activePage * limit - limit);
    };

    const checkboxHandle = () => {
        setChecked(!checked);
        setSelected(false);
        setIsFilter(false);
        setStartDate(moment().format("DD-MM-YYYY"));
        setStartTime(moment().format("00:00:00"));
        setTimeTo(moment().format("HH:mm:ss"));
        setActivePage(1);
        setRowCount(0);
    };

    const sorting = (field, direction) => {
        if (checked) {
            filerDateForSorting['dateFrom'] = '';
            filerDateForSorting['dateTo'] = '';
        }
        //  console.log({...filerDateForSorting}, "filerDateForSorting")
        getAllGames({
            orderBy: field,
            order: direction,
            ...filerDateForSorting,
            limit,
            pickedDate: checked ? '' : pickedData[pickedDate],
            offset: 0,
            priority: checked ? 'new' : 'complete'
        }, true);
        setSortedField(field);
        setActivePage(1);
        setDirection(direction);
        setRowCount(0);
    };
    const filterGames = (obj) => {
        setIsFilter(true);
        setSelected(false);
        if (!!requestMessage) resetRequestMessage('');
        setFilerDateForSorting(obj);
        getAllGames({...obj, limit, offset: 0, pickedDate: '', priority: 'complete'});
        setPickedDate('');
        setActivePage(1);
        setRowCount(0);
        setChecked(false)

    };
    const kill = () => {
        setOpenConfirmModal(false);
        killOneGame({id: killedTableId})
    };
    const openModal = (id) => {
        setOpenConfirmModal(true);
        setKilledTableId(id);
    };
    const select = (e, data) => {
        if (!!requestMessage) resetRequestMessage('');
        if (data.value) {
            setChecked(false);
            setSelected(true);
            setIsFilter(true);
            let startDate = moment().subtract(pickedData[data.value], "days").format("DD-MM-YYYY");
            let startTime = moment().format("HH:mm:ss");
            setStartDate(startDate);
            setStartTime(startTime);
            setTimeTo(moment().format("HH:mm:ss"));
            setPickedDate(data.value);
            setFilerDateForSorting(obj => ({...obj, dateFrom: '', dateTo: ''}));
            setActivePage(1);
            setRowCount(0);
            setOffset(0);
        } else {
            setPickedDate('closed');
            setStartDate(moment().format("DD-MM-YYYY"));
            setStartTime(moment().format("00:00:00"));
            setTimeTo(moment().format("HH:mm:ss"));
            setSelected(false);
        }
    };
    const reset = () => {
        //resetGameTable();
        setSelected(false);
        setChecked(false);
        setIsFilter(false);
        setPickedDate('');
        setFilerDateForSorting({});
    };

    const getRowCount = (e, data) => {
        let activePage = Math.floor(offset / limit) + 1;
        setLimit(+data.value);
        if (checked) {
            filerDateForSorting['dateFrom'] = '';
            filerDateForSorting['dateTo'] = '';
        }
        getAllGames({
            pickedDate: checked ? '' : pickedData[pickedDate],
            limit: +data.value,
            offset: data.value > rowCount ? 0 : +data.value,
            ...filerDateForSorting,
            priority: checked ? 'new' : 'complete'
        });
        setRowCount(0);
        setActivePage(activePage);
    };

    return (
        <div className="game-content">
            {confirmModal ?
                <Confirm
                    className={'own-confirm'}
                    open={confirmModal}
                    content='Are you sure, you want to delete this game?'
                    onCancel={() => setOpenConfirmModal(false)}
                    onConfirm={kill}
                    confirmButton={'Delete'}
                /> : null}
            <div className="page-title">
                <h2>Games List</h2>
                <TimeZone/>
            </div>
            <div className="filter-div game_">
                <Filter filterGames={filterGames} reset={reset} startDate={startDate} startTime={startTime}
                        timeTo={timeTo}/>
                <div className="margin-l">
                    <div className="label">Custom Filter:</div>
                    {/*{!selected ? <>*/}
                    {/*       */}
                    {/*        <Select*/}
                    {/*            clearable={true}*/}
                    {/*            selectOnBlur={false}*/}
                    {/*                  placeholder={'Not Selected'} options={dateFilter}*/}
                    {/*                onChange={(e, data) => select(e, data)}/></>*/}
                    {/*    :*/}
                    {/*    <Select*/}
                    {/*        clearable={true} selectOnBlur={false}*/}
                    {/*             placeholder={pickedDate} options={dateFilter} onChange={(e, data) => select(e, data)}/>*/}
                    {/*}*/}
                    <Select
                        clearable={true} selectOnBlur={false}
                        placeholder={'Not Selected'} options={dateFilter} onChange={(e, data) => select(e, data)}/>
                </div>
                <div className="margin-l live">
                    <div className="label"> Live</div>
                    <Checkbox
                        toggle
                        checked={checked}
                        onChange={checkboxHandle}
                    />
                </div>

            </div>

            <div className={requestMessage.error ? 'req-mess err' : 'req-mess'}>
                {requestMessage.message}
            </div>
            {
                gameReducer.length ?
                    <>
                        {!loader?
                            <>
                        <div className="row-count">
                            <div className="label"> Rows count per page</div>
                            <div>
                                <Select
                                    selectOnBlur={false}
                                    className="own"
                                    placeholder={`${limit} rows`}
                                    options={rowItems}
                                    onChange={getRowCount}
                                />
                            </div>
                        </div>

                        <div className='table-and-pg'>
                            <SortableGamesTable toggleType={toggleType} data={gameReducer} sorting={sorting}
                                                count={rowCount} kill={openModal}
                                                liveStatus={checked}/>
                            <Pagination
                                activePage={activePage}
                                totalPages={totalPagesReducer}
                                className="pagi-own"
                                onPageChange={totalPagesReducer > 1 ? onPageChange : null}
                            />
                        </div>
                            </>:<Loader active className={'db_loader'}> Loading... </Loader>}
                    </>
                    :
                    <div> No data</div>
            }
        </div>
    );
}

const mapStateToProps = ({gameReducer, totalPagesReducer, requestMessage, globalPlayerReducer, loaderReducer}) => ({
    gameReducer,
    totalPagesReducer,
    requestMessage,
    toggleType: globalPlayerReducer,
    loader: loaderReducer,

});

const mapDispatchToProps = {
    getAllGames,
    resetRequestMessage,
    killOneGame,
    resetGameTable
};

export default connect(mapStateToProps, mapDispatchToProps)(Game);
