import React from 'react';
import { useTranslation } from 'react-i18next';

function InfoTimerKnock({ time }) {
    const { t } = useTranslation();

    return (
        <div className="tour-info__info-row">
            <div className="tour-info__info-cell tour-info__info-cell--left">{t('Timer')}</div>
            <div className="tour-info__info-cell tour-info__info-cell--right">
                <span>
                    {time} {t('Sec')}
                </span>
            </div>
        </div>
    );
}

export default InfoTimerKnock;
