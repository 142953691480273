import {createReducer} from '../../helpers/general';
import {GENERAL_TYPE} from '../actionTypes';

const {SET_ALL_DATA, SET_IP_ADDRESS, SET_DIFF_TIME, SET_GLOBAL_PLAYER} = GENERAL_TYPE;

const data = {
    games: [],
    tournament: [],
    spin: []
};

export const generalReducer = createReducer(data, (state, {value}) => {
    return {
        [SET_ALL_DATA]: value ? {...state, ...value} : state,
    };
});

export const userIpAddressReducer = createReducer('', (state, {value}) => {
    return {
        [SET_IP_ADDRESS]: value ? value : state,
    };
});
export const diffTimeReducer = createReducer(0, (state, {value}) => {
    return {
        [SET_DIFF_TIME]: value ? value : state,
    };
});
export const globalPlayerReducer = createReducer('', (state, {value}) => {
    return {
        [SET_GLOBAL_PLAYER]: value ? value : state,
    };
});

export default {
    generalReducer,
    userIpAddressReducer,
    diffTimeReducer,
    globalPlayerReducer
};
