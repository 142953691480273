import { createReducer } from '../../helpers/general';
import { SPIN_TYPES} from '../actionTypes';

const {SET_ALL_SPINS, SET_ONE_SPINS, SET_SPIN_PRIZE, SET_ALL_SPINS_RULES,
    SET_ONE_SPIN_RULE,SET_ONE_SPIN_GAME_TYPE} = SPIN_TYPES;


export const allSpinsReducer = createReducer([], (state, { value }) => {
    return {
        [SET_ALL_SPINS]: value?value:state,

    };
});
export const allSpinsRulesReducer = createReducer([], (state, { value }) => {
    return {

        [SET_ALL_SPINS_RULES]: value?value:state,
    };
});
export const oneSpinReducer = createReducer([], (state, { value }) => {
    return {
        [SET_ONE_SPINS]: value?value:state,
    };
});
export const oneSpinRuleReducer = createReducer([], (state, { value }) => {
    return {
        [SET_ONE_SPIN_RULE]: value?value:state,
    };
});
export const oneSpinPrizeReducer = createReducer([], (state, { value }) => {
    return {
        [SET_SPIN_PRIZE]: value?value:state,
    };
});
export const oneSpinGameTypeReducer = createReducer({}, (state, { value }) => {
    return {
        [SET_ONE_SPIN_GAME_TYPE]: value?value:state,
    };
});

export default {
    allSpinsReducer,
    oneSpinReducer,
    oneSpinPrizeReducer,
    allSpinsRulesReducer,
    oneSpinRuleReducer,
    oneSpinGameTypeReducer

};
