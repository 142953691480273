import './currentPlayer.css';
import React, {Suspense, useEffect, useState} from 'react';
import {
    updatePlayerBlackList,
    getRoundsForPlayer, getPlayerBlackList, getOnePlayerBlackListInfo,
} from '../../redux/actions/playersActions';
import {resetRequestMessage} from '../../redux/actions/otherActions';
import {Button, Icon, Table} from 'semantic-ui-react';
import {connect} from 'react-redux';
import PlayerTabs from "../../components/PlayerTabs";

import HocModal from "../../components/HOC"
import p from "../../permissionsConstants";
import PlayerSettings from "../../components/PlayerSettings";
import TimeZone from "../../components/TimeZone";


const Blacklist = React.lazy(() => import("../../components/BlackList"));

const {winRate, cash, tournamentPlayers, tournamentWin, buyIn, rake, showBlackListControlToggles} = p;


const CurrentPlayer = ({
                           match,
                           getPlayerBlackList,
                           blackListReducer,
                           currentBlacklist,
                           permissions,
                           getOnePlayerBlackListInfo,
                           toggle,
                           getRoundsForPlayer
                       }) => {

    const [showPlayerSettingsModal, setShowPlayerSettingsModal] = useState('');
    const [id, setId] = useState(null);
    const [toggleType, setToggleType] = useState(toggle === 'Ajarabet');


    useEffect(() => {
        getPlayerBlackList({id: match.params.id, toggleType});
        getOnePlayerBlackListInfo({id: match.params.id, toggleType});
        setId(+match.params.id);
        setToggleType(toggle === 'Ajarabet');
    }, [match.params.id]);


    const openPlayerSettings = () => {
        setShowPlayerSettingsModal(true);
    };

    const close = () => {
        //  if (!!requestMessage) resetRequestMessage('');
        setShowPlayerSettingsModal(false);
        getRoundsForPlayer({playerId: +match.params.id, limit: 50, offset: 0, toggleType});
        getPlayerBlackList({id: match.params.id, toggleType});
        getOnePlayerBlackListInfo({id: match.params.id, toggleType})
    };
    const PlayerSettingsModal = HocModal(PlayerSettings, {
        title: 'Player Settings',
        close,
        id: match.params.id,
        name: ''
    });

    return (
        <div className="player-info-content">
            <div className="page-title">
                <h2> Current Player ID - {match.params.id}</h2>
                {permissions && permissions[showBlackListControlToggles] ?
                <span style={{color: 'white'}}>Player Settings:
                <Button circular icon='settings' size={"large"} onClick={openPlayerSettings}
                        style={{backgroundColor: 'transparent'}}/>
                </span>:null}
                <TimeZone/>
            </div>
            {showPlayerSettingsModal ?
                <PlayerSettingsModal/> : null
            }

            <Table sortable celled fixed size="small">
                <Table.Header>
                    <Table.Row>
                        <Table.Cell>
                            <h5>Player info </h5>
                        </Table.Cell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>

                        <Table.Row>
                            <Table.Cell>Player
                                ID: &nbsp;</Table.Cell>
                            {toggle === 'Ajarabet' ?
                                <Table.Cell>{blackListReducer[0] && blackListReducer[0].remote_id}</Table.Cell>:
                                <Table.Cell>{blackListReducer[0] && blackListReducer[0].player_id}</Table.Cell>}
                        </Table.Row>
                                <Table.Row>
                        <Table.Cell>Registration
                            Date: &nbsp;</Table.Cell><Table.Cell>{blackListReducer[0] && blackListReducer[0].created_at}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Last Login
                            date: &nbsp;</Table.Cell><Table.Cell>{blackListReducer[0] && blackListReducer[0].lastseen_at}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Amount of Logins:
                            &nbsp;</Table.Cell><Table.Cell>{blackListReducer[0] && blackListReducer[0].stats_visits}</Table.Cell>
                    </Table.Row>
                    {permissions && permissions[winRate] ?
                        <Table.Row>
                            <Table.Cell>Win
                                Rate: &nbsp;</Table.Cell><Table.Cell>{blackListReducer[0] && blackListReducer[0].stats_winrate}</Table.Cell>
                        </Table.Row> : null}
                    {permissions && permissions[cash] ?
                        <Table.Row>
                            <Table.Cell>Cash Games
                                Played: &nbsp;</Table.Cell><Table.Cell>{blackListReducer[0] && blackListReducer[0].played_cash}</Table.Cell>
                        </Table.Row> : null}
                    {permissions && permissions[tournamentPlayers] ?
                        <Table.Row>
                            <Table.Cell>Tournaments
                                Played: &nbsp;</Table.Cell><Table.Cell>{blackListReducer[0] && blackListReducer[0].played_tournaments}</Table.Cell>
                        </Table.Row> : null}
                    <Table.Row>
                        <Table.Cell>Label: &nbsp;</Table.Cell><Table.Cell>
                        <Icon
                            color={blackListReducer[0] && blackListReducer[0].label ? blackListReducer[0].label : 'grey'}
                            name='star'
                            className={'unset'}/></Table.Cell>
                    </Table.Row>
                    {permissions && permissions[buyIn] ?
                        <Table.Row>
                            <Table.Cell>Buy In: &nbsp;</Table.Cell>
                            <Table.Cell>{blackListReducer[0] && Number(blackListReducer[0].buyin)}</Table.Cell>
                        </Table.Row> : null}
                    {permissions && permissions[tournamentWin] ?
                        <Table.Row>
                            <Table.Cell>Win Amount: &nbsp;</Table.Cell>
                            <Table.Cell>{blackListReducer[0] && Number(blackListReducer[0].tournament_win)}</Table.Cell>
                        </Table.Row> : null}
                    {permissions && permissions[rake] ?
                        <Table.Row>
                            <Table.Cell>Rake: &nbsp;</Table.Cell><Table.Cell>{blackListReducer[0] && Number(blackListReducer[0].rake)}</Table.Cell>
                        </Table.Row> : null}
                    <Table.Row>
                        <Table.Cell>Currency: &nbsp;</Table.Cell><Table.Cell>{blackListReducer[0] && blackListReducer[0].currency}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Language: &nbsp;</Table.Cell><Table.Cell>{blackListReducer[0] && blackListReducer[0].locale}</Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
            <br/>
            <Table sortable celled fixed size="small" className={'blacklist'}>
                <Table.Header>
                    <Table.Row>
                        <Table.Cell>
                            <h5>Already in this blacklists </h5>
                        </Table.Cell>
                    </Table.Row>
                </Table.Header>
                <Suspense fallback={<Table.Body><Table.Row>
                    <Table.Cell>Loading ...</Table.Cell>
                </Table.Row></Table.Body>}>
                    <Blacklist currentBlacklist={currentBlacklist}/>
                </Suspense>
            </Table>
            <PlayerTabs id={+match.params.id}/>
        </div>
    );
};

const mapStateToProps = ({userReducer, blackListReducer, onePlayerBlacklistReducer, globalPlayerReducer}) => ({
    blackListReducer,
    permissions: userReducer.permissions,
    currentBlacklist: Object.keys(onePlayerBlacklistReducer),
    toggle: globalPlayerReducer
});

const mapDispatchToProps = {
    resetRequestMessage,
    updatePlayerBlackList,
    getPlayerBlackList,
    getOnePlayerBlackListInfo,
    getRoundsForPlayer

};

export default connect(mapStateToProps, mapDispatchToProps)(CurrentPlayer);
