import './tournament.css';
import React, {useEffect, useState} from 'react';
import {Button, Pagination, Dropdown, Select, Loader, Confirm} from 'semantic-ui-react';
import {
    openTournament,
    getAllTournaments,
    deleteTournament,
} from '../../redux/actions/tournamentsAction';
import {resetRequestMessage} from '../../redux/actions/otherActions';
import {connect} from 'react-redux';
import SortableTournamentsTable from '../../components/SortableTournamentsTable';
import CreateTournament from '../../components/CreateTournament';
import ModalForSearchingTournament from '../../components/SearchingModal';
import ModalComponentContent from '../../components/ModalComponentContent';
import p from "../../permissionsConstants";
import TimeZone from "../../components/TimeZone";
import FilterTournament from "../../components/FilterTournament";

const {tournamentCreateBtn} = p;


const rowItems = [
    // {key: '10', text: '10 rows', value: '10'},
    // {key: '30', text: '20 rows', value: '20'},
    {key: '50', text: '50 rows', value: '50'},
    {key: '100', text: '100 rows', value: '100'},
];

function Tournament({...redux}) {
    const {
        tournamentReducer,
        totalPagesReducer,
        getAllTournaments,
        requestMessage,
        resetRequestMessage,
        openTournament,
        deleteTournament,
        permissions,
        loader,
    } = redux;
    const columns = [
        {key: 1, value: 'id', text: 'id'},
        {key: 2, value: 'name', text: 'Name'},
        {key: 3, value: 'createdAt', text: 'Created At'},
        {key: 4, value: 'startDate', text: 'Start Date'},
        {key: 5, value: 'announceDate', text: 'Announc. Date'},
        {key: 6, value: 'playerRegDate', text: 'Player Reg. Date'},
        {key: 13, value: 'finished_at', text: 'End Date'},
        {key: 7, value: 'gameType', text: 'Game KInd'},
        {key: 8, value: 'state', text: 'Status'},
        {key: 9, value: 'players', text: 'All Reg. Players (min/max)'},
        {key: 10, value: 'buyIn', text: 'BuyIn'},
        {key: 11, value: 'prizePool', text: 'PrizePool'},
        {key: 12, value: 'priorityInLobby', text: 'Priority In Lobby'},
    ];
    const allColumns = [
        'id',
        'name',
        'createdAt',
        'startDate',
        'announceDate',
        'playerRegDate',
        'finished_at',
        'gameType',
        'state',
        'players',
        'buyIn',
        'prizePool',
        'priorityInLobby',
    ];
    const [sortedField, setSortedField] = useState('');
    const [colArr, setColArr] = useState(columns);
    const [colsName, setColsName] = useState([]);
    const [direction, setDirection] = useState('DESC');
    const [activePage, setActivePage] = useState('');
    const [rowCount, setRowCount] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const [type, setType] = useState('');
    const [openUniversalModal, setOpenUniversalModal] = useState(false);
    const [componentName, setComponentName] = useState(false);
    const [confirmModal, setOpenConfirmModal] = useState(false);
    const [openSearchModal, setOpenSearchModal] = useState(false);
    const [filerDateForSorting, setFilerDateForSorting] = useState({});
    const [limit, setLimit] = useState(50);
    const [offset, setOffset] = useState(0);
    const [id, setId] = useState(null);


    useEffect(() => {
        let setTimeOutId;
        const colNames = [];
        if (!openSearchModal && !openModal) {
            for (let el of colArr) {
                colNames.push(el.value);
            }
            setColsName(colNames);
            getAllTournaments({limit, offset, columnsToBeShown: colNames, ...filerDateForSorting});
        } else {
            if (!requestMessage) {
                setOpenSearchModal(false);
            }
        }
        if (requestMessage && !requestMessage.error) {
            setTimeOutId = setTimeout(() => {
                closeCreateModal();
                getAllTournaments({limit, offset, columnsToBeShown: allColumns, ...filerDateForSorting});
                resetRequestMessage('');
                if (openSearchModal) {
                    setOpenSearchModal(false);
                }
                if (openUniversalModal) {
                    setOpenUniversalModal(false);
                }
            }, 1000);
        }
        return () => clearTimeout(setTimeOutId);
    }, [requestMessage, limit]);

    useEffect(() => {
        if (openSearchModal) {
            setOpenSearchModal(false);
        }
        if (openModal) {
            setOpenModal(false);
        }
    }, [tournamentReducer]);

    const getRowCount = (e, data) => {
        let activePage = Math.floor(offset / limit) + 1;
        setLimit(+data.value);
        setOffset(data.value > rowCount ? 0 : +data.value);
        setRowCount(0);
        setActivePage(activePage);
    };
    const onPageChange = (e, data) => {
        getAllTournaments({
            offset: (data.activePage - 1) * limit,
            orderBy: sortedField,
            order: direction,
            ...filerDateForSorting,
            limit,
            columnsToBeShown: colsName,
        });
        setOffset((data.activePage - 1) * limit);
        setActivePage(data.activePage);
        setRowCount(() => data.activePage * limit - limit);
    };

    const handleOpenModal = (modalType) => {
        if (!!requestMessage) resetRequestMessage('');
        modalType === 'create' ? setOpenModal(true) : setOpenSearchModal(true);
    };
    const closeCreateModal = () => {
        if (!!requestMessage) resetRequestMessage('');
        setOpenModal(false);
    };
    const closeSearchModal = () => {
        //  if (!!requestMessage) resetRequestMessage('');
        setOpenSearchModal(false);
    };
    const close = () => {
        if (!!requestMessage) resetRequestMessage('');
        setOpenUniversalModal(false);
    };
    const tableCols = (e, data) => {
        if (data.value.length) {
            let d;
            d = data.value.map((el) => {
                return columns.filter((item) => {
                    return item.value + '' === el + '';
                });
            });
            setColArr([{key: 1, value: 'id', text: 'id'}, ...d.flat(Infinity)]);
            setColsName([...data.value]);
            getAllTournaments({limit, offset, columnsToBeShown: ['id', ...data.value]});
        } else {
            const colNames = [];
            for (let el of columns) {
                colNames.push(el.value);
            }
            setColArr(columns);
            getAllTournaments({limit, offset, columnsToBeShown: colNames});

        }
    };
    const search = (data) => {
         setFilerDateForSorting(data);
         getAllTournaments({limit, offset, columnsToBeShown: allColumns, ...data});
         // setOpenSearchModal(false);
    };

    const action = (type, id) => {
        if (type === 'Edit') {
            openTournament({id});
            setOpenUniversalModal(true);
            setType(type);
            setComponentName('CreateTournament');
        } else if (type === 'Copy') {
            openTournament({id});
            setOpenUniversalModal(true);
            setType(type);
            setComponentName('CreateTournament');
        } else if (type === 'Delete') {
            setId(id);
            setOpenConfirmModal(true);
        } else if (type === 'Add') {
            if (!!requestMessage && requestMessage.error) {
                resetRequestMessage('');
            }
            setOpenUniversalModal(true);
            setType(type);
            setComponentName('AddPlayersToTournament');
            setId(id);
        }
    };

    const deleteTable = () => {
        deleteTournament({id});
        setOpenConfirmModal(false);
    };
    const resetAll = () => {
        getAllTournaments({limit:50, offset:0, columnsToBeShown: allColumns });
        };

    const sorting = (field, direction) => {
        getAllTournaments({
            orderBy: field,
            order: direction,
            ...filerDateForSorting,
            limit,
            offset: 0,
            columnsToBeShown: colsName,
        });
        setSortedField(field);
        setActivePage(1);
        setDirection(direction);
        setRowCount(0);
    };

    return (
        <div className="tournament-content">

            {confirmModal ?
                <Confirm
                    className={'own-confirm'}
                    open={confirmModal}
                    content='Are you sure, you want to delete this game?'
                    onCancel={() => setOpenConfirmModal(false)}
                    onConfirm={deleteTable}
                    confirmButton={'Delete'}
                /> : null}
            <div className="page-title min-width">
                {openUniversalModal && (
                    <ModalComponentContent
                        componentName={componentName}
                        title={type}
                        close={close}
                        id={id}
                        name={'tournament'}
                    />
                )}

                <h2>Tournaments List</h2>

                {!openUniversalModal ?
                    <div className={requestMessage && requestMessage.error ? 'req-mess err set' : 'req-mess set'}>
                        {requestMessage && requestMessage.message}
                    </div> : null}
                <div className='action-buttons'>
                    {/*<Button*/}
                    {/*    primary*/}
                    {/*    className="create-user btn belote"*/}
                    {/*    onClick={() => handleOpenModal('search')}*/}
                    {/*>*/}
                    {/*    Search*/}
                    {/*</Button>*/}
                    {permissions && permissions[tournamentCreateBtn] ?
                        <Button
                            primary
                            className="create-user btn belote-green"
                            onClick={() => handleOpenModal('create')}
                        >
                            Create Tournament
                        </Button> : null}
                </div>
                <TimeZone/>
            </div>
            {openModal && <CreateTournament close={closeCreateModal} title="Create Tournament"/>}
            {/*{openSearchModal && (*/}
            {/*    <ModalForSearchingTournament*/}
            {/*        close={closeSearchModal}*/}
            {/*        title="Search Tournament"*/}
            {/*        makeSearch={search}*/}
            {/*    />*/}
            {/*)}*/}
            <FilterTournament
                close={closeSearchModal}
                title="Search Tournament"
                makeSearch={search}
                reset={resetAll}

            />

            {!loader ? tournamentReducer.length ? (
                <>
                    <Dropdown
                        className="margin-12-top"
                        placeholder="Filter"
                        fluid
                        multiple
                        selection
                        options={columns.filter((el) => el.value !== 'id')}
                        onChange={tableCols}
                    />
                    <div className="row-count">
                        <div className="label">Rows count per page</div>
                        <div>
                            <Select
                                selectOnBlur={false}
                                className="own"
                                placeholder={`${limit} rows`}
                                options={rowItems}
                                onChange={getRowCount}
                            />
                        </div>
                    </div>
                    <div className='table-and-pg'>
                        <SortableTournamentsTable
                            data={tournamentReducer}
                            cols={colArr}
                            action={action}
                            sorting={sorting}
                            count={rowCount}
                        />
                        <Pagination
                            activePage={activePage}
                            totalPages={totalPagesReducer}
                            className="pagi-own"
                            onPageChange={totalPagesReducer > 1 ? onPageChange : null}
                        />
                    </div>
                </>
            ) : (
                <div className="error-mes date-err">No data was found</div>
            ) : <Loader active> Loading tournament list ... </Loader>}
        </div>
    );
}

const mapStateToProps = ({userReducer, tournamentReducer, totalPagesReducer, requestMessage, loaderReducer}) => ({
    tournamentReducer,
    totalPagesReducer,
    requestMessage,
    permissions: userReducer.permissions,
    loader: loaderReducer,
});

const mapDispatchToProps = {
    getAllTournaments,
    resetRequestMessage,
    openTournament,
    deleteTournament,

};

export default connect(mapStateToProps, mapDispatchToProps)(Tournament);
