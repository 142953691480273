import React, {Component} from 'react';
import {Input, Button} from 'semantic-ui-react';
import {connect} from 'react-redux';
import '@trendmicro/react-datepicker/dist/react-datepicker.css';
import '../createTournament/createTournament.css';
import {addPlayerToTournament} from '../../../redux/actions/tournamentsAction';
import {resetRequestMessage} from '../../../redux/actions/otherActions';
import p from "../../../permissionsConstants";

const {add, addUpload} = p;


class AddPlayersToTournament extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            file: '',
            error: '',
        };
        this.fileInputRef = React.createRef();
    }

    getId = (e) => {
        this.setState({id: e.target.value});
    };
    onChange = (e) => {
        this.setState({file: e.target.files[0], error: ''});
    };
    action = (type, id) => {
        const {requestMessage, resetRequestMessage} = this.props;

        if (type === 'uploadPlayers') {

            if (!!requestMessage) resetRequestMessage('');

            const extensionArr = ['xlsx', 'xlsm ', 'xlt', 'xls'];
            let file = this.state.file;
            let extension = file.name.split('.');

            if (extensionArr.indexOf(extension[extension.length - 1]) >= 0) {
                const data = new FormData();
                data.append('file', file);
                data.append('tournamentId', id);
                //  new Response(data).text().then(console.log)
                this.props.addPlayerToTournament(type, data, 'multipart/form-data');
            } else {
                this.setState({
                    error: 'Wrong File format',
                    file: '',
                });
            }
        } else if (type === 'addSinglePlayer') {
            this.props.addPlayerToTournament(
                type,
                {tournamentId: id, id: this.state.id},
                'application/json'
            );
        }
    };

    render() {
        const {id, file, error} = this.state;
        const {requestMessage, disable, title, close, tournamentId, permissions} = this.props;

        return (
            <div style={{width: '100%'}}>
                <div className="create-tournament-content">
                    {permissions && permissions[add] ?
                        <div className="input-box border-bottom">
                            <div style={{display: 'flex'}}>
                                <div className="margin-12">
                                    <Input
                                        disabled={disable}
                                        label="Player ID:"
                                        value={id}
                                        onChange={this.getId}
                                        type="text"
                                    />
                                </div>
                                <div className="margin-12">
                                    <Button
                                        positive
                                        icon="checkmark"
                                        labelPosition="right"
                                        content={title}
                                        onClick={() => this.action('addSinglePlayer', tournamentId)}
                                    />
                                </div>
                            </div>
                        </div> : null}
                    {permissions && permissions[addUpload] ?
                        <div className="input-box- border-bottom">
                            <div>Please, upload only excel files (*.xlsx, *.xls)</div>
                            <div style={{display: 'flex'}}>
                                <div className="margin-12">
                                    <div style={{display: 'flex', flexDirection: 'column-reverse'}}>
                                        <span className="upload">{file ? file.name : null}</span>
                                        <span className={error ? 'error-mes upload' : 'error-mes'}>
                                        {error}
                                    </span>
                                        <Button
                                            content="Choose File"
                                            labelPosition="left"
                                            icon="file"
                                            onClick={() => this.fileInputRef.current.click()}
                                        />
                                        <div
                                            style={{display: 'flex', flexDirection: 'column-reverse'}}
                                        >
                                            <input
                                                ref={this.fileInputRef}
                                                type="file"
                                                hidden
                                                onChange={this.onChange}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="margin-12">
                                    <Button
                                        disabled={!file}
                                        positive
                                        icon="checkmark"
                                        labelPosition="right"
                                        content={'Upload File'}
                                        onClick={() => this.action('uploadPlayers', tournamentId)}
                                    />
                                </div>
                            </div>
                        </div> : null}
                </div>

                <div className="create-tournament-actions prize">
                    <div
                        className={
                            requestMessage && requestMessage.error ? 'req-mess err' : 'req-mess'
                        }
                    >
                        {requestMessage && requestMessage.message}
                    </div>

                    <Button color="black" onClick={close}>
                        Cancel
                    </Button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({requestMessage, userReducer}) => ({
    requestMessage,
    permissions: userReducer.permissions
});

const mapDispatchToProps = {
    addPlayerToTournament,
    resetRequestMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPlayersToTournament);
