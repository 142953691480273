import React, { useEffect, useState } from 'react';
import { GAME_CARDS_APPEARANCE_CLASSES } from '../../../constants/game/classes';
import { cardToCardId } from '../../../helpers/helpers/gameplay';
import Deck from '../reusables/TableMidPart/Deck/Deck';

function DeckContainerHR({ playedCards = [], trumpCandidateCard, lastHand, cardsColorId }) {
    const [isCardVisible, setIsCardVisible] = useState(true);
    const cardAppearanceClassName = GAME_CARDS_APPEARANCE_CLASSES[cardsColorId];

    useEffect(() => {
        isCardVisible && lastHand.playerId && setIsCardVisible(false);
    }, [isCardVisible, lastHand]);

    const playedCardsIds = playedCards.reduce((acc, el) => {
        acc.push(cardToCardId(el));
        return acc;
    }, []);

    return (
        <Deck
            {...{
                playedCardsIds,
                trumpCandidateCard,
                cardAppearanceClassName,
                isCardVisible,
            }}
        />
    );
}

export default DeckContainerHR;
