import { createReducer } from '../../helpers/general';

import { SETTINGS_TYPES } from '../actionTypes';
const { SET_SETTINGS } = SETTINGS_TYPES;

export const settingReducer = createReducer({}, (state, { value }) => {
    return {
        [SET_SETTINGS]: value ? value.data : state,
    };
});

export default {
    settingReducer,
};
