import { createAction, createReducer } from '../../helpers/helpers/redux';

/** Action types **/
const UPDATE_PLAYER_SETTINGS = 'UPDATE_PLAYER_SETTINGS';
const SET_PLAYER = 'SET_PLAYER';
const SET_PLAYER_BALANCE = 'SET_PLAYER_BALANCE';
const SET_PLAYER_NOTES = 'SET_PLAYER_NOTES';

/** Actions **/
export const setPlayer = createAction(SET_PLAYER);
export const updatePlayerSettings = createAction(UPDATE_PLAYER_SETTINGS);
export const setPlayerNotes = createAction(SET_PLAYER_NOTES);
export const setPlayerBalance = createAction(SET_PLAYER_BALANCE);

const initialValue = {
    id: '',
    settings: {
        cardsColorId: '',
        fieldColorId: '',
        avatar: '',
        language: '',
    },
    balance: 0,
    notes: {},
};

/** Reducers **/
export const player = createReducer(initialValue, (state, { value }) => ({
    [SET_PLAYER]: () => ({ ...state, ...value }),
    [SET_PLAYER_BALANCE]: () => ({ ...state, balance: value }),
    [SET_PLAYER_NOTES]: () => ({ ...state, notes: { ...state.notes, [value.id]: value } }),
    [UPDATE_PLAYER_SETTINGS]: () => ({ ...state, settings: { ...state.settings, ...value } }),
}));

export default {
    player
}
