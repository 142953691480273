import React, {useState} from 'react';
import {connect} from 'react-redux';
import {Button, Checkbox, Form, Icon, TextArea} from "semantic-ui-react";
import {
    addOrDeleteFromBlackList,
    addLabel,
    updateBlacklist,
    updatePlayerBlackList
} from '../redux/actions/playersActions';

import p from "../permissionsConstants";

const {showBlackListControlToggles} = p;

const labelArr = ['red', 'yellow', 'green', 'blue', 'violet', 'black'];
const blackList = [
    {key: '11', text: 'CLASSIC', value: 'CLASSIC', kind: '1'},
    {key: '22', text: 'OPEN', value: 'OPEN', kind: '2'},
    {key: '23', text: 'TOURNAMENT', value: 'TOURNAMENT', kind: '3'},
    {key: '24', text: 'FREE ROLL', value: 'FREEROLL', kind: '4'},
    {key: '25', text: 'SPIN', value: 'SPIN', kind: '5'},
    {key: '26', text: 'LEADER BOARD', value: 'LEADERBOARD', kind: '6'},
    {key: '26', text: 'GLOBAL', value: 'GLOBAL', kind: '7'},
];


function PlayerSetting({id: playerId, ...redux}) {
    const {player, addOrDeleteFromBlackList, currentBlacklist, addLabel, permissions, updatePlayerBlackList, toggleType} = redux;
    const [label, setLabel] = useState(player.length ? player[0].label : '');
    const [textAreaObj, setTextAreaObj] = useState({});

    const saveLabel = () => {
        addLabel({labelColor: label, playerId: +playerId})
    };
    const setStatus = (e, kind) => {
        if (currentBlacklist[e]) {
            addOrDeleteFromBlackList({
                playerId,
                type: 'delete',
                kind,
                reason: '',
                toggleType: toggleType === 'Ajarabet'
            });
            const {[e]: a, ...obj} = currentBlacklist;
            updatePlayerBlackList({...obj})
        } else {
            addOrDeleteFromBlackList({
                playerId,
                type: 'add',
                kind,
                reason: textAreaObj[e],
                toggleType: toggleType === 'Ajarabet'
            });
            updatePlayerBlackList({...currentBlacklist, [e]: {reason: textAreaObj[e]}})
        }

    };
    const getComment = (e, data, id) => {
        setTextAreaObj((prev) => ({
            ...prev,
            [id]: data.value
        }))
    };
    // const onBlurSend = (id, kind) => {
    //     console.log({id},"onBlurSend")
    //     if (currentBlacklist[id]) {
    //         updatePlayerBlackList({...currentBlacklist,[id]:{...[id], reason: textAreaObj[id]}})
    //        // updateBlacklist({playerId, kind, reason: textAreaObj[id] })
    //     }
    //
    // }

    return (
        <div className="settings-content">
            <div>
                <div className="check-box border-bottom">
                    <div className="label">
                        Label:
                        {
                            labelArr.map((item, key) => {
                                return <Icon key={key} color={item ? item : 'lightgrey'}
                                             className={label === item ? 'selected-icon pointer' : 'pointer'}
                                             name='star' size={'large'} onClick={() => setLabel(item)}/>
                            })
                        }
                    </div>
                    <Button positive className="create-user btn" onClick={saveLabel}>
                        Save
                    </Button>

                </div>
                {permissions && permissions[showBlackListControlToggles] ?
                    <div className="check-box border-bottom">
                        <div className="page-title"><h3>Player Status</h3></div>
                        {

                            blackList.map((item, key) => {
                                return (
                                    <div key={key}>
                                        <div className="check-box">
                                            <div className="label"> {item.text}</div>
                                            <Checkbox
                                                toggle
                                                checked={!!currentBlacklist[item.value]}
                                                onChange={() => setStatus(item.value, item.kind)
                                                }
                                            />
                                        </div>
                                        <Form>
                                            <TextArea placeholder='Tell us why'
                                                // onBlur={()=>onBlurSend(item.value, item.kind)}
                                                      value={currentBlacklist[item.value] && currentBlacklist[item.value].reason}
                                                      onChange={(e, data) => getComment(e, data, item.value, item.kind)}/>
                                        </Form>
                                    </div>
                                );
                            })
                        }

                    </div> : null}
            </div>
        </div>
    );
}

const mapStateToProps = ({userReducer, requestMessage, blackListReducer, onePlayerBlacklistReducer, globalPlayerReducer}) => ({
    requestMessage,
    player: blackListReducer,
    currentBlacklist: onePlayerBlacklistReducer,
    permissions: userReducer.permissions,
    toggleType: globalPlayerReducer
});

const mapDispatchToProps = {
    addOrDeleteFromBlackList,
    addLabel,
    updateBlacklist,
    updatePlayerBlackList
};
export default connect(mapStateToProps, mapDispatchToProps)(PlayerSetting);
