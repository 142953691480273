import React, { Component } from 'react';
import { Button, Modal, Table } from 'semantic-ui-react';
import '../spin.css'
import { connect } from 'react-redux';
import SpinGameInfoTable from './OneSpinInfoTables/SpinGameInfoTable';
import SpinCoefficientInfoTable from './OneSpinInfoTables/SpinCoefficientInfoTable';
import SpinWinInfoTable from './OneSpinInfoTables/SpinWinInfoTable';

class OneSpinRule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: true,
            dimmer: 'blurring',
            language: 'en',
            error: { errName: '', errPass: '' },
        };
    }


    close = (e) => {
        const { close } = this.props;
        close(e);
    };

    add = () => {
        const { changeUserGroupConnection } = this.props;
        const { userId, groupId } = this.state;
        const type = 'connect';
        changeUserGroupConnection({
            type,
            userId,
            groupId,
        });
    };

    render() {
        const { open, dimmer, } = this.state;
        const { requestMessage, data, oneSpinGameInfo } = this.props;

        const gameTableInfo = {
            title: 'Game Information',
            tableHeaderCells: ['Game Type', 'Points', 'Time', 'Disconnect Time', 
            'Combinations', 'Trumps', 'Buy-in'],
        }
        
        const coefficientTableInfo = {
            title: 'Probability coefficient',
        }

        const winTableInfo = {
            title: 'Winnings distribution',
            tableHeaderCells: ['Places:', '1-st Place:', '2-st Place:', '3-st Place:', '4-st Place:']
        }

        return (
            <div>
                <Modal
                    dimmer={dimmer}
                    open={open}
                    onClose={() => {
                        this.close(false);
                    }}
                >
                    <Modal.Header>
                        Current Spin Info
                    </Modal.Header>
                    <Modal.Content>
                        <div className="input-box">
                            <SpinGameInfoTable tableInfo={gameTableInfo} data={oneSpinGameInfo} />
                        </div>
                        <div className="input-box">
                            <SpinCoefficientInfoTable tableInfo={coefficientTableInfo} data={data} />                           
                        </div>
                        <div className="input-box">
                            <SpinWinInfoTable tableInfo={winTableInfo} data={data} />
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <div className={requestMessage.error ? 'req-mess err' : 'req-mess'}>
                            {requestMessage.message}
                        </div>
                        <Button
                            color="black"
                            onClick={() => {
                                this.close(false);
                            }}
                        >
                            Close
                        </Button>

                    </Modal.Actions>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = ({ requestMessage, oneSpinGameTypeReducer }) => ({
    requestMessage,
    oneSpinGameInfo: oneSpinGameTypeReducer
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(OneSpinRule);
