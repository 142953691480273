import React, { Component } from 'react';
import { Button, Icon, Modal } from 'semantic-ui-react';

export default class ModalForMessages extends Component {

    render() {
        return (
            <Modal
                open={true}
                onClose={this.props.close}
                basic
                size="small"
                className={this.props.error ? 'req-mess err' : 'req-mess'}
            >
                <Modal.Content>
                    <h3>{this.props.message}</h3>
                </Modal.Content>
                <Modal.Actions>
                    <Button color="green" onClick={this.props.close} inverted>
                        <Icon name="checkmark" />{this.props.cms?'Close':'Ok'}
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}
