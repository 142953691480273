import React from 'react';
import InfoStatusKnock from './components/InfoStatusKnock';
import InfoBuyInKnock from './components/InfoBuyInKnock';
import InfoPrizeKnock from './components/InfoPrizeKnock';
import InfoStartDateKnock from './components/InfoStartDateKnock';
import InfoTypeKnock from './components/InfoTypeKnock';
import InfoTimerKnock from './components/InfoTimerKnock';
import InfoPlayersKnock from './components/InfoPlayersKnock';

function InfoKnockout({ status, buyIn, prize, settings, time, players, startDate }) {
    return (
        <div className="tour-info__info">
            <InfoStatusKnock status={status} />
            <InfoBuyInKnock buyIn={buyIn} />
            <InfoPrizeKnock prize={prize} />
            <InfoStartDateKnock startDate={startDate} />
            <InfoTypeKnock settings={settings} />
            <InfoTimerKnock time={time} />
            <InfoPlayersKnock players={players} />
        </div>
    );
}

export default InfoKnockout;
