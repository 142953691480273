import React, {useRef, useState} from 'react';
import {Button} from "semantic-ui-react";
import {uploadBannerOrHtml} from "../redux/actions/cmsAction";
import {connect} from "react-redux";
import {extensionArr} from '../pages/cms/imageExtensions'


function Upload({name: type, id: placeholderId, ...redux}) {
    const {uploadBannerOrHtml} = redux;
    const fileInputRef = useRef(null);
    const [error, setError] = useState('');
    const [file, setFile] = useState('');


    const onChange = (e) => {
        setFile(e.target.files[0]);
        setError('')
    };


    const action = (type) => {

        let extension = file.name.split('.');
        if (extensionArr.indexOf(extension[extension.length - 1]) >= 0) {
            const data = new FormData();
            data.append('content', file);
            // new Response(data).text().then(console.log)
            uploadBannerOrHtml(type, data, 'multipart/form-data', placeholderId)
        } else {
            setError('Wrong File format');
            setFile('')
        }
    };

    return (
        <div className="margin-l input-box padding-0  cms">
            <div className="check-box">
                {type === 'banner' &&
                <div className="input-box- border-bottom">
                    <div>Upload image...</div>
                    <div style={{display: 'flex'}}>
                        <div className="margin-12">
                            <div style={{display: 'flex', flexDirection: 'column-reverse'}}>
                                <span className="upload">{file ? file.name : null}</span>
                                <span className={error ? 'error-mes upload' : 'error-mes'}>
                                        {error}
                                    </span>
                                <Button
                                    content="Choose File"
                                    labelPosition="left"
                                    icon="file"
                                    onClick={() => fileInputRef.current.click()}
                                />
                                <div
                                    style={{display: 'flex', flexDirection: 'column-reverse'}}
                                >
                                    <input
                                        ref={fileInputRef}
                                        type="file"
                                        hidden
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="margin-12">
                            <Button
                                disabled={!file}
                                positive
                                icon="checkmark"
                                labelPosition="right"
                                content={'Upload File'}
                                onClick={() => action('uploadBanner')}
                            />
                        </div>
                    </div>
                </div>
                }
            </div>
        </div>
    );
}


const mapStateToProps = () => ({});

const mapDispatchToProps = {
    uploadBannerOrHtml,

};

export default connect(mapStateToProps, mapDispatchToProps)(Upload);


