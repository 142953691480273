import { createReducer } from '../../helpers/general';
import { MAIN_TYPES } from '../actionTypes';

const { GLOBAL_LOADER_HIDE, GLOBAL_LOADER_SHOW,LOADER_HIDE, LOADER_SHOW, GLOBAL_AUTH_TRUE, GLOBAL_AUTH_FALSE } = MAIN_TYPES;

export const globalLoaderReducer = createReducer(true, (state, action) => {
    return {
        [GLOBAL_LOADER_HIDE]: false,
        [GLOBAL_LOADER_SHOW]: true,
    };
});
export const loaderReducer = createReducer(true, (state, action) => {
    return {
        [LOADER_HIDE]: false,
        [LOADER_SHOW]: true,
    };
});

export const authReducer = createReducer(false, (state, action) => {
    return {
        [GLOBAL_AUTH_FALSE]: false,
        [GLOBAL_AUTH_TRUE]: true,
    };
});

export default {
    globalLoaderReducer,
    authReducer,
    loaderReducer
};
