import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import GameTabs from '../../components/GameTabs';
import { getOneGame } from '../../redux/actions/gamesAction';

const OpenOneGame = ({ match, getOneGame, oneGameReducer }) => {
    const [oneGame, setOneGame] = useState([]);
    useEffect(() => {
        const id = match.params.gameId;
        getOneGame({ id });
    }, []);
    useEffect(() => {
        setOneGame(oneGameReducer);
    }, [oneGameReducer]);

    return (
        <div className="spin-info-content">
            <div className="page-title">
                <h2> Game info </h2>
            </div>
            <GameTabs isGame={true} id={match.params.gameId} oneGame={oneGame} />
        </div>
    );
};

const mapStateToProps = ({ oneGameReducer }) => ({
    oneGameReducer,
});

const mapDispatchToProps = {
    getOneGame,
};

export default connect(mapStateToProps, mapDispatchToProps)(OpenOneGame);
