import React from 'react';
import { getCurrencySymbol } from '../../../../helpers/game';

function ItemKnockoutPrizePool({ place, percent, winAmount }) {
    return (
        <div className="prizepool__row">
            <div className="prizepool__cell prizepool__cell--position">{place}</div>
            <div className="prizepool__cell prizepool__cell--percent">{percent}</div>
            <div className="prizepool__cell prizepool__cell--amount">{getCurrencySymbol() + winAmount}</div>
        </div>
    );
}

export default ItemKnockoutPrizePool;
