import React from 'react';
import PropTypes from 'prop-types';
import { images } from '../../../../../../assets';
import { TABLE_ROW_GAME_TYPE_IDS } from '../../../../../../constants/classicLobby/ids';
import { TimeTillInfo } from '../../../../../LobbyScene/universal/Table/ItemsRowTable/TypeRow';

GameTypeList.propTypes = {
    settings: PropTypes.object,
};

GameTypeList.defaultProps = {
    settings: {},
};

const { BLOT_REBLOT, CAPOT_2X, NO_TRUMPS, POINT_DIFF, PRIVATE_GAME, WITHOUT_4_AND_100, DAVE } = TABLE_ROW_GAME_TYPE_IDS;

function GameTypeList({ settings, timeAndTill = {} }) {
    const settingImages = {
        [WITHOUT_4_AND_100]: images.without4and100,
        [BLOT_REBLOT]: images.belote_rebelote,
        [NO_TRUMPS]: images.no_trumps,
        [DAVE]: images[`quansh${settings.dave}x`],
        [PRIVATE_GAME]: images.privateGame,
        [CAPOT_2X]: images.capot_2x,
        [POINT_DIFF]: images.pointDiff,
    };

    const { time, till } = timeAndTill;

    const settingsImages = Object.entries(settingImages)
        .map(([key, image]) => !!settings[key] && image)
        .filter((image) => !!image);

    return (
        <div className="score__row">
            <ul className="score__game-rule-list">
                {!!time && !!till && <TimeTillInfo time={time} till={till} />}
                {settingsImages.map((image, i) => (
                    <li className="score__game-rule-item" key={i}>
                        <img src={image} alt={''} />
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default React.memo(GameTypeList);
