import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import Select from 'react-select';



class SingleSelect extends Component {
    state = {
        isClearable: true,
        isDisabled: false,
        isLoading: false,
        isRtl: false,
        isSearchable: true,
        options: this.props.aclAllGroups.filter((e)=>this.props.user.id !==1? e.id !==1:e ).map((el)=>({
            label:el.title,
            value:el.title,
            id:el.id
        }))
    };
    ch = (e) => {
        const {addToGroup,userId}=this.props;
        if(!!e){
            addToGroup('connect', userId, e.id);
        }

    };

    render() {
        const {
            isClearable,
            isSearchable,
            isDisabled,
            isLoading,
            isRtl,
            options
        } = this.state;
        return (
            <Fragment>
                <Select
                    className="basic-single"
                    classNamePrefix="select own"
                    isDisabled={isDisabled}
                    isLoading={isLoading}
                    isClearable={isClearable}
                    isRtl={isRtl}
                    isSearchable={isSearchable}
                    options={options}
                    onChange={this.ch}
                />


            </Fragment>
        );
    }
}

const mapStateToProps = ({requestMessage, aclAllGroups, aclAllUsers, aclResourcesReducer, userReducer}) => ({
    requestMessage,
    aclAllGroups,
    aclAllUsers,
    aclResourcesReducer,
    user: userReducer,
});

export default connect(mapStateToProps, null)(SingleSelect);
