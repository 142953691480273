import { useEffect } from 'react';

export function useSetOpenedCardsToHand(openedCardsData, setCards, isOpponentContainer = false, isSpectator = false) {
    useEffect(() => {
        const { isOpponent, cards: openedCards = [] } = openedCardsData;
        if (
            !!openedCards.length &&
            ((isOpponent && isOpponentContainer) || (!isOpponent && isSpectator && !isOpponentContainer))
        ) {
            setCards(openedCards);
        }
    }, [openedCardsData]);
}
