import React from 'react';
import { setClass } from '../../../../helpers/game';
import { useTranslation } from 'react-i18next';

function TabKnockout({ isActive, onClick, label, id }) {
    const { t } = useTranslation();
    return (
        <li className="tour-info__tab">
            <button
                type="button"
                className={`tour-info__tab-button ${setClass(isActive, 'tour-info__tab-button--active')}`}
                id={id}
                onClick={onClick}
            >
                <span className="tour-info__tab-button-text">{t(label)}</span>
            </button>
        </li>
    );
}

export default TabKnockout;
