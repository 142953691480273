import React, { useState, useEffect } from 'react';
import { blotFieldImages } from '../../../../../assets';
import { setClass } from '../../../../../helpers/game';
import { emitTrade } from '../../../../../api/emitters';
import { TRADE_TYPES } from '../../../../../api/constants/moveTypes';
import { connect } from 'react-redux';
import TrumpIcon from './components/TrumpIcon';
import { CARD_VALUE_IDS } from '../../../../../constants/game/ids';
import { useTranslation } from 'react-i18next';
const { a, club, diamond, heartRed, spade } = blotFieldImages;
const trumpsData = [
    { image: heartRed, suit: 'heart' },
    { image: club, suit: 'club' },
    { image: diamond, suit: 'diamond' },
    { image: spade, suit: 'spade' },
    { image: a, suit: 'ace' },
];

const {
    PASS_TRUMP,
    PASS_CANDIDATE_TRUMP,
    CHOOSE_TRUMP_WITH_CARD,
    CHOOSE_TRUMP_WITHOUT_CARD,
    CHOOSE_CANDIDATE_TRUMP,
} = TRADE_TYPES;

function ChoseTrumpCloud(redux) {
    const { isMyTurn, activeRoomId, trumpCandidateCard, gameSettings, validActions, tradeStageData } = redux;
    const { isFirstTradeStage, isSecondTradeStage } = tradeStageData;

    const [chosenTrump, setChosenTrump] = useState('');
    const [choosableTrumps, setChoosableTrumps] = useState([]);
    const { canPassTrump, canPassCandidateCard } = validActions.actions;
    const canPass = (canPassCandidateCard && isFirstTradeStage) || (canPassTrump && isSecondTradeStage);
    const { t } = useTranslation();

    useEffect(() => {
        if (!!trumpCandidateCard) {
            setChoosableTrumps(trumpsData.filter((trump) => trump.suit !== trumpCandidateCard.suit));
        }
    }, [trumpCandidateCard]);

    useEffect(() => {
        if (!!choosableTrumps.length) {
            setChosenTrump(choosableTrumps[0].suit);
        }
    }, [choosableTrumps]);

    return (
        <div
            className={`my-control ${setClass(isSecondTradeStage, 'my-control--with-trump')} ${setClass(
                isFirstTradeStage && !canPass,
                'my-control--one-button'
            )} ${setClass(isFirstTradeStage && canPass, 'my-control--two-button')}`}
        >
            <div className="my-control__trump">
                <div className="my-control__trump-title">{t('Choose a trump')}</div>
                <ul className="my-control__trump-list">
                    {choosableTrumps.map((trump) => {
                        const isAceIcon = trump.suit === 'ace';
                        const needAceIcon = !(isAceIcon && gameSettings.isNoTrumps);
                        if (needAceIcon) {
                            return (
                                <TrumpIcon
                                    key={trump.suit}
                                    image={trump.image}
                                    suit={trump.suit}
                                    onClick={handleChooseTrump}
                                    isActive={chosenTrump === trump.suit}
                                />
                            );
                        } else return null;
                    })}
                </ul>
            </div>
            {isMyTurn && isSecondTradeStage && (
                <button className="my-control__button my-control__button--take" onClick={handleTakeWithTableCard}>
                    {t('With open card')}
                </button>
            )}
            <button
                className={`my-control__button my-control__button--trump my-control__button--trump-${
                    isFirstTradeStage ? trumpCandidateCard.suit : chosenTrump
                }`}
                onClick={handleTakeTrump}
            >
                {t('Take')}
            </button>
            {canPass && (
                <button className="my-control__button my-control__button--wont" onClick={handleWontTake}>
                    {t("I don't want")}
                </button>
            )}
        </div>
    );

    function handleChooseTrump({ currentTarget: { id } }) {
        setChosenTrump(id);
    }

    function handleTakeTrump() {
        if (isSecondTradeStage) {
            tradeEmitting(CHOOSE_TRUMP_WITHOUT_CARD, chosenTrump);
        } else {
            tradeEmitting(CHOOSE_CANDIDATE_TRUMP, trumpCandidateCard.suit);
        }
    }
    function handleWontTake() {
        if (isSecondTradeStage) {
            tradeEmitting(PASS_TRUMP, trumpCandidateCard.suit);
        } else {
            tradeEmitting(PASS_CANDIDATE_TRUMP, trumpCandidateCard.suit);
        }
    }
    function handleTakeWithTableCard() {
        tradeEmitting(CHOOSE_TRUMP_WITH_CARD, chosenTrump);
    }

    function tradeEmitting(type, trumpSuit) {
        trumpSuit = trumpSuit === CARD_VALUE_IDS.ACE ? 'no_trump' : trumpSuit;
        emitTrade({ roomId: activeRoomId, move: { type, trumpSuit } });
    }
}

const mapStateToProps = ({
    activeRoomId,
    currentGame: { trumpCandidateCard, gameSettings, validActions, isMyTurn, tradeStageData },
}) => ({
    activeRoomId,
    trumpCandidateCard,
    isMyTurn,
    gameSettings,
    validActions,
    tradeStageData,
});

export default connect(mapStateToProps)(ChoseTrumpCloud);
