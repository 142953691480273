import React, { useState, useEffect } from 'react';
import { useTimer } from '../../../../../shared/hooks/general/useTimer';

function DisconnectTimer({ sec }) {
    const [timerText, setTimerText] = useState('');
    const [timerCount, startTimer, stopTimer] = useTimer(sec, 1000, true);

    useEffect(() => {
        startTimer();
        return stopTimer;
    }, [sec]);

    useEffect(() => {
        setTimerText(createTimerText(timerCount));
        timerCount <= 0 && stopTimer();
    }, [timerCount]);

    return <span className="disconnect-timer">{!!timerCount ? timerText : ':'}</span>;
}

export function createTimerText(sec) {
    let timerInSeconds = sec;
    const minutes = '0' + Math.floor(timerInSeconds / 60);
    const seconds = '0' + Math.floor(timerInSeconds % 60);
    return `${minutes.slice(-2)} : ${seconds.slice(-2)}`;
}

export default DisconnectTimer;
