import React, { useEffect, useState } from 'react';
import TableMidPart from '../reusables/TableMidPart/TableMidPart';
import DeckContainerHR from './DeckContainerHR';
import RoomRootContainer from '../reusables/RoomRootContainer';
import ScoreBoardContainerHR from './ScoreBoardContainerHR';
import { connect } from 'react-redux';
import OpponentContainerHR from './OpponentContainerHR';
import PlayerAvatarContainerHR from './PlayerAvatarContainerHR';
import PlayerCardsHR from './PlayerCardsHR';
import OpponentCardsHR from './OpponentCardsHR';
import { useDefineLastHandCardsData } from '../../../shared/hooks/gameplay/useDefineLastHandCardsData';
import { useDefineTradeText } from '../../../shared/hooks/gameplay/texts/useDefineTradeText';
import { useDefineDeclareCombText } from '../../../shared/hooks/gameplay/texts/useDefineDeclareCombText';
import { useDefineShownCombinationData } from '../../../shared/hooks/gameplay/useDefineShownCombinationData';
import { useDefineThrowCardsText } from '../../../shared/hooks/gameplay/texts/useDefineThrowCardsText';
import { useDefineOpenCardsData } from '../../../shared/hooks/gameplay/useDefineOpenCardsData';
import { createTimerText } from '../reusables/TimerAvatar/components/DisconnectTimer';
import { useDefineDaveText } from '../../../shared/hooks/gameplay/texts/useDefineDaveText';
import { player } from '../../../redux/ducks/playerDucks';

function HistoryRoom({ replay, stepIndex, ...redux }) {
    const {
        lastHand,
        playedCards,
        trumpCandidateCard,
        roomId,
        bet,
        till,
        score,
        davePower,
        quanshData,
        moveStageScore,
        sceneData,
        scene,
        gameSettings,
        time,
        players,
        trumper,
        trumpSuit,
        cards,
        currentHand,
        onChooseTrump,
        declaredCombData,
        currentPlayer,
        timer,
        shownCombData,
        openMyCards,
        throwMyCardsData,
        opponentCards,
    } = replay;

    const { owner, opponent } = players;
    const {
        user: {
            id: userId,
            remoteId: userRemoteId,
            settings: { avatarId: userAvatarId, cardsColorId: userCardsColorId },
        },
    } = redux;

    const bottomPlayerId = userId;
    const topPlayerId = [owner, opponent].find(el => el.id !== bottomPlayerId).id;

    const [disconnectText, setDisconnectText] = useState({});
    const [cloudsTextData, setCloudsTextData] = useState({});
    const [daveData, setDaveData] = useState({});

    useDefineTradeText(onChooseTrump, bottomPlayerId, setCloudsTextData);
    useDefineDeclareCombText(declaredCombData, bottomPlayerId, setCloudsTextData);
    useDefineThrowCardsText(openMyCards || throwMyCardsData, bottomPlayerId, setCloudsTextData);
    useDefineDaveText(daveData, bottomPlayerId, setCloudsTextData);
    const lastHandCardsData = useDefineLastHandCardsData(lastHand, bottomPlayerId);
    const openedCardsData = useDefineOpenCardsData(opponentCards, bottomPlayerId);
    const shownCombination = useDefineShownCombinationData(shownCombData, userId);
    const isEventData =
        openMyCards || throwMyCardsData || declaredCombData || shownCombData || timer.stepTimer === 0 || quanshData;


    useEffect(() => {
        setCloudsTextData({});
    }, [stepIndex]);

    useEffect(() => {
        if (!!quanshData) {
            const { playerId, isOffer, davePower, gameOver } = quanshData;
            setDaveData({ ...davePower, isOffer, playerId, isGameOver: gameOver });
        }
    }, [quanshData]);

    useEffect(() => {
        const { playerId, stepTimer, continueTimer } = timer;
        if (stepTimer === 0) {
            const DISCONNECT_TIMER_TEXT = createTimerText(continueTimer / 1000);
            const isOpponent = playerId !== bottomPlayerId;
            setDisconnectText({
                playerText: !isOpponent && DISCONNECT_TIMER_TEXT,
                opponentText: isOpponent && DISCONNECT_TIMER_TEXT,
            });
        } else {
            setDisconnectText({});
        }
    }, [timer]);

    return (
        <RoomRootContainer>
            <PlayerCardsHR
                bottomPlayerId={bottomPlayerId}
                cardsData={cards[bottomPlayerId]}
                currentHand={currentHand}
                lastHandCardData={lastHandCardsData.playerCardData}
                stepIndex={stepIndex}
                shownCombination={shownCombination}
                isEventData={isEventData}
            />
            <OpponentCardsHR
                bottomPlayerId={bottomPlayerId}
                cardsData={cards[topPlayerId]}
                cardsColorId={userCardsColorId}
                currentHand={currentHand}
                lastHandCardData={lastHandCardsData.opponentCardData}
                stepIndex={stepIndex}
                shownCombination={shownCombination}
                openedCardsData={openedCardsData}
                isEventData={isEventData}
            />
            <OpponentContainerHR
                {...{
                    trumper,
                    trumpSuit,
                    owner,
                    opponent,
                    bottomPlayerId,
                    cloudText: cloudsTextData.opponentText,
                    isPlayersTurn: currentPlayer.id !== bottomPlayerId,
                    replayDisconnectTimerText: disconnectText.opponentText,
                }}
            />
            <TableMidPart>
                <DeckContainerHR
                    lastHand={lastHand}
                    playedCards={playedCards}
                    trumpCandidateCard={trumpCandidateCard}
                    cardsColorId={userCardsColorId}
                />
                <ScoreBoardContainerHR
                    {...{
                        roomId,
                        bet,
                        till,
                        score,
                        davePower,
                        moveStageScore,
                        sceneData,
                        scene,
                        gameSettings,
                        time,
                        opponent,
                    }}
                />
            </TableMidPart>
            <div className="blot-field__user blot-field__user--me">
                <div className="blot-field__user-container">
                    <PlayerAvatarContainerHR
                        playerId={bottomPlayerId}
                        visualId={userRemoteId}
                        avatarId={userAvatarId}
                        trumper={trumper}
                        trumpSuit={trumpSuit}
                        cloudText={cloudsTextData.playerText}
                        isPlayersTurn={currentPlayer.id === bottomPlayerId}
                        replayDisconnectTimerText={disconnectText.playerText}
                    />
                </div>
            </div>
        </RoomRootContainer>
    );
}

const mapStateToProps = ({ player }) => ({
    // user: {"id":126,"settings":{"currency":"AMD","language":"HY","avatarId":3,"cardsColorId":3,"fieldColorId":3},"balance":15000,"notes":{"110":{"note":"qweqwe","color":"2","id":110},"127":{"note":"1","color":"2","id":127}},"remoteId":10},
    user: player
});

export default connect(mapStateToProps)(HistoryRoom);
