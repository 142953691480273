import React, {useEffect, useState} from 'react';
import {
    savePrizeRules,
    setPrizeDataFromTournamentTemplate,
    setSelectedTournamentData,
} from '../../../redux/actions/tournamentsAction';
import {connect} from 'react-redux';
import {Button, Input, Select} from 'semantic-ui-react';

const winnersList = [
    {key: '1', text: '1', value: '0'},
    {key: '8', text: '2', value: '1'},
    {key: '2', text: '4', value: '2'},
    {key: '3', text: '8', value: '3'},
    {key: '4', text: '16', value: '4'},
    {key: '5', text: '32', value: '5'},
    {key: '6', text: '64', value: '6'},
];

const places = [
    {place: '1-st place', amount: 0, amountPercent: 0, amountTotal: 0, percentTotal: 0, count: 1},
    {place: '2-nd place', amount: 0, amountPercent: 0, amountTotal: 0, percentTotal: 0, count: 1},
    {place: '3-4 places', amount: 0, amountPercent: 0, amountTotal: 0, percentTotal: 0, count: 2},
    {place: '5-8 places', amount: 0, amountPercent: 0, amountTotal: 0, percentTotal: 0, count: 4},
    {
        place: '9-16 places',
        amount: 0,
        amountPercent: 0,
        amountTotal: 0,
        percentTotal: 0,
        count: 8,
    },
    {
        place: '17-32 places',
        amount: 0,
        amountPercent: 0,
        amountTotal: 0,
        percentTotal: 0,
        count: 16,
    },
    {
        place: '33-64 places',
        amount: 0,
        amountPercent: 0,
        amountTotal: 0,
        percentTotal: 0,
        count: 32,
    },
];

function Prize({setPrizeData, editOrCopy, disable, resetTemplate, ...redux}) {
    const {
        savePrizeRules,
        prize,
        tournamentData,
        setPrizeDataFromTournamentTemplate,
        setSelectedTournamentData,
        tournamentPrizeReducer,
    } = redux;
    const [winnersCount, setWinnersCount] = useState('');
    const [winners, setWinners] = useState('');
    const [index, setIndex] = useState(0);
    const [mapPlaces, setPlaces] = useState([]);
    const [error, setError] = useState({name: '', prize: ""});
    const [prizeAmount, setPrizeAmount] = useState('');
    const [prizeTemplateName, setTemplateName] = useState('');
    const [amountSum, setAmountSum] = useState(0);
    const [amountPercent, setAmountPercent] = useState(0);

    useEffect(() => {
        clearAll()
    }, []);
    useEffect(() => {
        if (resetTemplate) {
            clearAll();
        }
    }, [resetTemplate])

    useEffect(() => {
        setPrizeDataFromTournamentTemplate({data: tournamentData.prizeDistribution});
        setTemplateName('');
        setPrizeAmount(tournamentData.prizePool);
        setWinners(tournamentData.winners);
    }, [
        setPrizeData,
        tournamentData.prizeDistribution,
        tournamentData.prizePool,
        tournamentData.winners,
    ]);

    useEffect(() => {
        if (mapPlaces && mapPlaces.length) {
            const obj = mapPlaces.reduce(
                (acc, el) => {
                    acc.sum += +el.amountTotal;
                    acc.percent += +el.percentTotal;
                    return acc;
                },
                {sum: 0, percent: 0}
            );
            setError((error) => ({...error, prize: ''}));
            setAmountSum(obj.sum);
            setAmountPercent(obj.percent);
        }

        setPrizeData({
            prizeDistribution: mapPlaces && mapPlaces.length ? mapPlaces : [],
            prizePool: prizeAmount ? +prizeAmount : '',
            winners: winners ? +winners : '',
            prizeRuleName: prizeTemplateName
        });
        //prizeDistribution
    }, [mapPlaces]);

    useEffect(() => {

        if (prize.prizeDistribution && prize.prizeDistribution.length) {
            let newArr;
            if (mapPlaces && mapPlaces.length) {
                newArr = mapPlaces.map((el) => {
                    el.amount = (el.amountPercent * prizeAmount) / 100;
                    el.amountTotal = (el.percentTotal * prizeAmount) / 100;
                    return el;
                });
                setPlaces(newArr);
            }
        } else {
            clearAll();
        }

    }, [prizeAmount, winnersCount]);

    useEffect(() => {
        if (!!prize) {
            setPlaces(prize.prizeDistribution);
            setWinners(prize.winners);
            setTemplateName(prize.prizeRuleName);
            setPrizeAmount(prize.amount);
        }
        if (!!prizeAmount) {
            if (prize && prize.prizeDistribution) {
                const newArr = prize.prizeDistribution.map((el) => {
                    el.amount = (el.amountPercent * prizeAmount) / 100
                    return el;
                });
                setPlaces(newArr);
            }
        }
        setPrizeData({
            prizeDistribution: prize.prizeDistribution,
            prizePool: prize.amount,
            winners: prize.winners,
            prizeRuleName: prize.prizeRuleName
        });
    }, [prize]);

    useEffect(() => {

        if (amountPercent > 100) {
            setError((error) => ({...error, prize: 'Data is not valid'}));
        }
    }, [amountSum, amountPercent]);

    const getPrize = (e) => {
        if (e.target.value.length <= 8) {
            setPrizeAmount(e.target.value);
            setPrizeData({
                ...prize,
                prizeDistribution: [...mapPlaces],
                prizePool: e.target.value,
            });
        }
    };

    const getWinnersCount = (e, data) => {

        if (data.value) {
            let current_places = places.slice(0, +data.value + 1);
            setPlaces(current_places);
            setWinnersCount(+data.value);
            setWinners(data.options[+data.value].text);
            setPrizeData({

                ...prize,
                winners: +data.value,
            });
        } else {
            setPrizeDataFromTournamentTemplate({data: []});
            clearAll();
        }
    };
    const setPrizeTemplateName = (e) => {
        if (e.target.value.length <= 20) {
            if (error) setError((error) => ({...error, name: ''}));

            setTemplateName(e.target.value);
            setPrizeData({
                ...prize,
                prizeRuleName: e.target.value,
                prizePool: prizeAmount,
                prizeDistribution: [...mapPlaces],
                winners: Math.pow(2, mapPlaces.length - 1)
            });
        } else {
            setError((error) => ({...error, name: 'Name should be less than 16 symbols'}));
        }
    };

    const getPrizeValue = (e, index, type, total) => {
        let val = e.target.value;
        if (!!error) setError((error) => ({...error, prize: ''}));
        if (val <= 10000000) {
            if (type === 'amount' && !error.prize) {
                if (total) {
                    const newArr = mapPlaces.map((el, i) => {
                        if (i === index) {
                            el.amount = val / mapPlaces[index].count;
                            el.amountTotal = val;
                            el.amountPercent = (
                                ((val / mapPlaces[index].count) * 100) /
                                prizeAmount
                            ).toFixed(2);
                            el.percentTotal = ((val * 100) / prizeAmount).toFixed(2);
                        }
                        return el;
                    });
                    setPlaces(newArr);
                } else {
                    const newArr = mapPlaces.map((el, i) => {
                        if (i === index) {
                            el.amount = val;
                            el.amountTotal = val * mapPlaces[index].count;
                            el.amountPercent = (
                                (val * mapPlaces[index].count * 100) /
                                prizeAmount
                            ).toFixed(2);
                            el.percentTotal =
                                ((val * 100) / prizeAmount).toFixed(2) * mapPlaces[index].count;
                        }
                        return el;
                    });
                    setPlaces(newArr);
                }
            } else {
                if (val <= 100) {
                    if (total) {
                        const newArr = mapPlaces.map((el, i) => {
                            if (i === index) {
                                el.percentTotal = val;
                                el.amountPercent = val / mapPlaces[index].count;
                                el.amount = ((val * prizeAmount) / 100 / mapPlaces[index].count).toFixed(2);
                                el.amountTotal = ((val * prizeAmount) / 100).toFixed(2);
                            }
                            return el;
                        });
                        setPlaces(newArr);
                    } else {
                        const newArr = mapPlaces.map((el, i) => {
                            if (i === index) {
                                el.amountPercent = val;
                                el.percentTotal = val * mapPlaces[index].count;
                                el.amount = ((val * prizeAmount) / 100).toFixed(2);
                                el.amountTotal =
                                    ((val * prizeAmount) / 100).toFixed(2) * mapPlaces[index].count;
                            }
                            return el;
                        });
                        setPlaces(newArr);
                    }
                }
            }
        }
            setIndex(index);
        };

        const autoFill = (e, key, type) => {
            // let val = e.target.value;
            // //if (!(prizeAmount - amountSum)) return;
            // if (index === mapPlaces.length - 2) {
            //     if (type === 'amount') {
            //         const newArr = mapPlaces.map((el, i) => {
            //             if (i === index + 1) {
            //                 el.amount = val !== '' ? (prizeAmount - amountSum) / el.count : '';
            //                 el.amountPercent = val !== '' ? (100 - amountPercent).toFixed(2) : '';
            //                 // console.log('amount:::', prizeAmount, amountSum, el.count);
            //             }
            //             return el;
            //         });
            //         setPlaces(newArr);
            //     } else {
            //         const newArr = mapPlaces.map((el, i) => {
            //             if (i === index + 1) {
            //                 el.amountPercent = val !== ''? 100 - amountPercent:'';
            //                 el.amount = val !== ''?((((100 - amountPercent) * prizeAmount) / 100) / el.count).toFixed(2):''
            //             }
            //             return el;
            //         });
            //         setPlaces(newArr);
            //     }
            // }
            // console.log(mapPlaces, "mapPlaces")
        };
        const clearAll = () => {
            setAmountSum(0);
            setAmountPercent(0);
            const newArr = mapPlaces ? mapPlaces.map((el) => {
                el.amount = 0;
                el.amountPercent = 0;
                el.amountTotal = 0;
                el.percentTotal = 0;
                return el;
            }) : [];

            setPlaces(newArr);
            setError('');
        };
        const save = () => {
            if(prizeTemplateName){
                const places = [];
                for (let i in mapPlaces) {
                    places.push({
                        position: +i + 1 + '',
                        percent: parseInt(mapPlaces[i].percentTotal) + '',
                    });
                }
                const saveObj = {
                    prizeDistribution: [...places],
                    prizeRuleName: prizeTemplateName,
                    winners,
                    prizePool: +prizeAmount,
                };

                savePrizeRules(saveObj);
                //console.log(saveObj, "mapPlaces-------SaVe")
            }
            else{
                setError((error) => ({...error, name: 'Template name is empty'}));
            }

        };
        return (
            <>
                <div className="margin-l date-flex p-left-16 ">
                    <div className="date-flex max-height min-height-550 float">
                        <div>
                            <div>
                                <div className="label">Prize pool:</div>
                                <Input
                                    placeholder="Prize pool"
                                    disabled={disable}
                                    value={prizeAmount}
                                    type="number"
                                    min={1}
                                    onChange={getPrize}
                                />
                            </div>
                            <div>
                                <div className="label">Count of winners</div>
                                <Select
                                    selectOnBlur={false}
                                    disabled={disable}
                                    clearable
                                    placeholder={winners ? winners + '' : 'Select'}
                                    options={winnersList}
                                    onChange={getWinnersCount}
                                />
                            </div>
                                <div>
                                    <div className="label">Add name:</div>
                                    <Input
                                        placeholder="New template name"
                                        disabled={disable}
                                        value={prizeTemplateName}
                                        type="text"
                                        onChange={setPrizeTemplateName}
                                    />
                                    <span className="error-mes">{error.name}</span>
                                </div>
                        </div>
                    </div>
                    <div className="places">
                        <span className="error-mes">{error.prize}</span>
                        <div
                            className={
                                (prize && prize.prizeDistribution) || winnersCount !== '' || prizeAmount
                                    ? 'label'
                                    : 'label none'
                            }
                        >
                            Prize pool divided by places
                        </div>
                        <span className={winnersCount !== '' || prizeAmount ? 'label-X own' : 'none'}>
                        left {prizeAmount ? (prizeAmount - amountSum).toFixed(2) : 0} -{' '}
                            {(100 - amountPercent).toFixed(2)} %
                    </span>

                        {mapPlaces && mapPlaces.length ? (
                            mapPlaces.map((item, key) => {
                                return (
                                    <div className="date-flex winner-range" key={key}>
                                        <div className="label">{item.place}</div>
                                        <div className="registration-day-manual">
                                            <div className="date-flex column margin-r">
                                                {/*<div className='left'>*/}
                                                {/*    {*/}
                                                {/*        !item.amount ? <span*/}
                                                {/*            className='label-X'>left {prizeAmount - amountSum} (/{item.count})</span> : null*/}
                                                {/*    }*/}
                                                {/*</div>*/}
                                                <div>
                                                    <input
                                                        type="number"
                                                        disabled={disable}
                                                        value={item.amount}
                                                        className="mini-input min-width-50 own"
                                                        onChange={(e) =>
                                                            getPrizeValue(e, key, 'amount', false)
                                                        }
                                                        onBlur={
                                                            key === mapPlaces.length - 2
                                                                ? (e) => autoFill(e, key, 'amount')
                                                                : null
                                                        }
                                                        min={1}
                                                        max={prizeAmount}
                                                        size={10}
                                                    />{' '}
                                                    <span className="label-X"> X {item.count}</span>
                                                    <input
                                                        type="number"
                                                        disabled={disable}
                                                        value={item.amountTotal}
                                                        className="mini-input min-width-50 own margin-l"
                                                        onChange={(e) =>
                                                            getPrizeValue(e, key, 'amount', true)
                                                        }
                                                        onBlur={
                                                            key === mapPlaces.length - 2
                                                                ? (e) => autoFill(e, key, 'amount')
                                                                : null
                                                        }
                                                        min={1}
                                                        max={prizeAmount}
                                                        size={10}
                                                    />
                                                </div>
                                            </div>
                                            <div className="date-flex column margin-r">
                                                {/*<div className='left'>*/}
                                                {/*    {*/}
                                                {/*        !item.amountPercent || item.amountPercent === 0.00 ? <span*/}
                                                {/*            className='label-X'>left {100 - amountPercent} %</span> : null*/}
                                                {/*    }*/}
                                                {/*</div>*/}
                                                <div>
                                                    <input
                                                        type="number"
                                                        disabled={disable}
                                                        value={item.amountPercent}
                                                        className="mini-input min-width-50 own"
                                                        onChange={(e) =>
                                                            getPrizeValue(e, key, 'percent', false)
                                                        }
                                                        onBlur={
                                                            key === mapPlaces.length - 2
                                                                ? (e) => autoFill(e, key, 'percent')
                                                                : null
                                                        }
                                                        min={1}
                                                        max={100}
                                                        size={5}
                                                    />{' '}
                                                    %<span className="label-X"> X {item.count}</span>
                                                    <input
                                                        type="number"
                                                        disabled={disable}
                                                        value={item.percentTotal}
                                                        className="mini-input min-width-50 own margin-l"
                                                        onChange={(e) =>
                                                            getPrizeValue(e, key, 'percent', true)
                                                        }
                                                        onBlur={
                                                            key === mapPlaces.length - 2
                                                                ? (e) => autoFill(e, key, 'percent')
                                                                : null
                                                        }
                                                        min={1}
                                                        max={100}
                                                        size={5}
                                                    />{' '}
                                                    %
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <div className="color-own">
                                {' '}
                                Enter the prize pool and select count of winners{' '}
                            </div>
                        )}
                        <span
                            className={
                                winners !== '' && prizeAmount && !disable
                                    ? 'label-X own pointer reset-prize'
                                    : 'none'
                            }
                            onClick={clearAll}
                        >
                        reset
                    </span>
                        {!editOrCopy &&
                        winners !== '' && prizeAmount ? (
                            <div className="create-tournament-actions save-prize">
                                <Button
                                    disabled={disable}
                                    positive
                                    icon="checkmark"
                                    labelPosition="right"
                                    content="Save Prize Template"
                                    onClick={save}
                                />
                            </div>
                        ) : null}
                    </div>
                </div>
            </>
        );
    }

    const mapStateToProps = ({onePrizeRuleReducer, tournamentPrizeReducer, oneTournamentReducer}) => ({
        prize: onePrizeRuleReducer,
        tournamentData: oneTournamentReducer,
        tournamentPrizeReducer
    });

    const mapDispatchToProps = {
        savePrizeRules,
        setPrizeDataFromTournamentTemplate,
        setSelectedTournamentData,
    };

    export default connect(mapStateToProps, mapDispatchToProps)(Prize);
