import React, {Component} from 'react';
import {Tab} from 'semantic-ui-react';
import Accordion from './Accordion';
import ResourcesList from './List';
import store from '../redux/store';
import p from "../permissionsConstants";
const {aclGroups, aclResources, aclUsers} = p;


class TabExampleColoredInverted extends Component {
    constructor(props) {
        super(props);
        this.panes = [
            {
                menuItem: 'Groups',
                render: () => (
                    <Tab.Pane attached={false}>
                        <Accordion names={Object.keys(this.props.groups)} data={this.props.groups} openModal={this.props.openModal}
                                   users={this.props.users} type="groups"/>
                    </Tab.Pane>
                ),
            },
            {
                menuItem: 'Users',
                render: () => (
                    <Tab.Pane attached={false}>
                        <Accordion
                            names={Object.keys(this.props.users)}
                            data={this.props.users}
                            type="users"
                            userGroupConnection={this.props.userGroupConnection}
                        />
                    </Tab.Pane>
                ),
            },
            {
                menuItem: 'Resources',
                render: () => (
                    <Tab.Pane attached={false}>
                        <ResourcesList names={Object.keys(this.props.resources)} data={this.props.resources}/>
                    </Tab.Pane>
                ),
            },
        ];
        this.state = {
            color: 'blue',
            permission: store.getState().userReducer['permissions']
        };

        this.prepareData()

    }


    getIndex = (arr, name) => {
        let i = null;
        for (const [index, el] of arr.entries()) {
            if (el.menuItem === name) {
                i = index;
                break;
            }
        }
        return i
    };

    prepareData = () => {
        const {permission} = this.state;
        if (!permission[aclGroups]) {
            const index = this.getIndex(this.panes, 'Groups')
            this.panes.splice(index, 1)
        }
        if (!permission[aclResources]) {
            const index = this.getIndex(this.panes, 'Resources')
            this.panes.splice(index, 1)
        }
        if (!permission[aclUsers]) {
            const index = this.getIndex(this.panes, 'Users')
            this.panes.splice(index, 1)
        }
    };


    render() {
        const {color} = this.state;
        return (
            <Tab
                menu={{color, inverted: true, attached: false, tabular: false}}
                panes={this.panes}
                style={{marginTop: '3%'}}
            />
        );
    }
}

export default TabExampleColoredInverted;
