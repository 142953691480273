import React, {useEffect, useState} from 'react';
import {Button, Pagination, Select, Input, Loader, Confirm} from 'semantic-ui-react';
import {
    getAllPrizeRules,
    getPrizeRuleName,
    deletePrizeRuleByName,
    searchTournamentsTemplates,
    getPrizePoolByKeywordPart,
    getPrizeRules,
    resetPrizeRule

} from '../../redux/actions/tournamentsAction';

import {resetRequestMessage, leftDropDownMenuControl} from '../../redux/actions/otherActions';
import {connect} from 'react-redux';
import TemplatesTournamentsTable from '../../components/TournamentsTemplateTable';
import ModalComponentContent from '../../components/ModalComponentContent';
import './tournament.css';


const rowItems = [
    // {key: '10', text: '10 rows', value: '10'},
    // {key: '30', text: '20 rows', value: '20'},
    {key: '50', text: '50 rows', value: '50'},
    {key: '100', text: '100 rows', value: '100'},
];

function AllPrizeRules({...redux}) {
    const {
        getPrizePoolByKeywordPart,
        templatesData,
        totalPagesReducer,
        getAllPrizeRules,
        requestMessage,
        resetRequestMessage,
        getPrizeRuleName,
        deletePrizeRuleByName,
        searchTournamentsTemplates,
        getPrizeRules,
        leftDropDownMenuControl,
        resetPrizeRule,
        loader
    } = redux;

    const columns = [
        //  { key: 1, value: 'id', text: 'id' },
        {key: 2, value: 'name', text: 'Name'},
        {key: 3, value: 'prizePool', text: 'Prize Pool'},
        {key: 4, value: 'winnersCount', text: 'Winners Count'},
    ];

    const [sortedField, setSortedField] = useState('');
    const [direction, setDirection] = useState('DESC');
    const [activePage, setActivePage] = useState(1);
    const [rowCount, setRowCount] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const [type, setType] = useState('');
    const [openUniversalModal, setOpenUniversalModal] = useState(false);
    const [tempName, setTempName] = useState(null);
    const [componentName, setComponentName] = useState(false);
    const [filterDateForSorting, setFilterDateForSorting] = useState({});
    const [limit, setLimit] = useState(50);
    const [offset, setOffset] = useState(0);
    const [confirmModal, setOpenConfirmModal] = useState(false);
    const [name, setTemplateName] = useState('');
    const [id, setId] = useState(null);



    useEffect(() => {
        if (tempName && tempName.trim()) {
            getPrizePoolByKeywordPart({title: tempName.trim(), limit, offset})
        }else if(tempName === ''){
          getAllPrizeRules({ limit, offset});
        }
      leftDropDownMenuControl('tournament');
    }, [tempName])

    useEffect(() => {
        let setTimeOutId,setTimeOutId1 ;
        if (!openModal) {
            if(tempName && tempName.trim()){
                getPrizePoolByKeywordPart({
                    title: tempName.trim(),
                    offset,
                    orderBy: sortedField,
                    order: direction,
                    ...filterDateForSorting,
                    limit,
                })
            }
            else{
                getAllPrizeRules({limit, offset: 0 , ...filterDateForSorting});
            }
            //setActivePage(1);
           // setRowCount(0);
        }
        if (requestMessage && !requestMessage.error) {
            setTimeOutId = setTimeout(() => {
                if (openUniversalModal) {
                    setOpenUniversalModal(false);
                }
                closeCreateModal();
            }, 1000);
        }
        if (requestMessage && requestMessage.error) {
            setTimeOutId1 = setTimeout(() => {
                resetRequestMessage('')
            }, 1500);
        }
        return () => {
            clearTimeout(setTimeOutId);
            clearTimeout(setTimeOutId1);
        }

    }, [requestMessage, limit]);


    const getRowCount = (e, data) => {
        let activePage = Math.floor(offset / limit) + 1;
        setLimit(+data.value);
        setOffset( data.value > rowCount ? 0 : +data.value)
        setRowCount(0);
        setActivePage(activePage);
    };
    const onPageChange = (e, data) => {
      if(tempName && tempName.trim()){
        getPrizePoolByKeywordPart({
          title: tempName.trim(),
          offset: (data.activePage - 1) * limit,
          orderBy: sortedField,
          order: direction,
          ...filterDateForSorting,
          limit,
        })
      }else{
        getAllPrizeRules({
          offset: (data.activePage - 1) * limit,
          orderBy: sortedField,
          order: direction,
          ...filterDateForSorting,
          limit,

        });
      }
        setOffset((data.activePage - 1) * limit);
        setActivePage(data.activePage);
        setRowCount(() => data.activePage * limit - limit);
    };
    const getValue = (e) => {
        setTempName(e.target.value)
      setActivePage(1);
      setOffset(0);
    }


    const closeCreateModal = () => {
        if (!!requestMessage) resetRequestMessage('');
        setOpenModal(false);
        getAllPrizeRules({limit, offset});
    };
    const close = () => {
        //  if (!!requestMessage) resetRequestMessage('');
        setOpenUniversalModal(false);
    };

    const action = (type, name, id) => {
        getPrizeRules()
        if (type === 'Edit') {
            getPrizeRuleName({title: name});
            setOpenUniversalModal(true);
            setType(type);
            setComponentName('EditorCopyPrizePoolRule');

        } else if (type === 'Copy') {
            getPrizeRuleName({title: name});
            setOpenUniversalModal(true);
            setType(type);
            setComponentName('EditorCopyPrizePoolRule');
        } else if (type === 'Create') {
            setOpenUniversalModal(true);
            setType(type);
            resetPrizeRule(null);
            setComponentName('CreatePrizePoolRuleTemplate');

        } else if (type === 'Delete') {
            setTemplateName(name);
            setOpenConfirmModal(true);
        }

    };

    const sorting = (field, direction) => {
        if(tempName && tempName.trim()){
            getPrizePoolByKeywordPart({
                title: tempName.trim(),
                orderBy: field === 'name' ? 'title' : 'amount',
                order: direction,
                ...filterDateForSorting,
                limit,
                offset: 0,
            })
        }
        else{
            getAllPrizeRules({
                orderBy: field === 'name' ? 'title' : 'amount',
                order: direction,
                ...filterDateForSorting,
                limit,
                offset: 0,
            });
        }
        setSortedField(field === 'name' ? 'title' : 'amount');
        setActivePage(1);
        setDirection(direction);
        setRowCount(0);
    };
    const deleteTemplate=()=>{
        deletePrizeRuleByName({name});
        setOpenConfirmModal(false);
    };

    return (
        <div className="tournament-content">
            {confirmModal ?
                <Confirm
                    className={'own-confirm'}
                    open={confirmModal}
                    content='Are you sure, you want to delete this template?'
                    onCancel={()=>setOpenConfirmModal(false)}
                    onConfirm={deleteTemplate}
                    confirmButton={'Delete'}
                />: null}
            <div className="page-title min-width">
                {openUniversalModal && (
                    <ModalComponentContent
                        componentName={componentName}
                        title={type}
                        close={close}
                        name={'prize template'}
                        id={id}
                    />
                )}
                <h2>Prize Rule Templates</h2>
                <div
                  className={
                    requestMessage && requestMessage.error ? 'req-mess err set' : 'req-mess set'
                  }
                >
                  {requestMessage && requestMessage.message}
                </div>
                <div>
                    <Input
                        label={'Search'}
                        placeholder={'Search by Name...'}
                        value={tempName !== null?tempName:''}
                        onChange={getValue}
                    />

                    <Button
                        primary
                        className="create-user btn belote-green"
                        onClick={() => action('Create')}
                    >
                        Create Template
                    </Button>
                </div>
            </div>

            { !loader ? templatesData && templatesData.length ? (
                <>
                    <div className="row-count">
                        <div className="label">Rows count per page</div>
                        <div>
                            <Select
                                selectOnBlur={false}
                                className="own"
                                placeholder={`${limit} rows`}
                                options={rowItems}
                                onChange={getRowCount}
                            />
                        </div>
                    </div>
                    <div className='table-and-pg'>
                    <TemplatesTournamentsTable
                        data={templatesData}
                        cols={columns}
                        action={action}
                        sorting={sorting}
                        count={rowCount}
                    />
                    <Pagination
                        activePage={activePage}
                        totalPages={totalPagesReducer}
                        className="pagi-own"
                        onPageChange={totalPagesReducer > 1 ? onPageChange : null}
                    />
                    </div>
                </>
            ) : (
                <div className="error-mes date-err">No data was found</div>
            ): <Loader active> Loading ... </Loader>}
        </div>
    );
}

const mapStateToProps = ({allPrizeRulesListReducer, totalPagesReducer, requestMessage, loaderReducer}) => ({
    templatesData: allPrizeRulesListReducer,
    totalPagesReducer,
    requestMessage,
    loader: loaderReducer,

});

const mapDispatchToProps = {
    getAllPrizeRules,
    resetRequestMessage,
    deletePrizeRuleByName,
    getPrizeRuleName,
    searchTournamentsTemplates,
    getPrizeRules,
    resetPrizeRule,
    getPrizePoolByKeywordPart,
    leftDropDownMenuControl,

};

export default connect(mapStateToProps, mapDispatchToProps)(AllPrizeRules);

