import React from 'react';
import { useTranslation } from 'react-i18next';
import { TABLE_ROW_GAME_TYPE_CLASSES } from '../../../../../../constants/classicLobby/classes';

function InfoTypeKnock({ settings }) {
    const tournamentsSettingsList = Object.keys(settings || {}).filter((id) => !!settings[id]);
    const { t } = useTranslation();

    return (
        <div className="tour-info__info-row">
            <div className="tour-info__info-cell tour-info__info-cell--left">{t('Type')}</div>
            <div className="tour-info__info-cell tour-info__info-cell--right">
                <ul className="lobby__table-cell-element lobby__table-cell-element--tournaments-info type-list">
                    {tournamentsSettingsList.map((setting) => (
                        <li key={setting} className={`type-item type-item--${TABLE_ROW_GAME_TYPE_CLASSES[setting]}`} />
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default InfoTypeKnock;
