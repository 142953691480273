import React from 'react';
import { setClass } from '../../../../../../../helpers/game';
import { NOTES_COLORS_CLASSES } from '../../../../../../../constants/game/classes';

function ColorButton({ isActive, colorId, onClick }) {
    return (
        <li className={`blot-field__user-icon-message-add-popup-color-choice-item ${setClass(isActive, 'active')}`}>
            <button
                className={`blot-field__user-icon-message-add-popup-color-choice-btn 
                blot-field__user-icon-message-add-popup-color-choice-btn-${NOTES_COLORS_CLASSES[colorId]}`}
                id={colorId}
                onClick={onClick}
            />
        </li>
    );
}

export default ColorButton;
