import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export function useDefineDaveText(davePower, bottomPlayerId, setter) {
    const { t } = useTranslation();

    useEffect(() => {
        const { current, offered, isOffer, playerId: davePlayerId, isGameOver } = davePower;
        const isBottomPlayerAction = davePlayerId === bottomPlayerId;
        let text = '';
        if (davePlayerId) {
            if (isGameOver) {
                text = t('I disagree');
            } else {
                if (isOffer) {
                    text = `${t('Quansh')} ${offered}x`;
                } else {
                    if (!!offered) {
                        const isAgree = current === offered;
                        if (isAgree) text = t('I agree');
                    }
                }
            }

            isBottomPlayerAction && setter({ playerText: text, opponentText: '' });
            !isBottomPlayerAction && setter({ opponentText: text, playerText: '' });
        }
    }, [davePower]);
}
