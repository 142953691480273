import React, {useEffect} from 'react';
import {Table} from 'semantic-ui-react';
import {connect} from 'react-redux';
import {getOneSpin} from '../../redux/actions/spinAction'

const gameCombs = {
    "1": "All combinations",
    "2": "Without 4 cards and 100",
    "3": "Belote / Rebelote only",
}

const CurrentSpin = ({ id,
                      ...redux
                     }) => {
    const {getOneSpin, spin, prize} = redux;
    useEffect(() => {
        getOneSpin({id})
    }, []);
    return (
        <div className="spin-info-content">
            <Table celled fixed size="small" className={'black'}>
                <Table.Header>
                    <Table.Row>
                        <Table.Cell>
                            <h5>Spin info </h5>
                        </Table.Cell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>ID: &nbsp;</Table.Cell><Table.Cell>{spin.id}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Start Date: &nbsp;</Table.Cell><Table.Cell>{spin.created_at}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Buy In: &nbsp;</Table.Cell><Table.Cell>{spin.buyin}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Prize Pool: &nbsp;</Table.Cell><Table.Cell>{spin.prizepool}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Game Points: &nbsp;</Table.Cell><Table.Cell>{spin.props_points}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Game Combs: &nbsp;</Table.Cell><Table.Cell>{gameCombs[spin.props_combs]}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Game Time: &nbsp;</Table.Cell><Table.Cell>{spin.props_timeout}s</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Disconnect Time:
                            &nbsp;</Table.Cell><Table.Cell>{spin.props_idle_timeout}s</Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
            <br/>

            <Table celled fixed size="small" className={'blacklist black'}>
                <Table.Header>
                    <Table.Row>
                        <Table.Cell>
                            <h4>Winners: {prize.winners} </h4>
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row className={'tr-spin-bg-color'}>
                        <Table.Cell>
                            Places:
                        </Table.Cell>
                        <Table.Cell>
                            1-st Place:
                        </Table.Cell>
                        <Table.Cell>
                            2-nd Place:
                        </Table.Cell>
                        <Table.Cell>
                            3-rd Place:
                        </Table.Cell>
                        <Table.Cell>
                            4-th Place:
                        </Table.Cell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    <Table.Row >
                        <Table.Cell>
                            Amount:
                        </Table.Cell>
                    {spin.prizeDistribution ? spin.prizeDistribution.map((item, key) => {
                        return (
                                <Table.Cell key={key}>
                                    {spin.buyin * item}
                                </Table.Cell>
                        )
                    }) : null
                    }
                    </Table.Row>
                </Table.Body>
            </Table>


        </div>
    );
};

const mapStateToProps = ({oneSpinReducer, oneSpinPrizeReducer}) => ({
    spin: oneSpinReducer,
    prize: oneSpinPrizeReducer
});

const mapDispatchToProps = {
    getOneSpin

};

export default connect(mapStateToProps, mapDispatchToProps)(CurrentSpin);