import {createReducer} from '../../helpers/general';
import {USER_TYPES, USERS_TYPES} from '../actionTypes';

const {RESET_USER_DATA, SET_USER_DATA, ERROR_MESSAGE_USER, SET_USER_TIMEZONE_NAME} = USER_TYPES;
const {SET_USERS, REQUEST_MESSAGE, RESET_REQUEST_MESSAGE} = USERS_TYPES;

export const userReducer = createReducer({}, (state, {value}) => {
    return {
        [SET_USER_DATA]: value ? prepareData(value) : state,
        [RESET_USER_DATA]: {},
        [ERROR_MESSAGE_USER]: {errors: value},
    };
});

export const usersDataReducer = createReducer([], (state, {value}) => {
    return {
        [SET_USERS]: value ? value : state,
    };
});
export const userTimeZoneReducer = createReducer('Asia/Yerevan', (state, {value}) => {
    return {
        [SET_USER_TIMEZONE_NAME]: value ? value : state,
    };
});
export const requestMessage = createReducer('', (state, {value}) => {
    return {
        [REQUEST_MESSAGE]: value,
        [RESET_REQUEST_MESSAGE]: value,
    };
});

function prepareData(val) {
    const data = {...val};
    const obj = {};
    const permissions = data['permissions']?data['permissions'].reduce((obj, elem) => {
        obj = {
            ...obj,
            [elem.trim()]: elem.trim()
        };
        return obj
    }, obj):null;
    return {...data, permissions}
}

export default {
    userReducer,
    usersDataReducer,
    requestMessage,
    userTimeZoneReducer
};
