import React,{useState, useEffect} from 'react';
import DateController from './DateController';
import Date from './startDate';
import { Icon } from 'semantic-ui-react';
import { setDateAndTime } from '../../../redux/actions/tournamentsAction';
import { connect } from 'react-redux';
import moment from 'moment';

function StartDateController({ period, editOrCopy, dailyErrorHandler,...redux }) {
    const { setDateAndTime, dateObj, tournamentData } = redux;


  useEffect(()=>{

    if (period){
    //  console.log(period,"StartDateController")
      setDateAndTime({
        ...dateObj,
        scheduleAt:[],
      });
    }

  },[period])
    const addDateController = () => {
   //  console.log(period,"tournamentData-addDateController")
      if (tournamentData.scheduledSequence && tournamentData.scheduledSequence.iterator || period) {
          dailyErrorHandler('Tournament can not have more than 1 start date, when period is active.  ')
             }else{

        if (dateObj.scheduleAt.length <= 8) {
          const newArr = [...dateObj.scheduleAt];
          newArr.push({
            date: moment().format('DD-MM-YYYY'),
            time: moment().format('HH:mm:ss'),
          });
          setDateAndTime({
            ...dateObj,
            scheduleAt: newArr,
          });
        }
      }

    };
    const removeDateController = (index) => {
        if (dateObj.scheduleAt.length > 0) {
            const newArr = [...dateObj.scheduleAt];
            newArr.splice(index, 1);
            setDateAndTime({
                ...dateObj,
                scheduleAt: newArr,
            });
        }
    };

    return (

        <div style={editOrCopy?{width:'500px',paddingLeft: '13px',minHeight:'40px'}:{flexBasis:'500px',minHeight:'40px'}}>
            <div className={!editOrCopy?'inline-flex':null}>
                <DateController
                    title={'Start Date:'}
                    stateName={'start'}
                    showSubPicker={false}
                    showDatePicker={true}
                    subTitle={null}
                    editOrCopy={editOrCopy}
                />
                <Icon name="add" className="rem-flex add-own" onClick={addDateController} />
            </div>
            {dateObj.scheduleAt.map((item, key) => {
                return (
                    <div key={key} className='inline-flex'>
                        <Date key={key} title={'Start Date:'} index={key}/>
                        <Icon
                            name="remove"
                            className="color-red remove-own rem-flex"
                            onClick={() => removeDateController(key)}
                        />
                    </div>
                );
            })}
        </div>

    );
}

const mapStateToProps = ({ tournamentDateReducer,oneTournamentReducer }) => ({
    dateObj: tournamentDateReducer,
    tournamentData: oneTournamentReducer,
});

const mapDispatchToProps = {
    setDateAndTime,
};

export default connect(mapStateToProps, mapDispatchToProps)(StartDateController);
