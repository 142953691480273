/**
 *  old reducer example:                            	new reducer example:
 *  function counterReducer(state = 0, action) {	 	const counterReducer = createReducer(0, (state, action)=>{
		switch (action.type) {                      		return {
			case 'INCREMENT':                       			'INCREMENT': state + 1,
				return state + 1;                   			'DECREMENT': state - 1,
			case 'DECREMENT':                       			'SOME_FUNCTIONALITY' : ()=> {
				return state - 1;                   				//do some functionality here
			case 'SOME_FUNCTIONALITY' :             				return 'something';
				//do something here                 			}
				return 'something';                 		}
			default:                                	});
				return state
		}
	}
 */

export function createReducer(initialState, cb) {
    return function(state = initialState, action) {
        const switchableObject = cb(state, action);
        if (switchableObject.hasOwnProperty(action.type)) {
            if (typeof switchableObject[action.type] === 'function') {
                return switchableObject[action.type]();
            }
            return switchableObject[action.type];
        }
        return state;
    };
}

export function createAction(type, haveValue = false) {
    return haveValue ? (value) => ({ type, value }) : { type };
}
