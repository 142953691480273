import React, {useEffect, useRef} from 'react';
import DatePick from '../../../components/Datapiker/DataPicker';
import { TimeInput } from '@trendmicro/react-datepicker';
import moment from 'moment';
import { setDateAndTime } from '../../../redux/actions/tournamentsAction';
import { connect } from 'react-redux';
import {arrClass} from "../../../components/Datapiker/general";

function DateController({
    title,
    stateName,
    showSubPicker,
    showDatePicker,
    subTitle,
    disable,
    template,
    editOrCopy,
    ...redux
}) {
    const { setDateAndTime, dateObj } = redux;
    const [showPicker, setShowPicker] = React.useState('');
    const [error, setError] = React.useState({ day: '', hour: '', min: '' });
    const [type, setType] = React.useState('');
    let refFrom = useRef(null);

    const handleClick = (e) => {
        if (showPicker === stateName && refFrom.current &&  !arrClass.includes(e.target.className)) {
            setShowPicker('')
        }

    };

    useEffect(() => {
        document.addEventListener('click', handleClick);
        return () => {
            document.removeEventListener('click', handleClick);
        };
    });

    useEffect(() => {
        if (type === stateName) {
            let newDate = dateObj[`startDate`].split('-');
            let newTime = dateObj[`startTime`].split(':');
            let subSrtact;
            //if(error.day || error.hour || error.min) setError({day:'',hour:'',min:''});
            let dateTime = new Date(
                +newDate[2],
                +newDate[1] - 1,
                +newDate[0],
                +newTime[0],
                +newTime[1],
                +newTime[2]
            );
            subSrtact = new Date(dateTime.setDate(dateTime.getDate() - dateObj[stateName].day));
            subSrtact = new Date(
                subSrtact.setHours(subSrtact.getHours() - dateObj[stateName].hour)
            );
            subSrtact = new Date(
                subSrtact.setMinutes(subSrtact.getMinutes() - dateObj[stateName].min)
            );
            setDateAndTime({
                ...dateObj,
                [`${stateName}Date`]: moment(subSrtact).format('DD-MM-YYYY'),
                [`${stateName}Time`]: moment(subSrtact).format('HH:mm:ss'),
            });
            //setType('')
        }
    }, [
        type,
        dateObj['register'],
        dateObj['announcementStart'],
        dateObj['announcementStartDate'],
        dateObj['announcementStartTime'],
        dateObj['registerDate'],
        dateObj['registerTime'],
    ]);

    const openPicker = (e) => {
        setShowPicker(e);
    };
    const getValueDate = (value, name) => {
        let newDate;
        newDate = value.split('-');
        if (!showSubPicker && stateName === 'start') {
            let register = new Date(
                Date.UTC(+newDate[2], +newDate[1]-1, +newDate[0] - dateObj['register']['day'])
            );
            let anouncement = new Date(
                Date.UTC(
                    +newDate[2],
                    +newDate[1] - 1,
                    +newDate[0] - dateObj['announcementStart']['day']
                )
            );

            setDateAndTime({
                ...dateObj,
                [name]: value,
                registerDate: moment(register).format('DD-MM-YYYY'),
                announcementStartDate: moment(anouncement).format('DD-MM-YYYY'),
            });
        } else {
            let startDate = dateObj['startDate'].split('-');
            let startTime = dateObj['startTime'].split(':');
            let date = value.split('-');
            let time = dateObj[`${stateName}Time`].split(':');
            let start = new Date(
                +startDate[2],
                +startDate[1],
                +startDate[0],
                +startTime[0],
                +startTime[1],
                +startTime[2]
            );
            let temp = new Date(+date[2], +date[1], +date[0], +time[0], +time[1], +time[2]);
            let diffDays = moment(start).diff(moment(temp), 'days');
            let diffHours = moment(start).diff(moment(temp), 'hours') % 24;
            let diffMin = moment(start).diff(moment(temp), 'minutes');
            if (diffDays > 0) {
                setDateAndTime({
                    ...dateObj,
                    [name]: value,
                    [stateName]: {
                        ...dateObj[stateName],
                        day: diffDays,
                        hour: diffHours,
                        min: diffMin - diffDays * 24 * 60 - diffHours * 60,
                    },
                });
            }
        }
        setShowPicker('');
    };

    const getValueTime = (value, name) => {
        setType('');
        let newTime, newDate, newDateAnounce;
        newTime = value.split(':');
        newDate = dateObj['registerDate'].split('-');
        newDateAnounce = dateObj['announcementStartDate'].split('-');
        if (!showSubPicker && stateName === 'start') {
            let d = new Date(
                +newDate[2],
                +newDate[1],
                +newDate[0],
                +newTime[0] - dateObj['register']['hour'],
                +newTime[1] - dateObj['register']['min'],
                +newTime[2]
            );
            let a = new Date(
                +newDateAnounce[2],
                +newDateAnounce[1],
                +newDateAnounce[0],
                +newTime[0] - dateObj['announcementStart']['hour'],
                +newTime[1] - dateObj['announcementStart']['min'],
                +newTime[2]
            );
            setDateAndTime({
                ...dateObj,
                [name]: value,
                registerTime: moment(d).format('HH:mm:ss'),
                announcementStartTime: moment(a).format('HH:mm:ss'),
            });
        } else {
            let startDate = dateObj['startDate'].split('-');
            let startTime = dateObj['startTime'].split(':');
            let date = dateObj[`${stateName}Date`].split('-');
            let time = value.split(':');
            let start = new Date(
                +startDate[2],
                +startDate[1],
                +startDate[0],
                +startTime[0],
                +startTime[1],
                +startTime[2]
            );
            let temp = new Date(+date[2], +date[1], +date[0], +time[0], +time[1], +time[2]);
            let diffDays = moment(start).diff(moment(temp), 'days');
            let diffHours = moment(start).diff(moment(temp), 'hours') % 24;
            let diffMin = moment(start).diff(moment(temp), 'minutes');
            // if (diffDays > 0) {
            //     setDateAndTime({
            //         ...dateObj,
            //         [name]: value,
            //         [stateName]: {
            //             ...dateObj[stateName],
            //             day: diffDays,
            //             hour: diffHours,
            //             min: diffMin - diffDays * 24 * 60 - diffHours * 60,
            //         },
            //     });
            // }
            setDateAndTime({
                ...dateObj,
                [name]: value,
                [stateName]: {
                    ...dateObj[stateName],
                    day: diffDays,
                    hour: diffHours,
                    min: diffMin - diffDays * 24 * 60 - diffHours * 60,
                },
            });
        }
        setShowPicker('');
    };
    const getValueByPart = (e, name) => {

   if( !isNaN(e.target.value) &&  e.target.value.length < 6) {
       setType(stateName);
       setDateAndTime({
           ...dateObj,
           [stateName]: {
               ...dateObj[stateName],
               [name]: +e.target.value,
           },
       });
   }


    };
    return (
        <div
            className={
                stateName === 'start'
                    ?editOrCopy? 'width-50 float margin-l date-flex v-align black-own':
                    'width-50 float margin-l date-flex v-align'
                    : editOrCopy?'margin-l date-flex v-align black-own':'margin-l date-flex v-align'
            }
            style={ stateName !== 'start'? {width: '672px' }: {width: 'none'}}
        >
            {!template &&
            <div className="date-flex max-height float width-60 between">
                <div className="min-width-100"
                     style={ stateName !== 'start'? {paddingLeft: "12px",width: 'min-content'}:null} >{title}</div>
                {showDatePicker ? (
                    <div className="date-from">
                        <div className="ui left icon input date  width-60">
                            <input
                                ref={refFrom}
                                type="text"
                                size={10}
                                value={dateObj[`${stateName}Date`]}
                                disabled={disable}
                                onClick={() => openPicker(stateName)}
                                onChange={() => openPicker(stateName)}
                            />
                            <i aria-hidden="true" className="calendar icon"/>
                        </div>
                        {showPicker === stateName && (
                            <DatePick
                                close={getValueDate}
                                value={dateObj[`${stateName}Date`]}
                                createTournament={stateName + 'Date'}
                            />
                        )}
                        <div className="left">
                            <TimeInput
                                className={disable ? 'disabled' : null}
                                insertion={disable ? 0 : 1}
                                value={dateObj[`${stateName}Time`]}
                                onChange={(e) => getValueTime(e, stateName + 'Time')}
                            />
                        </div>
                    </div>
                ) : null}
            </div>
            }
            {showSubPicker && (
                <div className="registrationDate-manual date-flex float column margin-l">
                    <div className="label margin-registration ">{template?title:subTitle}</div>
                    <div className="registration-day-manual">
                        <div>
                            Day:
                            <input
                                type="text"
                                size={2}
                                value={dateObj[stateName].day ? dateObj[stateName].day : ''}
                                className="mini-input"
                                disabled={disable}
                                onChange={(e) => getValueByPart(e, 'day')}
                            />
                            <span className="error-mes min-height-16">{error.day}</span>
                        </div>
                        <div>
                            Hour:
                            <input
                                type="text"
                                size={2}
                                value={dateObj[stateName].hour ? dateObj[stateName].hour : ''}
                                className="mini-input"
                                disabled={disable}
                                onChange={(e) => getValueByPart(e, 'hour')}
                            />
                            <span className="error-mes min-height-16">{error.hour}</span>
                        </div>
                        <div>
                            Min:
                            <input
                                type="text"
                                size={2}
                                value={dateObj[stateName].min ? dateObj[stateName].min : ''}
                                className="mini-input"
                                disabled={disable}
                                onChange={(e) => getValueByPart(e, 'min')}
                            />
                            <span className="error-mes min-height-16">{error.min}</span>
                        </div>
                    </div>
                </div>
            )}
          </div>
    );
}

const mapStateToProps = ({ tournamentDateReducer }) => ({
    dateObj: tournamentDateReducer,
});

const mapDispatchToProps = {
    setDateAndTime,
};

export default connect(mapStateToProps, mapDispatchToProps)(DateController);
