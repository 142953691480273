import React, { useState, useEffect } from 'react';
import {  Loader, Table } from 'semantic-ui-react';
import { getGameLogs, resetGameLogs } from '../redux/actions/logsActions';
import { connect } from 'react-redux';
import moment from 'moment';
import Checkbox from 'semantic-ui-react/dist/commonjs/modules/Checkbox';
import {resetRequestMessage} from "../redux/actions/otherActions";


function Logs({ logFor, db, gameId, ...redux }) {
    const { gameLogs, cols, getGameLogs, clearLogs, requestMessage, resetRequestMessage} = redux;
    const [colsState, setCols] = useState(gameLogs);
    const [frontLogs, setFrontLogs] = useState([]);
    const [type, setType] = useState({ back: '', front: '' });


    useEffect(() => {
        getGameLogs({ needLogs: true, id: gameId, from:0, logsFor: db });
        return ()=> {
            console.log('unmount - LOGS');
            clearLogs();
            resetRequestMessage('');
        }
    }, []);
    useEffect(() => {
        const dateIndex = cols ? cols.indexOf('date') : null;
        if (dateIndex !== null) {
            cols.splice(dateIndex, 1);
        }
        setCols(cols);
        setFrontLogs(gameLogs);
    }, [cols, gameLogs]);

    useEffect(() => {
        let filteredLogs;
        if (type.hasOwnProperty('back') && type.hasOwnProperty('front')) {
            setFrontLogs(gameLogs);
        } else if (type.hasOwnProperty('back')) {
            filteredLogs = gameLogs.filter((el) => el['TR_PREFIX'].action.indexOf('FRONT') === -1);
            setFrontLogs(filteredLogs);
        } else if (type.hasOwnProperty('front')) {
            filteredLogs = gameLogs.filter((el) => el['TR_PREFIX'].action.indexOf('FRONT') !== -1);
            setFrontLogs(filteredLogs);
        } else {
            setFrontLogs([]);
        }
    }, [type]);


    const handleFilter = (t) => {
        if (type.hasOwnProperty(t)) {
            const obj = type;
            delete obj[t];
            setType({ ...obj });
        } else {
            setType((type) =>
                ({ ...type, [t]: '' }));
        }
    };

    return (
        <>
            {logFor === 'game' ?
                <div className="check-box flex-end">
                    <Checkbox className={'margin-r'}
                              label={'Show backend logs'}
                              onClick={() => handleFilter('back')}
                              checked={type.hasOwnProperty('back')}
                    />
                    <Checkbox className={'margin-r'}
                              label={'Show frontend logs'}
                              onClick={() => handleFilter('front')}
                              checked={type.hasOwnProperty('front')}
                    />
                </div> : null}

            {gameLogs.length && frontLogs.length ?
                    <Table sortable celled fixed size="small">
                        <Table.Header>
                            <Table.Row>
                                <Table.Cell>
                                    Date
                                </Table.Cell>
                                <Table.Cell colSpan={colsState.length}>
                                    Info
                                </Table.Cell>
                            </Table.Row>

                        </Table.Header>
                        <Table.Body>
                            {
                                frontLogs.map((item, index) => {
                                    return (
                                        <Table.Row key={index}>
                                            <Table.Cell>
                                                {moment(item['TR_PREFIX']['date']).format('DD-MM-YYYY HH:mm:ss')}
                                            </Table.Cell>
                                            <Table.Cell colSpan={colsState.length}>
                                                <Table celled fixed size="small">
                                                    <Table.Body>
                                                        {colsState.length ?
                                                            colsState.map((el, key) => {
                                                                return (
                                                                    <Table.Row key={key}>
                                                                        <Table.Cell style={{ width: '120px' }}>
                                                                            {el + ':'}
                                                                        </Table.Cell>
                                                                        <Table.Cell>
                                                                            {item['TR_PREFIX'][el] ? item['TR_PREFIX'][el] : '_'}
                                                                        </Table.Cell>
                                                                    </Table.Row>);
                                                            }) : null}
                                                    </Table.Body>
                                                </Table>
                                            </Table.Cell>
                                        </Table.Row>
                                    );
                                })
                            }
                        </Table.Body>
                    </Table>

                : type.hasOwnProperty('front') && type.hasOwnProperty('back') ?
                    !gameLogs.length ? requestMessage.error?<div>{requestMessage.message} </div> : <div>No logs for this games</div> :
                        <Loader active> Loading ... </Loader> : 'No logs'}


        </>
    );
}

const mapStateToProps = ({ gameLogsReducer,requestMessage}) => ({
    gameLogs: gameLogsReducer,
    cols: gameLogsReducer.length ? Object.keys(gameLogsReducer[0]['TR_PREFIX']) : [],
    requestMessage
});

const mapDispatchToProps = {
    getGameLogs,
    clearLogs: ()=>resetGameLogs,
    resetRequestMessage
};

export default connect(mapStateToProps, mapDispatchToProps)(Logs);
