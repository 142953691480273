import { getToken } from '../../configs/server';

import { TOURNAMENTS_TYPES, USERS_TYPES, OTHER_TYPES, MAIN_TYPES, GAME_TYPES } from '../actionTypes';
import moment from 'moment';
import { createAction } from '../../helpers/general';
import instance from '../../helpers/Api';
import { sortingByDate } from './gamesAction';

const {
    SET_TOURNAMENTS,
    SET_DATE_OR_TIME,
    SET_All_PRIZE_RULES,
    SET_ONE_PRIZE_RULE,
    SET_SELECTED_TOURNAMENT_DATA,
    SET_DATE_OR_TIME_FROM_SELECTED_TOURNAMENT,
    RESET_DATE_OR_TIME,
    SET_TOURNAMENTS_TEMPLATES,
    SET_TOURNAMENTS_TEMPLATES_LIST,
    SET_TOURNAMENT_PLAYERS,
    SET_WINNERS_TRANSACTIONS,
    RESET_TABS_INFO,
    SET_All_PRIZE_RULES_LIST,
    RESET_ONE_PRIZE_RULE,
    SET_TOURNAMENT_ROUNDS,
    SET_ADMIN_ADDED_PLAYERS_LIST,
    UPDATE_ADMIN_ADDED_PLAYERS_LIST,
} = TOURNAMENTS_TYPES;
const { REQUEST_MESSAGE } = USERS_TYPES;
const { GET_TOTAL_PAGES_COUNT_NEW } = OTHER_TYPES;
const { LOADER_HIDE, LOADER_SHOW } = MAIN_TYPES;
const { SET_GAME_LOGS } = GAME_TYPES;


export function getAllTournaments(data) {
    return (dispatch) => {
        instance.post('/api/tournaments/get', data)
            .then((res) => {
                //console.log(res, "res-tournaments");
                if (!res.data.error) {
                    dispatch({ type: SET_TOURNAMENTS, value: res.data.data.tournaments });
                    dispatch({ type: GET_TOTAL_PAGES_COUNT_NEW, value: res.data.data });
                    dispatch(createAction(LOADER_HIDE));
                } else {
                    dispatch({ type: REQUEST_MESSAGE, value: res.data });
                }
            })
            .catch((e) => {
                dispatch(createAction(LOADER_SHOW));
                console.log('getPlayers', e);
            });
    };
}

export function getTournamentPlayers(data) {
    return (dispatch) => {
        instance.post('/api/tournaments/getTournamentPlayers', data)
            .then((res) => {
                dispatch({ type: SET_TOURNAMENT_PLAYERS, value: res.data.data.players });
                dispatch({ type: GET_TOTAL_PAGES_COUNT_NEW, value: res.data.data });
            })
            .catch((e) => {
                console.log('getTournamentPlayers', e);
            });
    };
}

export function getWinners(data, type) {
    return (dispatch) => {
        // instance.post('/api/tournaments/transactions', data)
        instance.post('/api/spin/getWinners', data)
            .then((res) => {
                if (!res.data.error) {
                    dispatch({ type: SET_WINNERS_TRANSACTIONS, value: prepareWinnersData(res.data.data, type)});
                    dispatch({ type: GET_TOTAL_PAGES_COUNT_NEW, value: res.data.data });
                }
            })
            .catch((e) => {
                console.log('getWinners', e);
            });
    };
}

export function cutPlayerFromTournament(data) {
    return (dispatch) => {
        instance.post('/api/tournaments/cutPlayerFromTournament', data)
            .then((res) => {
                dispatch({ type: REQUEST_MESSAGE, value: res.data });
            })
            .catch((e) => {
                console.log('cutPlayerFromTournament', e);
            });
    };
}

export function getAllTournamentsTemplates() {
    return (dispatch) => {
        instance.post('/api/tournaments/getTemplateNames')
            .then((res) => {
                dispatch({ type: SET_TOURNAMENTS_TEMPLATES, value: res.data });
            })
            .catch((e) => {
                console.log('getPlayers', e);
            });
    };
}

export function getAdminAddedPlayers(data) {
    return (dispatch) => {
        instance.post('/api/tournaments/getAdminAddedPlayers', data)
            .then((res) => {
                // console.log(res.data, "res-SET_ADMIN_ADDED_PLAYERS_LIST");
                if (!res.data.error) {
                    if (!res.data.data.length) {
                        dispatch({ type: REQUEST_MESSAGE, value: { error: true, message: 'No users added by admin' } });
                    } else {
                        dispatch({ type: SET_ADMIN_ADDED_PLAYERS_LIST, value: res.data });
                    }
                } else {
                    dispatch({ type: REQUEST_MESSAGE, value: res.data });
                }

            })
            .catch((e) => {
                console.log('getAdminAddedPlayers', e);
            });
    };
}

export function getRoundsOfTournament(data) {
    return (dispatch) => {
        instance.post('/api/tournaments/getRounds', data)
            .then((res) => {
                //  console.log(res, "res-getRound");
                dispatch({ type: SET_TOURNAMENT_ROUNDS, value: res.data });
            })
            .catch((e) => {
                console.log('getRound', e);
            });
    };
}

export function getAllTournamentsTemplatesList(data) {
    return (dispatch) => {
        instance.post('/api/tournaments/getAllTournamentTemplates', data)
            .then((res) => {
                //console.log(res, "res-getAllTournamentTemplates"); tournamentTemplate
                dispatch({ type: SET_TOURNAMENTS_TEMPLATES_LIST, value: res.data.data.tournamentTemplate });
                dispatch({ type: GET_TOTAL_PAGES_COUNT_NEW, value: res.data.data });
                dispatch(createAction(LOADER_HIDE));

            })
            .catch((e) => {
                dispatch(createAction(LOADER_SHOW));
                console.log('getAllTournamentTemplates', e);
            });
    };
}

export function getAllPrizeRules(data) {
    // console.log(data, "getAllTournamentTemplates-dataa")
    return (dispatch) => {
        instance.post('/api/tournaments/getAllPrizeRules', data)
            .then((res) => {
                dispatch({ type: SET_All_PRIZE_RULES_LIST, value: preparePrizeListData(res.data.data.prizePool) });
                dispatch({ type: GET_TOTAL_PAGES_COUNT_NEW, value: res.data.data });
                dispatch(createAction(LOADER_HIDE));
            })
            .catch((e) => {
                dispatch(createAction(LOADER_SHOW));
                console.log('getAllPrizeRules', e);
            });
    };
}

export function getPrizePoolByKeywordPart(data) {
    // console.log(data, "getAllTournamentTemplates-dataa")
    return (dispatch) => {
        instance.post('/api/tournaments/getPrizePoolByKeywordPart', data)
            .then((res) => {
                // console.log(res.data, "res-getPrizePoolByKeywordPart")
                if (!res.data.error && res.data.data.pageCount) {
                    dispatch({ type: SET_All_PRIZE_RULES_LIST, value: preparePrizeListData(res.data.data.prizePool) });
                } else {
                    dispatch({ type: SET_All_PRIZE_RULES_LIST, value: [] });
                }
                dispatch({ type: GET_TOTAL_PAGES_COUNT_NEW, value: res.data.data });
                dispatch(createAction(LOADER_HIDE));
            })
            .catch((e) => {
                dispatch(createAction(LOADER_SHOW));
                console.log('getAllPrizeRules', e);
            });
    };
}

export function searchTournamentsTemplates(data) {
    // console.log(data, "getAllTournamentTemplates-dataa")
    return (dispatch) => {
        instance.post('/api/tournaments/getTournamentTemplateByKeywordPart', data)
            .then((res) => {
                //console.log(res, "res-searchTournamentsTemplates");
                dispatch({ type: SET_TOURNAMENTS_TEMPLATES_LIST, value: res.data.data.tournaments });
                dispatch({ type: GET_TOTAL_PAGES_COUNT_NEW, value: res.data.data });
                dispatch(createAction(LOADER_HIDE));
            })
            .catch((e) => {
                dispatch(createAction(LOADER_SHOW));
                console.log('searchTournamentsTemplates', e);
            });
    };
}

export function getCurrentTournamentByName(data) {
    //  console.log(data, 'getCurrentTournamentByName')

    return (dispatch) => {
        instance.post('/api/tournaments/getTournamentTemplateByName', data)
            .then((res) => {
                // console.log(res, 'res-getCurrentTournamentByName')
                let dateDataRegister = convertToDayHourMin(
                    res.data.data[0].playerRegistrationStart,
                    'register',
                );
                let dateDataAnnounceStart = convertToDayHourMin(res.data.data[0].announcementStart, 'announcementStart');
                let dateDataAnnounceEnd = convertToDayHourMin(res.data.data[0].announcementEnd, 'announcementEnd');
                dispatch({ type: SET_SELECTED_TOURNAMENT_DATA, value: res.data.data[0] });
                dispatch({
                    type: SET_DATE_OR_TIME_FROM_SELECTED_TOURNAMENT,
                    value: {
                        ...dateDataRegister, ...dateDataAnnounceStart, ...dateDataAnnounceEnd,
                        // finishedAt: {min: res.data.data[0].finishedAt}
                    },
                });


                dispatch({
                    type: SET_ONE_PRIZE_RULE,
                    value: { data: res.data.data[0].prizeDistribution },
                });
            })
            .catch((e) => {
                console.log('getCurrentTournamentByName', e);
            });
    };
}

export function createTournamentTemplate(data) {
    return (dispatch) => {
        instance.post('/api/tournaments/createTournamentTemplate', data)
            .then((res) => {
                //  console.log( res, "RES- createNewTournament")
                dispatch({ type: REQUEST_MESSAGE, value: res.data });
            })
            .catch((e) => {
                console.log(e, 'err-createTournamentTemplate');
            });
    };
}

export function updateTournamentTemplate(data) {
    return (dispatch) => {
        instance.post('/api/tournaments/updateTournamentTemplate', data)
            .then((res) => {
                //  console.log( res, "RES- createNewTournament")
                dispatch({ type: REQUEST_MESSAGE, value: res.data });
            })
            .catch((e) => {
                console.log(e, 'err-updateTournamentTemplate');
            });
    };
}

export function deleteTournamentTemplate(data) {
    return (dispatch) => {
        instance.post('/api/tournaments/deleteTournamentTemplate', data)
            .then((res) => {
                //  console.log( res, "RES- createNewTournament")
                dispatch({ type: REQUEST_MESSAGE, value: res.data });
            })
            .catch((e) => {
                console.log(e, 'err-deleteTournamentTemplate');
            });
    };
}

export function deletePrizeRuleByName(data) {
    return (dispatch) => {
        instance.post('/api/tournaments/deletePrizeRuleByName', data)
            .then((res) => {
                //  console.log( res, "RES- deletePrizeRuleByName")
                dispatch({ type: REQUEST_MESSAGE, value: res.data });
            })
            .catch((e) => {
                console.log(e, 'err-deletePrizeRuleByName');
            });
    };
}

export function createNewTournament(data) {
    return (dispatch) => {
        instance.post('/api/tournaments/create', data)
            .then((res) => {
                //  console.log( res, "RES- createNewTournament")
                dispatch({ type: REQUEST_MESSAGE, value: res.data });
            })
            .catch((e) => {
                console.log(e, 'err-createNewTournament');
            });
    };
}

export function savePrizeRules(data) {
    return (dispatch) => {
        instance.post('/api/tournaments/savePrizeRules', data)
            .then((res) => {
                //  console.log( res, "RES- createNewTournament")
                dispatch({ type: REQUEST_MESSAGE, value: res.data });
            })
            .catch((e) => {
                console.log(e, 'err-savePrizeRules');
            });
    };
}

export function updatePrizeTable(data) {
    return (dispatch) => {
        instance.post('/api/tournaments/updatePrizeTable', data)
            .then((res) => {
                //  console.log( res, "RES- updatePrizeTable")
                dispatch({ type: REQUEST_MESSAGE, value: res.data });
            })
            .catch((e) => {
                console.log(e, 'err-updatePrizeTable');
            });
    };
}

export function updateTournament(data) {
    return (dispatch) => {
        instance.post('/api/tournaments/update', data)
            .then((res) => {
                //  console.log( res, "RES- updatePrizeTable")
                dispatch({ type: REQUEST_MESSAGE, value: res.data });
            })
            .catch((e) => {
                console.log(e, 'err-updateTournament');
            });
    };
}

export function getPrizeRules() {
    return (dispatch) => {
        instance.post('/api/tournaments/getPrizeRules')
            .then((res) => {
                //console.log(res, 'RES- getPrizeRules')
                dispatch({ type: SET_All_PRIZE_RULES, value: res.data });
            })
            .catch((e) => {
                console.log(e, 'err-getPrizeRules');
            });
    };
}

export function getPrizeRuleName(data) {
    return (dispatch) => {
        instance.post('/api/tournaments/getPrizeRuleName', data)
            .then((res) => {
                // console.log( res, "RES-getPrizeRuleName")
                if (res.data.error) {
                    dispatch({ type: REQUEST_MESSAGE, value: res.data });
                } else {
                    dispatch({ type: SET_ONE_PRIZE_RULE, value: res.data });
                }
            })
            .catch((e) => {
                console.log(e, 'err-getPrizeRuleName');
            });
    };
}

export function openTournament(data) {
    return (dispatch) => {
        instance.post('/api/tournaments/copy', data)
            .then((res) => {
                const { data: data } = res;
                if (!data.error) {
                    prepareData(res.data.data[0]);
                    prepareDateAndTime(res.data.data[0]);
                    dispatch({
                        type: SET_SELECTED_TOURNAMENT_DATA,
                        value: { ...prepareData(res.data.data[0]) },
                    });
                    dispatch({
                        type: SET_DATE_OR_TIME_FROM_SELECTED_TOURNAMENT,
                        value: { ...prepareDateAndTime(res.data.data[0]) },
                    });
                    dispatch({
                        type: SET_ONE_PRIZE_RULE,
                        value: { data: res.data.data[0].prizeDistribution },
                    });
                    dispatch({ type: SET_GAME_LOGS, value: sortingByDate(res.data.data[0].logs) });
                } else {
                    dispatch({ type: REQUEST_MESSAGE, value: data.message });
                    console.log(data.message, 'err-openTournament');
                }
            })
            .catch((e) => {
                console.log(e, 'err-openTournament');
            });
    };
}

export function deleteTournament(data) {
    return (dispatch) => {
        instance.post('/api/tournaments/delete', data)
            .then((res) => {
                // console.log(res,"deleteTournament")
                dispatch({ type: REQUEST_MESSAGE, value: res.data });
            })
            .catch((e) => {
                console.log(e, 'err-deleteTournament');
            });
    };
}

export function addPlayerToTournament(type, data, header) {
    return (dispatch) => {
        instance.post(`/api/tournaments/${type}`, data, {
            headers: {
                'Content-Type': header, authorization: getToken(),
            },
        })
            .then((res) => {
                // console.log(res,"addPlayerToTournament")
                dispatch({ type: REQUEST_MESSAGE, value: res.data });
            })
            .catch((e) => {
                console.log(e, 'err-addPlayerToTournament');
            });
    };
}

export function setDateAndTime(value) {
    return (dispatch) => {
        dispatch({ type: SET_DATE_OR_TIME, value: value });
    };
}

export function setSelectedTournamentData(value) {
    return (dispatch) => {
        dispatch({ type: SET_SELECTED_TOURNAMENT_DATA, value: value });
    };
}

export function setPrizeDataFromTournamentTemplate(value) {
    return (dispatch) => {
        dispatch({ type: SET_ONE_PRIZE_RULE, value: value });
    };
}

export function resetDateAndTime(value) {
    return (dispatch) => {
        dispatch({ type: RESET_DATE_OR_TIME, value: value });
    };
}

export function resetPrizeRule(value) {
    return (dispatch) => {
        dispatch({ type: RESET_ONE_PRIZE_RULE, value: value });
    };
}

export function resetTabInfo(value) {
    return (dispatch) => {
        dispatch({ type: RESET_TABS_INFO, value: value });
    };
}

function prepareDateAndTime(val) {
    let startDate = splitDateTime(val.startDate, 'start');
    let playerRegDate = splitDateTime(val.playerRegDate, 'register');
    let announceDate = splitDateTime(val.announceDate, 'announcementStart');
    let announcementEnd = splitDateTime(val.announcementEnd, 'announcementEnd');
    let register = duration(val.startDate, val.playerRegDate, 'register');
    let announcementStart = duration(val.startDate, val.announceDate, 'announcementStart');
    let announcementEndDuration = duration(val.startDate, val.announcementEnd, 'announcementEnd');
    // let finishedAt = duration(val.startDate, val.finishedAt, 'finishedAt');

    const data = {
        ...startDate,
        ...playerRegDate,
        ...announceDate,
        ...announcementEnd,
        ...register,
        ...announcementStart,
        ...announcementEndDuration,
        //...finishedAt
    };

    return data;
}

function splitDateTime(val, name) {
    const splitDate = val.split(' ');
    return {
        [`${name}Date`]: splitDate[0],
        [`${name}Time`]: splitDate[1],
    };
}

function duration(start, val, name) {
    let splitStart = start.split(' ');
    let valDate = val.split(' ');
    let startDate = splitStart[0].split('-');
    let startTime = splitStart[1].split(':');
    let date = valDate[0].split('-');
    let time = valDate[1].split(':');

    let momentStart = new Date(
        +startDate[2],
        +startDate[1] - 1,
        +startDate[0],
        +startTime[0],
        +startTime[1],
        +startTime[2],
    );
    let temp = new Date(+date[2], +date[1] - 1, +date[0], +time[0], +time[1], +time[2]);
    //|| name === 'finishedAt'

    if (name === 'announcementEnd') {
        let p = momentStart;
        momentStart = temp;
        temp = p;
    }

    let diffDays = moment(momentStart).diff(moment(temp), 'days');
    let diffHours = moment(momentStart).diff(moment(temp), 'hours') % 24;
    let diffMin = moment(momentStart).diff(moment(temp), 'minutes');
    // if (name === 'finishedAt') {
    //     return {
    //         [name]: {
    //             min: diffMin,
    //         },
    //     };
    // }
    return {
        [name]: {
            day: diffDays,
            hour: diffHours,
            min: diffMin - diffDays * 24 * 60 - diffHours * 60,
        },
    };
}

function prepareData(val) {
    const temp = { ...val };
    let data;
    // delete temp.startDate;
    // delete temp.totalRounds;
    // delete temp.currentRound;
    // delete temp.state;
    // delete temp.playerRegDate;
    // delete temp.announceDate;
    // delete temp.announcementEnd;
    data = {
        ...temp,
    };

    return data;
}

function convertToDayHourMin(time, type) {
    let j, h, m;
    if (time >= 0) {
        j = time / 1440;
        h = (time % 1440) / 60;
        m = time % 60;
    }
    return { [type]: { day: Math.floor(j), hour: Math.floor(h), min: Math.floor(m) } };
}

function preparePrizeListData(val) {
    const names = [...Object.keys(val)];
    const tmp = { ...val };

    const data = [];
    for (let el of names) {
        const result = {};
        let arr = [];
        result['name'] = el;
        result['prizePool'] = tmp[el].amount;

        for (const id in tmp[el]) {
            if (id !== 'amount') {
                arr.push({ position: id, percent: tmp[el][id] });
            }

        }
        result['winners'] = [...arr];
        result['winnersCount'] = Math.pow(2, result['winners'].length - 1);
        data.push(result);
    }


    return data;
}

export function updateAdminAddedPlayerList(value) {
    return (dispatch) => dispatch({ type: UPDATE_ADMIN_ADDED_PLAYERS_LIST, value: value });
}


export function prepareWinnersData(val, type) {
    const { distribution, kind } = val;
    console.log(val,"typetypetype");

    const value = val['data'];
    // const sorted_arr =value.sort((a,b)=>(a.place > b.place)? 1: -1 );
    // console.log(sorted_arr,"sorted_arrsorted_arrsorted_arr");
    const places = {
        '1/1': '1 - st place',
        '1/2': '2 - nd place',
    };
    const prize = {
        '4': 3,
        '8': 4,
        '16': 5,
        '32': 6,
        '64': 7,
    };

    const arr = [];
    for (let i = 0; i < value.length; i++) {
        const el = value[i];
        if (el.round === '1/1') {
            arr.push({
                place: places[el.round],
                winner: el['winner'],
                amount: type !== 'spin' ? (distribution[0]['percent'] * distribution[0]['amount']) / 100 : 0,
                //  amount: kind === 1 ? (distribution[i]['percent'] * distribution[i]['amount'] )/ 100 : el['buyin'] * distribution[i],
            });
            arr.push({
                place: places['1/2'],
                winner: el['winner'] === el.player_id_host ? el.player_id_guest : el.player_id_host,
                amount: type !== 'spin' ? (distribution[1]['percent'] * distribution[1]['amount']) / 100:0,
                // amount: kind === 1 ? !!distribution[i+1]? (distribution[i+1]['percent'] * distribution[i+1]['amount'] )/ 100 : 0
                // : el['buyin'] * distribution[i+1],
            });
        } else {
            const prop = el.round.split('/');
            const places = (+prop[1] + 1) + ' - ' + prop[1] * 2 + ' places';
            let number = prop[1] * 2;
            let qty = number - (+prop[1] + 1);
            // let log1 = Math.log(number , 2)
            // let log = Math.ceil(Math.log(number , 2));
            const amountEl = distribution.filter((el) => prize[number] && el.position === prize[number]);
            //console.log(amountEl,"amountEl")
            arr.push({
                place: places,
                winner: el['winner'] === el.player_id_host ? el.player_id_guest : el.player_id_host,
                amount: type !== 'spin' ? amountEl.length ? ((amountEl[0]['percent'] * amountEl[0]['amount']) / 100) / (qty + 1) : 0:0,

            });
        }
    }
    arr.sort((a, b) => (parseInt(a.place) > parseInt(b.place)) ? 1 : -1);
        if (type === 'spin' && value.length){
            for (let i=0; i < distribution.length; i++) {
                arr[i]['amount'] = distribution[i] * value[0]['buyin']
            }
        }
   // console.log(arr, 'sorted');
    return arr;

}
