import React from 'react';
import PickyDateTime from 'react-picky-date-time';
import './datapiker.css';

export default class YourOwnComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showPickyDateTime: null,
            date: '30',
            month: '01',
            year: '2000',
            hour: '03',
            minute: '10',
            second: '40',
            meridiem: 'AM',
        };
    }

    onYearPicked = (res) => {
        const { year } = res;
        this.setState({ year: year });
    };

    onMonthPicked = (res) => {
        const { month, year } = res;
        this.setState({ year: year, month: month });
    };

    onDatePicked = (res) => {
        const { date, month, year } = res;
        const { type, label, close, createTournament } = this.props;
        this.setState({ year: year, month: month, date: date });
        if (type && label) {
            let value = date + '-' + month + '-' + year;
            close(type, value, label);
        } else if (createTournament) {
            // console.log(createTournament,"in datapicker")
            let value = date + '-' + month + '-' + year;
            close(value, createTournament);
        } else {
            close(date + '-' + month + '-' + year);
        }
    };

    onResetDate = (res) => {
        const { date, month, year } = res;
        this.setState({ year: year, month: month, date: date });
    };

    onResetDefaultDate = (res) => {
        const { date, month, year } = res;
        this.setState({ year: year, month: month, date: date });
    };

    onSecondChange = (res) => {
        this.setState({ second: res.value });
    };

    onMinuteChange = (res) => {
        this.setState({ minute: res.value });
    };

    onHourChange = (res) => {
        this.setState({ hour: res.value });
    };

    onMeridiemChange = (res) => {
        this.setState({ meridiem: res });
    };

    onResetTime = (res) => {
        this.setState({
            second: res.clockHandSecond.value,
            minute: res.clockHandMinute.value,
            hour: res.clockHandHour.value,
        });
    };

    onResetDefaultTime = (res) => {
        this.setState({
            second: res.clockHandSecond.value,
            minute: res.clockHandMinute.value,
            hour: res.clockHandHour.value,
        });
    };

    onClearTime = (res) => {
        this.setState({
            second: res.clockHandSecond.value,
            minute: res.clockHandMinute.value,
            hour: res.clockHandHour.value,
        });
    };

    // just toggle your outter component state to true or false to show or hide <PickyDateTime/>
    openPickyDateTime = () => {
        this.setState({ showPickyDateTime: true });
    };

    onClose = () => {
        this.props.close(this.props.value);
    };

    render() {
        return (
              <PickyDateTime
                size="xs"
                mode={0}
                locale="en-us"
                show={true}
                onClose={() => this.onClose()}
                onYearPicked={(res) => this.onYearPicked(res)}
                onMonthPicked={(res) => this.onMonthPicked(res)}
                onDatePicked={(res) => this.onDatePicked(res)}
                onResetDate={(res) => this.onResetDate(res)}
                onResetDefaultDate={(res) => this.onResetDefaultDate(res)}
                onSecondChange={(res) => this.onSecondChange(res)}
                onMinuteChange={(res) => this.onMinuteChange(res)}
                onHourChange={(res) => this.onHourChange(res)}
                onMeridiemChange={(res) => this.onMeridiemChange(res)}
                onResetTime={(res) => this.onResetTime(res)}
                onResetDefaultTime={(res) => this.onResetDefaultTime(res)}
                onClearTime={(res) => this.onClearTime(res)}

            />
        );
    }
}
