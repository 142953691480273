import React, { useState, useEffect } from 'react';
import { Button, Grid, Form, Segment, Message } from 'semantic-ui-react';
import { connect } from 'react-redux';
import {userAuthLogin, resetUserData, getUserIpAddress} from '../redux/actions/usersActions';

function SignIn({ userAuthLogin, resetUserData, userData, authValid, history, userIpAddressReducer }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {

        if (authValid) {
            history.replace('/admin');
        }

    });

    return (
        <div>
            <div className="belote-logo" />
            <Grid textAlign="center" style={{ height: '100vh' }} verticalAlign="middle">
                <Grid.Column style={{ maxWidth: 450 }}>
                    <Form size="large">
                        <Message
                            color="red"
                            hidden={!userData.errors}
                            content={userData.errors || ''}
                        />
                        <Segment className={'login'}>
                            <Form.Input
                                fluid
                                icon="user"
                                iconPosition="left"
                                placeholder="Username"
                                onChange={usernameHandler}
                            />
                            <Form.Input
                                fluid
                                icon="lock"
                                iconPosition="left"
                                placeholder="Password"
                                type="password"
                                onChange={passwordHandler}
                            />
                            <Button
                                disabled={!username.length || !password.length}
                                className={'belote'}
                                fluid
                                size="large"
                                onClick={clientHandler}
                            >
                                Sign In
                            </Button>
                        </Segment>
                    </Form>
                </Grid.Column>
            </Grid>
        </div>

    );

    function usernameHandler(e) {
        setUsername(e.target.value);
    }

    function passwordHandler(e) {
        setPassword(e.target.value);
    }

    function clientHandler() {
        resetUserData();
        if(userIpAddressReducer === ''){
            getUserIpAddress().then((ip)=>{
                userAuthLogin({ username, password, ip });
            }).catch((e)=>{
                console.log(e, "ipAddress-Error")
            });
        }
        else{
            userAuthLogin({ username, password, ip: userIpAddressReducer });
        }
    }
}

const mapStateToProps = ({ userReducer, authReducer, userIpAddressReducer}) => ({
    userData: userReducer,
    authValid: authReducer,
    userIpAddressReducer
});

const mapDispatchToProps = {
    userAuthLogin,
    resetUserData: () => resetUserData,

};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
