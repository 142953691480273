import React from 'react';
import { setClass } from '../../../../../../helpers/game';
import PropTypes from 'prop-types';

ThrownCard.propTypes = {
    isMissing: PropTypes.bool.isRequired,
};

ThrownCard.defaultProps = {
    isMissing: false,
};

function ThrownCard({ isMissing, cardId }) {
    return (
        <li
            className={`throw-cards__item throw-cards__item-${cardId} ${setClass(
                isMissing,
                'throw-cards__item--with-opacity'
            )}`}
        />
    );
}

export default ThrownCard;
