import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { playCard, rearrangeSingleCard, throwSingleCard } from '../../../helpers/helpers/gameplay';
import { setClass } from '../../../helpers/game';
import { GAME_CARDS_APPEARANCE_CLASSES } from '../../../constants/game/classes';
import { connect } from 'react-redux';
import { incrementCurrentGameCardZIndex } from '../../../redux/ducks/currentGameDucks';

HandCard.propTypes = {
    isSelectable: PropTypes.bool,
};

function HandCard({
    cardId,
    isCardActive,
    isWrongCard,
    onCardClick,
    isSelectable,
    className = '',
    styles = {},
    playCardData,
    bottomPlayerId,
    isOpponentCard,
    cardsLength,
    index,
    setTableCardIds,
    throwCards,
    isGameStart,
    cardsColorId,
    isReplay,
    ...redux
}) {
    const cardRef = useRef(null);
    const { resize, incrementCurrentGameCardZIndex, cardZIndex, blotFieldRef } = redux;

    useEffect(() => {
        let timoutId;

        if (!!cardRef.current && !!blotFieldRef.current) {
            if (!isGameStart) {
                rearrangeSingleCard(
                    isOpponentCard,
                    cardRef.current,
                    cardsLength,
                    index,
                    blotFieldRef.current,
                    isReplay ? 0 : 0.2
                );
            } else {
                timoutId = setTimeout(
                    () =>
                        rearrangeSingleCard(
                            isOpponentCard,
                            cardRef.current,
                            cardsLength,
                            index,
                            blotFieldRef.current,
                            0.5
                        ),
                    80 * index
                );
            }
        }
        return () => clearTimeout(timoutId);
    }, [cardsLength, blotFieldRef, cardRef, index]);

    useEffect(() => {
        if (!!playCardData) {
            if (
                !!playCardData.cardId &&
                isOpponentCard === playCardData.isOpponentMoved &&
                !!cardRef.current &&
                cardId === playCardData.cardId
            ) {
                incrementCurrentGameCardZIndex();
                playCard(cardRef.current, playCardData.isOpponentMoved, !playCardData.withAnim, cardZIndex).then(() => {
                    setTableCardIds((prev) => [...prev, cardId]);
                });
            }
        }
    }, [playCardData, cardRef]);

    useEffect(() => {
        if (!!throwCards) {
            const { playerId, isOffer, agree } = throwCards;
            if (!!playerId && !isOffer && agree) {
                const isOpponentThrown = playerId !== bottomPlayerId;
                if (isOpponentCard === isOpponentThrown && !!blotFieldRef.current) {
                    throwSingleCard(cardRef.current, blotFieldRef.current);
                }
            }
        }
    }, [throwCards, blotFieldRef]);

    useEffect(() => {
        if (!!resize && !!cardRef.current && !!blotFieldRef.current) {
            rearrangeSingleCard(isOpponentCard, cardRef.current, cardsLength, index, blotFieldRef.current);
        }
    }, [resize, cardRef, blotFieldRef, index]);

    return (
        <div
            ref={cardRef}
            style={{
                position: 'absolute',
                transition: isReplay ? '0s' : '0.3s',
                transform: isOpponentCard && !!cardId && 'rotateY(180deg)',
                ...styles,
            }}
            className={`blot-field__user-card-wrapper card-field card-field--${cardId} ${className} ${setClass(
                isSelectable,
                'card-field--selectable'
            )} ${setClass(isCardActive, 'card-field--active')} ${setClass(isWrongCard, 'card-field--active-red')}`}
            onClick={handleClick}
            id={cardId}
        />
    );

    function handleClick() {
        isSelectable && onCardClick(cardId);
    }
}

const mapStateToProps = ({ resize, currentGame: { cardZIndex }, globalRefs: { blotFieldRef } }) => ({
    resize,
    cardZIndex,
    blotFieldRef,
});

const mapDispatchToProps = {
    incrementCurrentGameCardZIndex,
};

export default connect(mapStateToProps, mapDispatchToProps)(HandCard);
