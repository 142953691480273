import React, { Component } from 'react';
import { Select, Input, Button, Checkbox, Radio } from 'semantic-ui-react';
import {
    createNewTournament,
    createTournamentTemplate,
    setDateAndTime,
    getPrizeRules,
    getPrizeRuleName,
    setSelectedTournamentData,
    setPrizeDataFromTournamentTemplate,
    getCurrentTournamentByName,
    resetDateAndTime,
    getAllTournamentsTemplates,
    resetPrizeRule,
} from '../../../redux/actions/tournamentsAction';
import { leftDropDownMenuControl, resetRequestMessage } from '../../../redux/actions/otherActions';
import { connect } from 'react-redux';
import '@trendmicro/react-datepicker/dist/react-datepicker.css';
import './createTournament.css';
import DateController from './DateController';
import Prize from './prize';
import store from '../../../redux/store';
import StartDateController from './startDateController';
import Date from './startDate';
import ModalForMessages from '../../../components/ModalForMessages';
import { reset } from './reset';
import moment from 'moment';

class CreateTournament extends Component {
    constructor(props) {
        super(props);
        this.state = {
            highlighted: false,
            periodState: false,
            showInput: false,
            trumps: false,
            buyIn: '',
            combination: '',
            tournamentName: '',
            minPlayers: '',
            maxPlayers: '',
            priorValue: '',
            open: true,
            gameTypeState: '',
            pointsState: '',
            timeState: '',
            dimmer: 'blurring',
            prizeTemplateId: null,
            resetTemplate: false,
            currentTemplateName: '',

            prize: {},
            gameType: [
                { key: '11', text: 'CLASSIC', value: 'CLASSIC' },
                { key: '22', text: 'OPEN', value: 'OPEN' },
            ],
            period: [
                { key: '1', text: 'Daily', value: 1 },
                { key: '2', text: 'Weekly', value: 7 },
                { key: '3', text: 'Monthly', value: 30 },
            ],
            points: [
                { key: '1', text: '51', value: '51' },
                { key: '2', text: '101', value: '101' },
                // {key: '3', text: '1 Round', value: '1Round'},
                // {key: '4', text: '2 Round', value: '2Round'},
                // {key: '5', text: '3 Round', value: '3Round'},
                // {key: '6', text: '4 Round', value: '4Round'},
                // {key: '7', text: '5 Round', value: '5Round'},
                // {key: '8', text: '6 Round', value: '6Round'},
                // {key: '9', text: '7 Round', value: '7Round'},
            ],
            time: [
                { key: '21', text: '20 s', value: '20' },
                { key: '22', text: '30 s', value: '30' },
                { key: '23', text: '40 s', value: '40' },
                //{key: '24', text: '50 s', value: '50'},
            ],
            disTime: [
                { key: '1', text: '30 s', value: '30' },
                { key: '2', text: '1 m', value: '60' },
                { key: '3', text: '2 m', value: '120' },
                { key: '4', text: '3 m', value: '180' },
            ],
            templateId: '',
            templateName: '',
            disconnectTimer: '',
            error: '',
            errorD: '',
        };

    }

    componentDidMount() {
        this.props.leftDropDownMenuControl('tournament');
        this.props.getPrizeRules();
        this.props.getAllTournamentsTemplates();
    }


    create = (action) => {
        const { prize, templateName } = this.state;
        const {
            createNewTournament, createTournamentTemplate, tournamentData,
        } = this.props;
        const dateObj = store.getState().tournamentDateReducer;
        const scheduledAt = [...dateObj.scheduleAt];
        const arrayOfScheduledAt = [];
        const changeValuesToInteger = [];
        let dataDate = {};

        if (scheduledAt.length) {
            for (let el of scheduledAt) {
                arrayOfScheduledAt.push(el.date + ' ' + el.time);
            }
        }
        arrayOfScheduledAt.unshift(dateObj['startDate'] + ' ' + dateObj['startTime']);

        for (let i in prize.prizeDistribution) {
            changeValuesToInteger.push({
                position: +i + 1,
                percent: +prize.prizeDistribution[i].percentTotal,
            });
        }
        if (tournamentData.scheduledSequence && tournamentData.scheduledSequence.iterator) {
            dataDate = {
                ...dataDate,
                scheduledSequence: {
                    ...tournamentData.scheduledSequence,
                    startDate: dateObj['startDate'] + ' ' + dateObj['startTime'],
                    endDate: dateObj['sequence']['date'] + ' ' + dateObj['sequence']['time'],
                },
            };
        } else {
            dataDate = { ...dataDate, scheduledAt: arrayOfScheduledAt };
        }
        const data = {
            ...tournamentData,
            ...dataDate,
            playerRegistrationStart:
                dateObj['register']['day'] * 24 * 60 +
                dateObj['register']['hour'] * 60 +
                dateObj['register']['min'],
            announcementStart:
                dateObj['announcementStart']['day'] * 24 * 60 +
                dateObj['announcementStart']['hour'] * 60 +
                dateObj['announcementStart']['min'],
            announcementEnd:
                dateObj['announcementEnd']['day'] * 24 * 60 +
                dateObj['announcementEnd']['hour'] * 60 +
                dateObj['announcementEnd']['min'],
            prizePool: prize.prizePool,
            winners: prize.winners,
            prizeDistribution: changeValuesToInteger,
            //finishedAt: dateObj['finishedAt']['min'],

        };

        if (action === 'create') {
            const finalData = { ...data };

            if (
                finalData.hasOwnProperty('scheduledSequence') &&
                finalData.scheduledSequence['iterator']
            ) {
                delete finalData['scheduledAt'];
            } else if (finalData.hasOwnProperty('scheduledSequence') &&
                !finalData.scheduledSequence['iterator']) {
                delete finalData['scheduledSequence'];
            }
            //finalData['created_at'] = moment().format("YYYY-MM-DD H:mm:ss");

            if (!finalData.hasOwnProperty('priorityInLobby') || finalData['priorityInLobby'] === '') {
                finalData['priorityInLobby'] = 0;
            }
            if (!finalData.hasOwnProperty('buyIn') || finalData['buyIn'] === '') {
                finalData['buyIn'] = 0;
            }

            if (finalData.hasOwnProperty('scheduledAt')) {
                for (let el of finalData['scheduledAt']) {
                    if (el < moment().format('DD-MM-YYYY HH:mm:ss')) {
                        delete finalData['scheduledAt'];
                        break;
                    }
                }
            }



            console.log(finalData, 'before');
            createNewTournament(finalData);


                } else {
            const finalData = { ...data };
            if (finalData.hasOwnProperty('scheduledAt')) {
                delete finalData['scheduledAt'];
            }
            if (finalData.hasOwnProperty('scheduledSequence')) {
                delete finalData['scheduledSequence'];
            }
            finalData['name'] = templateName;
            if (finalData.hasOwnProperty('scheduledAt')) {
                for (let el of finalData['scheduledAt']) {
                    if (el < moment().format('DD-MM-YYYY HH:mm:ss')) {
                        delete finalData['scheduledAt'];
                        break;
                    }
                }
            }
            createTournamentTemplate(finalData);
        }
    };

    handleChangeNum = (e, name) => {
        if (e.target.value.length <= 6) {
            this.props.setSelectedTournamentData({
                ...this.props.tournamentData,
                [name]: +e.target.value,
            });
        }

    };

    saveAndCreate = (e) => {
        if (e) {
            this.setState({ showInput: e });
        } else {
            this.create('save');
        }
    };

    handleChangeComb = (index) => {
        this.props.setSelectedTournamentData({ ...this.props.tournamentData, gameCombs: index });
    };

    trumpsStatus = (e) => {
        const status = e === 'on' ? 'off' : 'on';
        this.props.setSelectedTournamentData({ ...this.props.tournamentData, gameNoTrump: status });
    };

    getSelectValue = (name, data) => {
        if (name !== 'periodState') {
            this.props.setSelectedTournamentData({
                ...this.props.tournamentData,
                [name]: name !== 'gameType' ? +data.value : data.value,
            });
        } else {
            if (data.value) {
                this.setState({ periodState: true });
                this.props.setSelectedTournamentData({
                    ...this.props.tournamentData,
                    scheduledSequence: {
                        iterator: +data.value,
                    },
                });
            } else {
                let newObj = { ...this.props.tournamentData };
                delete newObj['scheduledSequence'];
                this.props.setSelectedTournamentData({ ...newObj });
                this.setState({ periodState: false });
                this.setState({ errorD: '' });
            }

        }
    };
    getTournamentName = (e) => {
        if (e.target.value.length <= 16) {
            if (this.state.error) {
                this.setState({ error: '' });
            }
            this.props.setSelectedTournamentData({
                ...this.props.tournamentData,
                name: e.target.value,
            });
        } else {

            this.setState({ error: 'Name should be less than 16 symbols' });
        }

    };
    getPrizeTemplateId = (e, data) => {
        const { tournamentPrizeReducer, getPrizeRuleName } = this.props;
        if (data.value) {
            let title = '';
            for (let el of tournamentPrizeReducer) {
                if (el.value === data.value) {
                    title = el.text;
                }
            }
            this.setState({
                prizeTemplateId: title,
            });
            getPrizeRuleName({ title });
        } else {
            this.props.setPrizeDataFromTournamentTemplate({ data: [] });
            this.setState({
                resetTemplate: true,
            });
            this.setState({
                prizeTemplateId: null,
            });
        }
    };

    setPrizeData = (data) => {
        this.setState({
            prize: data,
            resetTemplate: false,
        });
    };

    isHighlighted = (e) => {
        this.props.setSelectedTournamentData({ ...this.props.tournamentData, isHighlighted: !e });
    };
    getTemplateName = (e) => {
        this.setState({
            templateName: e.target.value,
        });
    };
    openCurrentTemplate = (e, data) => {
        if (data.value) {
            this.setState({ currentTemplateName: data.value });
            this.props.getCurrentTournamentByName({ name: data.value });
        } else {
            this.props.setSelectedTournamentData(reset);
            this.props.resetDateAndTime({});
            this.setState({ currentTemplateName: 'Choose Template' });

        }
    };

    closeModal = (e) => {
        const { setSelectedTournamentData, resetDateAndTime, resetPrizeRule, resetRequestMessage } = this.props;
        const { showInput } = this.state;
        if (showInput) {
            this.setState({ showInput: false });
            resetRequestMessage('');
            return;
        }
        if (!e && !showInput) {
            setSelectedTournamentData(reset);
            resetDateAndTime({});
            this.setState({ periodState: false });
            this.setState({ currentTemplateName: '' });
            this.setState({ prizeTemplateId: null });
            resetPrizeRule(null);
        }
        resetRequestMessage('');

    };

    render() {
        const {
            templateName,
            gameType,
            error,
            time,
            disTime,
            points,
            period,
            periodState,
            showInput,
            resetTemplate,
            currentTemplateName,
            prizeTemplateId,
            errorD,
        } = this.state;
        const {
            requestMessage,
            tournamentPrizeReducer,
            tournamentData,
            tournamentTemplates,
        } = this.props;

        return (
            <div>
                {!!requestMessage ? (
                    <ModalForMessages
                        error={requestMessage.error}
                        message={requestMessage.message}
                        close={() => this.closeModal(requestMessage.error)}
                    />
                ) : null}
                <div className="create-tournament-header">
                    <h2>Create a tournament</h2>
                </div>
                <div className="create-tournament-content">
                    <div className="input-box p-left">
                        <div className="check-box">
                            <div className="label">Tournament Name:</div>
                            <Input
                                placeholder="Max 16 symbol..."
                                value={tournamentData.name}
                                onChange={this.getTournamentName}
                            />
                            <span className="error-mes">{error}</span>
                        </div>
                        <div className="check-box">
                            <div className="label">Tournament Templates</div>
                            {
                                currentTemplateName ?
                                    <>
                                        {currentTemplateName ? null : null}
                                        <Select
                                            selectOnBlur={false}
                                            clearable={true}
                                            placeholder={currentTemplateName}
                                            options={tournamentTemplates}
                                            onChange={this.openCurrentTemplate}
                                        />
                                    </> :
                                    <Select
                                        selectOnBlur={false}
                                        clearable={true}
                                        placeholder="Choose Template"
                                        options={tournamentTemplates}
                                        onChange={this.openCurrentTemplate}
                                    />
                            }
                        </div>
                    </div>
                    <div className="input-box create-tournament-date border-vert">
                        <div className="error-mes req">{errorD}</div>
                        <div className="check-box test">
                            <StartDateController editOrCopy={false} period={periodState}
                                                 dailyErrorHandler={(e) => this.setState({ errorD: e })}/>
                            <div style={{ flexBasis: '500px', flex: 'none' }}>
                                <div className="label min-width-50 fl-left">Period</div>
                                {tournamentData.scheduledSequence &&
                                tournamentData.scheduledSequence.iterator ?
                                    <>
                                        {tournamentData.scheduledSequence &&
                                        tournamentData.scheduledSequence.iterator ? null : null}
                                        <Select
                                            selectOnBlur={false}
                                            clearable={true}
                                            placeholder={
                                                tournamentData.scheduledSequence &&
                                                tournamentData.scheduledSequence.iterator
                                                    ? period[
                                                        tournamentData.scheduledSequence.iterator === 1
                                                            ? 0
                                                            : tournamentData.scheduledSequence.iterator === 7
                                                            ? 1
                                                            : 2
                                                        ].text
                                                    : 'Choose period'
                                            }
                                            options={period}
                                            onChange={(e, data) => this.getSelectValue('periodState', data)}
                                        /></> :
                                    <Select
                                        selectOnBlur={false}
                                        clearable={true}
                                        placeholder={
                                            'Choose period'
                                        }
                                        options={period}
                                        onChange={(e, data) => this.getSelectValue('periodState', data)}
                                    />

                                }
                                <div className="float-r">
                                    {tournamentData.scheduledSequence &&
                                    tournamentData.scheduledSequence.iterator ? (
                                        <Date title={'End Date:'} index={null}/>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <DateController
                            title={'Announcement Start Date:'}
                            stateName={'announcementStart'}
                            showSubPicker={true}
                            showDatePicker={true}
                            subTitle={'before start date'}
                        />
                        <DateController
                            title={'Registration Date:'}
                            stateName={'register'}
                            showSubPicker={true}
                            showDatePicker={true}
                            subTitle={'before start date'}
                        />
                        <DateController
                            title={'Announcement End Date:'}
                            stateName={'announcementEnd'}
                            showSubPicker={true}
                            showDatePicker={false}
                            subTitle={'After end of Tournament'}
                        />
                    </div>
                    <div className="input-box border-bottom p-left">
                        <div>
                            <div className="margin-12">
                                <Input
                                    label="Min Players:"
                                    value={tournamentData.minPlayers === 0 ? '' : tournamentData.minPlayers}
                                    onChange={(e) => this.handleChangeNum(e, 'minPlayers')}
                                    type="number"
                                    min={1}
                                />
                            </div>
                            <div className="margin-12">
                                <Input
                                    label="Priority in Lobby"
                                    value={tournamentData.priorityInLobby === 0 ? '' : tournamentData.priorityInLobby}
                                    onChange={(e) => this.handleChangeNum(e, 'priorityInLobby')}
                                    type="number"
                                    min={1}
                                    max={10}
                                />
                            </div>
                        </div>
                        <div className="margin-12">
                            <Input
                                label="Max Players:"
                                value={tournamentData.maxPlayers === 0 ? '' : tournamentData.maxPlayers}
                                onChange={(e) => this.handleChangeNum(e, 'maxPlayers')}
                                type="number"
                                min={1}
                            />
                            <div>
                                <div className="label"> Highlighted:</div>
                                <Checkbox
                                    toggle
                                    checked={tournamentData.isHighlighted}
                                    onChange={() =>
                                        this.isHighlighted(tournamentData.isHighlighted)
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="input-box border-bottom date-flex p-top-0 p-left-39">
                    <div className="registration-day-manual column">
                        <div className="margin-r">
                            <div className="label">Game types:</div>
                            {tournamentData.gameType ?
                                <>
                                    {tournamentData.gameType ? null : null}
                                    <Select
                                        selectOnBlur={false}
                                        clearable
                                        placeholder={
                                            tournamentData.gameType
                                        }
                                        options={gameType}
                                        onChange={(e, data) => this.getSelectValue('gameType', data)}
                                    /></> :
                                <Select
                                    selectOnBlur={false}
                                    clearable
                                    placeholder={
                                        'Game type'
                                    }
                                    options={gameType}
                                    onChange={(e, data) => this.getSelectValue('gameType', data)}
                                />
                            }
                        </div>
                        <div className="margin-r">
                            <div className="label">Points:</div>
                            {tournamentData.gamePoints ?
                                <>
                                    {tournamentData.gamePoints ? null : null}
                                    <Select
                                        selectOnBlur={false}
                                        clearable
                                        placeholder={
                                            tournamentData.gamePoints
                                        }
                                        options={points}
                                        onChange={(e, data) => this.getSelectValue('gamePoints', data)}
                                    /></> :
                                <Select
                                    selectOnBlur={false}
                                    clearable
                                    placeholder={
                                        'Points'
                                    }
                                    options={points}
                                    onChange={(e, data) => this.getSelectValue('gamePoints', data)}
                                />
                            }

                        </div>
                        <div className="margin-r">
                            <div className="label">Time:</div>
                            {tournamentData.gameTime ?
                                <>
                                    {tournamentData.gameTime ? null : null}
                                    <Select
                                        selectOnBlur={false}
                                        clearable
                                        placeholder={
                                            tournamentData.gameTime
                                                ? tournamentData.gameTime + ' s'
                                                : 'Time'
                                        }
                                        options={time}
                                        onChange={(e, data) => this.getSelectValue('gameTime', data)}
                                    /></> :
                                <Select
                                    selectOnBlur={false}
                                    clearable
                                    placeholder={
                                        'Time'
                                    }
                                    options={time}
                                    onChange={(e, data) => this.getSelectValue('gameTime', data)}
                                />
                            }
                        </div>
                        <div className="margin-r">
                            <div className="label">Disconnect Time:</div>
                            {tournamentData.disconnectTimer ?
                                <>
                                    {tournamentData.disconnectTimer ? null : null}
                                    <Select
                                        selectOnBlur={false}
                                        clearable
                                        placeholder={
                                            tournamentData.disconnectTimer
                                                ? tournamentData.disconnectTimer > 30
                                                ? tournamentData.disconnectTimer / 60 + ' m'
                                                : tournamentData.disconnectTimer + ' s'
                                                : 'Disconnet Time'
                                        }
                                        options={disTime}
                                        onChange={(e, data) => this.getSelectValue('disconnectTimer', data)}
                                    /></> :
                                <Select
                                    selectOnBlur={false}
                                    clearable
                                    placeholder={
                                        'Disconnet Time'
                                    }
                                    options={disTime}
                                    onChange={(e, data) => this.getSelectValue('disconnectTimer', data)}
                                />
                            }
                        </div>
                        <div className='margin-32'>
                            <Input
                                label="Buy-in"
                                value={tournamentData.buyIn === 0 ? '' : tournamentData.buyIn}
                                onChange={(e) => this.handleChangeNum(e, 'buyIn')}
                                type="number"
                                min={1}
                            />
                        </div>

                    </div>
                    <div>
                        <div className="check-box">
                            <div>
                                <Radio
                                    label="All combinations"
                                    name="radioGroup"
                                    value="ALLCOMBS"
                                    checked={tournamentData.gameCombs === 'ALLCOMBS'}
                                    onChange={() => this.handleChangeComb('ALLCOMBS')}
                                />
                            </div>
                            <div>
                                <Radio
                                    label="Without 4 cards and 100"
                                    name="radioGroup"
                                    value="NO4NO100"
                                    checked={tournamentData.gameCombs === 'NO4NO100'}
                                    onChange={() => this.handleChangeComb('NO4NO100')}
                                />
                            </div>
                            <div>
                                <Radio
                                    label="Belote / Rebelote only"
                                    name="radioGroup"
                                    value="ONLYBR"
                                    checked={tournamentData.gameCombs === 'ONLYBR'}
                                    onChange={() => this.handleChangeComb('ONLYBR')}
                                />
                            </div>
                        </div>
                        <div className="check-box">
                            <div className="label"> No Trumps</div>
                            <Checkbox
                                toggle
                                checked={tournamentData.gameNoTrump === 'on'}
                                onChange={() => this.trumpsStatus(tournamentData.gameNoTrump)}
                            />
                        </div>
                    </div>
                </div>
                <div className="input-box min-height-250 border-bottom ">
                    <Prize setPrizeData={this.setPrizeData} editOrCopy={false} resetTemplate={resetTemplate}/>
                    <div className="check-box">
                        <div className="label">Prize Templates</div>
                        {
                            prizeTemplateId ?
                                <>
                                    {prizeTemplateId ? null : null}
                                    <Select
                                        selectOnBlur={false}
                                        clearable
                                        placeholder={prizeTemplateId}
                                        options={tournamentPrizeReducer}
                                        onChange={this.getPrizeTemplateId}
                                    />
                                </> :
                                <Select
                                    selectOnBlur={false}
                                    clearable
                                    placeholder="Templates"
                                    options={tournamentPrizeReducer}
                                    onChange={this.getPrizeTemplateId}
                                />

                        }

                    </div>
                </div>
                <div className="create-tournament-actions prize">
                    {/*<div className={requestMessage.error ? 'req-mess err' : 'req-mess'}>*/}
                    {/*    {requestMessage.message}*/}
                    {/*</div>*/}
                    <Button
                        positive
                        icon="checkmark"
                        labelPosition="right"
                        content="Create"
                        onClick={() => this.create('create')}
                    />

                    <Button
                        color="black"
                        onClick={
                            !showInput
                                ? () => this.saveAndCreate(true)
                                : () => this.saveAndCreate(false)
                        }
                    >
                        {!showInput ? 'Save as template' : 'Save'}
                    </Button>

                    <div className="template-name abs">
                        {showInput && (
                            <div className="check-box">
                                <div className="label">Template Name:</div>
                                <Input
                                    focus={true}
                                    placeholder="Enter template name..."
                                    value={templateName}
                                    onChange={this.getTemplateName}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({
                             requestMessage,
                             tournamentPrizeReducer,
                             tournamentTemplatesReducer,
                             oneTournamentReducer,
                         }) => ({
    requestMessage,
    tournamentPrizeReducer,
    tournamentData: oneTournamentReducer,
    tournamentTemplates: tournamentTemplatesReducer,
});

const mapDispatchToProps = {
    setDateAndTime,
    getPrizeRules,
    getPrizeRuleName,
    createNewTournament,
    resetRequestMessage,
    leftDropDownMenuControl,
    setSelectedTournamentData,
    setPrizeDataFromTournamentTemplate,
    getCurrentTournamentByName,
    resetDateAndTime,
    getAllTournamentsTemplates,
    createTournamentTemplate,
    resetPrizeRule,


};

export default connect(mapStateToProps, mapDispatchToProps)(CreateTournament);
