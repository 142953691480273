import React, { Component } from 'react';
import { Select, Button, Modal } from 'semantic-ui-react';
import {
    createNewTournament,
    getAllTournamentsTemplates,
    getCurrentTournamentByName,
    resetDateAndTime,
    setSelectedTournamentData,
} from '../redux/actions/tournamentsAction';
import { connect } from 'react-redux';

import '@trendmicro/react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { reset } from '../pages/tournament/createTournament/reset';
import EditOrCopyTournament from '../pages/tournament/editOrCopy/EditOrCopyTournament';

class ModalExampleDimmer extends Component {
    state = {
        minPlayers: '',
        maxPlayers: '',
        stateValue: '',
        open: true,
        dimmer: 'blurring',
        templatesData: [{ key: '1', text: 'Template 1', value: 1 }],
        scheduleDate: moment().format('DD-MM-YYYY'),
        scheduleTime: moment().format('HH:mm:ss'),
        finishDate: moment().format('DD-MM-YYYY'),
        finishTime: moment().format('HH:mm:ss'),
        error: { errName: '', errPass: '' },
        showCurrentTemplate: false,
    };
    create = () => {
        const {
            templateId,
            minPlayers,
            maxPlayers,
            stateValue,
            scheduleDate,
            scheduleTime,
            finishDate,
            finishTime,
        } = this.state;
        const { createNewTournament } = this.props;
        const data = {
            scheduledAt: scheduleDate + ' ' + scheduleTime,
           // finishedAt: finishDate + ' ' + finishTime,
            state: +stateValue,
            minPlayers: +minPlayers,
            maxPlayers: +maxPlayers,
            templateId: templateId ? +templateId : null,
        };
        createNewTournament(data);
    };
    openCurrentTemplate = (e, data) => {
        if (data.value) {
            this.props.getCurrentTournamentByName({ name: data.value });
            this.setState({ showCurrentTemplate: true });
        } else {
            this.props.setSelectedTournamentData(reset);
            this.props.resetDateAndTime({});
            this.setState({ showCurrentTemplate: false });
        }
    };

    componentDidMount() {
        this.props.getAllTournamentsTemplates();
    }

    render() {
        const { open, dimmer, showCurrentTemplate } = this.state;
        const { close, title, tournamentTemplates } = this.props;
        return (
            <div style={{ width: '100%' }}>
                >
                <Modal dimmer={dimmer} open={open} onClose={close} className={'edit-or-copy'}>
                    <Modal.Header>{title} </Modal.Header>
                    <Modal.Content>
                        {!showCurrentTemplate && (
                            <div className="check-box">
                                <div className="label">Tournament Templates</div>
                                <Select
                                    selectOnBlur={false}
                                    clearable
                                    placeholder="Templates"
                                    options={tournamentTemplates}
                                    onChange={this.openCurrentTemplate}
                                />
                            </div>
                        )}
                        {showCurrentTemplate && (
                            <EditOrCopyTournament title={'Create'} close={close} disable={true} />
                        )}
                    </Modal.Content>
                    {!showCurrentTemplate && (
                        <Modal.Actions>
                            {/*<div className={requestMessage.error ? 'req-mess err' : 'req-mess'}>*/}
                            {/*    {requestMessage.message}*/}
                            {/*</div>*/}
                            <Button color="black" onClick={close}>
                                Cancel
                            </Button>
                        </Modal.Actions>
                    )}
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = ({ requestMessage, tournamentTemplatesReducer }) => ({
    requestMessage,
    tournamentTemplates: tournamentTemplatesReducer,
});

const mapDispatchToProps = {
    createNewTournament,
    getCurrentTournamentByName,
    setSelectedTournamentData,
    getAllTournamentsTemplates,
    resetDateAndTime,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalExampleDimmer);
