import React from 'react';

function DotLoader() {
    return (
        <div className="blot-field__user-tooltip blot-field__user-tooltip--with-loader">
            <div className="blot-field__user-tooltip-loader">
                <div className="blot-field__user-tooltip-loader-item" />
                <div className="blot-field__user-tooltip-loader-item" />
                <div className="blot-field__user-tooltip-loader-item" />
            </div>
        </div>
    );
}

export default DotLoader;
