/** Action types **/
import { createAction, createReducer } from '../../helpers/helpers/redux';

const SET_CURRENT_GAME = 'SET_CURRENT_GAME';
const UPDATE_CURRENT_GAME = 'UPDATE_CURRENT_GAME';
const REMOVE_CURRENT_GAME = 'REMOVE_CURRENT_GAME';
const RESET_CURRENT_GAME_TIMER = 'RESET_CURRENT_GAME_TIMER';
const UPDATE_CURRENT_GAME_USER_ACTIONS = 'UPDATE_CURRENT_GAME_USER_ACTIONS';
const UPDATE_CURRENT_GAME_DAVE_POWER = 'UPDATE_CURRENT_GAME_DAVE_POWER';
const UPDATE_CURRENT_GAME_VALID_ACTIONS = 'UPDATE_CURRENT_GAME_VALID_ACTIONS';
const SET_CURRENT_GAME_THROW_CARDS = 'SET_CURRENT_GAME_THROW_CARDS';
const SET_CURRENT_GAME_CHOOSE_TRUMP = 'SET_CURRENT_GAME_CHOOSE_TRUMP';
const UPDATE_CURRENT_GAME_CLOUDS_TEXT = 'SET_CURRENT_GAME_CLOUD_TEXT';
const RESET_CURRENT_GAME_CLOUDS_TEXT = 'RESET_CURRENT_GAME_CLOUD_TEXT';
const SET_CURRENT_GAME_LAST_HAND = 'SET_CURRENT_GAME_LAST_HAND';
const SET_CURRENT_GAME_CURRENT_HAND = 'SET_CURRENT_GAME_CURRENT_HAND';
const RESET_CURRENT_GAME = 'RESET_CURRENT_GAME';
const SET_CURRENT_GAME_TRADE_STAGE_DATA = 'SET_CURRENT_GAME_TRADE_STAGE_DATA';
const INCREMENT_CURRENT_GAME_CARD_Z_INDEX = 'INCREMENT_CURRENT_GAME_CARD_Z_INDEX';

/** Actions **/
export const setCurrentGame = createAction(SET_CURRENT_GAME);
export const resetCurrentGame = createAction(RESET_CURRENT_GAME);
export const updateCurrentGame = createAction(UPDATE_CURRENT_GAME);
export const removeCurrentGame = createAction(REMOVE_CURRENT_GAME);
export const resetCurrentGameTimer = createAction(RESET_CURRENT_GAME_TIMER);
export const updateCurrentGameUserActions = createAction(UPDATE_CURRENT_GAME_USER_ACTIONS);
export const updateCurrentGameDavePower = createAction(UPDATE_CURRENT_GAME_DAVE_POWER);
export const updateCurrentGameValidActions = createAction(UPDATE_CURRENT_GAME_VALID_ACTIONS);
export const setCurrentGameThrowCards = createAction(SET_CURRENT_GAME_THROW_CARDS);
export const setCurrentGameChooseTrump = createAction(SET_CURRENT_GAME_CHOOSE_TRUMP);
export const updateCurrentGameCloudsText = createAction(UPDATE_CURRENT_GAME_CLOUDS_TEXT);
export const resetCurrentGameCloudsText = createAction(RESET_CURRENT_GAME_CLOUDS_TEXT);
export const setCurrentGameLastHand = createAction(SET_CURRENT_GAME_LAST_HAND);
export const setCurrentGameCurrentHand = createAction(SET_CURRENT_GAME_CURRENT_HAND);
export const setCurrentGameTradeStageData = createAction(SET_CURRENT_GAME_TRADE_STAGE_DATA);
export const incrementCurrentGameCardZIndex = createAction(INCREMENT_CURRENT_GAME_CARD_Z_INDEX);

const initialState = {
    userActions: {},
    validActions: { actions: {} },
    gameSettings: {},
    davePower: {},
    throwCards: {},
    chooseTrump: {},
    cloudTextData: {},
    opponentCards: {},
    tradeStageData: {},
    players: { owner: {}, opponent: {} },
    currentPlayer: {},
    lastHand: [],
    lastHandCardsData: {},
    cardZIndex: 0,
    spectatorList: {},
    trumper: {},
    score: [],
};

/** Reducers **/
//prettier-ignore
export const currentGame = createReducer(initialState, (state, { value }) => ({
    [SET_CURRENT_GAME]:()=> ({ ...initialState, ...value }),
    [RESET_CURRENT_GAME]:()=> initialState,
    [UPDATE_CURRENT_GAME]:()=> ({ ...state, ...value }),
    [REMOVE_CURRENT_GAME]:()=> initialState,
    [RESET_CURRENT_GAME_TIMER]:()=> ({ ...state, timer: { playerId: value, stepTimer: 0, continueTimer: 0 } }),
    [UPDATE_CURRENT_GAME_USER_ACTIONS]:()=> ({ ...state, userActions: { ...state.userActions, ...value } }),
    [UPDATE_CURRENT_GAME_DAVE_POWER]:()=> ({ ...state, davePower: { ...state.davePower, ...value } }),
    [UPDATE_CURRENT_GAME_VALID_ACTIONS]:()=> ({ ...state, validActions: { actions: { ...state.validActions.actions, ...value } }, }),
    [SET_CURRENT_GAME_THROW_CARDS]:()=> ({ ...state, throwCards: value }),
    [SET_CURRENT_GAME_CHOOSE_TRUMP]:()=> ({ ...state, chooseTrump: value}),
    [UPDATE_CURRENT_GAME_CLOUDS_TEXT]:()=> ({ ...state, cloudTextData: {...state.cloudTextData, ...value}}),
    [RESET_CURRENT_GAME_CLOUDS_TEXT]:()=> ({ ...state, cloudTextData: {...state.cloudTextData, [value]: ''}}),
    [SET_CURRENT_GAME_LAST_HAND]: ()=> ({...state, lastHand: [...state.lastHand, value]}),
    [SET_CURRENT_GAME_CURRENT_HAND]: ()=> ({ ...state, currentHand: value}),
    [SET_CURRENT_GAME_TRADE_STAGE_DATA]: ()=> ({ ...state, tradeStageData: value}),
    [INCREMENT_CURRENT_GAME_CARD_Z_INDEX]: ()=> ({ ...state, cardZIndex: state.cardZIndex + 1}),
}));
