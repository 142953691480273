import { useEffect } from 'react';

export function useSetShownCombinationCardsToHandCards(
    shownCombination,
    cards,
    setCards,
    isOpponentContainer = false,
    isSpectator = false
) {
    useEffect(() => {
        if (!!shownCombination) {
            const { playerId, cards: shownCards, isOpponent } = shownCombination;
            if (!!playerId) {
                if (
                    !!shownCards.length &&
                    ((isOpponentContainer && isOpponent) || (!isOpponent && !isOpponentContainer && isSpectator))
                ) {
                    setCards(cards.map((cardId, i) => shownCombination.cards[i] || ''));
                } else {
                    setCards(cards.map(() => ''));
                }
            }
        }
    }, [shownCombination]);
}
