export const TABLE_ROW_GAME_TYPE_IDS = {
    WITHOUT_4_AND_100: 'isWithout4and100',
    BLOT_REBLOT: 'isBlotReblot',
    NO_TRUMPS: 'isNoTrumps',
    DAVE: 'isDave',
    PRIVATE_GAME: 'isPrivateGame',
    CAPOT_2X: 'isCapot2x',
    POINT_DIFF: 'isPointDiff',
    ALL_COMBINATIONS: 'isAllCombinations',
};

const {
    BLOT_REBLOT,
    CAPOT_2X,
    DAVE,
    NO_TRUMPS,
    PRIVATE_GAME,
    WITHOUT_4_AND_100,
    POINT_DIFF,
    ALL_COMBINATIONS,
} = TABLE_ROW_GAME_TYPE_IDS;

export const FILTER_ITEM_IDS = {
    POINTS: 'isPoints',
    ROUNDS: 'isRounds',
    T20: 'isT20',
    T30: 'isT30',
    T40: 'isT40',
    POINT_DIFF,
    ALL_COMBINATIONS,
    WITHOUT_4_AND_100,
    BLOT_REBLOT,
    NO_TRUMPS,
    PRIVATE_GAME,
    CAPOT_2X,
    DAVE,
};

export const TABLE_CREATOR_ITEM_IDS = {
    RADIO_IDS: { ALL_COMBINATIONS, WITHOUT_4_AND_100, BLOT_REBLOT },
    POINTS_DROP_ITEM_IDS: {
        FIFTY_ONE: '51',
        HUNDRED_ONE: '101',
        R1: '1',
        R2: '2',
        R3: '3',
        R4: '4',
        R5: '5',
        R6: '6',
        R7: '7',
    },
    TIME_DROP_ITEM_IDS: ['20', '30', '40', '50'],
    DAVE_DROP_ITEM_IDS: ['2', '4', '8', '16'],
    CHECKBOX_IDS: { POINT_DIFF, DAVE, NO_TRUMPS, PRIVATE_GAME, CAPOT_2X },

    DROPDOWN_IDS: {
        POINTS: 'points',
        TIME: 'time',
        DAVE: 'dave',
    },
};
