import React from 'react';

function ItemRowMatchScore({ totalScore, handScore, combinationScore }) {
    return (
        <div className="score__line-decoration-score__cell">
            <span className="score__line-decoration-score__text">{totalScore || 0}</span>
            <span className="score__line-decoration-score__text score__line-decoration-score__text--with-opacity">
                {`(${handScore || 0}+${combinationScore || 0})`}
            </span>
        </div>
    );
}

export default ItemRowMatchScore;
