import { SETTINGS_AVATAR_IDS } from '../constants/game/ids';

import logo from './img/logo.svg';
import bronze from './img/bronze.png';
import silver from './img/silver.png';
import gold from './img/gold.png';
import without4and100 from './img/without_4_cards_and_100.svg';
import capot_2x from './img/capot_2x.svg';
import belote_rebelote from './img/belote_rebelote_only.svg';
import no_trumps from './img/no_trumps.svg';
import userIcon2 from './img/blot-field/user-icon-2.png';
import heartRed from './img/blot-field/heart-red.svg';
import club from './img/blot-field/club.svg';
import diamond from './img/blot-field/diamond.svg';
import spade from './img/blot-field/spade.svg';
import a from './img/blot-field/a.svg';
import userIcon from './img/blot-field/user-icon.png';
import tabCloseIcon from './img/icon-close.svg';
import dave from './img/dave.svg';
import pointDiff from './img/point_difference.svg';
import privateGame from './img/private_game.svg';
import Avatar1 from './img/menu/Avatar_1.svg';
import Avatar2 from './img/menu/Avatar_2.svg';
import Avatar3 from './img/menu/Avatar_3.svg';
import Avatar4 from './img/menu/Avatar_4.svg';
import Avatar5 from './img/menu/Avatar_5.svg';
import Avatar6 from './img/menu/Avatar_6.svg';
import Avatar7 from './img/menu/Avatar_7.svg';
import Avatar8 from './img/menu/Avatar_8.svg';
import Avatar9 from './img/menu/Avatar_9.svg';
import Avatar10 from './img/menu/Avatar_10.svg';
import Avatar11 from './img/menu/Avatar_11.svg';
import Avatar12 from './img/menu/Avatar_12.svg';
import Avatar13 from './img/menu/Avatar_13.svg';
import Avatar14 from './img/menu/Avatar_14.svg';
import Avatar15 from './img/menu/Avatar_15.svg';
import Avatar16 from './img/menu/Avatar_16.svg';
import Avatar17 from './img/menu/Avatar_17.svg';
import Avatar18 from './img/menu/Avatar_18.svg';
import Avatar19 from './img/menu/Avatar_19.svg';
import Avatar20 from './img/menu/Avatar_20.svg';
import quansh2x from './img/quansh_2x.svg';
import quansh4x from './img/quansh_4x.svg';
import quansh8x from './img/quansh_8x.svg';
import quansh16x from './img/quansh_16x.svg';

const {
    AVATAR_1,
    AVATAR_2,
    AVATAR_3,
    AVATAR_4,
    AVATAR_5,
    AVATAR_6,
    AVATAR_7,
    AVATAR_8,
    AVATAR_9,
    AVATAR_10,
    AVATAR_11,
    AVATAR_12,
    AVATAR_13,
    AVATAR_14,
    AVATAR_15,
    AVATAR_16,
    AVATAR_17,
    AVATAR_18,
    AVATAR_19,
    AVATAR_20,
} = SETTINGS_AVATAR_IDS;

export const images = {
    logo,
    bronze,
    silver,
    gold,
    without4and100,
    capot_2x,
    belote_rebelote,
    no_trumps,
    tabCloseIcon,
    dave,
    pointDiff,
    privateGame,
    quansh2x,
    quansh4x,
    quansh8x,
    quansh16x,
};

export const blotFieldImages = {
    userIcon,
    heartRed,
    club,
    diamond,
    spade,
    a,
    userIcon2,
};

export const avatarImages = {
    [AVATAR_1]: Avatar1,
    [AVATAR_2]: Avatar2,
    [AVATAR_3]: Avatar3,
    [AVATAR_4]: Avatar4,
    [AVATAR_5]: Avatar5,
    [AVATAR_6]: Avatar6,
    [AVATAR_7]: Avatar7,
    [AVATAR_8]: Avatar8,
    [AVATAR_9]: Avatar9,
    [AVATAR_10]: Avatar10,
    [AVATAR_11]: Avatar11,
    [AVATAR_12]: Avatar12,
    [AVATAR_13]: Avatar13,
    [AVATAR_14]: Avatar14,
    [AVATAR_15]: Avatar15,
    [AVATAR_16]: Avatar16,
    [AVATAR_17]: Avatar17,
    [AVATAR_18]: Avatar18,
    [AVATAR_19]: Avatar19,
    [AVATAR_20]: Avatar20,
};
