import { SETTINGS_TYPES, USERS_TYPES } from '../actionTypes';
import instance from '../../helpers/Api';

const { SET_SETTINGS } = SETTINGS_TYPES;
const { REQUEST_MESSAGE } = USERS_TYPES;

export function getAllSettings({ order, orderBy }) {
    return (dispatch) => {
        instance.post('/api/settings/get', {order,orderBy})
            .then((res) => {
                //console.log(res,"settings")
                dispatch({ type: SET_SETTINGS, value: res.data });
            })
            .catch((e) => {
                console.log('getPlayers', e);
            });
    };
}

export function updateSetting({ params }) {
    return (dispatch) => {
        instance.post('/api/settings/update', {params})
            .then((res) => {
                //console.log(res,"update-setting")
                dispatch({ type: REQUEST_MESSAGE, value: res.data });
            })
            .catch((e) => {
                console.log('getSetting', e);
            });
    };
}

export function createSetting(data) {
    return (dispatch) => {
        instance.post('/api/settings/create', data)
            .then((res) => {
                //console.log(res, "create-setting")
                dispatch({ type: REQUEST_MESSAGE, value: res.data });
            })
            .catch((e) => {
                console.log('create', e);
            });
    };
}
