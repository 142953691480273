import { useEffect, useState } from 'react';
import { cardToCardId } from '../../../helpers/helpers/gameplay';

export function useDefineOpenCardsData(openedCards, bottomPlayerId) {
    const [openedCardsData, setOpenedCardsData] = useState({});
    useEffect(() => {
        if (!!openedCards) {
            const { playerId: openedPlayerId, cards } = openedCards;
            if (!!openedPlayerId) {
                const isOpponent = openedPlayerId !== bottomPlayerId;
                setOpenedCardsData({ isOpponent, cards: cards.map((card) => cardToCardId(card)) });
            }
        }
    }, [openedCards]);

    return openedCardsData;
}
