import {PLAYERS_TYPES, USERS_TYPES, TOURNAMENTS_TYPES, OTHER_TYPES, MAIN_TYPES} from '../actionTypes';
import {createAction} from "../../helpers/general";
import instance from '../../helpers/Api';

const {SET_PLAYERS, SET_BLACKLIST, UPDATE_BLACKLIST, SET_ONE_PLAYER_BLACKLIST} = PLAYERS_TYPES;
const {REQUEST_MESSAGE} = USERS_TYPES;
const {SET_TRANSACTIONS} = TOURNAMENTS_TYPES;
const {GET_TOTAL_PAGES_COUNT_NEW} = OTHER_TYPES;
const {LOADER_HIDE, LOADER_SHOW} = MAIN_TYPES;


export function getAllPlayers(data) {
    return (dispatch) => {
        instance.post('/api/players/get', data)
            .then((res) => {
                if (res.data.error) {
                    dispatch({type: REQUEST_MESSAGE, value: res.data});
                } else {

                    dispatch({type: SET_PLAYERS, value: res.data.data.players});
                    dispatch({type: GET_TOTAL_PAGES_COUNT_NEW, value: res.data.data});
                    dispatch(createAction(LOADER_HIDE));
                }
            })
            .catch((e) => {
                dispatch(createAction(LOADER_SHOW));
                console.log('getPlayers', e);
            });
    };
}

export function getPlayerBlackList(data) {
    return (dispatch) => {
        instance.post('/api/players/getOne', data)
            .then((res) => {
                // console.log(res, "get_blackList")
                dispatch(setPlayerBlackList(res.data));
            })
            .catch((e) => {
                console.log('getBlackList', e);
            });
    };
}

export function addLabel({labelColor, playerId}) {
    return (dispatch) => {
        instance.post('/api/players/addLabel', {labelColor, playerId})
            .then(() => {
                dispatch(getPlayerBlackList({playerId}))
            })
            .catch((e) => {
                console.log('getBlackList', e);
            });
    };
}

export function updateBlacklist(data) {
    return (dispatch) => {
        instance.post('/api/players/updateBlacklist', data)
            .then(() => {
                dispatch(getPlayerBlackList({playerId: data.playerId}))
            })
            .catch((e) => {
                console.log('updateBlacklist', e);
            });
    };
}

export function getOnePlayerBlackListInfo(data) {
    return (dispatch) => {
        instance.post('/api/players/onePlayerBlackListInfo', data)
            .then((res) => {
             dispatch({type: SET_ONE_PLAYER_BLACKLIST, value: prepareBlackListData(res.data.data)});

            })
            .catch((e) => {
                console.log('getOnePlayerBlackListInfo', e);
            });
    };
}

export function getRoundsForPlayer(data) {
    //  console.log(data, "getRoundsForPlayer")
    return (dispatch) => {
        instance.post('/api/players/getRoundsForPlayer', data)
            .then((res) => {
                //  console.log(res.data.data, "res-Data-getRoundsForPlayer")
                dispatch({type: SET_TRANSACTIONS, value: res.data.data.rounds});
                dispatch({type: GET_TOTAL_PAGES_COUNT_NEW, value: res.data.data});
                //   if (!res.data.error && res.data.data.rounds.length) {
                //       dispatch({type: SET_TRANSACTIONS, value: res.data.data.rounds});
                //       dispatch({type: GET_TOTAL_PAGES_COUNT_NEW, value: res.data.data});
                //   } else {
                //       dispatch({
                //           type: REQUEST_MESSAGE,
                //           value: {...res.data, message: 'No games yet'},
                //       });
                //   }
            })
            .catch((e) => {
                console.log('getRoundsForPlayer', e);
            });
    };
}

export function getPlayerTransactions(data) {
    return (dispatch) => {
        instance.post('/api/players/playerTransactions', data)
            .then((res) => {
                // console.log(res.data.data, "RES_playerTransactions")
                dispatch({type: SET_TRANSACTIONS, value: res.data.data.transactions});
                dispatch({type: GET_TOTAL_PAGES_COUNT_NEW, value: res.data.data});
                //  if (!res.data.error && res.data.data.transactions.length) {
                //      dispatch({type: SET_TRANSACTIONS, value: res.data.data.transactions})
                //      dispatch({type: GET_TOTAL_PAGES_COUNT_NEW, value: res.data.data});
                //  } else {
                //      dispatch({
                //          type: REQUEST_MESSAGE,
                //          value: {...res.data, message: 'No Transactions'},
                //      });
                //  }
            })
            .catch((e) => {
                console.log('playerTransactions', e);
            });
    };
}
export function getPlayerTickets(data) {
    return (dispatch) => {
        instance.post('/api/players/playerTickets', data)
            .then((res) => {
           //   console.log(res.data.data, "RES_playerTickets")
                dispatch({type: SET_TRANSACTIONS, value: res.data.data.tickets});
                dispatch({type: GET_TOTAL_PAGES_COUNT_NEW, value: res.data.data});
                //  if (!res.data.error && res.data.data.transactions.length) {
                //      dispatch({type: SET_TRANSACTIONS, value: res.data.data.transactions})
                //      dispatch({type: GET_TOTAL_PAGES_COUNT_NEW, value: res.data.data});
                //  } else {
                //      dispatch({
                //          type: REQUEST_MESSAGE,
                //          value: {...res.data, message: 'No Transactions'},
                //      });
                //  }
            })
            .catch((e) => {
                console.log('playerTickets', e);
            });
    };
}

export function addOrDeleteFromBlackList(data) {
    return (dispatch) => {
        instance.post('/api/players/addOrDelete', data)
            .then((res) => {
                //console.log({type, res:res.data.data}, "type-add_or-del-blackList")
                if (res.data.error) {
                    dispatch({type: REQUEST_MESSAGE, value: res.data});
                }

            })
            .catch((e) => {
                console.log('addOrDelFromBlacklist', e);
            });
    };
}

export function getPlayerSessions(data) {
    // console.log(data, "playerSessions")
    return (dispatch) => {
        instance.post('/api/players/playerSessions', data)
            .then((res) => {
                //console.log(res.data.data, "RES_playerSessions")
                dispatch({type: SET_TRANSACTIONS, value: res.data.data.sessions});
                dispatch({type: GET_TOTAL_PAGES_COUNT_NEW, value: res.data.data});
                // if (!res.data.error && res.data.data.sessions.length) {
                //     dispatch({type: SET_TRANSACTIONS, value: res.data.data.sessions})
                //     dispatch({type: GET_TOTAL_PAGES_COUNT_NEW, value: res.data.data});
                // } else {
                //     dispatch({
                //         type: REQUEST_MESSAGE,
                //         value: {...res.data, message: 'No player sessions'},
                //     });
                // }
            })
            .catch((e) => {
                console.log('playerSessions', e);
            });
    };
}

export function setPlayerBlackList(value) {
    return {type: SET_BLACKLIST, value: value};
}


export function updatePlayerBlackList(value) {
    return {type: UPDATE_BLACKLIST, value: value};
}

function prepareBlackListData(val){
    const obj={};
    for(let el of val){
        obj[el.blackList_title]=el
    }
    return obj
}
