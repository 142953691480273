import instance from '../../helpers/Api';
import { GAME_TYPES, USERS_TYPES } from '../actionTypes';
import { sortingByDate } from './gamesAction';
import { createAction } from '../../helpers/general';
const { SET_GAME_LOGS, RESET_GAME_LOGS } = GAME_TYPES;
const {REQUEST_MESSAGE} = USERS_TYPES;


export function getGameLogs(data) {
    return (dispatch) => {
        instance.post('/api/games/getOne', data)
            .then((res) => {
                if (!res.data.error) {
                    dispatch({type: SET_GAME_LOGS, value: sortingByDate(res.data.data)});
                }
                else {
                    dispatch({type: REQUEST_MESSAGE, value: res.data});

                }
            })
            .catch((e) => {
                console.log('getGameLogs', e);
            });
    };
}

export const resetGameLogs = createAction(RESET_GAME_LOGS);
