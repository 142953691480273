import React, { Component } from 'react';
import { Button, Modal, Icon } from 'semantic-ui-react';
import { updateOtherUsers } from '../redux/actions/usersActions';
import { connect } from 'react-redux';
import { editResource, saveResourceChanginq } from '../redux/actions/aclmanagmentAction';
import { resetRequestMessage } from '../redux/actions/otherActions';
import AutoCompleteInput from './AutoComplete';

class EditResource extends Component {
    state = {
        open: true,
        dimmer: 'blurring',
        edit: {
            users: { toDelete: [], toAdd: [] },
            groups: { toDelete: [], toAdd: [] },
            resourceId: null,
        },
        addUser: [],
        addGroup: [],
    };

    saveChanges = () => {
        const { edit } = this.state;
        if (edit.resourceId) {
            this.props.saveResourceChanginq(edit);
        }
    };

    deleteOrAdd = (type, name, id, resourceId, remove = false) => {
        const { edit } = this.state;
        let users = [...edit.users[type]];
        let groups = [...edit.groups[type]];
        let rem_id;
        if (!remove) {
            if (name === 'users') {
                users.push(id);
            } else {
                groups.push(id);
            }
        } else {
            if (name === 'users') {
                rem_id = users.indexOf(id);
                users.splice(rem_id, 1);
            } else {
                rem_id = groups.indexOf(id);
                groups.splice(rem_id, 1);
            }
        }
        this.setState({
            edit: {
                ...edit,
                [name]: {
                    ...edit[name],
                    [type]: name === 'users' ? users : groups,
                },
                resourceId,
            },
        });
    };
    getValue = (type, value, resId, obj) => {
        const { addGroup, addUser } = this.state;
        if (type === 'groups') {
            this.setState({
                addGroup: [...addGroup, obj],
            });
        } else {
            this.setState({
                addUser: [...addUser, obj],
            });
        }
        this.deleteOrAdd('toAdd', type, value, resId);
    };

    removeFromAdded = (type, id, resId) => {
        const { addGroup, addUser } = this.state;
        const temp = type === 'groups' ? addGroup : addUser;
        for (const [i, el] of temp.entries()) {
            if (el.id === id) {
                temp.splice(i, 1);
            }
        }
        if (type === 'groups') {
            this.setState({
                addGroup: [...temp],
            });
        } else {
            this.setState({
                addUser: [...temp],
            });
        }
        this.deleteOrAdd('toAdd', type, id, resId, true);
    };

    render() {
        const { open, dimmer, edit, addGroup, addUser } = this.state;
        const {
            title,
            name,
            requestMessage,
            editResource,
            resources,
            resetRequestMessage,
        } = this.props;
        const resId = resources.id;

        return (
            <div>
                <Modal
                    dimmer={dimmer}
                    open={open}
                    onClose={() => {
                        editResource({ open: false, name: null });
                        if (!!requestMessage) resetRequestMessage('');
                    }}
                >
                    <Modal.Header>
                        {title} - {name}
                    </Modal.Header>
                    <Modal.Content>
                        <div className="acl-edit-res">
                            Groups:
                            <div className="flex-b">
                                {resources.groups.map((item, key) => {
                                    return (
                                        <div
                                            className="res-group-list"
                                            style={{
                                                display: edit.groups['toDelete'].includes(item.id)
                                                    ? 'none'
                                                    : '',
                                            }}
                                            key={key}
                                        >
                                            {item.group_name}{' '}
                                            <Icon
                                                name="delete"
                                                size="small"
                                                className="pointer"
                                                onClick={() =>
                                                    this.deleteOrAdd(
                                                        'toDelete',
                                                        'groups',
                                                        item.id,
                                                        resId
                                                    )
                                                }
                                            />
                                        </div>
                                    );
                                })}
                                {addGroup.length
                                    ? addGroup.map((item, key) => {
                                          return (
                                              <div className="res-group-list" key={key}>
                                                  {item.title}{' '}
                                                  <Icon
                                                      name="delete"
                                                      size="small"
                                                      className="pointer"
                                                      onClick={() =>
                                                          this.removeFromAdded(
                                                              'groups',
                                                              item.id,
                                                              resId
                                                          )
                                                      }
                                                  />
                                              </div>
                                          );
                                      })
                                    : null}
                            </div>
                            <div className="check-box auto-complete">
                                <AutoCompleteInput
                                    type="groups"
                                    getValue={this.getValue}
                                    resId={resId}
                                    name={name}
                                    addGroup={addGroup}
                                />
                            </div>
                        </div>

                        <div className="acl-edit-res">
                            Users:
                            <div className="flex-b">
                                {resources.users.map((item, key) => {
                                    return (
                                        <div
                                            className="res-user-list"
                                            key={key}
                                            style={{
                                                display: edit.users['toDelete'].includes(
                                                    item.user_id
                                                )
                                                    ? 'none'
                                                    : '',
                                            }}
                                        >
                                            {item.user_name}
                                            <Icon
                                                name="delete"
                                                size="small"
                                                className="pointer"
                                                onClick={() =>
                                                    this.deleteOrAdd(
                                                        'toDelete',
                                                        'users',
                                                        item.user_id,
                                                        resId
                                                    )
                                                }
                                            />
                                        </div>
                                    );
                                })}
                                {addUser.length
                                    ? addUser.map((item, key) => {
                                          return (
                                              <div className="res-group-list" key={key}>
                                                  {item.username}{' '}
                                                  <Icon
                                                      name="delete"
                                                      size="small"
                                                      className="pointer"
                                                      onClick={() =>
                                                          this.removeFromAdded(
                                                              'users',
                                                              item.id,
                                                              resId
                                                          )
                                                      }
                                                  />
                                              </div>
                                          );
                                      })
                                    : null}
                            </div>
                            <div className="check-box auto-complete">
                                <AutoCompleteInput
                                    type="users"
                                    getValue={this.getValue}
                                    resId={resId}
                                    name={name}
                                    addUser={addUser}
                                />
                            </div>
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <div className={requestMessage.error ? 'req-mess err' : 'req-mess'}>
                            {' '}
                            {requestMessage.message}
                        </div>
                        <Button
                            color="black"
                            onClick={() => {
                                editResource({ open: false, name: null });
                                if (!!requestMessage) resetRequestMessage('');
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            positive
                            icon="checkmark"
                            labelPosition="right"
                            content="Save"
                            onClick={this.saveChanges}
                        />
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = ({ requestMessage }) => ({
    requestMessage,
});

const mapDispatchToProps = {
    updateOtherUsers,
    editResource,
    saveResourceChanginq,
    resetRequestMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditResource);
