import { combineReducers } from 'redux';
import usersReducer from './usersReducer';
import mainReducer from './mainReducer';
import otherReducer from './otherReducer';
import playerReducer from './playerReducer';
import gameReducer from './gameReducer';
import settingReducer from './settingReducer';
import aclManagmentReducer from './aclmanagmentReducer';
import tournamentReducer from './tournamentsReducer';
import spinsReducer from './spinReducer';
import cms from './cmsReducer';
import general from './generalReducer';
import player from '../ducks/playerDucks';
import globalRefs from '../ducks/globalRefsDucks';
import allTicketsReducer from './ticketReducer';
import { tabReducer } from '../ducks/tabDuck';
import bazarBlotReducer from '../reducers/bazarBlotReducer';
import bazarBlotCurrentReducer from '../reducers/bazarBlotReducer';
import bazarBlotLogsReducer from '../reducers/bazarBlotReducer';

export default combineReducers({
    ...allTicketsReducer,
    ...usersReducer,
    ...mainReducer,
    ...otherReducer,
    ...playerReducer,
    ...gameReducer,
    ...bazarBlotReducer,
    ...bazarBlotCurrentReducer,
    ...bazarBlotLogsReducer,
    ...settingReducer,
    ...aclManagmentReducer,
    ...tournamentReducer,
    ...spinsReducer,
    ...cms,
    ...general,
    ...player,
    currentGame: () => ({}),
    ...globalRefs,
    tabReducer,
});
