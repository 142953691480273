import { GAME_STATUS_IDS } from './ids';
const { BUSY, JOINED, REGISTRATION, STARTED, WAITING, FINISHED, ANNOUNCED } = GAME_STATUS_IDS;

export const SCENE_NAMES = {
    ROOM_SCENE: 'ROOM_SCENE',
    LOBBY_SCENE: 'LOBBY_SCENE',
    LOADING_SCENE: 'LOADING_SCENE',
};

export const SIDES = {
    RIGHT: 'right',
    LEFT: 'left',
};
export const LOBBY_TAB_NAMES = {
    CLASSIC: 'CLASSIC',
    OPEN: 'OPEN',
    SPIN: 'SPIN',
    TOURNAMENTS: 'TOURNAMENTS',
    LEADERBOARD: 'LEADERBOARD',
};
export const LOBBY_SORT_STATUSES = {
    INACTIVE_SORT: 0,
    ASCENDING_SORT: 1,
    DESCENDING_SORT: 2,
};

export const GAME_MIN_POINT = 51;

export const GAME_STATUS_NAMES = {
    [BUSY]: 'Busy',
    [JOINED]: 'Joined',
    [REGISTRATION]: 'Registration',
    [STARTED]: 'Started',
    [WAITING]: 'Waiting',
    [FINISHED]: 'Finished',
    [ANNOUNCED]: 'Announced',
};

export const CURRENCY_SYMBOLS = {
    AMD: '\u058f',
    USD: '\u0024',
};

export const DECK_CARDS_COUNT = 24;
