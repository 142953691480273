import React from 'react';

function RowMoveScore({ score }) {
    const { playerScore = {}, opponentScore = {} } = score;

    return (
        <div className="score__line-decoration-score__row">
            <div className="score__line-decoration-score__cell">
                <span className="score__line-decoration-score__text">{createScoreText(playerScore)}</span>
            </div>
            <div className="score__line-decoration-score__cell">
                <span className="score__line-decoration-score__text">{createScoreText(opponentScore)}</span>
            </div>
        </div>
    );
}

function createScoreText(score) {
    const { handScore, combinationScore } = score;
    let text = handScore || '0';
    if (!!combinationScore) {
        text += ` + ${combinationScore}`;
    }
    return `(${text})`;
}

export default RowMoveScore;
