import {GAME_TYPES, MAIN_TYPES, OTHER_TYPES, USERS_TYPES} from '../actionTypes';
import instance from '../../helpers/Api';
import moment from "moment";
import {createAction} from "../../helpers/general";

const {SET_GAMES, SET_ONE_GAMES, SET_GAME_LOGS} = GAME_TYPES;
const {REQUEST_MESSAGE} = USERS_TYPES;
const {GET_TOTAL_PAGES_COUNT_NEW} = OTHER_TYPES;
const {LOADER_HIDE, LOADER_SHOW} = MAIN_TYPES;


export function getAllGames(data, sorting = false) {
    //console.log({page, order, orderBy, dateFrom, dateTo}, "{page, order, orderBy, dateFrom, dateTo}")
    return (dispatch) => {
        if (!sorting) {
            dispatch(createAction(LOADER_SHOW));
        }

        instance.post('/api/games/get', data)
            .then((res) => {
                //console.log(res,"RES-getGames");
                if (res.data.error) {
                    dispatch({type: REQUEST_MESSAGE, value: res.data});
                } else {
                    dispatch({type: SET_GAMES, value: res.data.data.games});
                    dispatch({type: GET_TOTAL_PAGES_COUNT_NEW, value: res.data.data});
                    dispatch(createAction(LOADER_HIDE));
                }
            })
            .catch((e) => {
                dispatch(createAction(LOADER_SHOW));
                console.log('getGames', e);
            });
    };
}

export function getOneGame({id}) {
    //console.log({page, order, orderBy, dateFrom, dateTo}, "{page, order, orderBy, dateFrom, dateTo}")
    return (dispatch) => {
        instance.post('/api/games/getOne', {id})
            .then((res) => {
               // console.log(res.data, "RES-getOneGame")
                if(!res.data.error){
                    dispatch({type: SET_ONE_GAMES, value: res.data});
                    if(res.data.data[0].logs){
                        dispatch({type: SET_GAME_LOGS, value: sortingByDate(res.data.data[0].logs)});
                    }
                }
                else {
                    console.log(res.data.message, "Err-getOneGame");
                }

            })
            .catch((e) => {
                console.log('getOneGame', e);
            });
    };
}


export  function sortingByDate(arr) {
    return arr.sort((a, b) => {
        const d1 = new Date(a['TR_PREFIX']['date']);
        const d2 = new Date(b['TR_PREFIX']['date']);
        return d1 - d2
    })
}

export function killOneGame(data) {

    return (dispatch) => {
        instance.post('/api/games/kill', data)
            .then((res) => {
                //console.log(res, "RES-getOneGame");
                dispatch({type: REQUEST_MESSAGE, value: res.data});
            })
            .catch((e) => {
                console.log('gameKill', e);
            });
    };
}

export function resetGameTable() {
    return (dispatch) => {
        dispatch({type: SET_GAMES, value: []});
    }
}
