import React, {useEffect, useState} from 'react';
import SortablePlayersTable from '../../components/SortablePlayersTable';
import {Loader, Pagination, Select} from 'semantic-ui-react';
import {getTickets} from '../../redux/actions/ticketsActions';
import {connect} from 'react-redux';
import FilterPlayersList from "../../components/FilterPlayersList";
import '../player/player.css';
import TimeZone from "../../components/TimeZone";
import Filter from "../../components/Filter";
import SortableTicketsTable from "../../components/SortableTicketsTable";
import TicketFilter from "../../components/TicketFilter";
import {allTicketsReducer} from "../../redux/reducers/ticketReducer";

const rowItems = [
    // { key: '10', text: '10 rows', value: '10' },
    // { key: '30', text: '20 rows', value: '20' },
    { key: '50', text: '50 rows', value: '50' },
    { key: '100', text: '100 rows', value: '100' },
];


function Tickets({getTickets, ...redux}) {
    const {tickets, totalPagesReducer, loader,toggleType} = redux;
    const [sortedField, setSortedField] = useState('');
    const [direction, setDirection] = useState('DESC');
    const [activePage, setActivePage] = useState('');
    const [rowCount, setRowCount] = useState(0);
    const [limit, setLimit] = useState(50);
    const [offset, setOffset] = useState(0);
    const [filerDateForSorting, setFilerDateForSorting] = useState({});
    const [selected, setSelected] = useState(false);
    const [checked, setChecked] = useState(false);
    const [IsFilter, setIsFilter] = useState(false);
    const [startTime, setStartTime] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [timeTo, setTimeTo] = useState(null);
    const [pickedDate, setPickedDate] = useState(null);

    useEffect(() => {
        getTickets({limit, offset, ...filerDateForSorting, toggleType});
    }, []);

    const onPageChange = (e, data) => {
        getTickets({
            offset: (data.activePage - 1) * limit,
            orderBy: sortedField,
            order: direction,
            ...filerDateForSorting,
            limit,
            toggleType
        });
        setOffset((data.activePage - 1) * limit);
        setActivePage(data.activePage);
        setRowCount(() => data.activePage * limit - limit);
    };

    const filterTickets = (data) => {
        getTickets({
            ...data,
            limit,
            offset,
            toggleType
        });
        setOffset(0);
        setActivePage(1);
        setRowCount(0);
        setFilerDateForSorting({... data});
    };

    const sorting = (field, direction) => {
        getTickets({
            orderBy: field,
            order: direction,
            ...filerDateForSorting,
            limit,
            offset: 0,
            toggleType
        });
        setSortedField(field);
        setActivePage(1);
        setDirection(direction);
        setRowCount(0);
    };
    const reset = () => {
        //resetGameTable();
        setSelected(false);
        setChecked(false);
        setIsFilter(false);
        setPickedDate('');
        setFilerDateForSorting({});
    };
    const getRowCount = (e, data) => {
        let activePage = Math.floor(offset / limit) + 1;
        setLimit(+data.value);
        getTickets({
            limit: +data.value,
            offset: data.value > rowCount ? 0 : +data.value,
            ...filerDateForSorting,
            toggleType
        });
        setRowCount(0);
        setActivePage(activePage);
    };
    return (
        <div className="player-content">
            <h2>Tickets List</h2>
            <TimeZone />
            {!loader?
                <>
                    <TicketFilter  filterTickets={filterTickets} reset={reset} startDate={startDate} startTime={startTime}
                            timeTo={timeTo}/>
                    <div className="row-count">
                        <div className="label">Rows count per page</div>
                        <div>
                            <Select
                                selectOnBlur={false}
                                className="own"
                                placeholder={`${limit} rows`}
                                options={rowItems}
                                onChange={getRowCount}
                            />
                        </div>
                    </div>
                    <div className='table-and-pg'>
                        <SortableTicketsTable toggleType={toggleType} data={tickets['tickets']} sorting={sorting} count={rowCount}/>
                        <Pagination
                            activePage={activePage}
                            totalPages={totalPagesReducer}
                            className="pagi-own"
                            onPageChange={totalPagesReducer > 1 ? onPageChange : null}
                        />
                    </div>
                </>:<Loader active> Loading players list ... </Loader>}
        </div>
    );
}

const mapStateToProps = ({playerReducer, allTicketsReducer, totalPagesReducer, loaderReducer,globalPlayerReducer}) => ({
    playerReducer,
    totalPagesReducer,
    loader: loaderReducer,
    toggleType:globalPlayerReducer,
    tickets: allTicketsReducer
});

const mapDispatchToProps = {
    getTickets,
};

export default connect(mapStateToProps, mapDispatchToProps)(Tickets);
