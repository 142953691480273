import React, { useEffect, useRef } from 'react';
import { setTableCardPosition } from '../../../helpers/helpers/gameplay';

export default function TableCardHR({ cardId, isOpponentCard, styles }) {
    const ref = useRef(null);

    useEffect(() => {
        if (ref.current && !!cardId) {
            setTableCardPosition(ref.current, isOpponentCard, 'cardZIndex');
        }
    }, [cardId, ref]);

    return (
        <div
            ref={ref}
            style={{ position: 'absolute', transform: isOpponentCard && cardId && 'rotateY(180deg)', ...styles }}
            className={`blot-field__user-card-wrapper card-field card-field--${cardId}`}
            id={cardId}
        />
    );
}
