import React, { Component } from 'react';
import { Select, Input, Button, Checkbox, Radio,  } from 'semantic-ui-react';
import {
    createTournamentTemplate,
    updateTournamentTemplate,
    setDateAndTime,
    getPrizeRules,
    getPrizeRuleName,
    setSelectedTournamentData,
    setPrizeDataFromTournamentTemplate,
    getCurrentTournamentByName,
    resetDateAndTime,
    getAllTournamentsTemplates,
} from '../redux/actions/tournamentsAction';
import { leftDropDownMenuControl, resetRequestMessage } from '../redux/actions/otherActions';
import { connect } from 'react-redux';
import '@trendmicro/react-datepicker/dist/react-datepicker.css';
import '../pages/tournament/createTournament/createTournament.css';
import DateController from '../pages/tournament/createTournament/DateController';
import Prize from '../pages/tournament/createTournament/prize';
import store from '../redux/store';
import ModalForMessages from './ModalForMessages';
import { reset } from '../pages/tournament/createTournament/reset';

class OpenTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: true,
            dimmer: 'blurring',
            prizeTemplateId: null,
            prize: {},
            error:'',
            gameType: [
                { key: '11', text: 'CLASSIC', value: 'CLASSIC' },
                { key: '22', text: 'OPEN', value: 'OPEN' },
            ],

            points: [
                { key: '1', text: '51', value: '51' },
                { key: '2', text: '101', value: '101' },
                // {key: '3', text: '1 Round', value: '1Round'},
                // {key: '4', text: '2 Round', value: '2Round'},
                // {key: '5', text: '3 Round', value: '3Round'},
                // {key: '6', text: '4 Round', value: '4Round'},
                // {key: '7', text: '5 Round', value: '5Round'},
                // {key: '8', text: '6 Round', value: '6Round'},
                // {key: '9', text: '7 Round', value: '7Round'},
            ],
            time: [
                { key: '21', text: '20 s', value: '20' },
                { key: '22', text: '30 s', value: '30' },
                { key: '23', text: '40 s', value: '40' },
                { key: '24', text: '50 s', value: '50' },
            ],
            disTime: [
                { key: '1', text: '30 s', value: '30' },
                { key: '2', text: '1 m', value: '60' },
                { key: '3', text: '2 m', value: '120' },
                { key: '4', text: '3 m', value: '180' },
            ],

        };
    }

    componentDidMount() {
      const { title } = this.props;
        if(title ==='Create'){
            this.props.setSelectedTournamentData(reset);
            this.props.resetDateAndTime({});
            this.props.setPrizeDataFromTournamentTemplate({ data: [] });

        }
        else{
          this.props.leftDropDownMenuControl(true);
          this.props.getAllTournamentsTemplates();
        }
        this.props.getPrizeRules();
    }

    componentDidUpdate() {
        const { requestMessage } = this.props;
        if (!!requestMessage && !requestMessage.error) {
          //console.log("componentDidUpdate")
        }
    }

    action = (type) => {
        const { prize } = this.state;
        const { createTournamentTemplate, tournamentData, updateTournamentTemplate } = this.props;
        const dateObj = store.getState().tournamentDateReducer;
        const changeValuesToInteger = [];
        for (let i in prize.prizeDistribution) {
            changeValuesToInteger.push({
                position: +i + 1,
                percent: +prize.prizeDistribution[i].percentTotal,
            });
        }
      //  console.log(changeValuesToInteger, 'changeValuesToInteger');
        const data = {
            ...tournamentData,
            playerRegistrationStart:
                dateObj['register']['day'] * 24 * 60 +
                dateObj['register']['hour'] * 60 +
                dateObj['register']['min'],
            announcementStart:
                dateObj['announcementStart']['day'] * 24 * 60 +
                dateObj['announcementStart']['hour'] * 60 +
                dateObj['announcementStart']['min'],
            announcementEnd:
                dateObj['announcementEnd']['day'] * 24 * 60 +
                dateObj['announcementEnd']['hour'] * 60 +
                dateObj['announcementEnd']['min'],
         // finishedAt: dateObj['finishedAt']['min'],
            prizePool: prize.prizePool,
            winners: prize.winners,
            prizeDistribution: changeValuesToInteger,
        };
      if (data.hasOwnProperty('scheduledAt')) {
        delete data['scheduledAt'];
      }
      if (data.hasOwnProperty('scheduledSequence')) {
        delete data['scheduledSequence'];
      }
        if (prize.prizeRuleName){
            data['prizepool_template_id']=prize.prizeRuleName
        }

        if (!data.hasOwnProperty('priorityInLobby') || data['priorityInLobby'] === '') {
            data['priorityInLobby'] = 0;
        }
        if (!data.hasOwnProperty('buyIn') || data['buyIn'] === '') {
            data['buyIn'] = 0;
        }

        if (type === 'Edit') {
            const finalData = { ...data, tournamentTemplateId:data.id };

            delete finalData['id'];
          updateTournamentTemplate(finalData);
        }  else if (type === 'Copy') {
            let finalData = { ...data };

          createTournamentTemplate(finalData);
        }else if (type === 'Create') {
            const finalData = { ...data };
            createTournamentTemplate(finalData);
        }
    };

    handleChangeNum = (e, name) => {
        this.props.setSelectedTournamentData({
            ...this.props.tournamentData,
            [name]: +e.target.value,
        });
    };


    handleChangeComb = (index) => {
        this.props.setSelectedTournamentData({ ...this.props.tournamentData, gameCombs: index });
    };

    trumpsStatus = (e) => {
        const status = e === 'on' ? 'off' : 'on';
        this.props.setSelectedTournamentData({ ...this.props.tournamentData, gameNoTrump: status });
    };

    getSelectValue = (name, data) => {
        this.props.setSelectedTournamentData({
            ...this.props.tournamentData,
            [name]: name !== 'gameType' ? +data.value : data.value,
        });



        //
        // this.props.setSelectedTournamentData({
        //     ...this.props.tournamentData,
        //     scheduledSequence: {
        //         iterator: +data.value,
        //     },
        // });

    };
    getTournamentName = (e) => {
        if (e.target.value.length <= 16) {
            if (this.state.error) {
                this.setState({error: ''})
            }

            this.props.setSelectedTournamentData({
                ...this.props.tournamentData,
                name: e.target.value,
            });

        } else {

            this.setState({error: 'Name should be less than 16 symbols'})
        }

    };
    getPrizeTemplateId = (e, data) => {
        const { tournamentPrizeReducer, getPrizeRuleName } = this.props;
        if (data.value) {
            let title = '';
            for (let el of tournamentPrizeReducer) {
                if (el.value === data.value) {
                    title = el.text;
                }
            }
            this.setState({
                prizeTemplateId: data.value,
            });
            getPrizeRuleName({ title });
        } else {
            this.props.setPrizeDataFromTournamentTemplate({ data: [] });
        }
    };

    setPrizeData = (data) => {
        this.setState({
            prize: data,
        });
    };

    isHighlighted = (e) => {
        this.props.setSelectedTournamentData({ ...this.props.tournamentData, isHighlighted: !e });
    };


    render() {
        const {
            gameType,
            time,
            disTime,
            points,
            error
             } = this.state;
        const {
            requestMessage,
            resetRequestMessage,
            tournamentPrizeReducer,
            tournamentData,
            close,
            title,
            disable,
        } = this.props;

        return (
            <div style={{ width: '100%' }}>


                <div className="create-tournament-content">
                  {!!requestMessage ? (
                    <ModalForMessages
                      error={requestMessage.error}
                      message={requestMessage.message}
                      close={() => resetRequestMessage('')}
                    />
                  ) : null}
                    <div className="input-box">
                        <div className="check-box">
                            <div className="label">Template Name:</div>
                            <Input
                                placeholder="Max 16 symbol..."
                                value={tournamentData.name}
                                disabled={disable}
                                onChange={this.getTournamentName}
                            />
                            <span className="error-mes">{error}</span>
                        </div>
                    </div>
                    <div className="input-box create-tournament-date border-vert black-own">
                          <DateController
                            title={'Announcement Start Date:'}
                            stateName={'announcementStart'}
                            showSubPicker={true}
                            showDatePicker={true}
                            subTitle={'before start date'}
                            disable={disable}
                            template={true}
                        />
                        <DateController
                            title={'Registration Date:'}
                            stateName={'register'}
                            showSubPicker={true}
                            showDatePicker={true}
                            subTitle={'before start date'}
                            disable={disable}
                            template={true}
                        />
                        <DateController
                            title={'Announcement End Date:'}
                            stateName={'announcementEnd'}
                            showSubPicker={true}
                            showDatePicker={false}
                            subTitle={'After end of Tournament'}
                            disable={disable}
                            template={true}
                        />
                        {/*<DateController*/}
                        {/*  title={'Finished At:'}*/}
                        {/*  stateName={'finishedAt'}*/}
                        {/*  showSubPicker={false}*/}
                        {/*  showDatePicker={false}*/}
                        {/*  subTitle={'Finished At'}*/}
                        {/*  disable={disable}*/}
                        {/*  template={true}*/}
                        {/*  finishedAt={true}*/}
                        {/*/>*/}
                    </div>
                    <div className="input-box border-bottom">
                        <div>
                            <div className="margin-12">
                                <Input
                                    disabled={disable}
                                    label="Min Players:"
                                    value={tournamentData.minPlayers}
                                    onChange={(e) => this.handleChangeNum(e, 'minPlayers')}
                                    type="number"
                                    min={1}
                                />
                            </div>
                            <div className="margin-12">
                                <Input
                                    disabled={disable}
                                    label="Priority in Lobby"
                                    value={tournamentData.priorityInLobby === 0 ? '' : tournamentData.priorityInLobby}
                                    onChange={(e) => this.handleChangeNum(e, 'priorityInLobby')}
                                    type="number"
                                    min={1}
                                    max={10}
                                />
                            </div>
                        </div>
                        <div className="margin-12">
                            <Input
                                disabled={disable}
                                label="Max Players:"
                                value={tournamentData.maxPlayers}
                                onChange={(e) => this.handleChangeNum(e, 'maxPlayers')}
                                type="number"
                                min={1}
                            />
                            <div>
                                <div className="label"> Highlighted:</div>
                                <Checkbox
                                    toggle
                                    checked={tournamentData.isHighlighted}
                                    disabled={disable}
                                    onChange={() =>
                                        this.isHighlighted(tournamentData.isHighlighted)
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="input-box border-bottom date-flex">
                    <div className="registration-day-manual column">
                        <div className="margin-r">
                            <div className="label">Game types:</div>
                            <Select
                                selectOnBlur={false}
                                disabled={disable}
                                clearable
                                placeholder={
                                    tournamentData.gameType ? tournamentData.gameType : 'Game type'
                                }
                                options={gameType}
                                onChange={(e, data) => this.getSelectValue('gameType', data)}
                            />
                        </div>
                        <div className="margin-r">
                            <div className="label">Points:</div>
                            <Select
                                selectOnBlur={false}
                                disabled={disable}
                                clearable
                                placeholder={
                                    tournamentData.gamePoints
                                        ? tournamentData.gamePoints + ''
                                        : 'Points'
                                }
                                options={points}
                                onChange={(e, data) => this.getSelectValue('gamePoints', data)}
                            />
                        </div>
                        <div className="margin-r">
                            <div className="label">Time:</div>
                            <Select
                                selectOnBlur={false}
                                disabled={disable}
                                clearable
                                placeholder={
                                    tournamentData.gameTime
                                        ? tournamentData.gameTime + ' s'
                                        : 'Time'
                                }
                                options={time}
                                onChange={(e, data) => this.getSelectValue('gameTime', data)}
                            />
                        </div>
                        <div className="margin-r">
                            <div className="label">Disconnect Time:</div>
                            <Select
                                selectOnBlur={false}
                                disabled={disable}
                                clearable
                                placeholder={
                                    tournamentData.disconnectTimer
                                        ? tournamentData.disconnectTimer > 30
                                        ? tournamentData.disconnectTimer / 60 + ' m'
                                        : tournamentData.disconnectTimer + ' s'
                                        : 'Disconnet Time'
                                }
                                options={disTime}
                                onChange={(e, data) => this.getSelectValue('disconnectTimer', data)}
                            />
                        </div>
                    </div>
                    <div>
                        <div className="check-box">
                            <div>
                                <Radio
                                    disabled={disable}
                                    label="All combinations"
                                    name="radioGroup"
                                    value="ALLCOMBS"
                                    checked={tournamentData.gameCombs === 'ALLCOMBS'}
                                    onChange={() => this.handleChangeComb('ALLCOMBS')}
                                />
                            </div>
                            <div>
                                <Radio
                                    disabled={disable}
                                    label="Without 4 cards and 100"
                                    name="radioGroup"
                                    value="NO4NO100"
                                    checked={tournamentData.gameCombs === 'NO4NO100'}
                                    onChange={() => this.handleChangeComb('NO4NO100')}
                                />
                            </div>
                            <div>
                                <Radio
                                    disabled={disable}
                                    label="Belote / Rebelote only"
                                    name="radioGroup"
                                    value="ONLYBR"
                                    checked={tournamentData.gameCombs === 'ONLYBR'}
                                    onChange={() => this.handleChangeComb('ONLYBR')}
                                />
                            </div>
                        </div>
                        <div className="check-box">
                            <div className="label"> No Trumps</div>
                            <Checkbox
                                toggle
                                disabled={disable}
                                checked={tournamentData.gameNoTrump === 'on'}
                                onChange={() => this.trumpsStatus(tournamentData.gameNoTrump)}
                            />
                        </div>
                        <div>
                            <Input
                                disabled={disable}
                                label="Buy-in"
                                value={tournamentData.buyIn === 0 ?'': tournamentData.buyIn}
                                onChange={(e) => this.handleChangeNum(e, 'buyIn')}
                                type="number"
                                min={1}
                            />
                        </div>
                    </div>
                </div>
                <div className="input-box min-height-250 border-bottom">
                    <Prize setPrizeData={this.setPrizeData} editOrCopy={true} disable={disable} />
                  <div className="check-box">
                      <div className="label">Prize Templates</div>
                      <Select
                        selectOnBlur={false}
                        disabled={disable}
                        clearable
                        placeholder="Templates"
                        options={tournamentPrizeReducer}
                        onChange={this.getPrizeTemplateId}
                      />
                  </div>
                </div>
                <div className="create-tournament-actions prize">
                    {/*<div className={requestMessage.error ? 'req-mess err' : 'req-mess'}>*/}
                    {/*    {requestMessage.message}*/}
                    {/*</div>*/}
                    <Button
                        positive
                        icon="checkmark"
                        labelPosition="right"
                        content={title === 'Edit'?'Save':'Create'}
                        onClick={() => this.action(title)}
                    />

                    <Button color="black" onClick={close}>
                        Cancel
                    </Button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({
                             requestMessage,
                             tournamentPrizeReducer,
                             tournamentTemplatesReducer,
                             oneTournamentReducer,
                         }) => ({
    requestMessage,
    tournamentPrizeReducer,
    tournamentData: oneTournamentReducer,
    tournamentTemplates: tournamentTemplatesReducer,
});

const mapDispatchToProps = {
    setDateAndTime,
    getPrizeRules,
    getPrizeRuleName,
    resetRequestMessage,
    leftDropDownMenuControl,
    setSelectedTournamentData,
    setPrizeDataFromTournamentTemplate,
    getCurrentTournamentByName,
    resetDateAndTime,
    getAllTournamentsTemplates,
    createTournamentTemplate,
    updateTournamentTemplate,


};

export default connect(mapStateToProps, mapDispatchToProps)(OpenTemplate);
