import { TABLE_HEADER_IDS } from '../../constants/game/ids';
import { CURRENCY_SYMBOLS, GAME_MIN_POINT, LOBBY_SORT_STATUSES } from '../../constants/game/other';
import { FILTER_ITEM_IDS, TABLE_CREATOR_ITEM_IDS, TABLE_ROW_GAME_TYPE_IDS } from '../../constants/classicLobby/ids';
import { isBetweenRange } from './general';
import { GAME_KINDS, GAME_SCENE_TYPES, LOBBY_ID } from '../../constants/general';
import store, { getStoreState } from '../../redux/store';

const { CLASSIC, OPEN } = GAME_KINDS;
const { ALL_COMBINATIONS, BLOT_REBLOT, WITHOUT_4_AND_100 } = TABLE_CREATOR_ITEM_IDS.RADIO_IDS;
/**
 * @param {array} data                        table row data
 * @param {{id: string, status: boolean}}     selectedSortItem
 * @param {string}                            initialSortId
 * @param {boolean}                           isInitialSortReversed
 * @return {array}                            returns sorted data
 * */
export function sortTableData(data, selectedSortItem, initialSortId, isInitialSortReversed = false) {
    const { id, status } = selectedSortItem;
    return [...data].sort((a, b) => {
        if (status === LOBBY_SORT_STATUSES.ASCENDING_SORT) {
            return (
                classicPlayer2Sort(a, b, id) ||
                classicPlayer1Sort(a, b, id) ||
                spinPlayersSort(a, b, id) ||
                startDateSort(a, b, id) ||
                tournamentsPlayersSort(a, b, id) ||
                tournamentsNameSort(a, b, id) ||
                a[id] - b[id]
            );
        }
        if (status === LOBBY_SORT_STATUSES.DESCENDING_SORT) {
            return (
                classicPlayer2Sort(b, a, id) ||
                classicPlayer1Sort(b, a, id) ||
                spinPlayersSort(b, a, id) ||
                startDateSort(b, a, id) ||
                tournamentsPlayersSort(b, a, id) ||
                tournamentsNameSort(b, a, id) ||
                b[id] - a[id]
            );
        }
        if (status === LOBBY_SORT_STATUSES.INACTIVE_SORT) {
            if (isInitialSortReversed) {
                return b[initialSortId] - a[initialSortId];
            }
            if (initialSortId === TABLE_HEADER_IDS.classicPlayer2) {
                return classicPlayer2Sort(a, b, id);
            }
            if (initialSortId === TABLE_HEADER_IDS.spinPlayers) {
                return spinPlayersSort(a, b, id);
            }
            return a[initialSortId] - b[initialSortId];
        }
        return 0;
    });
}

function classicPlayer2Sort(first, second, id) {
    if ((first.kind === CLASSIC || first.kind === OPEN) && id === TABLE_HEADER_IDS.classicPlayer2) {
        const firstJoinedPlayer = first.players.opponentId || first.applicantId;
        const secondJoinedPlayer = second.players.opponentId || second.applicantId;
        return firstJoinedPlayer - secondJoinedPlayer;
    }
}

function classicPlayer1Sort(first, second, id) {
    if ((first.kind === CLASSIC || first.kind === OPEN) && id === TABLE_HEADER_IDS.classicPlayer1) {
        return first.players.ownerId - second.players.ownerId;
    }
}

function spinPlayersSort(first, second, id) {
    if (id === TABLE_HEADER_IDS.spinPlayers) {
        const firstPlayerCount = first.players.filter((el) => !!el.id).length;
        const secondPlayerCount = second.players.filter((el) => !!el.id).length;
        return firstPlayerCount - secondPlayerCount;
    }
}

function tournamentsPlayersSort(first, second, id) {
    if (id === TABLE_HEADER_IDS.tournamentsPlayers) {
        return first.players.registrated - second.players.registrated;
    }
}

function startDateSort(first, second, id) {
    if (id === TABLE_HEADER_IDS.startDate) {
        return new Date(first[id]).getTime() - new Date(second[id]).getTime();
    }
}

function tournamentsNameSort(first, second, id) {
    if (id === TABLE_HEADER_IDS.name) {
        const isSmaller = first[id] < second[id];
        return isSmaller ? -1 : 1;
    }
}

/**
 * @param {string} name            class name, if setClass became true
 * @param {boolean} condition
 * @param {string} failed        class name, if setClass became false
 * @return {string}                returns conditional className
 * */
export function setClass(condition, name, failed = '') {
    return !!condition ? name : failed;
}

export function setLocalRoomId(roomId) {
    try {
        const playerId = getStoredPlayer().id.toString();
        localStorage.setItem(playerId, roomId);
    } catch (e) {}
}

export function getLocalRoomId() {
    try {
        const playerId = getStoredPlayer().id.toString();
        return Number(localStorage.getItem(playerId)) || LOBBY_ID;
    } catch (e) {}
}

export function getActiveRoomId() {
    return getStoreState().activeRoomId;
}

export function getStoredPlayer() {
    return store.getState().player;
}

export function getTopSidePlayer() {
    const {
        currentGame,
        player: { id: userId },
    } = getStoreState();
    if (currentGame.roomId) {
        const {
            players: { owner, opponent },
        } = currentGame;

        if (userId === owner.id) {
            return opponent;
        }
        if (userId === opponent.id) {
            return owner;
        }

        return opponent;
    }
    return {};
}

export function getTopSidePlayer2(bottomPlayerId, owner, opponent) {
    if (!!owner && !!opponent) {
        return [owner, opponent].find((player) => player.id !== bottomPlayerId);
    } else {
        return {};
    }
}

export function getBotSidePlayer() {
    const {
        currentGame,
        player: { id: userId },
    } = getStoreState();
    if (currentGame.roomId) {
        const {
            players: { owner, opponent },
        } = currentGame;

        if (userId === owner.id) {
            return owner;
        }
        if (userId === opponent.id) {
            return opponent;
        }

        return owner;
    }
    return {};
}

export function getCurrencySymbol() {
    let currency;
    try {
        currency = getStoredPlayer().settings.currency;
    } catch (e) {
        console.log(e);
    }
    return CURRENCY_SYMBOLS[currency] || '$';
}

export function filterTableData(data, selectedFilter) {
    const { POINTS, ROUNDS, T20, T30, T40 } = FILTER_ITEM_IDS;

    const isSomethingChecked = !!Object.values(selectedFilter).find((id) => !!id);
    const checkedTimeIds = [T20, T30, T40].filter((id) => !!selectedFilter[id]);
    const checkedScoreTypeIds = [POINTS, ROUNDS].filter((id) => !!selectedFilter[id]);
    const checkedGameTypeIds = Object.values(TABLE_ROW_GAME_TYPE_IDS).filter((id) => !!selectedFilter[id]);

    if (isSomethingChecked) {
        const min = selectedFilter.min.slice(1);
        const max = selectedFilter.max.slice(1);

        return data.filter((room) => {
            let isGameTypesValid = true;
            let isTimeValid = true;
            let isScoreValid = true;

            const isBetweenValid = isBetweenRange(room.bet, min || 0, max || Number.MAX_SAFE_INTEGER);

            if (!!checkedTimeIds.length) {
                isTimeValid = checkedTimeIds.includes('isT' + room.time);
            }

            if (!!checkedGameTypeIds.length) {
                isGameTypesValid = checkedGameTypeIds.every((checkedId) => {
                    return room.gameSettings[checkedId];
                });
            }

            if (!!checkedScoreTypeIds.length) {
                isScoreValid = checkedScoreTypeIds.find(
                    (checkedId) =>
                        (checkedId === POINTS && room.till >= GAME_MIN_POINT) ||
                        (checkedId === ROUNDS && room.till < GAME_MIN_POINT)
                );
            }

            return isBetweenValid && isGameTypesValid && isTimeValid && isScoreValid;
        });
    } else {
        return data;
    }
}

export function isUserThePlayer(eventPlayerId) {
    return eventPlayerId === getStoredPlayer().id;
}

export function isThisRoom(eventRoomId) {
    return eventRoomId === getActiveRoomId();
}

export function isSpinPreGameStage(game) {
    if (!!game) {
        const { roomId, scene, isAnimation } = game;
        return !!roomId && !!isAnimation && scene === GAME_SCENE_TYPES.SPIN;
    }
    return false;
}

export function getIsUsersTurn(currentGame) {
    const playerId = getStoredPlayer().id;
    return currentGame.currentPlayer.id === playerId;
}

export function creatorDataToRoomSettings(tableCreatorData) {
    const { amount, points, time, dave, radio, isPointDiff, isNoTrumps, isPrivateGame, isCapot2x } = tableCreatorData;

    return {
        kind: store.getState().lobbyActiveTab.toLowerCase(),
        bet: Number(amount),
        time: Number(time),
        till: Number(points),
        gameSettings: {
            isPointDiff,
            isPrivateGame,
            isCapot2x,
            isNoTrumps,
            isBlotReblot: radio === BLOT_REBLOT,
            isAllCombinations: radio === ALL_COMBINATIONS,
            isWithout4and100: radio === WITHOUT_4_AND_100,
            dave: Number(dave) || null,
        },
    };
}

export function roomSettingsToCreatorData(room) {
    const {
        kind,
        bet,
        time,
        till,
        gameSettings: {
            isPointDiff,
            isPrivateGame,
            isCapot2x,
            isNoTrumps,
            isBlotReblot,
            isAllCombinations,
            isWithout4and100,
            dave,
        },
    } = room;

    return {
        kind,
        amount: bet,
        points: till,
        time,
        dave,
        isPointDiff,
        isNoTrumps,
        isPrivateGame,
        isDave: !!dave,
        isCapot2x,
        radio:
            (isBlotReblot && BLOT_REBLOT) ||
            (isAllCombinations && ALL_COMBINATIONS) ||
            (isWithout4and100 && WITHOUT_4_AND_100),
    };
}

export function getRoundText(round) {
    return round === '1/1' ? 'Final' : round;
}
