import React, { useEffect, useRef } from 'react';
import { setBatCardPosition } from '../../../helpers/helpers/gameplay';
import { connect } from 'react-redux';

function BatCard({ cardId, isOpponentCard, styles = {}, lastHandCardData, bottomPlayerId, ...redux }) {
    const ref = useRef(null);
    const { blotFieldRef, deckRef, headerRef, resize } = redux;
    // //instant moveToBat
    useEffect(() => {
        if (cardId) {
            const { batOwnerId, lastHandCardId } = lastHandCardData;
            if (
                !!ref.current &&
                lastHandCardId === cardId &&
                !!blotFieldRef.current &&
                !!deckRef.current
            ) {
                setBatCardPosition(
                    ref.current,
                    batOwnerId !== bottomPlayerId,
                    isOpponentCard,
                    blotFieldRef.current,
                    deckRef.current
                );
            }
        }
    }, [ref, cardId, blotFieldRef, deckRef, lastHandCardData]);

    useEffect(() => {
        if (!!resize && !!ref.current && !!blotFieldRef.current) {
            if (cardId) {
                const { batOwnerId, lastHandCardId } = lastHandCardData;
                if (lastHandCardId === cardId && !!deckRef.current && !!headerRef.current) {
                    setBatCardPosition(
                        ref.current,
                        batOwnerId !== bottomPlayerId,
                        isOpponentCard,
                        blotFieldRef.current,
                        deckRef.current
                    );
                }
            }
        }
    }, [resize, ref, blotFieldRef, deckRef]);

    return (
        <div
            ref={ref}
            style={{
                position: 'absolute',
                transform: `scale(0.3) ${isOpponentCard ? 'rotateY(180deg)' : ''}`,
                ...styles,
            }}
            className={`blot-field__user-card-wrapper card-field card-field--${cardId}`}
            id={cardId}
        />
    );
}

const mapStateToProps = ({ globalRefs: { blotFieldRef, deckRef, headerRef }, resize }) => ({
    blotFieldRef,
    deckRef,
    headerRef,
    resize,
});

export default connect(mapStateToProps)(BatCard);
