import { useState, useRef, useCallback } from 'react';
import { useEffect } from 'react';

export function useTimer(initialValue, ms, isReversed) {
    const [count, setCount] = useState(initialValue);
    const intervalRef = useRef(null);

    const start = useCallback(() => {
        if (intervalRef.current !== null) {
            return;
        }

        if (isReversed) {
            intervalRef.current = setInterval(() => {
                setCount((prev) => prev - 1);
            }, ms);
        } else {
            intervalRef.current = setInterval(() => {
                setCount((prev) => prev + 1);
            }, ms);
        }
    }, [isReversed, ms]);

    useEffect(() => {
        if (isReversed) {
            if (count === 0) {
                stop();
            }
        }
    });

    const stop = useCallback(() => {
        if (intervalRef.current === null) {
            return;
        }
        clearInterval(intervalRef.current);
        intervalRef.current = null;
    }, []);

    const reset = useCallback(() => {
        setCount(initialValue);
    }, [initialValue]);

    return [count, start, stop, reset];
}
