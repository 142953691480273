import _ from 'lodash';
import React, { PureComponent } from 'react';
import { Icon, Table } from 'semantic-ui-react'
import { Link } from 'react-router-dom';


const playersTbCols={
    cols: ['Registration Date',  'Last Login Date', 'label', 'Block Status','Rake', 'Win Rate', 'Cash Games Played', 'Tournament Played', 'Amount of Logins'],
    keys: ['created_at', 'lastseen_at', 'label', 'status', 'rake', 'stats_winrate', 'played_cash', 'played_tournaments', 'stats_visits' ],
};
export default class SortablePlayersTable extends PureComponent {
    state = {
        column: null,
        data: this.props.data,
        toggleType: this.props.toggleType,
        direction: 'descending',
    };
    handleSort = (clickedColumn) => () => {
        const { column, direction } = this.state;
        const { sorting } = this.props;
        if (column !== clickedColumn) {
            this.setState({
                column: clickedColumn,
                //	data: _.sortBy(data, [clickedColumn]),
                direction: 'ascending',
            });
        }

        this.setState({
            direction: direction === 'ascending' ? 'descending' : 'ascending',
        });

        sorting(clickedColumn, direction === 'descending' ? 'ASC' : 'DESC');
    };

    render() {
        const { column, direction, toggleType } = this.state;
        const { data, count } = this.props;
        let i = count;
        return (
            <Table sortable celled  size="small">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>No:</Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === (toggleType ==='Ajarabet'?'remoteId':'id')? direction : null}
                            onClick={this.handleSort( (toggleType ==='Ajarabet'?'remoteId':'id'))}
                        >
                           Player ID
                        </Table.HeaderCell>
                        {
                            playersTbCols.cols.map((item,index)=>{
                                return (
                                    <Table.HeaderCell key={index}
                                        sorted={column === playersTbCols.keys[index] ? direction : null}
                                        onClick={this.handleSort(playersTbCols.keys[index])}
                                    >
                                        {item}
                                    </Table.HeaderCell>
                                )
                            })
                        }
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {_.map(
                        data,
                        ({ id, remote_id, created_at, lastseen_at, label, status, rake,stats_winrate,played_cash,played_tournaments, stats_visits}, key) => {
                            return (
                                <Table.Row key={key}>
                                    <Table.Cell>{++i}</Table.Cell>
                                    <Table.Cell>
                                        <Link className="id-hover" to={`/admin/players/${toggleType === 'Ajarabet'?remote_id:id}`}>
                                            {toggleType === 'Ajarabet'?remote_id:id}</Link>
                                    </Table.Cell>
                                    <Table.Cell>
                                        {created_at}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {lastseen_at}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Icon  color={label?label:'grey'} name='star' /></Table.Cell>
                                    <Table.Cell title={status}><div className="ellipsis">{status}</div></Table.Cell>
                                    <Table.Cell>
                                      {Number(rake)}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {stats_winrate + ' %'}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {played_cash}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {played_tournaments}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {Number(stats_visits)}
                                    </Table.Cell>
                                </Table.Row>
                            );
                        }
                    )}
                </Table.Body>
            </Table>
        );
    }
}
