import React from 'react';
import InactiveCards from '../InactiveCards';
import AvatarOpponent from './components/AvatarOpponent';

function Opponent({
    cloudText,
    chosenTrumpSuit,
    playerId,
    visualId,
    avatarId,
    isDotLoaderVisible,
    withoutTimer,
    withoutAvatar,
    withoutNotes,
    isPlayersTurn,
    replayDisconnectTimerText,
}) {
    return (
        <div className="blot-field__user blot-field__user--you">
            <div className="blot-field__user-container">
                {!withoutAvatar && (
                    <AvatarOpponent
                        {...{
                            cloudText,
                            chosenTrumpSuit,
                            playerId,
                            visualId,
                            avatarId,
                            isDotLoaderVisible,
                            withoutTimer,
                            withoutNotes,
                            isPlayersTurn,
                            replayDisconnectTimerText,
                        }}
                    />
                )}
                <InactiveCards />
            </div>
        </div>
    );
}

export default Opponent;
