import React from 'react';
import Autosuggest from 'react-autosuggest';
import { connect } from 'react-redux';

class AutoCompleteInput extends React.Component {
    state = {
        value: '',
        suggestions: [],
    };

    getSuggestions = (value) => {
        const {
            aclAllGroups,
            type,
            aclAllUsers,
            aclResourcesReducer,
            name,
            addGroup,
            addUser,
            addUserToGroup,
            userGroupIds,
            user
        } = this.props;
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        const temp = aclResourcesReducer[name];
        const arr = [];
        if (type === 'groups') {
            if (!addUserToGroup) {
                for (let el of temp.groups.concat(addGroup)) {
                    arr.push(el.id);
                }
            } else {
                for (let el of userGroupIds) {
                    arr.push(el.id);
                }
            }
            if(user.id !== 1 ){
                return inputLength === 0
                    ?[]
                    : aclAllGroups.filter(
                        (lang) => lang.id !== 1 &&
                            lang.title.toLowerCase().slice(0, inputLength) === inputValue &&
                            !arr.includes(lang.id)
                    );
            }
            return inputLength === 0
                ?[]
                : aclAllGroups.filter(
                    (lang) =>
                        lang.title.toLowerCase().slice(0, inputLength) === inputValue &&
                        !arr.includes(lang.id)
                );

        }

        for (let el of temp.users.concat(addUser)) {
            arr.push(el.user_id);
            if (el.id) {
                arr.push(el.id);
            }
        }
        return inputLength === 0
            ? aclAllUsers
            : aclAllUsers.filter(
                  (lang) =>
                      lang.username.toLowerCase().slice(0, inputLength) === inputValue &&
                      !arr.includes(lang.id)
              );
    };

    getSuggestionValue = (suggestion) => {
        const { type, getValue, addToGroup, resId, userId, addUserToGroup } = this.props;
        if (!addUserToGroup) {
            if (type === 'groups') {
                getValue(type, suggestion.id, resId, suggestion);
                return suggestion.title;
            }
            getValue(type, suggestion.id, resId, suggestion);
            return suggestion.username;
        } else {
            addToGroup('connect', userId, suggestion.id);
            return suggestion.title;
        }
    };

    renderSuggestion = (suggestion) => {
        const { type } = this.props;
        if (type === 'groups') {
            return <div>{suggestion.title}</div>;
        }
        return <div>{suggestion.username}</div>;
    };

    onChange = (event, { newValue }) => {
        this.setState({
            value: newValue,
        });
    };

    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: this.getSuggestions(value)
        });
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: [],
        });
    };

    render() {
        const { value, suggestions } = this.state;
        const { type } = this.props;
        // Autosuggest will pass through all these props to the input.
        const inputProps = {
            placeholder: `Type a ${type === 'groups' ? 'GroupName' : 'UserName'}`,
            value,
            onChange: this.onChange,
        };

        // Finally, render it!
        return (
            <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={this.renderSuggestion}
                inputProps={inputProps}
                className="ui input"
            />
        );
    }
}

const mapStateToProps = ({ requestMessage, aclAllGroups, aclAllUsers, aclResourcesReducer,userReducer }) => ({
    requestMessage,
    aclAllGroups,
    aclAllUsers,
    aclResourcesReducer,
    user: userReducer,
});

export default connect(mapStateToProps, null)(AutoCompleteInput);
