import React, { Component } from 'react';
import { Tab } from 'semantic-ui-react';
import SortTable from './SortTable';
import ShowAllRounds from './ShowAllRounds';
import CurrentSpin from '../pages/spin/CurrentSpin';
import Logs from "./LogsTable";




const dataForTabs = {
    regPlayers: {
        title: 'Registered Players',
        cols: ['PlayerID', 'Registration Type'],
        keys: ['player_id', 'player_id_remote', 'registration_type'],
        type: 'regPlayers',
        message: 'No registered players yet',
        classname: 'winners',
        pageName: 'players',
    },
    winners: {
        title: 'Winners',
        cols: ['Rank', 'PlayerID',  'Amount'],
        keys: ['place', 'winner',   'amount'],
        type: 'transactions',
        message: 'No winners yet',
        classname: 'winners',
        pageName: 'players',
    },
};

class SpinTabs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            color: 'blue',

        };
        this.panes = [
            {
                menuItem: 'Info',
                render: () => (
                    <Tab.Pane attached={false}>
                        <CurrentSpin id={props.id}/>
                    </Tab.Pane>
                ),
            },
            {
                menuItem: 'Rounds',
                render: () => (
                    <Tab.Pane attached={false}>
                        <ShowAllRounds tournamentId={props.id}/>
                    </Tab.Pane>
                ),
            },
            {
                menuItem: 'Registered Players',
                render: () => (
                    <Tab.Pane attached={false}>
                        <SortTable params={{ tournamentId: props.id }} data={dataForTabs.regPlayers} sortable={true}/>
                    </Tab.Pane>
                ),
            },
            {
                menuItem: 'Winners',
                render: () => (
                    <Tab.Pane attached={false}>
                        <SortTable  winnersFor ='spin' params={{ tournamentId: props.id }} data={dataForTabs.winners}  sortable={false} />
                    </Tab.Pane>
                ),
            },
            {
                menuItem: 'Logs',
                render: () => (
                    <Tab.Pane attached={false}>
                        <Logs gameId={props.id} logFor={'spin'} db={'spinId'}/>
                    </Tab.Pane>
                ),
            },
        ];

    }

    render() {
        const { color } = this.state;
        return (
            <Tab
                menu={{ color, inverted: true, attached: false, tabular: false }}
                panes={this.panes}
                style={{ marginTop: '3%' }}
            />
        );
    }
}

export default SpinTabs;
