import React, {useEffect, useRef, useState} from 'react';
import {Button, Input, Select} from 'semantic-ui-react';
import {TimeInput} from '@trendmicro/react-datepicker';
import DatePick from './Datapiker/DataPicker';

import '@trendmicro/react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import {arrClass} from "./Datapiker/general";

const gameObj = [
    {key: '11', text: 'CLASSIC', value: 'CLASSIC'},
    {key: '22', text: 'OPEN', value: 'OPEN'},
];



const Filter = ({filterGames, reset, startDate, startTime, timeTo,bazarBlot }) => {
    const [filterObj, setFilterObj] = useState({
        dateFrom: startDate ? startDate : moment().format('DD-MM-YYYY'),
        timeFrom: startTime ? startTime : moment().format('00:00:00'),
        dateTo: moment().format('DD-MM-YYYY'),
        timeTo: timeTo ? timeTo : moment().format('HH:mm:ss'),
        tableId: '',
        playerIdHost: '',
        playerIdGuest: '',
        winnerId: '',
        kind: '',
        amountFrom:'',
        amountTo:''
    });
    const [game, setGame] = useState('');
    const [dateValueFrom, setDateValueFrom] = useState(moment().format('DD-MM-YYYY'));
    const [timeValueFrom, setTimeValueFrom] = useState(moment().format('00:00:00'));
    const [dateValueTo, setDateValueTo] = useState(moment().format('DD-MM-YYYY'));
    const [timeValueTo, setTimeValueTo] = useState(moment().format('HH:mm:ss'));
    const [showPicker, setShowPicker] = useState('');
    const [selectedGame, setSelectedGame] = useState(0);

    let refTo = useRef(null);
    let refFrom = useRef(null);

    useEffect(() => {
        if (startTime && startDate && timeTo) {
            setTimeValueFrom(startTime);
            setDateValueFrom(startDate);
            setTimeValueTo(timeTo);
        }

    }, [startDate, startTime]);



    const handleClick = (e) => {
        if ((showPicker === 'from' || showPicker === 'to') && refFrom.current && refTo.current && !arrClass.includes(e.target.className)) {
            setShowPicker('')
        }

    };

    useEffect(() => {
        document.addEventListener('click', handleClick);
        return () => {
            document.removeEventListener('click', handleClick);
        };
    });
    const getTimeFrom = (value) => {
        setTimeValueFrom(value);
        setFilterObj((obj) => ({
            ...obj,
            timeFrom: value
        }))
    };
    const getTimeTo = (value) => {
        setTimeValueTo(value);
        setFilterObj((obj) => ({
            ...obj,
            timeTo: value
        }))
    };
    const openPicker = (e) => {
        setShowPicker(e);
    };
    const closeFromDatePicker = (obj) => {
        setDateValueFrom(obj);
        setFilterObj((object) => ({
            ...object,
            dateFrom: obj
        }));
        setShowPicker('');
    };
    const closeToDatePicker = (obj) => {
        setDateValueTo(obj);
        setFilterObj((object) => ({
            ...object,
            dateTo: obj
        }));
        setShowPicker('');
    };

    const handleFilter = () => {

        setShowPicker('');
        const obj = {
            dateFrom: filterObj.dateFrom + ' ' + filterObj.timeFrom,
            dateTo: filterObj.dateTo + ' ' + filterObj.timeTo,
            player_id_guest: filterObj.playerIdGuest,
            player_id_host: filterObj.playerIdHost,
            tableId: filterObj.tableId,
            kind: filterObj.kind,
            winnerId: filterObj.winnerId,
            amountFrom:filterObj.amountFrom,
            amountTo:filterObj.amountTo,

        };
         filterGames(obj);
    };
    const resetAll = () => {
        setShowPicker('');
        setGame('');
        setDateValueFrom(moment().format('DD-MM-YYYY'));
        setTimeValueFrom(moment().format('00:00:00'));
        setDateValueTo(moment().format('DD-MM-YYYY'));
        setTimeValueTo(moment().format('HH:mm:ss'));
        setSelectedGame(0);
        setFilterObj((obj) => ({
            ...obj,
            dateFrom: moment().format('DD-MM-YYYY'),
            timeFrom: moment().format('00:00:00'),
            dateTo: moment().format('DD-MM-YYYY'),
            timeTo: moment().format('HH:mm:ss'),
            kind: '',
            tableId: '',
            playerIdHost: '',
            playerIdGuest: '',
            winnerId: '',
            amountFrom:'',
            amountTo:'',
        }));
        filterGames({
            dateFrom: moment().format('DD-MM-YYYY')+ ' ' + moment().format('00:00:00'),
            dateTo: moment().format('DD-MM-YYYY')+ ' ' + moment().format('HH:mm:ss'),
        });
        reset();
    };
    const handleValue = (stateName, e) => {
        const val = e.target.value;
        setFilterObj((obj) => ({
            ...obj,
            [stateName]: +val
        }))

    };
    const getSelectValue = (name, data) => {
         if (data.value) {
             setGame(data.value);
             setSelectedGame(1);
            setFilterObj((object) => ({
                ...object,
                kind: data.value,


            }));
        }
    };

    return (
        <div className="input-box-game">
            <div className="filter-inputs flex-wrap">
                <div className="input-box game">
                    <div className="margin-l">
                        <div className='label'>Created At From:</div>
                        <div className="date-from">
                            <div className="ui left icon input date">
                                <input
                                    ref={refFrom}
                                    type="text"
                                    placeholder="Choose date..."
                                    size={10}
                                    value={dateValueFrom}
                                    onClick={() => openPicker('from')}
                                    onChange={() => openPicker('from')}
                                />
                                <i aria-hidden="true" className="calendar icon"/>
                            </div>
                            {showPicker === 'from' && (
                                <DatePick close={closeFromDatePicker} value={dateValueFrom}/>
                            )}
                            <div className="left">
                                <TimeInput
                                    value={timeValueFrom}
                                    onChange={(value) => getTimeFrom(value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="margin-l-20">
                        <div className='label' >Created At To: </div>
                        <div className="date-to">
                            <div className="ui left icon input date">
                                <input
                                    ref={refTo}
                                    type="text"
                                    placeholder="Choose date..."
                                    size={10}
                                    value={dateValueTo}
                                    onClick={() => openPicker('to')}
                                    onChange={() => openPicker('to')}
                                />
                                <i aria-hidden="true" className="calendar icon"/>
                            </div>
                            {showPicker === 'to' && (
                                <DatePick close={closeToDatePicker} value={dateValueTo}/>
                            )}
                            <div className="left">
                                <TimeInput value={timeValueTo} onChange={(value) => getTimeTo(value)}/>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="input-box game">
                <div className="check-box">
                    <div className="label">Select game:</div>
                    {!selectedGame? <>
                        {game?null:null}
                        <Select
                            selectOnBlur={false}
                            clearable
                            placeholder={'Not selected'}
                            options={gameObj}
                            onChange={(e, data) => getSelectValue('game', data)}
                        /></>:<Select
                        selectOnBlur={false}
                        clearable
                        placeholder={game}
                        options={gameObj}
                        onChange={(e, data) => getSelectValue('game', data)}
                    />
                    }
                </div>
                <div className="check-box">
                    <div className="label">Winner ID:</div>
                    <Input
                        placeholder="Winner ID"
                        value={filterObj.winnerId?filterObj.winnerId:''}
                        onChange={(e) => handleValue('winnerId', e)}
                        type={'number'}
                    />
                </div>

            </div>

            <div className="input-box game">

                <div className="check-box">
                    <div className="label">Table ID:</div>
                    <Input
                        placeholder="Table ID"
                        value={filterObj.tableId?filterObj.tableId:''}
                        onChange={(e) => handleValue('tableId', e)}
                        type={'number'}
                    />
                </div>
                <div className="check-box">
                {!bazarBlot ? (
                        <div className="label">Player ID Host:</div>
                    ) : (
                        <div className="label">Player ID:</div>
                    )}
                    <Input
                        placeholder="Player ID Host"
                        value={filterObj.playerIdHost ? filterObj.playerIdHost : ''}
                        onChange={(e) => handleValue('playerIdHost', e)}
                        type={'number'}
                    />
                </div>
                {!bazarBlot && (
                    <div className="check-box">
                        <div className="label">Player ID Guest:</div>
                        <Input
                            placeholder="Player ID Guest"
                            value={filterObj.playerIdGuest ? filterObj.playerIdGuest : ''}
                            onChange={(e) => handleValue('playerIdGuest', e)}
                            type={'number'}
                        />
                    </div>
                )}
            </div>

            <div className="input-box game">
                <div className="check-box">
                    <div className="label">Win Amount:</div>
                    <div className="item">
                        <Input
                            label="From:"
                            value={filterObj.amountFrom?filterObj.amountFrom:''}
                            onChange={(e) => handleValue('amountFrom', e)}
                            type="number"
                            min={1}
                            size={"mini"}
                        />
                        <Input
                            label="To:"
                            value={filterObj.amountTo?filterObj.amountTo:''}
                            onChange={(e) => handleValue('amountTo', e)}
                            type="number"
                            min={1}
                            size={"mini"}
                        />
                    </div>
                </div>

            </div>



            <div className="filter-div">
                <Button primary className="filter-btn belote-green" onClick={handleFilter}>
                    Filter
                </Button>
                <Button className="filter-btn" onClick={resetAll}>
                    Reset All
                </Button>
            </div>

        </div>
    );
};
export default Filter;
