import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export function useDefineThrowCardsText(throwCards, bottomPlayerId, setter) {
    const { t } = useTranslation();

    useEffect(() => {
        if (!!throwCards) {
            const { playerId: throwPlayerId, isOffer, agree, myCards } = throwCards;
            const isBottomPlayerAction = throwPlayerId === bottomPlayerId;
            let text = '';
            if (throwPlayerId) {
                if (isOffer) {
                    text = 'The rest is mine';
                } else {
                    if (!myCards) {
                        text = agree ? 'I agree' : 'I disagree';
                    }
                    if (myCards) {
                        text = 'The rest is yours';
                    }
                }
                isBottomPlayerAction && setter({ playerText: t(text), opponentText: '' });
                !isBottomPlayerAction && setter({ opponentText: t(text), playerText: '' });
            }
        }
    }, [throwCards]);
}
