import { createReducer } from '../../helpers/general';
import { CMS_TYPES} from '../actionTypes';

const {SET_ALL_BANNERS, SET_ALL_PLACEHOLDERS,EDIT_PLACEHOLDER} = CMS_TYPES;


export const cmsAllBanners = createReducer([], (state, { value }) => {
    return {
        [SET_ALL_BANNERS]: value?value:state,
    };
});



export const allPlaceHolders = createReducer({}, (state, { value }) => {
    return {
        [SET_ALL_PLACEHOLDERS]: value?{...state,...value}:state,
        [EDIT_PLACEHOLDER]: value?value:state,
    };
});

export default {
    cmsAllBanners,
    allPlaceHolders
};
