import { useEffect, useState } from 'react';

export function useTimerData(data, playerId) {
    const [timerData, setTimerData] = useState({});

    useEffect(() => {
        if (!!data) {
            const { stepTimer, continueTimer, playerId: timerPlayerId } = data;
            if (!!timerPlayerId) {
                setTimerData(playerId === timerPlayerId ? { stepTimer, continueTimer } : {});
            }
        }
    }, [data]);

    return [timerData];
}

export default useTimerData;
