import { ACLMANAGMENT_TYPES, USERS_TYPES } from '../actionTypes';
import instance from '../../helpers/Api';


const {
    SET_ACL_USERS,
    SET_ACL_GROUPS,
    SET_ALL_RESOURCES,
    OPEN_EDIT_MODAL,
    SET_ALL_ACL_GROUPS,
    SET_ALL_ACL_USERS,
} = ACLMANAGMENT_TYPES;
const { REQUEST_MESSAGE } = USERS_TYPES;

export function getUsersForAclPage() {
    return (dispatch) => {
        instance.post('/api/acl/getUsers')
            .then((res) => {
                dispatch({ type: SET_ACL_USERS, value: res.data });
            })
            .catch((e) => {
                console.log('getUsersForAclPage', e);
            });
    };
}
export function getGroupsForAclPage() {
    return (dispatch) => {
        instance.post('/api/acl/groupsRes')
            .then((res) => {
                //  console.log(res,"RES-getGroupsForAclPage");
                dispatch({ type: SET_ACL_GROUPS, value: res.data });
            })
            .catch((e) => {
                console.log('getGroupsForAclPage', e);
            });
    };
}

export function getAllResources() {
    return (dispatch) => {
        instance.post('/api/acl/getResGroups')
            .then((res) => {
                // console.log(res,"RES-getAllResources");
                dispatch({ type: SET_ALL_RESOURCES, value: res.data });
            })
            .catch((e) => {
                console.log('getGroupsForAclPage', e);
            });
    };
}
export function getAllGroups() {
    return (dispatch) => {
        instance.post('/api/acl/getAllGroups')
            .then((res) => {
                //console.log(res,"RES-getAllGroups");
                dispatch({ type: SET_ALL_ACL_GROUPS, value: res.data });
            })
            .catch((e) => {
                console.log('getAllGroups', e);
            });
    };
}
export function getAllUsers() {
    return (dispatch) => {
        instance.post('/api/acl/getAllUsers')
            .then((res) => {
                //  console.log(res,"RES-getAllUsers");
                dispatch({ type: SET_ALL_ACL_USERS, value: res.data });
            })
            .catch((e) => {
                console.log('getAllUsers', e);
            });
    };
}
export function saveResourceChanginq(data) {
    return (dispatch) => {
        instance.post('/api/acl/groupUserRes', data)
            .then((res) => {
                //console.log(res,"RES-saveResource");
                dispatch({ type: REQUEST_MESSAGE, value: res.data });
            })
            .catch((e) => {
                console.log('saveResource', e);
            });
    };
}

export function createNewGroup(data) {
    return (dispatch) => {
        instance.post('/api/acl/addGroup', data)
            .then((res) => {
                // console.log( res, "Res-createNewGroup")
                dispatch({ type: REQUEST_MESSAGE, value: res.data });
            })
            .catch((e) => {
                console.log('createNewGroup', e);
            });
    };
}
export function createNewResource(data) {
    return (dispatch) => {
        instance.post('/api/acl/addResource', data)
            .then((res) => {
                // console.log( res, "Res-addResource")
                dispatch({ type: REQUEST_MESSAGE, value: res.data });
            })
            .catch((e) => {
                console.log('addResource', e);
            });
    };
}

export function changeUserGroupConnection(data) {
    return (dispatch) => {
        instance.post('/api/acl/userGroups', data)      
            .then((res) => {
                // console.log( res, "Res-userGroups")
                dispatch({ type: REQUEST_MESSAGE, value: res.data });
            })
            .catch((e) => {
                console.log('userGroups', e);
            });
    };
}

export function editResource(value) {
    return (dispatch) => {
        dispatch({ type: OPEN_EDIT_MODAL, value: value });
    };
}
