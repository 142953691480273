import React from 'react';
import { Table } from 'semantic-ui-react';
import '../../spin.css'


const SpinWinInfoTable = ({data, tableInfo}) => {
    return (
        <Table celled fixed size="small" className={'blacklist black'}>
            <Table.Header>
                <Table.Row>
                    <Table.Cell colSpan={5} className='t-title'>
                        Winnings distribution:
                                        </Table.Cell>
                </Table.Row>
                <Table.Row className={'tr-spin-bg-color'}>
                    {tableInfo.tableHeaderCells.length && tableInfo.tableHeaderCells.map(el => {
                        return <Table.Cell key={el}>
                            {el}
                        </Table.Cell>
                    })}
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {data.length ? data.map((item, key) => {
                    return (
                        <Table.Row key={key}>
                            <Table.Cell>
                                {`X ${item.x}`}
                            </Table.Cell>
                            {
                                item.distribution ? item.distribution.map((el, k) => {
                                    return (
                                        <Table.Cell key={k}>
                                            {el}
                                        </Table.Cell>)
                                })
                                    : null
                            }
                        </Table.Row>
                    )
                }) : null
                }
            </Table.Body>
        </Table>
    )
}

export default SpinWinInfoTable