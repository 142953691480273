import React from 'react';
import { useTranslation } from 'react-i18next';
import { getCurrencySymbol } from '../../../../../../helpers/game';

function InfoPrizeKnock({ prize }) {
    const { t } = useTranslation();

    return (
        <div className="tour-info__info-row">
            <div className="tour-info__info-cell tour-info__info-cell--left">{t('Prize')}</div>
            <div className="tour-info__info-cell tour-info__info-cell--right">
                <span className="dirty-gold">{getCurrencySymbol() + prize}</span>
            </div>
        </div>
    );
}

export default InfoPrizeKnock;
