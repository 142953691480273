import React, {PureComponent} from 'react';
import {Icon, Table} from 'semantic-ui-react';


export default class TemplatesTournamentsTable extends PureComponent {
    state = {
        column: null,
        data: this.props.data,
        direction: 'descending',
        colNames: this.props.cols,
        keys: this.props.keys,
    };

    handleSort = (clickedColumn) => () => {
        const {column, direction} = this.state;
        const {sorting} = this.props;

        if (column !== clickedColumn) {
            this.setState({
                column: clickedColumn,
                //	data: _.sortBy(data, [clickedColumn]),
                direction: 'ascending',
            });
        }

        this.setState({
            direction: direction === 'ascending' ? 'descending' : 'ascending',
        });

        sorting(clickedColumn, direction === 'descending' ? 'ASC' : 'DESC');
    };

    render() {
        const {column, direction} = this.state;
        const {data, count, cols, action} = this.props;
        let i = count;
        return (
            <Table sortable celled fixed size="small" selectable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>No:</Table.HeaderCell>
                        {cols.map((item, index) => {

                                if (item.value !== 'winnersCount' && item.value !== 'name' ) {
                                    return (
                                        <Table.HeaderCell
                                            key={index}
                                            sorted={column === item.value ? direction : null}
                                            onClick={this.handleSort(item.value)}
                                        >
                                            {item.text}
                                        </Table.HeaderCell>)
                                }
                                return (
                                    <Table.HeaderCell
                                        key={index}>
                                        {item.text}
                                    </Table.HeaderCell>


                                );
                            }
                        )}
                        <Table.HeaderCell style={{width: '60px'}} title="Copy Tournament">
                            copy
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{width: '60px'}} title="Add Player">
                            edit
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{width: '70px'}} title="Delete Tournament">
                            delete
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {data.length &&
                    data.map((dataItem, index) => {
                        return (
                            <Table.Row key={index}>
                                <Table.Cell>{++i}</Table.Cell>
                                {cols.map((item, ind) => {
                                    return (
                                        <Table.Cell key={ind}>
                                            {dataItem[item.value]}
                                        </Table.Cell>
                                    );
                                })}
                                <Table.Cell
                                    onClick={() => action('Copy', dataItem['name'])}
                                    className="pointer"
                                    title="Copy Tournament"
                                >
                                    <Icon name="copy" style={{padding: '12px'}}/>
                                </Table.Cell>
                                <Table.Cell
                                    onClick={() => action('Edit', dataItem['name'])}
                                    className="pointer"
                                    title="Edit Tournament"
                                >
                                    <Icon name="edit" style={{padding: '12px'}}/>
                                </Table.Cell>
                                <Table.Cell
                                    onClick={() => action('Delete', dataItem['name'], dataItem['id'])}
                                    className="pointer"
                                    title="Delete Tournament"
                                >
                                    <Icon name="delete" style={{padding: '12px'}}/>
                                </Table.Cell>


                            </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>
        );
    }
}
