import React, { Component } from 'react';
import { Accordion, Icon, Button } from 'semantic-ui-react';
import AutoCompleteInput from './AutoComplete';

export default class AccordionExampleFluid extends Component {
    state = {
        activeIndex: 0,
        currentUserInfo: [],
        addGroup: [],
    };

    handleClick = (e, titleProps) => {
        const { aclManReducer, userNames } = this.props;
        const { index } = titleProps;
        const { activeIndex } = this.state;
        const newIndex = activeIndex === index ? -1 : index;
        const resources = aclManReducer ? aclManReducer[userNames[index]]['resources'] : [];

        this.setState({
            activeIndex: newIndex,
            currentUserInfo: resources,
        });
    };

    addToGroup = (type, userId, goupId) => {
        const { userGroupConnection } = this.props;
        userGroupConnection(type, userId, goupId);
    };
      addResourse = (goupId) => {
        const { openModal } = this.props;
        openModal(goupId)

            };

    render() {
        const { activeIndex, addGroup } = this.state;
        const { data, names, type, userGroupConnection } = this.props;

        return (
            <Accordion fluid styled>
                {names
                    ? names.map((item, key) => {
                          return (
                              <div key={key}>
                                  <Accordion.Title
                                      active={activeIndex === key}
                                      index={key}
                                      onClick={this.handleClick}
                                      key={key}
                                  >
                                      <Icon name="dropdown" />
                                      {item}
                                  </Accordion.Title>
                                  <Accordion.Content active={activeIndex === key}>
                                      {data && data[item]['groups'] ? (
                                          <div className="groups-list">
                                              <div className="groups-title">
                                                  <h5>{type === 'groups' ? 'Users' : 'Groups'}</h5>
                                              </div>
                                              <div className="user-list">
                                                  {data && data[item]['groups']
                                                      ? data[item]['groups'].map((itm, key) => {
                                                            return (
                                                                <div
                                                                    className="res-group-list"
                                                                    key={key}
                                                                >
                                                                    {itm.title}{' '}
                                                                    <Icon
                                                                        name="delete"
                                                                        size="small"
                                                                        className="pointer"
                                                                        onClick={() =>
                                                                            userGroupConnection(
                                                                                'disconnect',
                                                                                data[item].id,
                                                                                itm.id
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            );
                                                        })
                                                      : null}
                                              </div>
                                              <div className="check-box auto-complete">
                                                  <AutoCompleteInput
                                                      type="groups"
                                                      addToGroup={this.addToGroup}
                                                      resId={null}
                                                      name={'users'}
                                                      addGroup={addGroup}
                                                      userGroupIds={data[item].groups}
                                                      userId={data[item].id}
                                                      addUserToGroup={true}
                                                  />
                                              </div>
                                          </div>
                                      ) : null}
                                      <div className="resources-list">
                                          <div className="resources-title">
                                              <h5 style={{width: '86%',float: 'left'}}>
                                                  Resources
                                                {type === 'groups' ?
                                                  <Icon name="add" title="Add Resource"
                                                        className="add-own"
                                                        onClick={()=>this.addResourse(item)}  />:null}

                                              </h5>

                                          </div>
                                          <div className="column">
                                              {data && data[item]['resources'].length
                                                  ? data[item]['resources'].map((item, key) => {
                                                        return (
                                                            <span
                                                                className="resource-name"
                                                                key={key}
                                                            >
                                                                {type === 'groups'
                                                                    ? item
                                                                    : item.path}
                                                            </span>
                                                        );
                                                    })
                                                  : 'No resources yet'}
                                          </div>
                                      </div>
                                      <br/>
                                    {type === 'groups' ?
                                      <div className="resources-list">
                                          <div className="resources-title">
                                              <h5>Users</h5>
                                          </div>
                                          <div className="column">
                                            {data && data[item]['users'].length
                                              ? data[item]['users'].map((item, key) => {
                                                return (
                                                  <span
                                                    className="resource-name"
                                                    key={key}
                                                  > {item}  </span>
                                                );
                                              })
                                              : 'No users yet'}
                                          </div>
                                      </div>:null
                                    }
                                  </Accordion.Content>
                              </div>
                          );
                      })
                    : null}
            </Accordion>
        );
    }
}
