import React from "react";
import './404-style.css';

export const NoMatchPage = () => {
       return (
        <div className="notfound-d">
            <div className="notfound">
                <div className="notfound-404">
                    <h3>Oops! Page not found</h3>
                    <h1><span>4</span><span>0</span><span>4</span></h1>
                </div>
            </div>
        </div>
    );
};