import React from 'react';
import { useTranslation } from 'react-i18next';

function InfoPlayersKnock({ players }) {
    const { minPlayers, maxPlayers, registrated } = players;
    const { t } = useTranslation();
    return (
        <div className="tour-info__info-row">
            <div className="tour-info__info-cell tour-info__info-cell--left">
                <div className="tour-info__info-cell-item tour-info__info-cell-item--top text-bold">{t('Players')}</div>
            </div>
            <div className="tour-info__info-cell tour-info__info-cell--right">
                <span className="text-bold">{registrated}</span>
                <span className="white-04">
                    {' '}
                    | {minPlayers} - {maxPlayers}
                </span>
            </div>
        </div>
    );
}

export default InfoPlayersKnock;
