import {createReducer} from "../../helpers/general";
import {TICKETS_TYPE} from "../actionTypes";

const {SET_TICKETS} = TICKETS_TYPE;

export const allTicketsReducer = createReducer([], (state, { value }) => {
    return {
        [SET_TICKETS]: value?value:state,

    };
});


export default  {
    allTicketsReducer
}
