import React from 'react';
import { setClass } from '../../../../../../helpers/game';

function TrumpIcon({ image, suit, onClick, isActive }) {
    return (
        <li
            className={`my-control__trump-item my-control__trump-item--${suit} ${setClass(
                isActive,
                'my-control__trump-item--active'
            )}`}
            id={suit}
            onClick={onClick}
        >
            <img src={image} alt={`${suit} is trump`} className="my-control__trump-img" />
        </li>
    );
}

export default TrumpIcon;
