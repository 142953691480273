const permissions = {
    home: 'home',
    users: 'users',
    players: 'players',
    games: 'games',
    gamesKIll: 'games_kill',
    aclGroups: 'aclGroups',
    aclResources: 'aclResources',
    aclUsers: 'aclUsers',
    spin: 'spin',
    spinList: 'spinList',
    cms: 'cms',
    cmsUpload: 'cms_uploadBanner',
    settings: 'settings',
    profile: 'profile',
    profileUpdate: 'users_update',
    createUserBtn: 'users_updateOthers',
    editUser: 'users_create',
    showBlackListControlToggles: 'players_addOrDelete',
    winRate: 'playersWinRate',
    cash: 'playersCashGames',
    tournamentPlayers: 'playersTournamentsPlayed',
    tournamentWin: 'playersTournamentsWon',
    buyIn: 'playersBuyin',
    rake: 'playersRake',
    playersGameTablesTab: 'players_getRoundsForPlayer',
    playersTransactionsTab: 'players_playerTransactions',
    playersSessions: 'players_playerSessions',
    tournamentCreateBtn: 'tournaments_create',
    copy: 'tournaments_copy',
    add: 'tournaments_addSinglePlayer',
    addUpload: 'tournaments_uploadPlayers',
    edit: 'tournaments_update',
    deleteTournament: 'tournaments_delete',
    regPlayers: 'tournaments_RegPlayers',
    unregistered: 'tournaments_CutPlayerFromTournament',
    tournamentList: 'tournamentList',
    createTournament: 'createTournament',
    tournamentTemplates: 'tournamentTemplates',
    tournamentPrizeTemplates: 'tournamentPrizeTemplates',
    tickets: 'tickets',
    playerTickets: 'playerTickets',

};
export default permissions
