import {places} from '../reducers/tournamentsReducer';
import {SPIN_TYPES, OTHER_TYPES, MAIN_TYPES, GAME_TYPES} from '../actionTypes';
import {createAction} from "../../helpers/general";
import instance from '../../helpers/Api';
import {sortingByDate} from "./gamesAction";

const {
    SET_ALL_SPINS,
    SET_ONE_SPINS,
    SET_SPIN_PRIZE,
    SET_ALL_SPINS_RULES,
    SET_ONE_SPIN_RULE,
    SET_ONE_SPIN_GAME_TYPE
} = SPIN_TYPES;
const { SET_GAME_LOGS} = GAME_TYPES;


const {GET_TOTAL_PAGES_COUNT_NEW} = OTHER_TYPES;
const {LOADER_HIDE, LOADER_SHOW} = MAIN_TYPES;

export function getAllSpins(data) {
    return (dispatch) => {
        instance.post('/api/spin/get', data)
            .then((res) => {
                dispatch({type: SET_ALL_SPINS, value: res.data.data.spins});
                dispatch({type: GET_TOTAL_PAGES_COUNT_NEW, value: res.data.data});
                dispatch(createAction(LOADER_HIDE));
            })
            .catch((e) => {
                dispatch(createAction(LOADER_SHOW));
                console.log('getspin', e);
            });
    };
}

export function getOneSpin(data) {
    return (dispatch) => {
        instance.post('/api/spin/getOne', data)
            .then((res) => {
                //console.log(res.data.data[0], "getOne-Spin")
                dispatch({type: SET_ONE_SPINS, value: res.data.data[0]});
                dispatch({type: SET_GAME_LOGS, value: sortingByDate(res.data.data[0].logs)});

                //dispatch({type: SET_SPIN_PRIZE, value: onePrizeDataPrepare(res.data.data)});
            })
            .catch((e) => {
                console.log('getOne', e);
            });
    };
}

export function getOneSpinRule(data, buyIn, prizePool) {
    return (dispatch) => {
        instance.post('/api/spin/getOneSpinRule', data)
            .then((res) => {
               //console.log(res.data.data.data[0], "getOneSpinRule");
                dispatch({
                    type: SET_ONE_SPIN_RULE,
                    value: makeDataForSpinePrizePool(res.data.data.confValue, buyIn, prizePool)
                });
                dispatch({
                    type: SET_ONE_SPIN_GAME_TYPE,
                    value: res.data.data.data[0]
                });
                // dispatch({type: SET_SPIN_PRIZE, value: onePrizeDataPrepare(res.data.data)});
            })
            .catch((e) => {
                dispatch(createAction(LOADER_SHOW));
                console.log('getOneSpinRule', e);
            });
    };
}

export function getAllSpinRules(data) {
    return (dispatch) => {
        instance.post('/api/spin/getSpinRules', data)
            .then((res) => {
               // console.log(res.data, "getSpinRules");
                dispatch({type: SET_ALL_SPINS_RULES, value: res.data.data});
                dispatch(createAction(LOADER_HIDE));
                // dispatch({type: SET_SPIN_PRIZE, value: onePrizeDataPrepare(res.data.data)});
            })
            .catch((e) => {
                console.log('getSpinRules', e);
            });
    };
}

function onePrizeDataPrepare(val) {
    const prizeData = [];
    const obj = val[0].prizeDistribution;
    const win = Object.keys(val[0].prizeDistribution);
    // prizeDistribution
    if (val.length && win.length) {
        for (let i in win) {
            let c = places[i] ? places[i].count : 1;

            prizeData.push({
                ...places[i],
                amountPercent: obj[win[i]] / c,
                percentTotal: obj[win[i]],
                amountTotal: (val[0].prizepool * obj[win[i]]) / 100,
                amount: (val[0].prizepool * (obj[win[i]] / c)) / 100,
            });
        }
        return {
            winners: win.length ? Math.pow(2, win.length - 1) : '',
            prizeDistribution: prizeData,
            prizePool: val[0].prizepool
        };
    }
//return {}
    return {
        winners: '',
        prizeDistribution: [],
        prizePool: ''
    };
}

function makeDataForSpinePrizePool(data, buyIn, prizePool) {
    for (const el of data) {
        el['distribution'] = el['distribution'].map((e) => e * buyIn)

    }
    if (prizePool) {
        data[0]['prizePool'] = prizePool;
    }
    return data;
}
