import React from 'react';
import { Table } from 'semantic-ui-react';
import '../../spin.css'


const SpinGameInfoTable = ({ data, tableInfo }) => {
    let gameCombination = data.props_combs
    if (gameCombination === 1) {
        gameCombination = 'All'
    } else if (gameCombination === 2) {
        gameCombination = 'Without 4 cards and 100'
    } else if (gameCombination === 3) {
        gameCombination = 'Belote/Rebelote only'
    }

    return (
        <Table celled fixed size="small" className={'blacklist black'}>
            <Table.Header>
                <Table.Row>
                    <Table.Cell colSpan={tableInfo.tableHeaderCells.length} className='t-title'>
                        {tableInfo.title}
                    </Table.Cell>
                </Table.Row>
                <Table.Row className={'tr-spin-bg-color'}>
                    {tableInfo.tableHeaderCells.length && tableInfo.tableHeaderCells.map(el => {
                        return <Table.Cell key={el}>
                            {el}
                        </Table.Cell>
                    })}
                </Table.Row>
            </Table.Header>
            <Table.Body>
                <Table.Row>
                    <Table.Cell>
                        {data.gameType}
                    </Table.Cell>
                    <Table.Cell>
                        {data.props_points}
                    </Table.Cell>
                    <Table.Cell>
                        {data.props_timeout + 's'}
                    </Table.Cell>
                    <Table.Cell>
                        {data.props_idle_timeout + 's'}
                    </Table.Cell>
                    <Table.Cell>
                        {gameCombination}
                    </Table.Cell>
                    <Table.Cell>
                        {data.props_notrumps === 1 ? 'Yes' : 'No'}
                    </Table.Cell>
                    <Table.Cell>
                        {data.buyin}
                    </Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>
    )
}

export default SpinGameInfoTable