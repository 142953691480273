export const TABLE_HEADER_IDS = {
    bet: 'bet',
    classicPlayer1: 'classicPlayer1',
    disabledScore: 'disabled-score',
    classicPlayer2: 'classicPlayer2',
    disabledStatus: 'disabled-status',
    till: 'till',
    time: 'time',
    disabledType: 'disabled-type',
    maxWin: 'maximumWin',
    spinPlayers: 'spinPlayers',
    tournamentsPlayers: 'tournamentsPlayers',
    name: 'name',
    buyIn: 'buyIn',
    prize: 'prize',
    startDate: 'startDate',
    playerId: 'playerId',
    disabledAllMatches: 'disabled-all-matches',
    disabledWonMatches: 'disabled-won-matches',
    disabledStatistics: 'disabled-statistics',
};

export const GAME_STATUS_IDS = {
    REGISTRATION: 'registration',
    STARTED: 'started',
    JOINED: 'joined',
    WAITING: 'waiting',
    FINISHED: 'finished',
    BUSY: 'busy',
    ANNOUNCED: 'announced',
};

export const NOTES_COLORS_IDS = {
    NONE: '1',
    GREEN: '2',
    RED: '3',
    YELLOW: '4',
    BLUE: '5',
};
export const COMBINATIONS_IDS = {
    TERZ: 'terz',
    FIFTY: 'fifty',
    HUNDRED: 'hundred',
    FOR_CARDS: 'four_cards',
    BELOTE: 'belote',
    REBELOTE: 'rebelote',
};

export const CARD_VALUE_IDS = {
    SEVEN: '7',
    EIGHT: '8',
    NINE: '9',
    TEN: '10',
    JACK: 'jack',
    QUEEN: 'queen',
    KING: 'king',
    ACE: 'ace',
};
export const SETTINGS_CARD_APPEARANCE_IDS = {
    CARD_GREEN: '1',
    CARD_BROWN: '2',
    CARD_PINK: '3',
};
export const SETTINGS_FIELD_APPEARANCE_IDS = {
    FIELD_BLUE: '1',
    FIELD_GREEN: '2',
    FIELD_BROWN: '3',
};

export const SETTINGS_AVATAR_IDS = {
    AVATAR_1: '1',
    AVATAR_2: '2',
    AVATAR_3: '3',
    AVATAR_4: '4',
    AVATAR_5: '5',
    AVATAR_6: '6',
    AVATAR_7: '7',
    AVATAR_8: '8',
    AVATAR_9: '9',
    AVATAR_10: '10',
    AVATAR_11: '11',
    AVATAR_12: '12',
    AVATAR_13: '13',
    AVATAR_14: '14',
    AVATAR_16: '16',
    AVATAR_17: '17',
    AVATAR_18: '18',
    AVATAR_19: '19',
    AVATAR_20: '20',
};

export const SETTINGS_LANGUAGE_IDS = {
    ENG: 'en',
    RUS: 'ru',
    ARM: 'HY',
};

export const CARD_SUIT_IDS = {
    SPADE: 'spade',
    CLUB: 'club',
    DIAMOND: 'diamond',
    HEART: 'heart',
    NO_TRUMP: 'no_trump',
};

export const DECK_CARDS_IDS = {
    spadeAce: 'spade-ace',
    clubAce: 'club-ace',
    heartAce: 'heart-ace',
    diamondAce: 'diamond-ace',
    spadeKing: 'spade-king',
    clubKing: 'club-king',
    heartKing: 'heart-king',
    diamondKing: 'diamond-king',
    spadeQueen: 'spade-queen',
    clubQueen: 'club-queen',
    heartQueen: 'heart-queen',
    diamondQueen: 'diamond-queen',
    spadeJack: 'spade-jack',
    clubJack: 'club-jack',
    heartJack: 'heart-jack',
    diamondJack: 'diamond-jack',
    spade10: 'spade-10',
    club10: 'club-10',
    heart10: 'heart-10',
    diamond10: 'diamond-10',
    spade9: 'spade-9',
    club9: 'club-9',
    heart9: 'heart-9',
    diamond9: 'diamond-9',
};

export const CARDS_IDS = {
    heart7: 'heart-7 ',
    heart8: 'heart-8 ',
    heart9: 'heart-9 ',
    heart10: 'heart-10 ',
    heartJack: 'heart-jack ',
    heartQueen: 'heart-queen ',
    heartKing: 'heart-king ',
    diamond7: 'diamond-7 ',
    diamond8: 'diamond-8 ',
    diamond9: 'diamond-9 ',
    diamond10: 'diamond-10 ',
    diamondJack: 'diamond-jack ',
    diamondQueen: 'diamond-queen ',
    diamondKing: 'diamond-king ',
    club7: 'club-7 ',
    club8: 'club-8 ',
    club9: 'club-9 ',
    club10: 'club-10 ',
    clubJack: 'club-jack ',
    clubQueen: 'club-queen ',
    clubKing: 'club-king ',
    spade7: 'spade-7 ',
    spade8: 'spade-8 ',
    spade9: 'spade-9 ',
    spade10: 'spade-10 ',
    spadeJack: 'spade-jack ',
    spadeQueen: 'spade-queen ',
    spadeKing: 'spade-king ',
};
