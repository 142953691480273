import React, {PureComponent} from 'react';
import {Icon, Table} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import store from '../redux/store';
import p from '../permissionsConstants';

const {copy, add, addUpload, edit, deleteTournament} = p;

export default class SortableTournamentsTable extends PureComponent {
    state = {
        column: null,
        data: this.props.data,
        direction: 'descending',
        colNames: this.props.cols,
        keys: this.props.keys,
        permission: store.getState().userReducer['permissions']
    };

    handleSort = (clickedColumn) => () => {
        const {column, direction} = this.state;
        const {sorting} = this.props;

        if (column !== clickedColumn) {
            this.setState({
                column: clickedColumn,
                direction: 'ascending',
            });
        }

        this.setState({
            direction: direction === 'ascending' ? 'descending' : 'ascending',
        });

        sorting(clickedColumn, direction === 'descending' ? 'ASC' : 'DESC');
    };


    render() {
        const {column, direction, permission} = this.state;
        const {data, count, cols, action} = this.props;
        const stateArr = ['Created', 'Scheduled', 'Announced', 'Busy', undefined];
        let i = count;
        return (
            <Table sortable celled size="small" className={'tournament-table'} selectable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>No:</Table.HeaderCell>
                        {cols.map((item, index) => {
                            if(item.value === 'players' ){
                                return (
                                    <Table.HeaderCell
                                        key={index}
                                          >
                                        {item.text}
                                    </Table.HeaderCell>
                                );
                            }
                            return (
                                <Table.HeaderCell
                                    key={index}
                                    sorted={ column === item.value ? direction : null}
                                    onClick={this.handleSort(item.value)}
                                >
                                    {item.text}
                                </Table.HeaderCell>
                            );

                        })}
                        {permission && permission[copy] ?
                            <Table.HeaderCell style={{width: '60px'}} title="Copy Tournament">
                                {' '}
                                copy{' '}
                            </Table.HeaderCell> : null}
                        {permission && (permission[add] || permission[addUpload]) ?
                            <Table.HeaderCell style={{width: '60px'}} title="Add Player">
                                {' '}
                                add{' '}
                            </Table.HeaderCell> : null}
                        {permission && permission[edit] ?
                            <Table.HeaderCell style={{width: '50px'}} title="Edit Tournament">
                                {' '}
                                edit{' '}
                            </Table.HeaderCell> : null}
                        {permission && permission[deleteTournament] ?
                            <Table.HeaderCell style={{width: '70px'}} title="Delete Tournament">
                                {' '}
                                delete{' '}
                            </Table.HeaderCell> : null}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {data.length &&
                    data.map((dataItem, index) => {
                        return (
                            <Table.Row key={index}>
                                <Table.Cell>{++i}</Table.Cell>
                                {cols.map((item, ind) => {
                                    if (item.value === 'players') {
                                        return (
                                            <Table.Cell key={ind}>
                                                <Link
                                                    to={`/admin/open/tournament/${
                                                        dataItem['id']
                                                    }`}
                                                >
                                                    {dataItem['players_count'] +
                                                    ' (' +
                                                    dataItem['minPlayers'] +
                                                    ' / ' +
                                                    dataItem['maxPlayers'] +
                                                    ')'}
                                                </Link>
                                            </Table.Cell>
                                        );
                                    } else {

                                        return (
                                            <Table.Cell key={ind}>
                                                <Link
                                                    to={`/admin/open/tournament/${
                                                        dataItem['id']
                                                    }`}
                                                >
                                                    {dataItem[item.value]}
                                                </Link>
                                            </Table.Cell>
                                        );

                                    }
                                })}
                                {permission && permission[copy] ?
                                    <Table.Cell
                                        onClick={() => action('Copy', dataItem['id'])}
                                        className="pointer"
                                        title="Copy Tournament"
                                    >
                                        <Icon name="copy" style={{padding: '12px'}}/>
                                    </Table.Cell> : null}
                                {permission && (permission[add] || permission[addUpload]) ?
                                    <Table.Cell
                                        onClick={
                                            stateArr.includes(dataItem['state']) || dataItem['state'] === 'InReg'
                                                ? () => action('Add', dataItem['id']) : null}
                                        className={
                                            stateArr.includes(dataItem['state']) || dataItem['state'] === 'InReg' ? 'pointer' : null
                                        }
                                        title={
                                            stateArr.includes(dataItem['state']) || dataItem['state'] === 'InReg' ? "Add Player" : null
                                        }
                                    >
                                        {(stateArr.includes(dataItem['state']) || dataItem['state'] === 'InReg') && (
                                            <Icon name="add" style={{padding: '12px'}}/>
                                        )}

                                    </Table.Cell> : null}
                                {permission && permission[edit] ?
                                    <Table.Cell
                                        onClick={
                                            stateArr.includes(dataItem['state'])
                                                ? () => action('Edit', dataItem['id'])
                                                : null
                                        }
                                        className={
                                            stateArr.includes(dataItem['state']) ? 'pointer' : null
                                        }
                                        title={
                                            stateArr.includes(dataItem['state']) ? "Edit Tournament" : null
                                        }
                                    >
                                        {stateArr.includes(dataItem['state'])  && (
                                            <Icon name="edit" style={{padding: '12px'}}/>
                                            )}
                                    </Table.Cell> : null}
                                {permission && permission[deleteTournament] ?
                                    <Table.Cell
                                        onClick={
                                            stateArr.includes(dataItem['state']) || dataItem['state'] === 'InReg'
                                                ? () => action('Delete', dataItem['id'])
                                                : null
                                        }
                                        className={
                                            stateArr.includes(dataItem['state']) || dataItem['state'] === 'InReg'? 'pointer' : null
                                        }
                                        title={
                                            stateArr.includes(dataItem['state']) || dataItem['state'] === 'InReg' ? "Delete Tournament" : null
                                        }

                                    >
                                        {(stateArr.includes(dataItem['state'])  || dataItem['state'] === 'InReg') && (
                                            <Icon name="delete" style={{padding: '12px'}}/>
                                        )}
                                    </Table.Cell> : null}
                            </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>
        );
    }
}
