import React, {Component} from 'react';
import {Tab} from 'semantic-ui-react';
import InfoTable from './InfoTable';
import SortTable from './SortTable';
import ShowAllRounds from "./ShowAllRounds";
import store from '../redux/store';
import p from "../permissionsConstants";
import Logs from "./LogsTable";

const {regPlayers} = p;


const dataForTabs = {
    regPlayers: {
        title: 'Registered Players',
        cols: ['PlayerID', 'Registration Type'],
        keys: ['player_id', 'player_id_remote', 'registration_type'],
        type: 'regPlayers',
        message: 'No registered players yet',
        classname: 'winners',
        pageName: 'players'
    },
    winners: {
        title: 'Winners',
        cols: ['Rank', 'PlayerID', 'Amount'],
        keys: ['place', 'winner', 'amount'],
        type: 'transactions',
        message: 'No winners yet',
        classname: 'winners',
        pageName: 'players'
    },
};

class TournamentsTabs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            color: 'blue',
            permission: store.getState().userReducer['permissions']
        };
        this.panes = [
            {
                menuItem: 'Info',
                render: () => (
                    <Tab.Pane attached={false}>
                        <InfoTable  id={props.id} />
                    </Tab.Pane>
                ),
            },
            {
                menuItem: 'Rounds',
                render: () => (
                    <Tab.Pane attached={false}>
                        <ShowAllRounds tournamentId={props.id}/>
                    </Tab.Pane>
                ),
            },
            {
                menuItem: 'Registered Players',
                render: () => (
                    <Tab.Pane attached={false}>
                        <SortTable params={{tournamentId: props.id}} data={dataForTabs.regPlayers} sortable={true}/>
                    </Tab.Pane>
                ),
            },
            {
                menuItem: 'Winners',
                render: () => (
                    <Tab.Pane attached={false}>
                        <SortTable  winnersFor ='tournament' params={{tournamentId: props.id}} data={dataForTabs.winners} sortable={false}/>
                    </Tab.Pane>
                ),
            },
            {
                menuItem: 'Logs',
                render: () => (
                    <Tab.Pane attached={false}  >
                        <Logs gameId={props.id}  logFor={'tournament'} db={'tournamentId'}/>
                    </Tab.Pane>
                ),
            },
        ];
        this.prepareData()
    }

    getIndex = (arr, name) => {
        let i = null;
        for (const [index, el] of arr.entries()) {
            if (el.menuItem === name) {
                i = index;
                break;
            }
        }
        return i
    };
    prepareData = () => {
        const {permission} = this.state;
        if (!permission[regPlayers]) {
            const index = this.getIndex(this.panes, 'Registered Players')
            this.panes.splice(index, 1)
        }
    };


    render() {
        const {color} = this.state;
        return (
            <Tab
                menu={{color, inverted: true, attached: false, tabular: false}}
                panes={this.panes}
                style={{marginTop: '3%'}}
            />
        );
    }
}

export default TournamentsTabs;
