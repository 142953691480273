export const TRADE_TYPES = {
    CHOOSE_CANDIDATE_TRUMP: 'choose_candidate_trump',
    CHOOSE_TRUMP_WITH_CARD: 'choose_trump_with_card',
    CHOOSE_TRUMP_WITHOUT_CARD: 'choose_trump_without_card',
    PASS_CANDIDATE_TRUMP: 'pass_candidate_card',
    PASS_TRUMP: 'pass_trump',
};

export const MOVE_TYPES = {
    DECLARE_COMBINATION: 'declare_combination',
    SHOW_COMBINATION: 'show_combination',
    MOVE: 'move',
    THROW_CARDS: 'throw_cards',
    OPEN_CARDS: 'open_cards',
};
