import HandCard from '../reusables/HandCard';
import { CARD_INTERACT_CLASSES } from '../../../constants/gameplay';
import React, { useState, useEffect } from 'react';
import { useSetInitialHandCardIds } from '../../../shared/hooks/gameplay/cards/useSetInitialHandCardIds';
import { cardToCardId } from '../../../helpers/helpers/gameplay';
import TableCardHR from './TableCardHR';
import BatCard from '../reusables/BatCard';
import { logOut } from '../../../redux/actions/usersActions';

let timeoutId = 0;

export default function PlayerCardsHR({
    cardsData,
    shownCombination,
    bottomPlayerId,
    currentHand,
    lastHandCardData,
    stepIndex,
    isEventData,
}) {
    const [cards, setCards] = useState([]);
    const [tableCard, setTableCard] = useState({});
    const [batCardData, setBatCardData] = useState({});


    useEffect(()=>{
        try{
            setCards(cardsData.map((data) => cardToCardId(data)));
        }
        catch(e){
            console.log(e, 'cardsData' );
        }


    }, [cardsData]);


    useEffect(() => {
        if (!currentHand.playerId && !!lastHandCardData) {
            if (!isEventData) {
                setTableCard({ cardId: lastHandCardData[0].lastHandCardId, zIndex: lastHandCardData[0].zIndex });
            }
            timeoutId = setTimeout(() => {
                setTableCard({});
                if (!!lastHandCardData) {
                    const { withAnim } = lastHandCardData[0];
                    if (!withAnim) {
                        setBatCardData(lastHandCardData[0]);
                    }
                } else {
                    setBatCardData({});
                }
            }, 1000);
        }

        return () => clearTimeout(timeoutId);
    }, [currentHand, lastHandCardData]);

    useEffect(() => {
        setTableCard({});
        if (!!lastHandCardData) {
            const { withAnim } = lastHandCardData[0];
            if (!withAnim) {
                setBatCardData(lastHandCardData[0]);
            }
        } else {
            setBatCardData({});
        }
        clearTimeout(timeoutId);
    }, [stepIndex]);

    useEffect(() => {
        const { card, playerId } = currentHand;
        if (!!playerId) {
            if (playerId === bottomPlayerId) {
                setTableCard({ cardId: cardToCardId(card) });
            }
        } else {
            setTableCard({});
        }
    }, [currentHand]);

    return (
        <div className="player-cards-container">
            {cards.map((cardId, i) => {
                return (
                    <HandCard
                        key={cardId || i}
                        cardId={cardId}
                        className={CARD_INTERACT_CLASSES.PLAYER_HAND_CARD}
                        styles={{ top: 'calc(100% + 500px)' }}
                        isCardActive={shownCombination.cards && shownCombination.cards.includes(cardId)}
                        bottomPlayerId={bottomPlayerId}
                        isOpponentCard={false}
                        cardsLength={cards.length}
                        index={i}
                        isReplay={true}
                    />
                );
            })}
            {!!tableCard.cardId && (
                <TableCardHR
                    cardId={tableCard.cardId}
                    isOpponentCard={false}
                    styles={{ top: 'calc(100% + 500px)', zIndex: tableCard.zIndex }}
                />
            )}
            {!!batCardData.lastHandCardId && (
                <BatCard
                    cardId={batCardData.lastHandCardId}
                    isOpponentCard={false}
                    bottomPlayerId={bottomPlayerId}
                    lastHandCardData={batCardData}
                    styles={{ top: 'calc(100% + 500px)', zIndex: batCardData.zIndex }}
                />
            )}
        </div>
    );
}
