import React, { useEffect} from 'react';
import { openTournament } from '../../../redux/actions/tournamentsAction';
import { connect } from 'react-redux';
import TournamentTabs from '../../../components/TournamentsTabs';
import './showTournamentTabs.css';


function OneTournamentDetails({ match, ...redux }) {
    const { openTournament } = redux;
    useEffect(() => {
        openTournament({ id: +match.params.id });
    }, [match.params.id]);
    return (
        <div className="tournament-content managment-content">
            <h2>Tournament Details</h2>
            <TournamentTabs
                id={+match.params.id}
            />
        </div>
    );
}

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = {
    openTournament,
};

export default connect(mapStateToProps, mapDispatchToProps)(OneTournamentDetails);
