import React, { Component } from 'react';
import { Select, Input, Checkbox, Button, Modal } from 'semantic-ui-react';
import { createNewUser } from '../redux/actions/usersActions';
import SingleSelect from './SelectAutoComplete';
import { connect } from 'react-redux';

class ModalExampleDimmer extends Component {
    state = {
        username: '',
        password: '',
        isActive: false,
        confirmPassword: null,
        language: 'en',
        open: true,
        dimmer: 'blurring',
        groupId:null,
        addGroup:[],
        languageOptions: [
            { key: 'en', text: 'English', value: 'en' },
            { key: 'ru', text: 'Russian', value: 'ru' },
        ],
        lang: 'Select Language',
        error: { errName: '', errPass: '' },
    };


    createUser = () => {
        const { username, password, isActive, language, error, confirmPassword, groupId } = this.state;
        let errName = '',
            errPass = '';
        if (username.length <= 7 || !username.length) {
            errName = 'Username must be at least 8 characters';
        }
        if (password.length <= 7 || !password.length) {
            errPass = 'Password must be at least 8 characters';
        }
          if (password !== confirmPassword) {
            errPass = 'Password and confirm password not the same';
          }
        if (errName.length || errPass.length) {
            this.setState({
                error: { ...error, errName, errPass },
            });
        } else {
            let data = { username, password, confirmPassword, isActive: isActive ? '1' : '0', language ,groupId};
            this.props.createNewUser(data);
        }
    };
      addToGroup = (type, userId, groupId) => {
         // console.log({type, userId, groupId},"type, userId, groupId")
        this.setState({
          groupId,
        })
      };
    getUserName = (e) => {
        this.setState({
            error: { ...this.state.error, errName: '' },
            username: e.target.value,
        });
    };
      getValue = (e) => {
        this.setState({
          error: { ...this.state.error, errName: '', errPass: '' },
          [e.target.name]: e.target.value,
        });
      };

    getStatus = () => {
        this.setState({
            isActive: !this.state.isActive,
        });
    };
    getLang = (e, data) => {
        this.setState({
            language: data.value,
        });
    };

    render() {
        const { open, dimmer, languageOptions, error, confirmPassword, addGroup } = this.state;
        const { close, title, requestMessage } = this.props;

        return (
            <div>
                <Modal dimmer={dimmer} open={open} onClose={() => close(false)}>
                    <Modal.Header>{title}
                        <div className="check-box auto-complete">
                            <SingleSelect
                              type="groups"
                              addToGroup={this.addToGroup}
                              resId={null}
                              name={'users'}
                              addGroup={addGroup}
                              userGroupIds={[]}
                              userId={null}
                              addUserToGroup={true}
                            />
                        </div>

                    </Modal.Header>
                    <Modal.Content>
                        <div className="input-box">
                            <div>
                                <div className="label">Username:</div>
                                <Input placeholder="Username" onChange={this.getUserName} />
                                <span className="error-mes">{error.errName}</span>
                            </div>
                            <div>
                                <div className="label">Password:</div>
                                <Input
                                    placeholder="Password"
                                    name={'password'}
                                    onChange={(e) => this.getValue(e)}
                                    type="password"
                                />
                            </div>
                            <div>
                                <div className="label">Confirm password:</div>
                                <Input
                                  value={confirmPassword !== null ? confirmPassword : ''}
                                  placeholder="Confirm password"
                                  name={'confirmPassword'}
                                  onChange={(e) => this.getValue(e)}
                                  type="password"
                                />
                                <span className="error-mes">{error.errPass}</span>
                            </div>
                        </div>
                        <div className="check-box">
                            <div> Is-Active</div>
                            <Checkbox toggle onChange={this.getStatus} />
                        </div>
                        <div className="check-box">
                            <Select
                                selectOnBlur={false}
                                placeholder="Select your language"
                                options={languageOptions}
                                onChange={this.getLang}
                            />
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <div className={requestMessage.error ? 'req-mess err' : 'req-mess'}>
                            {' '}
                            {requestMessage.message}{' '}
                        </div>
                        <Button color="black" onClick={() => close(false)}>
                            Cancel
                        </Button>
                        <Button
                            positive
                            icon="checkmark"
                            labelPosition="right"
                            content="Create User"
                            onClick={this.createUser}
                        />
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = ({ requestMessage }) => ({
    requestMessage,
});

const mapDispatchToProps = {
    createNewUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalExampleDimmer);
