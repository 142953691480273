import React from 'react';
import GameTypeList from './components/GameTypeList';
import { getCurrencySymbol } from '../../../../../helpers/game';
import RowMatchScore from './components/RowMatchScore';
import RowMoveScore from './components/RowMoveScore';
import { useTranslation } from 'react-i18next';

function ScoreBoard(props) {
    const {
        roomId,
        bet,
        leftTotalScore,
        rightTotalScore,
        gameSettings,
        moveScoreData,
        powerText = '',
        matchScoreRows = [],
        tillText,
        tillValue,
        typeListTimeAndTill,
    } = props;

    const { t } = useTranslation();

    return (
        <div className="blot-field__score score">
            <div className="score__row score__row--first">
                <div className="score-text">{t('Me')}</div>
                <div className="score-vs">
                    {leftTotalScore}
                    <span className="score-vs__dotted">:</span>
                    {rightTotalScore}
                </div>
                <div className="score-text">{t('You')}</div>
            </div>
            <div className="score__line-main">
                <div className="score__line-decoration-score">
                    {moveScoreData && <RowMoveScore score={moveScoreData} />}
                    {matchScoreRows.map(({ playerScore, opponentScore }, i) => (
                        <RowMatchScore leftScore={playerScore} rightScore={opponentScore} key={i} />
                    ))}
                </div>
            </div>
            <div className="score__rows">
                <div className="score__row">
                    <div className="score__key">ID</div>
                    <div className="score__value">{roomId}</div>
                </div>
                <div className="score__row">
                    <div className="score__key">{t(tillText)}</div>
                    <div className="score__value">{tillValue}</div>
                </div>
                <div className="score__row score__row--bet" style={{ visibility: !!bet ? 'visible' : 'hidden' }}>
                    <div className="score__key">{t('Bet')}</div>
                    <div className="score__value">{`${powerText} ${getCurrencySymbol() + bet}`}</div>
                </div>
                <GameTypeList settings={gameSettings} timeAndTill={typeListTimeAndTill} />
            </div>
        </div>
    );
}

export default ScoreBoard;
