import _ from 'lodash';
import React, {PureComponent} from 'react';
import {Icon, Table} from 'semantic-ui-react';
import store from '../redux/store';
import p from '../permissionsConstants';

const {editUser} = p;

export default class SortableUsersTable extends PureComponent {
    state = {
        column: null,
        data: this.props.data,
        direction: 'descending',
        permissions: store.getState().userReducer['permissions'],
    };

    handleSort = (clickedColumn) => () => {
        const {column, direction} = this.state;
        const {sorting} = this.props;

        if (column !== clickedColumn) {
            this.setState({
                column: clickedColumn,
                //	data: _.sortBy(data, [clickedColumn]),
                direction: 'ascending',
            });
        }

        this.setState({
            //data: data.reverse(),
            direction: direction === 'ascending' ? 'descending' : 'ascending',
        });

        sorting(clickedColumn, direction === 'descending' ? 'ASC' : 'DESC');
    };

    render() {
        const {column, direction, permissions} = this.state;
        const {data, count, openEditModal, deleteCurrentUser} = this.props;
        let i = count;
        return (
            <Table sortable celled fixed size="small">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>No:</Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === 'id' ? direction : null}
                            onClick={this.handleSort('id')}
                        >
                            ID:
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === 'username' ? direction : null}
                            onClick={this.handleSort('username')}
                        >
                            User Name:
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === 'is_active' ? direction : null}
                            onClick={this.handleSort('is_active')}
                        >
                            Is Active:
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === 'created_at' ? direction : null}
                            onClick={this.handleSort('created_at')}
                        >
                            Created At:
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === 'lastLoginDate' ? direction : null}
                            onClick={this.handleSort('lastLoginDate')}
                        >
                            Last Login Date:
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === 'lastLoginIp' ? direction : null}
                            onClick={this.handleSort('lastLoginIp')}
                        >
                            Last Login IP:
                        </Table.HeaderCell>

                        <Table.HeaderCell
                            sorted={column === 'locale' ? direction : null}
                            onClick={this.handleSort('locale')}
                        >
                            Locale:
                        </Table.HeaderCell>
                        {permissions && permissions[editUser] ?
                            <Table.HeaderCell>Edit</Table.HeaderCell> : null}
                        <Table.HeaderCell>Delete</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {_.map(data, ({id, username, is_active, created_at, locale, lastseen_at, last_ip}) => (
                        <Table.Row key={id}>
                            <Table.Cell>{++i}</Table.Cell>
                            <Table.Cell>{id}</Table.Cell>
                            <Table.Cell>{username}</Table.Cell>
                            <Table.Cell>{is_active}</Table.Cell>
                            <Table.Cell>
                                {created_at}
                            </Table.Cell>
                            <Table.Cell>{lastseen_at}</Table.Cell>
                            <Table.Cell>{last_ip}</Table.Cell>
                            <Table.Cell>{locale}</Table.Cell>
                            {permissions && permissions[editUser] ?
                                <Table.Cell onClick={() => openEditModal(id)} className="pointer">
                                    <Icon name="edit" style={{padding: '12px'}}/>
                                    edit
                                </Table.Cell> : null}
                            <Table.Cell onClick={() => deleteCurrentUser(id)} className="pointer color-red">
                                <Icon name="delete" style={{padding: '12px'}}/>
                                delete
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        );
    }
}
