export function forMap(iterationCount, cb) {
    forMap.break = '#34#null#break#hdk89';

    const arr = [];
    if (typeof iterationCount !== 'number' || isNaN(iterationCount)) {
        throw new TypeError('first arg should be a number');
    }
    if (typeof cb !== 'function') {
        throw new TypeError(cb + ' is not a function');
    }

    for (let index = 0; index < iterationCount; index++) {
        const val = cb(index, iterationCount);
        if (val === forMap.break) {
            break;
        }
        arr.push(val);
    }
    return arr;
}

export function addKey(object, key) {
    object[key] = null;
    return object;
}

export function canConvertToNumber(value) {
    return typeof Number(value) === 'number' && !isNaN(value);
}

export function isBetweenRange(x, min, max) {
    return x >= min && x <= max;
}

/**
 * @param {number | string} number
 * @return {string}
 * */
export function numWithCommas(number = 0) {
    let parts = number.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join('.');
}

export function getDateFromIso(iso) {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
    const parsed = new Date(iso);
    const minutes = parsed.getMinutes();
    const hours = parsed.getHours();
    const seconds = parsed.getSeconds();
    const year = parsed.getFullYear();

    let monthNum = parsed.getMonth();
    monthNum = monthNum < 10 ? `0${monthNum}` : monthNum;
    let time = '';
    time += (hours < 10 ? '0' : '') + hours + ':';
    time += (minutes < 10 ? '0' : '') + minutes;

    let day = parsed.getDate();
    day = day < 10 ? `0${day}` : day;

    const monthText = months[parsed.getMonth()];

    return { time, day, year, monthNum, monthText, seconds };
}

export function capitalize(word) {
    if (typeof word !== 'string') return '';
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}

export function isDevelopmentStage() {
    return process.env.NODE_ENV === 'development';
}
