import React, {Component} from 'react';
import {Select, Input, Button, Checkbox, Radio} from 'semantic-ui-react';
import {
    createNewTournament,
    createTournamentTemplate,
    updateTournament,
    setDateAndTime,
    getPrizeRules,
    getPrizeRuleName,
    setSelectedTournamentData,
    setPrizeDataFromTournamentTemplate,
    getCurrentTournamentByName,
    resetDateAndTime,
    getAllTournamentsTemplates,
} from '../../../redux/actions/tournamentsAction';
import {leftDropDownMenuControl, resetRequestMessage} from '../../../redux/actions/otherActions';
import {connect} from 'react-redux';
import '@trendmicro/react-datepicker/dist/react-datepicker.css';
import '../createTournament/createTournament.css';
import DateController from '../createTournament/DateController';
import Prize from '../createTournament/prize';
import store from '../../../redux/store';
import StartDateController from '../createTournament/startDateController';
import ModalForMessages from '../../../components/ModalForMessages';
import {reset} from '../createTournament/reset';
import p from "../../../permissionsConstants";
import moment from "moment";

const {copy, edit} = p;


class EditOrCopyTournament extends Component {
    constructor(props) {
        super(props);
        this.state = {
            highlighted: false,
            showInput: false,
            trumps: false,
            buyIn: '',
            combination: '',
            tournamentName: '',
            minPlayers: '',
            maxPlayers: '',
            priorValue: '',
            open: true,
            gameTypeState: '',

            pointsState: '',
            timeState: '',
            dimmer: 'blurring',
            prizeTemplateId: null,
            prize: {},
            gameType: [
                {key: '11', text: 'CLASSIC', value: 'CLASSIC'},
                {key: '22', text: 'OPEN', value: 'OPEN'},
            ],

            points: [
                {key: '1', text: '51', value: '51'},
                {key: '2', text: '101', value: '101'},
                // {key: '3', text: '1 Round', value: '1Round'},
                // {key: '4', text: '2 Round', value: '2Round'},
                // {key: '5', text: '3 Round', value: '3Round'},
                // {key: '6', text: '4 Round', value: '4Round'},
                // {key: '7', text: '5 Round', value: '5Round'},
                // {key: '8', text: '6 Round', value: '6Round'},
                // {key: '9', text: '7 Round', value: '7Round'},
            ],
            time: [
                {key: '21', text: '20 s', value: '20'},
                {key: '22', text: '30 s', value: '30'},
                {key: '23', text: '40 s', value: '40'},
                {key: '24', text: '50 s', value: '50'},
            ],
            disTime: [
                {key: '1', text: '30 s', value: '30'},
                {key: '2', text: '1 m', value: '60'},
                {key: '3', text: '2 m', value: '120'},
                {key: '4', text: '3 m', value: '180'},
            ],
            templateId: '',
            templateName: '',
            disconnectTimer: '',
            error: {errName: '', errPass: ''},
        };
    }

    componentDidMount() {
        this.props.leftDropDownMenuControl(true);
        this.props.getPrizeRules();
        this.props.getAllTournamentsTemplates();
    }

    componentDidUpdate() {
        const {requestMessage} = this.props;
        if (!!requestMessage && !requestMessage.error) {
          if(!this.props.tournamentPrizeReducer.length){
              this.props.getPrizeRules();
          }
        }
    }

    action = (type) => {
        const {prize, templateName} = this.state;
        const {createNewTournament, createTournamentTemplate, tournamentData, updateTournament} = this.props;
        const dateObj = store.getState().tournamentDateReducer;
        const scheduledAt = [...dateObj.scheduleAt];
        const arrayOfScheduledAt = [];
        const changeValuesToInteger = [];
        let dataDate;

        if (scheduledAt.length) {
            for (let el of scheduledAt) {
                arrayOfScheduledAt.push(el.date + ' ' + el.time);
            }
        }
        arrayOfScheduledAt.unshift(dateObj['startDate'] + ' ' + dateObj['startTime']);

        for (let i in prize.prizeDistribution) {
            changeValuesToInteger.push({
                position: +i + 1,
                percent: +prize.prizeDistribution[i].percentTotal,
            });
        }
        if (tournamentData.scheduledSequence && tournamentData.scheduledSequence.iterator) {
            dataDate = {
                ...dataDate,
                scheduledSequence: {
                    ...tournamentData.scheduledSequence,
                    startDate: dateObj['startDate'] + ' ' + dateObj['startTime'],
                    endDate: dateObj['sequence']['date'] + ' ' + dateObj['sequence']['time'],
                },
            };
        } else {
            dataDate = {...dataDate, scheduledAt: arrayOfScheduledAt};
        }
        const data = {
            ...tournamentData,
            ...dataDate,
            playerRegistrationStart:
                dateObj['register']['day'] * 24 * 60 +
                dateObj['register']['hour'] * 60 +
                dateObj['register']['min'],
            announcementStart:
                dateObj['announcementStart']['day'] * 24 * 60 +
                dateObj['announcementStart']['hour'] * 60 +
                dateObj['announcementStart']['min'],
            announcementEnd:
                dateObj['announcementEnd']['day'] * 24 * 60 +
                dateObj['announcementEnd']['hour'] * 60 +
                dateObj['announcementEnd']['min'],
            // finishedAt: dateObj['finishedAt']['min'],
            prizePool: prize.prizePool,
            winners: prize.winners,
            prizeDistribution: changeValuesToInteger,
        };

        if (type === 'Edit') {
            let finalData = {...data};
            if (finalData.hasOwnProperty('scheduledSequence') && finalData.scheduledSequence['iterator']) {
                delete finalData['scheduledAt']
            }
            const id = finalData['id'];
            const startDate = !!finalData['scheduledAt'] ? finalData['scheduledAt'][0]: '';
            delete finalData['id'];
            delete finalData['scheduledAt'];
            finalData['tournamentId'] = id;
            finalData['startDate'] = startDate;
            if (prize.prizeRuleName) {
                finalData = {...finalData, prizepool_template_id: prize.prizeRuleName}
            }
           //console.log(finalData, 'Edit-createNewTournament');
            updateTournament(finalData)
        } else if (type === 'save') {
            const finalData = {...data};
            if (finalData.hasOwnProperty('scheduledAt')) {
                delete finalData['scheduledAt'];
            }
            if (finalData.hasOwnProperty('scheduledSequence')) {
                delete finalData['scheduledSequence'];
            }
            finalData['name'] = templateName;
         // console.log(finalData, 'saveTemplate-createNewTournament');
            createTournamentTemplate(finalData);
        } else if (type === 'Copy') {
            const finalData = {...data};

            if (data.hasOwnProperty('scheduledAt')) {
                for (let el of data['scheduledAt']) {
                    if (el < moment().format('DD-MM-YYYY HH:mm:ss')) {
                        delete data['scheduledAt'];
                        break;
                    }
                }
            }

          // finalData['created_at'] = moment().format("YYYY-MM-DD H:mm:ss");
          // console.log(finalData, 'Copy-createNewTournament');
           createNewTournament(finalData);
        } else if (type === 'Create') {
            const finalData = {...data};

            if (data.hasOwnProperty('scheduledAt')) {
                for (let el of data['scheduledAt']) {
                    if (el < moment().format('DD-MM-YYYY HH:mm:ss')) {
                        delete data['scheduledAt'];
                        break;
                    }
                }
            }
           // finalData['created_at'] = moment().format("YYYY-MM-DD H:mm:ss");
          // console.log(finalData, 'Create-createNewTournament');
          createNewTournament(finalData)
        }
    };

    handleChangeNum = (e, name) => {
        this.props.setSelectedTournamentData({
            ...this.props.tournamentData,
            [name]: +e.target.value,
        });
    };

    saveAsTemplate = (e) => {
        if (e) {
            this.setState({showInput: e});
        } else {
            this.action('save');
        }
    };

    handleChangeComb = (index) => {
        this.props.setSelectedTournamentData({...this.props.tournamentData, gameCombs: index});
    };

    trumpsStatus = (e) => {
        const status = e === 'on' ? 'off' : 'on';
        this.props.setSelectedTournamentData({...this.props.tournamentData, gameNoTrump: status});
    };

    getSelectValue = (name, data) => {
        this.props.setSelectedTournamentData({
            ...this.props.tournamentData,
            [name]: name === 'gameType' ? data.value : +data.value,
        });

    };
    getTournamentName = (e) => {
        this.props.setSelectedTournamentData({
            ...this.props.tournamentData,
            name: e.target.value,
        });
    };
    getPrizeTemplateId = (e, data) => {
        const {tournamentPrizeReducer, getPrizeRuleName} = this.props;
        if (data.value) {
            let title = '';
            for (let el of tournamentPrizeReducer) {
                if (el.value === data.value) {
                    title = el.text;
                }
            }
            this.setState({
                prizeTemplateId: data.value,
            });
            getPrizeRuleName({title});
        } else {
            this.props.setPrizeDataFromTournamentTemplate({data: []});
        }
    };

    setPrizeData = (data) => {
        this.setState({
            prize: data,
        });
    };

    isHighlighted = (e) => {
        this.props.setSelectedTournamentData({...this.props.tournamentData, isHighlighted: !e});
    };
    getTemplateName = (e) => {
        this.setState({
            templateName: e.target.value,
        });
    };
    openCurrentTemplate = (e, data) => {
        if (data.value) {
            this.props.getCurrentTournamentByName({name: data.value});
        } else {
            this.props.setSelectedTournamentData(reset);
            this.props.resetDateAndTime({});
        }
    };

    render() {
        const {
            templateName,
            gameType,
            error,
            time,
            disTime,
            points,
            showInput,
        } = this.state;
        const {
            requestMessage,
            resetRequestMessage,
            tournamentPrizeReducer,
            tournamentData,
            close,
            title,
            disable,
            permissions
        } = this.props;
          return (
            <div style={{width: '100%'}}>
                {!!requestMessage ? (
                    <ModalForMessages
                        error={requestMessage.error}
                        message={requestMessage.message}
                        close={() => resetRequestMessage('')}
                    />
                ) : null}

                <div className="create-tournament-content">
                    <div className="input-box p-left">
                        <div className="check-box">
                            <div className="label">Tournament Name:</div>
                            <Input
                                placeholder="Max 32 symbol..."
                                value={tournamentData.name}
                                disabled={disable}
                                onChange={this.getTournamentName}
                            />
                            <span className="error-mes">{error.errName}</span>
                        </div>
                    </div>
                    <div className="input-box create-tournament-date border-vert">
                        <StartDateController editOrCopy={true}/>
                        <DateController
                            title={'Announcement Start Date:'}
                            stateName={'announcementStart'}
                            showSubPicker={true}
                            showDatePicker={true}
                            subTitle={'before start date'}
                            disable={disable}
                            editOrCopy={true}

                        />
                        <DateController
                            title={'Registration Date:'}
                            stateName={'register'}
                            showSubPicker={true}
                            showDatePicker={true}
                            subTitle={'before start date'}
                            disable={disable}
                            editOrCopy={true}
                        />
                        <DateController
                            title={'Announcement End Date:'}
                            stateName={'announcementEnd'}
                            showSubPicker={true}
                            showDatePicker={false}
                            subTitle={'After end of Tournament'}
                            disable={disable}
                            editOrCopy={true}
                        />
                        {/*<DateController*/}
                        {/*    title={''}*/}
                        {/*    stateName={'finishedAt'}*/}
                        {/*    showSubPicker={false}*/}
                        {/*    showDatePicker={false}*/}
                        {/*    subTitle={'Finished At'}*/}
                        {/*    finishedAt={true}*/}
                        {/*    disable={disable}*/}
                        {/*/>*/}
                    </div>
                    <div className="input-box border-bottom p-left">
                        <div>
                            <div className="margin-12">
                                <Input
                                    disabled={disable}
                                    label="Min Players:"
                                    value={tournamentData.minPlayers}
                                    onChange={(e) => this.handleChangeNum(e, 'minPlayers')}
                                    type="number"
                                    min={1}
                                />
                            </div>
                            <div className="margin-12">
                                <Input
                                    disabled={disable}
                                    label="Priority in Lobby"
                                    value={tournamentData.priorityInLobby === 0 ? '' : tournamentData.priorityInLobby}
                                    onChange={(e) => this.handleChangeNum(e, 'priorityInLobby')}
                                    type="number"
                                    min={1}
                                    max={10}
                                />
                            </div>
                        </div>
                        <div className="margin-12">
                            <Input
                                disabled={disable}
                                label="Max Players:"
                                value={tournamentData.maxPlayers}
                                onChange={(e) => this.handleChangeNum(e, 'maxPlayers')}
                                type="number"
                                min={1}
                            />
                            <div>
                                <div className="label"> Highlighted:</div>
                                <Checkbox
                                    toggle
                                    checked={tournamentData.isHighlighted}
                                    disabled={disable}
                                    onChange={() =>
                                        this.isHighlighted(tournamentData.isHighlighted)
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="input-box border-bottom date-flex p-top-0 p-left-39">
                    <div className="registration-day-manual column">
                        <div className="margin-r">
                            <div className="label">Game types:</div>
                            <Select
                                selectOnBlur={false}
                                disabled={disable}
                                clearable
                                placeholder={
                                    tournamentData.gameType ? tournamentData.gameType : 'Game type'
                                }
                                options={gameType}
                                onChange={(e, data) => this.getSelectValue('gameType', data)}
                            />
                        </div>
                        <div className="margin-r">
                            <div className="label">Points:</div>
                            <Select
                                selectOnBlur={false}
                                disabled={disable}
                                clearable
                                placeholder={
                                    tournamentData.gamePoints
                                        ? tournamentData.gamePoints + ''
                                        : 'Points'
                                }
                                options={points}
                                onChange={(e, data) => this.getSelectValue('gamePoints', data)}
                            />
                        </div>
                        <div className="margin-r">
                            <div className="label">Time:</div>
                            <Select
                                selectOnBlur={false}
                                disabled={disable}
                                clearable
                                placeholder={
                                    tournamentData.gameTime
                                        ? tournamentData.gameTime + ' s'
                                        : 'Time'
                                }
                                options={time}
                                onChange={(e, data) => this.getSelectValue('gameTime', data)}
                            />
                        </div>
                        <div className="margin-r">
                            <div className="label">Disconnect Time:</div>
                            <Select
                                selectOnBlur={false}
                                disabled={disable}
                                clearable
                                placeholder={
                                    tournamentData.disconnectTimer
                                        ? tournamentData.disconnectTimer > 30
                                        ? tournamentData.disconnectTimer / 60 + ' m'
                                        : tournamentData.disconnectTimer + ' s'
                                        : 'Disconnet Time'
                                }
                                options={disTime}
                                onChange={(e, data) => this.getSelectValue('disconnectTimer', data)}
                            />
                        </div>

                        <div className='margin-32'>
                        <Input
                            label="Buy-in"
                            value={tournamentData.buyIn === 0 ?'': tournamentData.buyIn}
                            onChange={(e) => this.handleChangeNum(e, 'buyIn')}
                            type="number"
                            min={1}
                        />
                    </div>
                    </div>

                    <div>
                        <div className="check-box">
                            <div>
                                <Radio
                                    disabled={disable}
                                    label="All combinations"
                                    name="radioGroup"
                                    value="ALLCOMBS"
                                    checked={tournamentData.gameCombs === 'ALLCOMBS'}
                                    onChange={() => this.handleChangeComb('ALLCOMBS')}
                                />
                            </div>
                            <div>
                                <Radio
                                    disabled={disable}
                                    label="Without 4 cards and 100"
                                    name="radioGroup"
                                    value="NO4NO100"
                                    checked={tournamentData.gameCombs === 'NO4NO100'}
                                    onChange={() => this.handleChangeComb('NO4NO100')}
                                />
                            </div>
                            <div>
                                <Radio
                                    disabled={disable}
                                    label="Belote / Rebelote only"
                                    name="radioGroup"
                                    value="ONLYBR"
                                    checked={tournamentData.gameCombs === 'ONLYBR'}
                                    onChange={() => this.handleChangeComb('ONLYBR')}
                                />
                            </div>
                        </div>
                        <div className="check-box">
                            <div className="label"> No Trumps</div>
                            <Checkbox
                                toggle
                                disabled={disable}
                                checked={tournamentData.gameNoTrump === 'on'}
                                onChange={() => this.trumpsStatus(tournamentData.gameNoTrump)}
                            />
                        </div>

                    </div>

                </div>
                <div className="input-box min-height-250 border-bottom">
                    <Prize setPrizeData={this.setPrizeData} editOrCopy={true} disable={disable}/>
                    <div className="check-box">
                        <div className="label">Prize Templates</div>
                        <Select
                            selectOnBlur={false}
                            disabled={disable}
                            clearable
                            placeholder="Templates"
                            options={tournamentPrizeReducer}
                            onChange={this.getPrizeTemplateId}
                        />
                    </div>
                </div>
                <div className="create-tournament-actions prize">
                    {permissions && (permissions[copy] || permissions[edit]) ?
                        <Button
                            positive
                            icon="checkmark"
                            labelPosition="right"
                            content={title === 'Edit' ? 'Save' : 'Create'}
                            onClick={() => this.action(title)}
                        /> : null}
                    {title !== 'Create' && (
                        <Button
                            color="black"
                            onClick={
                                !showInput
                                    ? () => this.saveAsTemplate(true)
                                    : () => this.saveAsTemplate(false)
                            }
                        >
                            Save as template
                        </Button>
                    )}

                    <Button color="black" onClick={close}>
                        Cancel
                    </Button>
                    <div className="template-name abs">
                        {showInput && (
                            <div className="check-box">
                                <div className="label">Template Name:</div>
                                <Input
                                    focus={true}
                                    placeholder="Enter template name..."
                                    value={templateName}
                                    onChange={this.getTemplateName}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({
                             requestMessage,
                             tournamentPrizeReducer,
                             tournamentTemplatesReducer,
                             oneTournamentReducer,
                             userReducer
                         }) => ({
    requestMessage,
    tournamentPrizeReducer,
    tournamentData: oneTournamentReducer,
    tournamentTemplates: tournamentTemplatesReducer,
    permissions: userReducer.permissions
});

const mapDispatchToProps = {
    setDateAndTime,
    getPrizeRules,
    getPrizeRuleName,
    createNewTournament,
    resetRequestMessage,
    leftDropDownMenuControl,
    setSelectedTournamentData,
    setPrizeDataFromTournamentTemplate,
    getCurrentTournamentByName,
    resetDateAndTime,
    getAllTournamentsTemplates,
    createTournamentTemplate,
    updateTournament
};

export default connect(mapStateToProps, mapDispatchToProps)(EditOrCopyTournament);
