import React, { useState, useEffect } from 'react';
import { Loader, Table } from 'semantic-ui-react';
import moment from 'moment';
import Checkbox from 'semantic-ui-react/dist/commonjs/modules/Checkbox';
import { getBazarLogs, resetBazarLogs } from '../redux/actions/bazarBlotAction';
import { connect } from 'react-redux';
import { resetRequestMessage } from '../redux/actions/otherActions';

function LogsTableBazar({ logFor, db, gameId, ...redux }) {
    const {
        bazarBlotLogsReducer,
        cols,
        getBazarLogs,
        clearLogs,
        requestMessage,
        resetRequestMessage,
    } = redux;
    const [colsState, setCols] = useState(bazarBlotLogsReducer);
    const [frontLogs, setFrontLogs] = useState([]);
    const [type, setType] = useState({ back: '', front: '' });

    useEffect(() => {
        getBazarLogs({ needLogs: true, id: gameId, from: 0, logsFor: db });
        return () => {
            console.log('unmount - LOGS');
            clearLogs();
            resetRequestMessage('');
        };
    }, []);

    useEffect(() => {
        const dateIndex = cols ? cols.indexOf('date') : null;
        if (dateIndex !== null) {
            cols.splice(dateIndex, 1);
        }
        setCols(cols);
        setFrontLogs(bazarBlotLogsReducer);
    }, [cols, bazarBlotLogsReducer]);

    useEffect(() => {
        let filteredLogs;
        if (type.hasOwnProperty('back') && type.hasOwnProperty('front')) {
            setFrontLogs(bazarBlotLogsReducer);
        } else if (type.hasOwnProperty('back')) {
            filteredLogs = bazarBlotLogsReducer.filter(
                (el) => el['TR_PREFIX'].action.indexOf('FRONT') === -1
            );
            setFrontLogs(filteredLogs);
        } else if (type.hasOwnProperty('front')) {
            filteredLogs = bazarBlotLogsReducer.filter(
                (el) => el['TR_PREFIX'].action.indexOf('FRONT') !== -1
            );
            setFrontLogs(filteredLogs);
        } else {
            setFrontLogs([]);
        }
    }, [type]);

    const handleFilter = (t) => {
        if (type.hasOwnProperty(t)) {
            const obj = type;
            delete obj[t];
            setType({ ...obj });
        } else {
            setType((type) => ({ ...type, [t]: '' }));
        }
    };

    return (
        <>
            {logFor === 'bazar' ? (
                <div className="check-box flex-end">
                    <Checkbox
                        className={'margin-r'}
                        label={'Show backend logs'}
                        onClick={() => handleFilter('back')}
                        checked={type.hasOwnProperty('back')}
                    />
                    <Checkbox
                        className={'margin-r'}
                        label={'Show frontend logs'}
                        onClick={() => handleFilter('front')}
                        checked={type.hasOwnProperty('front')}
                    />
                </div>
            ) : null}

            {bazarBlotLogsReducer.length && frontLogs.length ? (
                <Table sortable celled fixed size="small">
                    <Table.Header>
                        <Table.Row>
                            <Table.Cell>Date</Table.Cell>
                            <Table.Cell colSpan={colsState.length}>Info</Table.Cell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {frontLogs.map((item, index) => {
                            return (
                                <Table.Row key={index}>
                                    <Table.Cell>
                                        {moment(item['TR_PREFIX']['date']).format(
                                            'DD-MM-YYYY HH:mm:ss'
                                        )}
                                    </Table.Cell>
                                    <Table.Cell colSpan={colsState.length}>
                                        <Table celled fixed size="small">
                                            <Table.Body>
                                                {colsState.length
                                                    ? colsState.map((el, key) => {
                                                          return (
                                                              <Table.Row key={key}>
                                                                  <Table.Cell
                                                                      style={{ width: '120px' }}
                                                                  >
                                                                      {el + ':'}
                                                                  </Table.Cell>
                                                                  <Table.Cell>
                                                                      {item['TR_PREFIX'][el]
                                                                          ? item['TR_PREFIX'][el]
                                                                          : '_'}
                                                                  </Table.Cell>
                                                              </Table.Row>
                                                          );
                                                      })
                                                    : null}
                                            </Table.Body>
                                        </Table>
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>
            ) : type.hasOwnProperty('front') && type.hasOwnProperty('back') ? (
                !bazarBlotLogsReducer.length ? (
                    requestMessage.error ? (
                        <div>{requestMessage.message} </div>
                    ) : (
                        <div>No logs for this games</div>
                    )
                ) : (
                    <Loader active> Loading ... </Loader>
                )
            ) : (
                'No logs'
            )}
        </>
    );
}

const mapStateToProps = ({ bazarBlotLogsReducer, requestMessage }) => ({
    bazarBlotLogsReducer,
    cols: bazarBlotLogsReducer.length ? Object.keys(bazarBlotLogsReducer[0]['TR_PREFIX']) : [],
    requestMessage,
});

const mapDispatchToProps = {
    getBazarLogs,
    clearLogs: () => resetBazarLogs,
    resetRequestMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(LogsTableBazar);
