import { useEffect, useState } from 'react';
import { cardToCardId } from '../../../helpers/helpers/gameplay';
import usePrevious from '../general/usePrevious';

export function useDefineShownCombinationData(shownCombData, bottomPlayerId) {
    const [shownCombination, setShownCombination] = useState({ cards: [] });
    const prevCombData = usePrevious(shownCombData);
    useEffect(() => {
        if (!!shownCombData) {
            if (JSON.stringify(prevCombData) !== JSON.stringify(shownCombData)) {
                const { playerId, cards = [] } = shownCombData;
                const isOpponent = playerId !== bottomPlayerId;
                setShownCombination({ isOpponent, playerId, cards: cards && cards.map((card) => cardToCardId(card)) });
            }
        } else {
            setShownCombination({ cards: [] });
        }
    }, [shownCombData]);

    return shownCombination;
}
