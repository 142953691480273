import React, { useEffect, useState } from 'react';
import HandCard from '../reusables/HandCard';
import { CARD_INTERACT_CLASSES } from '../../../constants/gameplay';
import TableCardHR from './TableCardHR';
import { cardToCardId } from '../../../helpers/helpers/gameplay';
import BatCard from '../reusables/BatCard';
import { useSetShownCombinationCardsToHandCards } from '../../../shared/hooks/gameplay/cards/useSetShownCombinationCardsToHandCards';
import { useSetOpenedCardsToHand } from '../../../shared/hooks/gameplay/cards/useSetOpenedCardsToHand';

let timeoutId = 0;

export default function OpponentCardsHR({
    cardsData,
    cardsColorId,
    bottomPlayerId,
    currentHand,
    lastHandCardData,
    stepIndex,
    shownCombination,
    openedCardsData,
    isEventData,
}) {
    const [cards, setCards] = useState([]);
    const [tableCard, setTableCard] = useState({});
    const [batCardData, setBatCardData] = useState({});
    useSetShownCombinationCardsToHandCards(shownCombination, cards, setCards, true);
    useSetOpenedCardsToHand(openedCardsData, setCards, true);

    useEffect(()=>{
        try{
            setCards(cardsData.map((data) => cardToCardId(data)));
        }
        catch(e){
            console.log(e,"cardsData");
        }

    }, [cardsData]);

    useEffect(() => {
        if (!currentHand.playerId && !!lastHandCardData) {
            if (!isEventData) {
                setTableCard({ cardId: lastHandCardData[0].lastHandCardId, zIndex: lastHandCardData[0].zIndex });
            }
            timeoutId = setTimeout(() => {
                setTableCard({});
                if (!!lastHandCardData) {
                    const { withAnim } = lastHandCardData[0];
                    if (!withAnim) {
                        setBatCardData(lastHandCardData[0]);
                    }
                } else {
                    setBatCardData({});
                }
            }, 1000);
        }

        return () => clearTimeout(timeoutId);
    }, [currentHand, lastHandCardData]);

    useEffect(() => {
        setTableCard({});
        if (!!lastHandCardData) {
            const { withAnim } = lastHandCardData[0];
            if (!withAnim) {
                setBatCardData(lastHandCardData[0]);
            }
        } else {
            setBatCardData({});
        }
        clearTimeout(timeoutId);
    }, [stepIndex]);

    useEffect(() => {
        const { card, playerId } = currentHand;
        if (!!playerId) {
            if (playerId !== bottomPlayerId) {
                setTableCard({ cardId: cardToCardId(card) });
            }
        } else {
            setTableCard({});
        }
    }, [currentHand]);

    return (
        <div className="opponent-cards-container">
            {cards.map((cardId, i) => (
                <HandCard
                    key={cardId || i}
                    cardId={cardId}
                    className={CARD_INTERACT_CLASSES.OPPONENT_HAND_CARD}
                    styles={{ top: 'calc(0% - 500px)' }}
                    bottomPlayerId={bottomPlayerId}
                    isOpponentCard={true}
                    cardsLength={cards.length}
                    index={i}
                    cardsColorId={cardsColorId}
                    isReplay={true}
                />
            ))}
            {tableCard.cardId && (
                <TableCardHR
                    cardId={tableCard.cardId}
                    isOpponentCard={true}
                    styles={{ top: 'calc(0% - 500px)', zIndex: tableCard.zIndex }}
                />
            )}
            {batCardData.lastHandCardId && (
                <BatCard
                    cardId={batCardData.lastHandCardId}
                    isOpponentCard={true}
                    bottomPlayerId={bottomPlayerId}
                    lastHandCardData={batCardData}
                    styles={{ top: 'calc(0% - 500px)', zIndex: batCardData.zIndex }}
                />
            )}
        </div>
    );
}
