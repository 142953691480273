import React from 'react';
import { Loader, Segment } from 'semantic-ui-react';

const MainLoader = () => (
    <Segment className="App-loader-own">
        <Loader size="massive" active />
    </Segment>
);

export default MainLoader;
