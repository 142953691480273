import { useState, useEffect } from 'react';
import { NOTES_COLORS_IDS } from '../../../constants/game/ids';

export function useGetPlayerNotes(notes, playerId) {
    const [playerNotes, setPlayerNotes] = useState({});
    useEffect(() => {
        if (!!notes[playerId]) {
            const { note, color } = notes[playerId];
            setPlayerNotes({ playerNoteText: note || '', playerColorId: color || NOTES_COLORS_IDS.NONE });
        } else {
            setPlayerNotes({ playerNoteText: '', playerColorId: NOTES_COLORS_IDS.NONE });
        }
    }, [notes, playerId]);

    return playerNotes;
}
