import React from 'react';
import ScoreBoard from './ScoreBoard';
import { GAME_SCENE_TYPES } from '../../../../../constants/general';
import { getRoundText } from '../../../../../helpers/game';
import { GAME_MIN_POINT } from '../../../../../constants/game/other';

const { SPIN, TOURNAMENT } = GAME_SCENE_TYPES;

function ScoreBoardContainerGeneral({
    till,
    time,
    scene,
    sceneData,
    roomId,
    bet,
    leftTotalScore,
    rightTotalScore,
    gameSettings,
    moveScoreData,
    powerText,
    matchScoreRows,
}) {
    const isTournament = scene === TOURNAMENT;
    const isSpin = scene === SPIN;
    const isRound = till < GAME_MIN_POINT;
    const tillText = isSpin || isTournament ? 'Round' : isRound ? 'Rounds' : 'Score';
    const tillValue = isSpin || isTournament ? getRoundText(sceneData.round) : till;
    const typeListTimeAndTill = (isSpin || isTournament) && { time, till };
    const betAmount = !isTournament && bet;

    return (
        <ScoreBoard
            {...{
                roomId,
                leftTotalScore,
                rightTotalScore,
                gameSettings,
                moveScoreData,
                powerText,
                matchScoreRows,
                tillText,
                tillValue,
                typeListTimeAndTill,
                bet: betAmount,
            }}
        />
    );
}

export default ScoreBoardContainerGeneral;
