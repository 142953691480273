import {GENERAL_TYPE} from '../actionTypes';
import instance from '../../helpers/Api';

const {SET_ALL_DATA, SET_IP_ADDRESS, SET_DIFF_TIME, SET_GLOBAL_PLAYER} = GENERAL_TYPE;

export function getGamesPlayed(data) {
    return (dispatch) => {
        instance.post('/api/general/getGamesPlayed', data)
            .then((res) => {
                dispatch({type: SET_ALL_DATA, value: {games: res.data.data}});
            })
            .catch((e) => {
                console.log('getGamesPlayed', e);
            });
    };
}

export function getTournamentsPlayed(data) {
    return (dispatch) => {
        instance.post('/api/general/getTournamentsPlayed', data)
            .then((res) => {
                dispatch({type: SET_ALL_DATA, value: {tournament: res.data.data}});

            })
            .catch((e) => {
                console.log('getTournamentsPlayed', e);
            });
    };
}

export function getSpinPlayed(data) {
    return (dispatch) => {
        instance.post('/api/general/getSpinPlayed', data)
            .then((res) => {
                dispatch({type: SET_ALL_DATA, value: {spin: res.data.data}});
            })
            .catch((e) => {
                console.log('getSpinPlayed', e);
            });
    };
}

export function setIpAddress(value) {
    return {type: SET_IP_ADDRESS, value}
}

export function setTimeDifference(value) {
    return {type: SET_DIFF_TIME, value}
}

export function setGlobalPlayer(value) {

    return {type: SET_GLOBAL_PLAYER, value}
}

