import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import useTimerData from '../../../../shared/hooks/general/useTimerData';
import DisconnectTimer from './components/DisconnectTimer';

TimerAvatar.propTypes = {
    timerData: PropTypes.object,
};

TimerAvatar.defaultProps = {
    timerData: {},
};

function TimerAvatar({ playerId, ...redux }) {
    const { timer, moveDuration } = redux;

    const timerRef = useRef(null);
    const [remainingMoveDuration, setRemainingMoveDuration] = useState(null);

    const [timerData] = useTimerData(timer, playerId);
    const { stepTimer, continueTimer } = timerData;

    const [disconnectTimer, setDisconnectTimer] = useState(null);

    useEffect(() => {
        setRemainingMoveDuration(stepTimer);
        !stepTimer && setDisconnectTimer(null);
    }, [stepTimer]);

    useEffect(() => {
        let intervalId;
        if (!!remainingMoveDuration) {
            intervalId = startTimer(timerRef, remainingMoveDuration / 1000, moveDuration, () => {
                setRemainingMoveDuration(0);
            });
        }
        if (remainingMoveDuration === 0) {
            setDisconnectTimer(continueTimer);
        }
        return () => clearInterval(intervalId);
    }, [remainingMoveDuration]);

    return (
        <div style={{ display: 'contents' }}>
            {!!remainingMoveDuration && (
                <svg className="blot-field__timer" viewBox="0 0 120 120">
                    <circle className="blot-field__timer-svg" r="54" cx="60" cy="60" ref={timerRef} />
                </svg>
            )}
            {!!disconnectTimer && <DisconnectTimer sec={disconnectTimer / 1000} />}
        </div>
    );
}

function startTimer(timerRef, remainingMoveDuration, moveDuration, onFinish) {
    const timerCircle = timerRef.current;
    let percent = 100 - (remainingMoveDuration * 100) / moveDuration;
    const circleLength = 2 * Math.PI * Number(timerCircle.getAttribute('r'));

    const intervalId = setInterval(() => {
        percent += 1 / moveDuration;

        timerCircle.style.strokeDasharray = circleLength.toString();
        timerCircle.style.strokeDashoffset = (circleLength - (circleLength / 100) * percent).toString();
        if (percent >= 100) {
            clearInterval(intervalId);
            onFinish();
        }
    }, 10);

    return intervalId;
}

const mapStateToProps = ({ currentGame: { timer, time } }) => ({
    timer,
    moveDuration: time,
});

export default connect(mapStateToProps)(TimerAvatar);
