export const reset = {
    gameNoTrump: 'off',
    gameCombs: '',
    name: '',
    playerRegistrationStart: '',
    announcementStart: '',
    announcementEnd: '',
    priorityInLobby: '',
    minPlayers: '',
    maxPlayers: '',
    gameType: '',
    gamePoints: '',
    gameTime: '',
    buyIn: '',
    templateName: '',
    prizePool: '',
    winners: '',
    prizeDistribution: [],
    isHighlighted: false,
    disconnectTimer: '',
    scheduledSequence: { startDate: '', iterator: '', endDate: '' },
};
