export const USER_TYPES = {
    SET_USER_DATA: 'SET_USER_DATA',
    RESET_USER_DATA: 'RESET_USER_DATA',
    ERROR_MESSAGE_USER: 'ERROR_MESSAGE_USER',
    SET_USER_TIMEZONE_NAME: 'SET_USER_TIMEZONE_NAME',
};

export const TICKETS_TYPE = {
    SET_TICKETS: 'SET_TICKETS',
};

export const MAIN_TYPES = {
    GLOBAL_LOADER_SHOW: 'GLOBAL_LOADER_SHOW',
    GLOBAL_LOADER_HIDE: 'GLOBAL_LOADER_HIDE',
    LOADER_SHOW: 'LOADER_SHOW',
    LOADER_HIDE: 'LOADER_HIDE',
    GLOBAL_AUTH_TRUE: 'GLOBAL_AUTH_TRUE',
    GLOBAL_AUTH_FALSE: 'GLOBAL_AUTH_FALSE',
};

export const USERS_TYPES = {
    SET_USERS: 'SET_USERS',
    REQUEST_MESSAGE: 'REQUEST_MESSAGE',
    RESET_REQUEST_MESSAGE: 'RESET_REQUEST_MESSAGE',
};

export const OTHER_TYPES = {
    GET_TOTAL_PAGES_COUNT: 'GET_TOTAL_PAGES_COUNT',
    OPEN_OR_CLOSE: 'OPEN_OR_CLOSE',
    GET_TOTAL_PAGES_COUNT_NEW: 'GET_TOTAL_PAGES_COUNT_NEW',
};
export const PLAYERS_TYPES = {
    SET_PLAYERS: 'SET_PLAYERS',
    SET_BLACKLIST: 'SET_BLACKLIST',
    SET_ONE_PLAYER_BLACKLIST: 'SET_ONE_PLAYER_BLACKLIST',
    UPDATE_BLACKLIST: 'UPDATE_BLACKLIST',
};
export const GAME_TYPES = {
    SET_GAMES: 'SET_GAMES',
    SET_ONE_GAMES: 'SET_ONE_GAMES',
    SET_GAME_LOGS: 'SET_GAME_LOGS',
    RESET_GAME_LOGS: 'RESET_GAME_LOGS',
};

export const BAZAR_BLOT_TYPES = {
    SET_BAZAR: 'SET_BAZAR',
    SET_CURRENT_BAZAR: 'SET_CURRENT_BAZAR',
    SET_BAZAR_LOGS: 'SET_BAZAR_LOGS',
    RESET_BAZAR_LOGS: 'RESET_BAZAR_LOGS',
};

export const SETTINGS_TYPES = {
    SET_SETTINGS: 'SET_SETTINGS',
};
export const TOURNAMENTS_TYPES = {
    SET_TOURNAMENTS: 'SET_TOURNAMENTS',
    SET_DATE_OR_TIME: 'SET_DATE_OR_TIME',
    SET_All_PRIZE_RULES: 'SET_All_PRIZE_RULES',
    SET_ONE_PRIZE_RULE: 'SET_ONE_PRIZE_RULE',
    SET_SELECTED_TOURNAMENT_DATA: 'SET_SELECTED_TOURNAMENT_DATA',
    SET_DATE_OR_TIME_FROM_SELECTED_TOURNAMENT: 'SET_DATE_OR_TIME_FROM_SELECTED_TOURNAMENT',
    RESET_DATE_OR_TIME: 'RESET_DATE_OR_TIME',
    SET_TOURNAMENTS_TEMPLATES: 'SET_TOURNAMENTS_TEMPLATES',
    SET_TOURNAMENT_DATA_FOR_EDIT_OR_COPY: 'SET_TOURNAMENT_DATA_FOR_EDIT_OR_COPY',
    SET_TOURNAMENT_PLAYERS: 'SET_TOURNAMENT_PLAYERS',
    SET_TRANSACTIONS: 'SET_TRANSACTIONS',
    RESET_TABS_INFO: 'RESET_TABS_INFO',
    SET_TOURNAMENTS_TEMPLATES_LIST: 'SET_TOURNAMENTS_TEMPLATES_LIST',
    SET_All_PRIZE_RULES_LIST: 'SET_All_PRIZE_RULES_LIST',
    RESET_ONE_PRIZE_RULE: 'RESET_ONE_PRIZE_RULE',
    SET_TOURNAMENT_ROUNDS: 'SET_TOURNAMENT_ROUNDS',
    SET_WINNERS_TRANSACTIONS: 'SET_WINNERS_TRANSACTIONS',
    SET_ADMIN_ADDED_PLAYERS_LIST: 'SET_ADMIN_ADDED_PLAYERS_LIST',
    UPDATE_ADMIN_ADDED_PLAYERS_LIST: 'UPDATE_ADMIN_ADDED_PLAYERS_LIST',
};

export const ACLMANAGMENT_TYPES = {
    SET_ACL_USERS: 'SET_ACL_USERS',
    SET_ACL_GROUPS: 'SET_ACL_GROUPS',
    SET_ALL_RESOURCES: 'SET_ALL_RESOURCES',
    OPEN_EDIT_MODAL: 'OPEN_EDIT_MODAL',
    SET_ALL_ACL_GROUPS: 'SET_ALL_ACL_GROUPS',
    SET_ALL_ACL_USERS: 'SET_ALL_ACL_USERS',
};

export const SPIN_TYPES = {
    SET_ALL_SPINS: 'SET_ALL_SPINS',
    SET_ONE_SPINS: 'SET_ONE_SPINS',
    SET_SPIN_PRIZE: 'SET_SPIN_PRIZE',
    SET_ALL_SPINS_RULES: 'SET_ALL_SPINS_RULES',
    SET_ONE_SPIN_RULE: 'SET_ONE_SPIN_RULE',
    SET_ONE_SPIN_GAME_TYPE: 'SET_ONE_SPIN_GAME_TYPE',
};

export const CMS_TYPES = {
    SET_ALL_BANNERS: 'SET_ALL_BANNERS',
    SET_BANNERS_DATA: 'SET_BANNERS_DATA',
    SET_ALL_PLACEHOLDERS: 'SET_ALL_PLACEHOLDERS',
    EDIT_PLACEHOLDER: 'EDIT_PLACEHOLDER',
};

export const GENERAL_TYPE = {
    SET_ALL_DATA: 'SET_ALL_DATA',
    SET_IP_ADDRESS: 'SET_IP_ADDRESS',
    SET_DIFF_TIME: 'SET_DIFF_TIME',
    SET_GLOBAL_PLAYER: 'SET_GLOBAL_PLAYER',
};
