import React from 'react';
import { setClass } from '../../../../../helpers/game';
import PopupNotesOpponent from './PopupNotesOpponent/PopupNotesOpponent';
import { avatarImages } from '../../../../../assets';
import TimerAvatar from '../../TimerAvatar/TimerAvatar';
import DotLoader from './DotLoader';

function AvatarOpponent({
    cloudText,
    chosenTrumpSuit,
    playerId,
    visualId,
    avatarId,
    isDotLoaderVisible,
    withoutTimer,
    withoutNotes,
    isPlayersTurn,
    replayDisconnectTimerText,
}) {
    return (
        <div
            className={`blot-field__user-icon blot-field__user-icon--with-tooltip ${setClass(
                !isDotLoaderVisible && !!cloudText,
                'blot-field__user-icon--say-terz'
            )} ${setClass(
                !!chosenTrumpSuit,
                `blot-field__user-icon--trump blot-field__user-icon--trump-${chosenTrumpSuit}`
            )}`}
        >
            <div className={`blot-field__user-id ${setClass(!playerId, 'blot-field__user-id--hidden')}`}>
                ID: {visualId}
            </div>
            <div className="blot-field__user-icon-container-outer">
                {!!playerId && !withoutNotes && <PopupNotesOpponent playerId={playerId} />}
                <div
                    className={`blot-field__user-icon-container blot-field__user-icon-container${setClass(
                        isPlayersTurn,
                        '--with-timer'
                    )}`}
                >
                    <img src={!!playerId ? avatarImages[avatarId] : ''} alt="" className="blot-filed__user-icon-img" />
                    {!withoutTimer && <TimerAvatar playerId={playerId} />}
                    {!!replayDisconnectTimerText && (
                        <span className="disconnect-timer">{replayDisconnectTimerText}</span>
                    )}
                </div>
            </div>
            {isDotLoaderVisible && <DotLoader />}
            <div className="blot-field__say-terz">
                <div className="blot-field__say-terz-text">{cloudText}</div>
            </div>
        </div>
    );
}

export default AvatarOpponent;
