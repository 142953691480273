import { useEffect } from 'react';
import { getCombinationCountData, getTextFromCombinationCountData } from '../../../../helpers/helpers/gameplay';
import { COMBINATION_VALUE_NAMES } from '../../../../constants/game/names';
import { useTranslation } from 'react-i18next';

export function useDefineDeclareCombText(declaredCombData, bottomPlayerId, setter) {
    const { t } = useTranslation();

    useEffect(() => {
        if (!!declaredCombData) {
            const { combinations = [], priority = {}, value, isTrump } = declaredCombData;
            const isBottomPlayerAction = bottomPlayerId === declaredCombData.playerId;
            let playerText = '';
            let opponentText = '';

            if (combinations.length) {
                const isBottomPlayerPriority = !!value && bottomPlayerId === priority.playerId;
                const isSecondDeclaration = !!value;
                const combinationCountData = getCombinationCountData(combinations);
                const combinationText = t(getTextFromCombinationCountData(combinationCountData)) + '!';
                const trumpText = t('Trump') + '!';
                const secondCombinationText = `${combinationText} ${COMBINATION_VALUE_NAMES[value]} ${
                    isTrump ? trumpText : ''
                }`;

                if (!!isSecondDeclaration) {
                    let priorValueText = COMBINATION_VALUE_NAMES[priority.cardValue];
                    if (isTrump) {
                        priorValueText.slice(0, -1);
                    }

                    if (isBottomPlayerAction) {
                        if (!isBottomPlayerPriority) {
                            playerText = secondCombinationText;
                            opponentText = `${priorValueText} ${priority.isTrump ? trumpText : ''}`;
                        } else {
                            playerText = secondCombinationText;
                            opponentText = t('Mine is lower');
                        }
                    } else {
                        if (isBottomPlayerPriority) {
                            opponentText = secondCombinationText;
                            playerText = `${priorValueText} ${priority.isTrump ? trumpText : ''}`;
                        } else {
                            playerText = t('Mine is lower');
                            opponentText = secondCombinationText;
                        }
                    }
                } else {
                    if (isBottomPlayerAction) {
                        playerText = combinationText;
                        opponentText = '';
                    } else {
                        opponentText = combinationText;
                        playerText = '';
                    }
                }
                setter({ playerText, opponentText });
            }
        }
    }, [declaredCombData]);
}
