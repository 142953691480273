import React from 'react';
import GameTabs from '../../components/GameTabs';
import { getOneGame } from '../../redux/actions/gamesAction';

const OpenOneBazar = ({ match }) => {
    return (
        <div className="spin-info-content">
            <div className="page-title">
                <h2> Bazar Blot info </h2>
            </div>
            <GameTabs isGame={false} id={match.params.id} />
        </div>
    );
};

export default OpenOneBazar;
