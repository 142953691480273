import React, {useState, useEffect, useRef} from 'react';
import {Button, Icon, Input, Select} from 'semantic-ui-react'
import {TimeInput} from '@trendmicro/react-datepicker';
import DatePick from './Datapiker/DataPicker';
import '@trendmicro/react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import {arrClass} from "./Datapiker/general";

const gameType = [
    {key: '11', text: 'CASH', value: 'CASH'},
    {key: '22', text: 'SPIN', value: 'SPIN'},
    {key: '23', text: 'TOURNAMENT', value: 'TOURNAMENT'},
];

const gameObj = [
    {key: '11', text: 'CLASSIC', value: 'CLASSIC'},
    {key: '22', text: 'OPEN', value: 'OPEN'},
];

const transactionTypeObj = [
    {key: '1', text: 'table-win', value: 'table-win'},
    {key: '2', text: 'table-bet', value: 'table-bet'},
    {key: '3', text: 'table-refund', value: 'table-refund'},
    {key: '4', text: 'table-rake', value: 'table-rake'},
    {key: '5', text: 'tournament-bet', value: 'tournament-bet'},
    {key: '6', text: 'tournament-refund', value: 'tournament-refund'},
    {key: '7', text: 'rollback', value: 'rollback'},
    {key: '8', text: 'tournament-win', value: 'tournament-win'},

];

const TransactionsFilter = ({filterTransactions}) => {
    const [err, setErr] = useState('');
    const [transactionId, setTransactionId] = useState('');
    const [transactionType, setTransactionType] = useState('');
    const [remoteId, setRemoteId] = useState('');
    const [tableId, setTableId] = useState('');
    const [playerIp, setPlayerIp] = useState('');
    const [amountFrom, setAmountFrom] = useState('');
    const [amountTo, setAmountTo] = useState('');
    const [selected, setSelected] = useState(0);
    const [selectedTransType, setSelectedTransType] = useState(0);
    const [selectedGame, setSelectedGame] = useState(0);
    const [status, setStatus] = useState('');
    const [game, setGame] = useState('');
    const [showPicker, setShowPicker] = useState('');
    const [startTimeFrom, setStartTimeFrom] = useState(moment().format('00:00:00'));
    const [startTimeTo, setStartTimeTo] = useState(moment().format('00:00:00'));
    const [startDateFrom, setStartDateFrom] = useState(moment().format('00-00-0000'));
    const [startDateTo, setStartDateTo] = useState(moment().format('00-00-0000'));

    let refTo = useRef(null);
    let refFrom = useRef(null);


    const handleClick = (e) => {
        if ((showPicker === 'startDateFrom' || showPicker === 'startDateTo')
            && refFrom.current && refTo.current && !arrClass.includes(e.target.className)) {
            setShowPicker('')
        }

    };

    useEffect(() => {
        document.addEventListener('click', handleClick);
        return () => {
            document.removeEventListener('click', handleClick);
        };
    });

    useEffect(() => {
        let setTimeoutId;
        if (err) {
            setTimeoutId = setTimeout(() => {
                setErr('')
            }, 1500)
        }
        return (() => clearTimeout(setTimeoutId))
    }, [err]);

    const closeLastLoginDatePicker = (date) => {
        switch (showPicker) {
            case 'startDateFrom':
                setStartDateFrom(date)
                break;
            case 'startDateTo':
                setStartDateTo(date);
                break;
            default:
                return null
        }
        setShowPicker('')
    }
    const handleValue = (stateName, e) => {
        let val;
        if (stateName === 'startTimeTo' || stateName === 'startTimeFrom') {
            val = e;
        } else {
            val = e.target.value.trim();
        }

        switch (stateName) {
            case 'transactionId':
                setTransactionId(val);
                break;
            case 'remoteId':
                setRemoteId(val);
                break;
            case 'tableId':
                setTableId(val);
                break;
            case 'startTimeFrom':
                setStartTimeFrom(val);
                break;
            case 'startTimeTo':
                setStartTimeTo(val);
                break;
            case 'amountFrom':
                setAmountFrom(val);
                break;
            case 'amountTo':
                setAmountTo(val);
                break;
            case 'playerIp':
                setPlayerIp(val);
                break;


            default:
                return null
        }
    };
    const getSelectValue = (name, data) => {
        if (data.value) {
            if (name === 'game') {
                setGame(data.value);
                setSelectedGame(1)
            } else if (name === 'transactionType') {
                setTransactionType(data.value);
                setSelectedTransType(1)
            } else {
                setStatus(data.value)
                setSelected(1)
            }
        } else {
            if (name === 'game') {
                setGame('');
                setSelectedGame(0)
            } else if (name === 'transactionType') {
                setTransactionType('')
                setSelectedTransType(0)
            } else {
                setStatus('')
                setSelected(0)
            }
        }
    };

    function isEmpty(obj) {
        for (let key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    const handleFilter = () => {
        if (showPicker) setShowPicker('');
        const dataFilter = {};


        const obj = {
            transactionId: +transactionId,
            transactionRemoteId: remoteId,
            tableId: +tableId,
            transactionType,
            gameType: status === 'CASH'?'SINGLE':status,
            gameKind: game,
            amountFrom:+amountFrom,
            amountTo:+amountTo,
            playerIp,
            createDateFrom: startDateFrom + ' ' + startTimeFrom,
            createDateTo: startDateTo + ' ' + startTimeTo,
        };
        for (let el in obj) {
            if (obj[el] && obj[el] !== "00-00-0000 00:00:00") {
                dataFilter[el] = obj[el]
            }
        }

        if (isEmpty(dataFilter)) {
            setErr('Empty data')
        } else {
            //console.log(dataFilter, "dataFilter")
            filterTransactions(dataFilter)
            setErr('')
        }
        console.log(obj,"objjjj")

    };

    const clearAll = () => {
        setTransactionId('');
        setRemoteId('');
        setTableId('');
        setStatus('');
        setAmountFrom('');
        setAmountTo('');
        setPlayerIp('');
        setShowPicker('');
        setStartTimeFrom(moment().format('00:00:00'));
        setStartTimeTo(moment().format('00:00:00'));
        setStartDateFrom(moment().format('00-00-0000'));
        setStartDateTo(moment().format('00-00-0000'));
        setSelected(0);
        setSelectedGame(0)
        setGame('')
        filterTransactions(null)
    }
    return (
        <div className={`filter-inputs input-box-setting flex-wrap`}>
            <div className="margin-l input-box padding-0 flex-wrap">
                <div className="check-box">
                    <div className="label">Transaction ID:</div>
                    <Input
                        placeholder="Transaction id"
                        value={transactionId}
                        onChange={(e) => handleValue('transactionId', e)}
                        type={'number'}
                    />
                </div>
                <div className="check-box">
                    <div className="label">AB Transaction ID:</div>
                    <Input
                        placeholder="AB Transaction ID"
                        value={remoteId}
                        onChange={(e) => handleValue('remoteId', e)}

                    />
                </div>
                <div className="check-box">
                    <div className="label">Table ID:</div>
                    <Input
                        placeholder="Table id"
                        value={tableId}
                        onChange={(e) => handleValue('tableId', e)}
                        type={'number'}
                    />
                </div>
                <div className="check-box">
                    <div className="label">Select game type:</div>
                    {!selected ? <>
                        {status ? null : null}
                        <Select
                            selectOnBlur={false}
                            clearable
                            placeholder={'Not selected'}
                            options={gameType}
                            onChange={(e, data) => getSelectValue('gameType', data)}
                        /></> : <Select
                        selectOnBlur={false}
                        clearable
                        placeholder={status}
                        options={gameType}
                        onChange={(e, data) => getSelectValue('gameType', data)}
                    />
                    }
                </div>
                <div className="check-box">
                    <div className="label">Select game:</div>
                    {!selectedGame ? <>
                        {game ? null : null}
                        <Select
                            selectOnBlur={false}
                            clearable
                            placeholder={'Not selected'}
                            options={gameObj}
                            onChange={(e, data) => getSelectValue('game', data)}
                        /></> : <Select
                        selectOnBlur={false}
                        clearable
                        placeholder={game}
                        options={gameObj}
                        onChange={(e, data) => getSelectValue('game', data)}
                    />
                    }
                </div>
                <div className="check-box">
                    <div className="label">Select Transaction type:</div>
                    {!selectedTransType ? <>
                        {transactionType ? null : null}
                        <Select
                            selectOnBlur={false}
                            clearable
                            placeholder={'Not selected'}
                            options={transactionTypeObj}
                            onChange={(e, data) => getSelectValue('transactionType', data)}
                        /></> : <Select
                        selectOnBlur={false}
                        clearable
                        placeholder={transactionType}
                        options={transactionTypeObj}
                        onChange={(e, data) => getSelectValue('transactionType', data)}
                    />
                    }
                </div>
                <div className="check-box">
                    <div className="label">Amount:</div>
                    <div className="item">
                        <Input
                            label="From:"
                            value={amountFrom}
                            onChange={(e) => handleValue('amountFrom', e)}
                            type="number"
                            min={1}
                            size={"mini"}
                        />
                        <Input
                            label="To:"
                            value={amountTo}
                            onChange={(e) => handleValue('amountTo', e)}
                            type="number"
                            min={1}
                            size={"mini"}
                        />
                    </div>
                </div>
                <div className="check-box">
                    <div className="label">Player IP:</div>
                    <Input
                        placeholder="Player IP"
                        value={playerIp}
                        onChange={(e) => handleValue('playerIp', e)}
                        type={'text'}
                    />
                </div>


                <div className="margin-l input-box padding-0 flex-wrap">
                    <div className="check-box">
                        <div className="label">Start date:</div>
                        <div style={{display: 'flex'}}>
                            <div className="margin-r ">
                                <label className="fnt-10">From:</label>
                                <div className="date-from">
                                    <div className="ui left icon input date">
                                        <input
                                            ref={refFrom}
                                            type="text"
                                            placeholder="Choose date..."
                                            size={10}
                                            value={startDateFrom}
                                            onClick={() => setShowPicker('startDateFrom')}
                                            onChange={() => setShowPicker('startDateFrom')}
                                        />
                                        <i aria-hidden="true" className="calendar icon"/>
                                    </div>
                                    {showPicker === 'startDateFrom' && (
                                        <DatePick close={closeLastLoginDatePicker} value={startDateFrom}/>
                                    )}
                                    <div className='left'>
                                        <TimeInput
                                            value={startTimeFrom}
                                            onChange={(e) => handleValue('startTimeFrom', e)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="margin-l">
                                <label className="fnt-10">To: </label>
                                <div className="date-to">
                                    <div className="ui left icon input date">
                                        <input
                                            ref={refTo}
                                            type="text"
                                            placeholder="Choose date..."
                                            size={10}
                                            value={startDateTo}
                                            onClick={() => setShowPicker('startDateTo')}
                                            onChange={() => setShowPicker('startDateTo')}
                                        />
                                        <i aria-hidden="true" className="calendar icon"/>
                                    </div>
                                    {showPicker === 'startDateTo' && (
                                        <DatePick close={closeLastLoginDatePicker} value={startDateTo}/>
                                    )}
                                    <div className='left'>
                                        <TimeInput value={startTimeTo}
                                                   onChange={(e) => handleValue('startTimeTo', e)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="margin-l filter-div">
                <Button primary className="filter-btn belote-green" onClick={handleFilter}>
                    Filter
                </Button>

                <Button  className="filter-btn" onClick={clearAll}>
                    Reset All
                </Button>
            </div>
            <div style={{minHeight: '20px'}}>
                <span className="error-mes date-err"> {err}</span>
            </div>
        </div>

    );
};

export default TransactionsFilter;
