export default  [
  // {
  //   key:'1',
  //   value: "Europe/London",
  //   offset: 1,
  //   text: "(UTC+01:00) Edinburgh, London",
  // },
  {
    key:'2',
    value: "Asia/Yerevan",
    offset: 4,
    text: "(UTC+04:00) Yerevan",

  },
  {
    key:'3',
    value: "Asia/Tbilisi",
    offset: 4,
    text: "(UTC+04:00) Tbilisi",
  },
  {
    key:'4',
    value: "Europe/Moscow",
    offset: 3,
    text: "(UTC+03:00) Moscow, St. Petersburg, Volgograd, Minsk",
  },
]