import { createReducer } from '../../helpers/general';
import { ACLMANAGMENT_TYPES } from '../actionTypes';

const {
    SET_ACL_USERS,
    SET_ACL_GROUPS,
    SET_ALL_RESOURCES,
    OPEN_EDIT_MODAL,
    SET_ALL_ACL_GROUPS,
    SET_ALL_ACL_USERS,
} = ACLMANAGMENT_TYPES;

export const aclUsersReducer = createReducer({}, (state, { value }) => {
    return {
        [SET_ACL_USERS]: value ? value.data : state,
    };
});

export const aclGroupsReducer = createReducer({}, (state, { value }) => {
    return {
        [SET_ACL_GROUPS]: value ? value.data : state,
    };
});
export const aclResourcesReducer = createReducer({}, (state, { value }) => {
    return {
        [SET_ALL_RESOURCES]: value ? value.data : state,
    };
});
export const resourceEditorModalControl = createReducer([], (state, { value }) => {
    return {
        [OPEN_EDIT_MODAL]: value ? value : state,
    };
});
export const aclAllGroups = createReducer([], (state, { value }) => {
    return {
        [SET_ALL_ACL_GROUPS]: value ? value.data : state,
    };
});
export const aclAllUsers = createReducer({ open: false, name: null }, (state, { value }) => {
    return {
        [SET_ALL_ACL_USERS]: value ? value.data : state,
    };
});

export default {
    aclUsersReducer,
    aclGroupsReducer,
    aclResourcesReducer,
    resourceEditorModalControl,
    aclAllGroups,
    aclAllUsers,
};
