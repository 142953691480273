import React from 'react';
import {Table} from 'semantic-ui-react';
import {Link} from 'react-router-dom'


const Rounds = ({data, keys}) => {

    return (
        <>
            {
                keys ? keys.map((el, key) => {
                    return (
                        <Table.Cell className='td-flex' key={key}>
                            <h5 className="round-title">{el !== '1/1' ? el : 'Final'} </h5>
                            {
                                data[el].map((item, index) => {
                                    return (
                                        <Link to={`/admin/games/${item.tableId}`} key={index} >
                                            <div className='table-id-div-flex'>Table ID: {item.tableId}</div>
                                            <div key={index} className='td-div-flex pointer'>
                                                <div
                                                    className={item.playerIdHost === item.winner ? 'winner' : 'not-winner'}>
                                                    {item.playerIdHost}
                                                </div>
                                                <div
                                                    className={item.playerIdGuest === item.winner ? 'winner' : 'not-winner'}>
                                                    {item.playerIdGuest}
                                                </div>
                                            </div>
                                        </Link>
                                    )
                                })}
                        </Table.Cell>
                    )
                }) : null
            }
        </>
    );

}

export default Rounds;

