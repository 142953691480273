import React, {useEffect, useState, useRef} from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import {TimeInput} from '@trendmicro/react-datepicker';
import DatePick from '../../components/Datapiker/DataPicker';
import {arrClass} from '../../components/Datapiker/general';
import DataTable from './DataTable';
import {Select, Button} from 'semantic-ui-react';
import {getGamesPlayed, getTournamentsPlayed, getSpinPlayed} from '../../redux/actions/generalAction';
import {resetRequestMessage} from '../../redux/actions/otherActions';
import './home.css';
import TimeZone from "../../components/TimeZone";


const dateFilter = [
    {key: '1', text: 'Last 24 hours', value: '1'},
    {key: '7', text: 'Last 7 days', value: '7'},
    {key: '30', text: ' Last 30 days', value: '30'},
    {key: '90', text: ' Last 90 days', value: '90'},
];

function Home({...redux}) {
    const {
        getGamesPlayed,
        getTournamentsPlayed,
        getSpinPlayed,
        resetRequestMessage,
        requestMessage,
        data
    } = redux;
    const [dateValueFrom, setDateValueFrom] = useState(moment().format('DD-MM-YYYY'));
    const [timeValueFrom, setTimeValueFrom] = useState(moment().format('00:00:00'));
    const [dateValueTo, setDateValueTo] = useState(moment().format('DD-MM-YYYY'));
    const [timeValueTo, setTimeValueTo] = useState(moment().format('HH:mm:ss'));
    const [showPicker, setShowPicker] = useState('');
    const [pickedDate, setPickedDate] = useState('Not Selected');
    const [err, setErr] = useState('');
    let refTo = useRef(null);
    let refFrom = useRef(null);


    useEffect(() => {
        handleFilter();
        // return(()=>{
        //     setDateValueFrom(moment().format("DD-MM-YYYY"));
        //     setTimeValueFrom(moment().format("00:00:00"));
        //     setDateValueTo(moment().format("DD-MM-YYYY"));
        //     setTimeValueTo(moment().format('HH:mm:ss'))
        //     handleFilter();
        // })
    }, []);

    const handleClick = (e) => {
        if ((showPicker === 'from' || showPicker === 'to') && refFrom.current && refTo.current && !arrClass.includes(e.target.className)) {
            setShowPicker('')
        }

    };

    useEffect(() => {
        document.addEventListener('click', handleClick);
        return () => {
            document.removeEventListener('click', handleClick);
        };
    });


    const handleFilter = () => {
        setShowPicker('');
        const obj = {
            dateFrom: dateValueFrom + ' ' + timeValueFrom,
            dateTo: dateValueTo + ' ' + timeValueTo,
        };
        getGamesPlayed(obj);
        getTournamentsPlayed(obj);
        getSpinPlayed(obj);
    };
    const openPicker = (e) => {
        if (err) setErr('');
        setShowPicker(e);
    };
    const closeFromDatePicker = (obj) => {
        setDateValueFrom(obj);
        setShowPicker('');
    };
    const closeToDatePicker = (obj) => {
        setDateValueTo(obj);
        setShowPicker('');
    };

    const getTimeFrom = (value) => {
        if (err) setErr('');
        setTimeValueFrom(value);
    };
    const getTimeTo = (value) => {
        if (err) setErr('');
        setTimeValueTo(value);

    };
    const select = (e, data) => {
        if (!!requestMessage) resetRequestMessage('');
        if (data.value){
            let startDate = moment().subtract(+data.value, "days").format("DD-MM-YYYY");
            let startTime = moment().format("HH:mm:ss");
            setPickedDate(+data.value);
            getGamesPlayed({pickedDate: +data.value, dateFrom: '', dateTo: ''});
            getTournamentsPlayed({pickedDate: +data.value, dateFrom: '', dateTo: ''});
            getSpinPlayed({pickedDate: +data.value, dateFrom: '', dateTo: ''});
            setDateValueFrom(startDate);
            setTimeValueFrom(startTime);
            setTimeValueTo(startTime)

        }
        else{
            setDateValueFrom(moment().format("DD-MM-YYYY"));
            setTimeValueFrom(moment().format("00:00:00"));
            setPickedDate(data.value);
            getGamesPlayed({pickedDate: '', dateFrom: moment().format("DD-MM-YYYY") + ' ' + moment().format("00:00:00"),
                dateTo: dateValueTo + ' ' + timeValueTo});
            getTournamentsPlayed({pickedDate: '', dateFrom: moment().format("DD-MM-YYYY") + ' ' + moment().format("00:00:00"),
                dateTo: dateValueTo + ' ' + timeValueTo});
            getSpinPlayed({pickedDate: '', dateFrom: moment().format("DD-MM-YYYY") + ' ' + moment().format("00:00:00"),
                dateTo: dateValueTo + ' ' + timeValueTo})
        }

    };


    return (
        <div className="home">
            <div className="page-title">
                <TimeZone />
                <div className="filter-inputs flex-wrap">
                    <div className="input-box home_">
                        <div className="margin-l">
                            <label>From:</label>
                            <div className="date-from">
                                <div className="ui left icon input date">
                                    <input
                                        ref={refFrom}
                                        type="text"
                                        placeholder="Choose date..."
                                        size={10}
                                        value={dateValueFrom}
                                        onClick={() => openPicker('from')}
                                        onChange={() => openPicker('from')}
                                    />
                                    <i aria-hidden="true" className="calendar icon"/>
                                </div>
                                {showPicker === 'from' && (
                                    <DatePick close={closeFromDatePicker}
                                              value={dateValueFrom}/>
                                )}
                                <div className="left">
                                    <TimeInput
                                        value={timeValueFrom}
                                        onChange={(value) => getTimeFrom(value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="margin-l-20">
                            <label>To: </label>
                            <div className="date-to">
                                <div className="ui left icon input date">
                                    <input
                                        ref={refTo}
                                        type="text"
                                        placeholder="Choose date..."
                                        size={10}
                                        value={dateValueTo}
                                        onClick={() => openPicker('to')}
                                        onChange={() => openPicker('to')}
                                    />
                                    <i aria-hidden="true" className="calendar icon"/>
                                </div>
                                {showPicker === 'to' && (
                                    <DatePick close={closeToDatePicker} value={dateValueTo}/>
                                )}
                                <div className="left">
                                    <TimeInput value={timeValueTo} onChange={(value) => getTimeTo(value)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="home-select">
                        <div className="label">Search by selecting:</div>
                        <Select selectOnBlur={false} clearable={true} placeholder={'Select'} options={dateFilter} onChange={select}/>
                    </div>
                </div>
            </div>
            <div className="filter-div">
                <Button primary className="filter-btn belote-green" onClick={handleFilter}>
                    Search
                </Button>
            </div>
            <div>
                <span className="error-mes date-err"> {err} </span>
            </div>
            <DataTable data={data}/>
        </div>
    );
}

const mapStateToProps = ({requestMessage, generalReducer}) => ({
    requestMessage,
    data: generalReducer
});

const mapDispatchToProps = {
    getGamesPlayed,
    getTournamentsPlayed,
    getSpinPlayed,
    resetRequestMessage
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);