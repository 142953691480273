import { TABLE_ROW_GAME_TYPE_IDS } from './ids';
const { WITHOUT_4_AND_100, DAVE, BLOT_REBLOT, CAPOT_2X, NO_TRUMPS, PRIVATE_GAME, POINT_DIFF } = TABLE_ROW_GAME_TYPE_IDS;

export const TABLE_SORT_ITEM_CONT_CLASSES = [
    'bet',
    'player-first',
    'score',
    'player-two',
    'status',
    'scores',
    'timer',
    'type',
];

export const TABLE_SORT_ITEM_CLASSES = [
    'bet-sort arrow-toggle',
    'player-sort player-sort--first arrow-toggle',
    '',
    'player-sort player-sort--second arrow-toggle arrow-toggle--right',
    '',
    'score-sort arrow-toggle',
    'timer-sort arrow-toggle',
    '',
];

export const TABLE_ROW_GAME_TYPE_CLASSES = {
    [WITHOUT_4_AND_100]: 'without-4-cards',
    [DAVE]: 'dave-2-n',
    [BLOT_REBLOT]: 'rebelote-only',
    [CAPOT_2X]: 'capot-2-x',
    [NO_TRUMPS]: 'no-trumps',
    [PRIVATE_GAME]: 'private-game',
    [POINT_DIFF]: 'point-diff',
};
