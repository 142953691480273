export const LOBBY_ID = -1;

export const GAME_SCENE_TYPES = {
    SINGLE: 'single',
    SPIN: 'spin',
    TOURNAMENT: 'tournament',
};

export const GAME_KINDS = {
    OPEN: 'open',
    CLASSIC: 'classic',
};

const { SPIN, TOURNAMENT, SINGLE } = GAME_SCENE_TYPES;
const { OPEN, CLASSIC } = GAME_KINDS;

export const GAME_NAMES = {
    [SPIN]: 'Spin',
    [TOURNAMENT]: 'Tournament',
    [SINGLE]: '',
    [OPEN]: 'Open',
    [CLASSIC]: 'Classic',
};
