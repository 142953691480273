import { TRADE_TYPES } from '../../../../constants/moveTypes';
import { useEffect } from 'react';
import { CARD_SUIT_NAMES } from '../../../../constants/game/names';
import { useTranslation } from 'react-i18next';

export function useDefineTradeText(chooseTrumpData, bottomPlayerId, setter) {
    const { t } = useTranslation();

    useEffect(() => {
        if (!!chooseTrumpData) {
            const { moveType, playerId: chosenPlayerId, trumpSuit } = chooseTrumpData;
            const {
                PASS_TRUMP,
                PASS_CANDIDATE_TRUMP,
                CHOOSE_TRUMP_WITHOUT_CARD,
                CHOOSE_CANDIDATE_TRUMP,
                CHOOSE_TRUMP_WITH_CARD,
            } = TRADE_TYPES;
            const candidatePass = moveType === PASS_CANDIDATE_TRUMP;
            const anotherTrumpPass = moveType === PASS_TRUMP;
            const chooseCandidate = moveType === CHOOSE_CANDIDATE_TRUMP;
            const chooseAnotherTrumpWithCard = moveType === CHOOSE_TRUMP_WITH_CARD;
            const chooseAnotherTrumpWithoutCard = moveType === CHOOSE_TRUMP_WITHOUT_CARD;
            const isPassed = candidatePass || anotherTrumpPass;
            let text = '';
            const isBottomPlayerAction = bottomPlayerId === chosenPlayerId;
            if (isPassed) {
                text = candidatePass ? "I don't want" : "I don't want other trump";
                text = t(text);
            } else {
                const suitText = t(`${CARD_SUIT_NAMES[trumpSuit]}s`);

                if (chooseCandidate) {
                    text = t('I take [suit]', { suit: suitText });
                }
                if (chooseAnotherTrumpWithoutCard) {
                    text = t('I take [suit] without open card', { suit: suitText });
                }
                if (chooseAnotherTrumpWithCard) {
                    text = t('I take [suit] with open card', { suit: suitText });
                }
            }

            isBottomPlayerAction && setter({ playerText: text, opponentText: '' });
            !isBottomPlayerAction && setter({ opponentText: text, playerText: '' });
        }
    }, [chooseTrumpData]);
}
