import React, {useEffect} from 'react';
import {getRoundsOfTournament} from '../redux/actions/tournamentsAction';
import {Table} from 'semantic-ui-react';
import {connect} from 'react-redux';
import Rounds from "./Round";

function ShowAllRounds({tournamentId, ...redux}) {
    const {getRoundsOfTournament, rounds, keys, toggleType} = redux;

    useEffect(() => {
        getRoundsOfTournament({tournamentId, remote_id: toggleType === 'Ajarabet'})

    }, [])

    function isEmpty(obj) {
        for (let key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    return (
        <>
            {!isEmpty(rounds) ?
                <Table sortable celled fixed size="small">
                    <Table.Header>
                        <Table.Row>
                            <Table.Cell>
                                <h5 className='r-title'>Rounds info </h5>
                                <div className='info-win-loose'>
                                    <div className='win-cube-bef'/>
                                    <div className='win-cube'> loss</div>
                                    <div className='win-loss-bef'/>
                                    <div className='loss-cube'> win</div>
                                </div>
                            </Table.Cell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <Table.Row className='rounds'>
                            <Rounds data={rounds} keys={keys} />
                        </Table.Row>
                    </Table.Body>
                </Table> : 'No rounds'}
        </>
    );
}

const mapStateToProps = ({requestMessage, roundsReducer, globalPlayerReducer}) => ({
    requestMessage,
    rounds: roundsReducer,
    keys: roundsReducer ? Object.keys(roundsReducer).sort((x, y) => y - x) : [],
    toggleType: globalPlayerReducer

});

const mapDispatchToProps = {
    getRoundsOfTournament
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowAllRounds);
