import { COMBINATIONS_IDS } from '../../constants/game/ids';
import { COMBINATION_NAMES } from '../../constants/game/names';
import gsap from 'gsap';

export let scale = 1;
let isFixed = false;
const MIN_HEIGHT = 710; // px
const MIN_WIDTH = 1024; // px

export function rearrangeSingleCard(isOpponent, card, cardsLength, index, blotField, duration) {
    const { cardTop, OFFSET_X, firstCardPosX } = getHandCardPositionAndOffset(isOpponent, card, cardsLength, blotField);
    const cardLeft = firstCardPosX + index * OFFSET_X + 'px';
    card.style.zIndex = isOpponent ? cardsLength + 1 - index : index + 2;
    gsap.to(card, {
        left: cardLeft,
        top: cardTop,
        duration,
    });
}

export const playCard = (card, isOpponent, isInstant, zIndex) => {
    return new Promise((resolve) => {
        card.style.transition = '';
        card.style.zIndex += zIndex;

        let top, left;
        const duration = isInstant ? 0 : 0.25;

        card.classList.remove('card-field--selectable');
        const { width, height } = card.getBoundingClientRect();
        const offsetX = width / 3.4;
        const offsetY = height / 5.9;

        if (isOpponent) {
            left = `calc(50% - ${width / 2 - offsetX}px)`;
            top = `calc(50% - ${height / 2 + offsetY}px)`;
        } else {
            left = `calc(50% - ${width / 2 + offsetX}px)`;
            top = `calc(50% - ${height / 2 - offsetY}px)`;
        }
        gsap.to(card, { top, left, onComplete: resolve, duration });
    });
};

export function setTableCardPosition(card, isOpponent, zIndex) {
    return playCard(card, isOpponent, true, zIndex);
}

export function moveSingleTableCardToBat(card, isOpponentSide, isOpponentCard, isInstant, blotField, deck) {
    return new Promise((resolve) => {
        let top, left, transform;

        card.style.transition = '';

        const duration = isInstant ? 0 : 0.25;

        const { wrapperX, wrapperH } = getWrapperDimensions(blotField);

        const { width: deckWidth, x: deckX } = deck.getBoundingClientRect();
        const { width: initialWidth, height: initialHeight } = card.getBoundingClientRect();

        const SCALE = 0.3;

        let cardWidth = initialWidth;
        let cardHeight = initialHeight;
        if (!isInstant) {
            cardWidth = initialWidth * SCALE;
            cardHeight = initialHeight * SCALE;
            transform = `${card.style.transform} scale(${SCALE})`;
        }

        const deckRightEdge = (deckX - wrapperX + deckWidth) / scale;

        const fromCardOffsetX = isOpponentCard ? cardWidth / 3 : -cardWidth / 3;
        const fromCardOffsetY = isOpponentCard ? -cardHeight / 4 : cardHeight / 4;

        const fromDeckOffsetY = wrapperH / 8;
        let cardPosX = deckRightEdge + fromCardOffsetX;
        let cardPosY = (wrapperH / 2 - 1.5 * cardHeight) / scale + fromCardOffsetY;
        if (isOpponentSide) {
            cardPosY -= fromDeckOffsetY;
        } else {
            cardPosY += fromDeckOffsetY;
        }

        top = cardPosY + 'px';
        left = cardPosX + 'px';
        // console.log({ top, left, transform, onComplete: resolve, duration });
        gsap.to(card, { top, left, transform, onComplete: resolve, duration });
    });
}

export function setBatCardPosition(card, isOpponentSide, isOpponentCard, blotField, deck) {
    return moveSingleTableCardToBat(card, isOpponentSide, isOpponentCard, true, blotField, deck);
}

export function throwSingleCard(card, blotField) {
    const { wrapperH } = getWrapperDimensions(blotField);

    setStyles(card, {
        transition: '1.5s',
        opacity: 0,
        top: wrapperH / 2 + 'px',
    });
}

export function getHandCardPositionAndOffset(isOpponent, card, cardsLength, blotField) {
    const { wrapperW, wrapperH } = getWrapperDimensions(blotField);

    const firstCard = card;
    const cardWidth = firstCard.getBoundingClientRect().width;
    const cardHeight = firstCard.getBoundingClientRect().height;
    const OFFSET_X = cardWidth / 3.5 / scale;
    const OFFSET_Y = cardHeight / 3 / scale;
    const halfCardsOffset = ((cardsLength - 1) / 2) * OFFSET_X;
    const firstCardPosX = wrapperW / 2 / scale - cardWidth / 2 - halfCardsOffset;
    const cardTop = isOpponent ? 0 - OFFSET_Y + 'px' : (wrapperH - cardHeight) / scale + OFFSET_Y + 'px';
    return { cardTop, OFFSET_X, firstCardPosX };
}

function getWrapperDimensions(blotField) {
    const { width: wrapperW, height: wrapperH, x: wrapperX, y: wrapperY } = blotField.getBoundingClientRect();
    return { wrapperW, wrapperH, wrapperX, wrapperY };
}

export function resizeWrapper(forCenterGame, wrapper) {
    if (!!forCenterGame && wrapper) {
        // console.log('RESIZED');
        let { offsetHeight: h, offsetWidth: w } = forCenterGame;

        if (h > MIN_HEIGHT) {
            isFixed = false;
            wrapper.classList.remove('fixed-size');
        } else {
            isFixed = true;
            wrapper.classList.add('fixed-size');
        }

        if (w < MIN_WIDTH || h < MIN_HEIGHT) {
            scale = h / MIN_HEIGHT;
        } else {
            scale = 1;
        }

        wrapper.style.transform = `scale(${scale})`;
        h -= 55;
    }
}

function setStyles(elem, styles) {
    Object.entries(styles).forEach(([key, value]) => (elem.style[key] = value));
}

export function cardToCardId(card) {
    if (!!card) {
        const { suit, value } = card;
        return suit + '-' + value;
    } else {
        return '';
    }
}

export function getCombinationCountData(combinations) {
    const { FIFTY, FOR_CARDS, HUNDRED, TERZ, BELOTE, REBELOTE } = COMBINATIONS_IDS;

    const obj = {
        [FOR_CARDS]: 0,
        [HUNDRED]: 0,
        [FIFTY]: 0,
        [TERZ]: 0,
        [BELOTE]: 0,
        [REBELOTE]: 0,
    };

    return combinations.reduce((acc, el) => {
        acc[el]++;
        return acc;
    }, obj);
}

export function getTextFromCombinationCountData(combinationCountData) {
    const filteredData = Object.entries(combinationCountData).reduce((acc, el) => {
        const [combId, count] = el;
        !!count && acc.push([COMBINATION_NAMES[combId], count]);
        return acc;
    }, []);

    let text = '';
    let countText = '';
    filteredData.forEach((item) => {
        const [name, count] = item;
        if (count > 1) {
            countText = count + 'x ';
        }
        text += `${countText}${name}, `;
    });
    return text.slice(0, -2);
}
