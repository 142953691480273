import Cookies from 'universal-cookie';
const cookies = new Cookies();
//export const uri = 'http://localhost:7777';
export const uri = '';

export const getToken = () => cookies.get('authToken');
export const getLanguage = () => cookies.get('locale');
export const setAuthToken = (token) => {
    cookies.set('authToken', token, { path: '/' });
};
export const removeAuthToken = () => {
    cookies.remove('authToken');
};
