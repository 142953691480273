import React from 'react';
import ItemRowMatchScore from './ItemRowMatchScore';

function RowMatchScore({ leftScore, rightScore }) {
    return (
        !!leftScore &&
        !!rightScore && (
            <div className="score__line-decoration-score__row">
                {[leftScore, rightScore].map(({ totalScore, handScore, combinationScore }, i) => (
                    <ItemRowMatchScore {...{ totalScore, handScore, combinationScore }} key={i} />
                ))}
            </div>
        )
    );
}

export default RowMatchScore;
