import _ from 'lodash';
import React, { PureComponent } from 'react';
import { Table, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import store from '../redux/store';
import p from '../permissionsConstants';
import moment from 'moment-timezone';
import { setCurrentBazar } from '../redux/actions/bazarBlotAction';
import '../pages/bazarBlot/bazarBlot.css';

const { gamesKIll } = p;

const ROOM_BET_TYPES = {
    1: 'Cash',
    2: 'Coins',
};

const CUSTOMS = {
    1: 'Player',
    0: 'System',
};

export default class SortableBazarTable extends PureComponent {
    state = {
        column: null,
        direction: 'descending',
        data: this.props.data,
        toggleType: this.props.toggleType,
        permissions: store.getState().userReducer['permissions'],
        liveStatus: false,
        diffTime: store.getState().diffTimeReducer,
        zone: store.getState().userTimeZoneReducer,
    };

    handleSetCurrentBazar = (current) => {
        store.dispatch(setCurrentBazar(current));
    };

    handleClick = (id) => {
        const desiredObject = this.props.data.find((obj) => obj.id === id);
        this.handleSetCurrentBazar(desiredObject);
    };

    handleSort = (clickedColumn) => () => {
        const { column, direction } = this.state;
        const { sorting } = this.props;

        if (column !== clickedColumn) {
            this.setState({
                column: clickedColumn,
                direction: 'ascending',
            });
        } else {
            this.setState({
                direction: direction === 'ascending' ? 'descending' : 'ascending',
            });
        }

        sorting(clickedColumn, direction === 'descending' ? 'ASC' : 'DESC');
    };

    isHighlighted = (date, id) => {
        if (date) {
            const nowTZ = moment.tz(moment(), this.state.zone).format('DD-MM-YYYY HH:mm:ss');
            const end = date.split(' ');
            const now = nowTZ.split(' ');
            const now_data = now[0].split('-');
            const now_time = now[1].split(':');
            const end_data = end[0].split('-');
            const end_time = end[1].split(':');
            const now_final = new Date(
                +now_data[2],
                +now_data[1],
                +now_data[0],
                +now_time[0],
                +now_time[1],
                +now_time[2]
            );
            const end_final = new Date(
                +end_data[2],
                +end_data[1],
                +end_data[0],
                +end_time[0],
                +end_time[1],
                +end_time[2]
            );
            const diffMin = moment(now_final).diff(moment(end_final), 'minutes');
            return diffMin >= 60;
        }
        return false;
    };

    componentDidUpdate(nextProps) {
        if (nextProps.liveStatus !== this.state.liveStatus) {
            this.setState({ liveStatus: nextProps.liveStatus });
        }
    }

    componentDidMount() {
        this.setState({ liveStatus: !this.state.liveStatus });
    }

    renderTableHeaderCell = (name, label) => {
        const { column, direction } = this.state;
        const sorted = column === name;
        const arrowIcon = sorted && direction === 'ascending' ? 'caret up' : 'caret down';

        return (
            <Table.HeaderCell
                sorted={sorted ? direction : null}
                onClick={this.handleSort(name)}
                className={typeof this.handleSort === 'function' ? 'clickable hover-cell' : ''}
                style={{
                    cursor: typeof this.handleSort === 'function' ? 'pointer' : 'default',
                }}
            >
                {label}
                {sorted && <Icon name={arrowIcon} />}
            </Table.HeaderCell>
        );
    };

    render() {
        const { column, direction, permissions, liveStatus, diffTime, toggleType } = this.state;
        const { data, count, kill } = this.props;
        let i = count;

        return (
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell className="rows-number">No:</Table.HeaderCell>
                        {this.renderTableHeaderCell('id', 'Table ID')}
                        <Table.HeaderCell>Player 1 ID</Table.HeaderCell>
                        <Table.HeaderCell>Player 2 ID</Table.HeaderCell>
                        <Table.HeaderCell>Player 3 ID</Table.HeaderCell>
                        <Table.HeaderCell>Player 4 ID</Table.HeaderCell>
                        <Table.HeaderCell>Winner 1 ID</Table.HeaderCell>
                        <Table.HeaderCell>Winner 2 ID</Table.HeaderCell>
                        {this.renderTableHeaderCell('bet', 'Bet Amount')}
                        <Table.HeaderCell>Win 1 Amount</Table.HeaderCell>
                        <Table.HeaderCell>Win 2 Amount</Table.HeaderCell>
                        {this.renderTableHeaderCell('bet_type', 'Bet Type')}
                        {this.renderTableHeaderCell('custom', 'Game Type')}
                        <Table.HeaderCell>Created By</Table.HeaderCell>
                        <Table.HeaderCell>Created At</Table.HeaderCell>
                        <Table.HeaderCell>Start Date</Table.HeaderCell>
                        <Table.HeaderCell>End Date</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {_.map(
                        data,
                        ({
                            id,
                            winner_1,
                            winner_2,
                            bet,
                            win_amount,
                            bet_type,
                            custom,
                            created_at,
                            started_at,
                            finished_at,
                            players,
                        }) => {
                            const playersArr = JSON.parse(players);
                            const playerKeys = Object.keys(playersArr);
                            const player1 = playerKeys[0];
                            const player2 = playerKeys[1];
                            const player3 = playerKeys[2];
                            const player4 = playerKeys[3];

                            return (
                                <Table.Row
                                    key={id}
                                    className={
                                        this.isHighlighted(started_at, id) && liveStatus
                                            ? 'lighted-yes'
                                            : null
                                    }
                                >
                                    <Table.Cell>{++i}</Table.Cell>
                                    <Table.Cell>
                                        <Link
                                            onClick={() => this.handleClick(id)}
                                            className="id-hover"
                                            to={`/admin/bazar/${id}`}
                                        >
                                            {id}
                                        </Link>
                                    </Table.Cell>
                                    <Table.Cell>{player1}</Table.Cell>
                                    <Table.Cell>{player2}</Table.Cell>
                                    <Table.Cell>{player3}</Table.Cell>
                                    <Table.Cell>{player4}</Table.Cell>
                                    <Table.Cell>{winner_1}</Table.Cell>
                                    <Table.Cell>{winner_2}</Table.Cell>
                                    <Table.Cell>{bet}</Table.Cell>
                                    <Table.Cell>{win_amount}</Table.Cell>
                                    <Table.Cell>{win_amount}</Table.Cell>
                                    <Table.Cell>{'Bazar'}</Table.Cell>
                                    <Table.Cell>{ROOM_BET_TYPES[bet_type]}</Table.Cell>
                                    <Table.Cell>{CUSTOMS[custom]}</Table.Cell>
                                    <Table.Cell>{created_at}</Table.Cell>
                                    <Table.Cell>{started_at}</Table.Cell>
                                    <Table.Cell>{finished_at}</Table.Cell>
                                </Table.Row>
                            );
                        }
                    )}
                </Table.Body>
            </Table>
        );
    }
}
