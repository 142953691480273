import React, {PureComponent} from 'react';
import {Icon, Table} from 'semantic-ui-react';
import {Link} from "react-router-dom";


export default class SpinTable extends PureComponent {
    state = {
        column: null,
        data: this.props.data,
        direction: 'descending',
        colNames: this.props.cols,
        keys: this.props.keys,
    };

    handleSort = (clickedColumn) => () => {
        const {column, direction} = this.state;
        const {sorting} = this.props;

        if (column !== clickedColumn) {
            this.setState({
                column: clickedColumn,
                //	data: _.sortBy(data, [clickedColumn]),
                direction: 'ascending',
            });
        }

        this.setState({
            direction: direction === 'ascending' ? 'descending' : 'ascending',
        });

        sorting(clickedColumn, direction === 'descending' ? 'ASC' : 'DESC');
    };

    render() {
        const {column, direction} = this.state;
        const {data, count, cols, edit, editSpin} = this.props;

        let i = count;
        return (
            <Table sortable celled fixed size="small">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>No:</Table.HeaderCell>
                        {cols.map((item, index) => {
                                return (
                                    <Table.HeaderCell
                                        key={index}
                                        sorted={column === item.value ? direction : null}
                                        onClick={this.handleSort(item.value)}
                                    >
                                        {item.text}
                                    </Table.HeaderCell>
                                );
                            }
                        )}
                        {edit ?
                            <Table.HeaderCell style={{width: '50px'}} title={'Open Spin'}>Info</Table.HeaderCell> : null
                        }
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {data.length ?
                        data.map((dataItem, index) => {
                            return (
                                <Table.Row key={index}>
                                    <Table.Cell>{++i}</Table.Cell>
                                    {cols.map((item, ind) => {
                                        return (
                                            <Table.Cell key={ind}>
                                                {!edit ?
                                                    <Link
                                                        to={`/admin/open/spin/${
                                                            dataItem['id']
                                                        }`}
                                                    >
                                                        {dataItem[item.value]}
                                                    </Link> : dataItem[item.value]}
                                            </Table.Cell>
                                        );
                                    })}
                                    {edit ?
                                        <Table.Cell className={'pointer'}
                                                    onClick={() => editSpin(dataItem['id'], dataItem['buyin'], dataItem['prizepool'])}>
                                            <Icon name="zoom-in" style={{padding: '12px'}} title={'Open Spin'}/>
                                        </Table.Cell> : null
                                    }
                                </Table.Row>
                            );
                        }) : <Table.Row>
                            <Table.Cell colspan={5}> No data</Table.Cell>
                        </Table.Row>}
                </Table.Body>
            </Table>
        );
    }
}
