import React from 'react';
import { forMap } from '../../../helpers/helpers/general';

function InactiveCards() {
    return (
        <ul className="blot-field__user-cards" style={{ visibility: 'hidden' }}>
            {forMap(9, (i) => (
                <li id="inactive" className="blot-field__user-card" key={i}>
                    <div
                        className="blot-field__user-card-wrapper card-field card-field--inactive card-field-transition"
                        id="inactive"
                    />
                </li>
            ))}
        </ul>
    );
}

export default InactiveCards;
