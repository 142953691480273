import React, {useEffect, useState} from 'react';
import SortablePlayersTable from '../../components/SortablePlayersTable';
import {Loader, Pagination, Select} from 'semantic-ui-react';
import {getAllPlayers} from '../../redux/actions/playersActions';
import {connect} from 'react-redux';
import FilterPlayersList from "../../components/FilterPlayersList";
import './player.css';
import TimeZone from "../../components/TimeZone";

const rowItems = [
  // { key: '10', text: '10 rows', value: '10' },
  // { key: '30', text: '20 rows', value: '20' },
  { key: '50', text: '50 rows', value: '50' },
  { key: '100', text: '100 rows', value: '100' },
];


function Player({getAllPlayers, ...redux}) {
    const {playerReducer, totalPagesReducer, loader,toggleType} = redux;
    const [sortedField, setSortedField] = useState('');
    const [direction, setDirection] = useState('DESC');
    const [activePage, setActivePage] = useState('');
    const [rowCount, setRowCount] = useState(0);
    const [limit, setLimit] = useState(50);
    const [offset, setOffset] = useState(0);
    const [filerDateForSorting, setFilerDateForSorting] = useState({});

    useEffect(() => {
        getAllPlayers({limit, offset, ...filerDateForSorting});

    }, []);

    const onPageChange = (e, data) => {
        getAllPlayers({
            offset: (data.activePage - 1) * limit,
            orderBy: sortedField,
            order: direction,
            ...filerDateForSorting,
            limit,
        });
        setOffset((data.activePage - 1) * limit);
        setActivePage(data.activePage);
        setRowCount(() => data.activePage * limit - limit);
    };
    const filterGames = (data) => {
        getAllPlayers({
           ...data,
            limit,
            offset
        });
        setOffset(0);
        setActivePage(1);
        setRowCount(0);
        setFilerDateForSorting({... data});
    };

    const sorting = (field, direction) => {
        getAllPlayers({
            orderBy: field,
            order: direction,
            ...filerDateForSorting,
            limit,
            offset: 0,
            toggleType
        });
        setSortedField(field);
        setActivePage(1);
        setDirection(direction);
        setRowCount(0);
    };
  const getRowCount = (e, data) => {
    let activePage = Math.floor(offset / limit) + 1;
    setLimit(+data.value);
    getAllPlayers({
      limit: +data.value,
      offset: data.value > rowCount ? 0 : +data.value,
      ...filerDateForSorting,
    });
    setRowCount(0);
    setActivePage(activePage);
  };
    return (
        <div className="player-content">
            <h2>Players List</h2>
            <TimeZone />
            {!loader?
            <>
            <FilterPlayersList filterGames={filterGames}  toggleType={toggleType}/>
            <div className="row-count">
                <div className="label">Rows count per page</div>
                <div>
                    <Select
                      selectOnBlur={false}
                      className="own"
                      placeholder={`${limit} rows`}
                      options={rowItems}
                      onChange={getRowCount}
                    />
                </div>
            </div>
            <div className='table-and-pg'>
                <SortablePlayersTable toggleType={toggleType} data={playerReducer} sorting={sorting} count={rowCount}/>
            <Pagination
                activePage={activePage}
                totalPages={totalPagesReducer}
                className="pagi-own"
                onPageChange={totalPagesReducer > 1 ? onPageChange : null}
            />
            </div>
            </>:<Loader active> Loading players list ... </Loader>}
        </div>
    );
}

const mapStateToProps = ({playerReducer, totalPagesReducer, loaderReducer,globalPlayerReducer}) => ({
    playerReducer,
    totalPagesReducer,
    loader: loaderReducer,
    toggleType:globalPlayerReducer
});

const mapDispatchToProps = {
    getAllPlayers,
};

export default connect(mapStateToProps, mapDispatchToProps)(Player);
