import {createAction} from '../../helpers/general';
import {MAIN_TYPES, OTHER_TYPES, USER_TYPES, USERS_TYPES} from '../actionTypes';

import {getToken, removeAuthToken, setAuthToken} from '../../configs/server';
import instance from '../../helpers/Api';
import moment from 'moment-timezone';
import {setGlobalPlayer, setTimeDifference} from './generalAction';

const { SET_USER_DATA, RESET_USER_DATA, ERROR_MESSAGE_USER, SET_USER_TIMEZONE_NAME } = USER_TYPES;
const { SET_USERS, REQUEST_MESSAGE } = USERS_TYPES;
const { GLOBAL_AUTH_TRUE, LOADER_HIDE, GLOBAL_LOADER_HIDE, GLOBAL_AUTH_FALSE, LOADER_SHOW } = MAIN_TYPES;
const { GET_TOTAL_PAGES_COUNT_NEW } = OTHER_TYPES;


export function getAllUsers(data) {
    return (dispatch) => {
        instance.post('/api/users/get', data)
            .then((res) => {
                //  console.log(res,"RES")
                dispatch(createAction(LOADER_HIDE));
                dispatch({ type: SET_USERS, value: res.data.data.users });
                dispatch({ type: GET_TOTAL_PAGES_COUNT_NEW, value: res.data.data });
            })
            .catch((e) => {
                dispatch(createAction(LOADER_SHOW));
                console.log('getUsers', e);
            });
    };
}

export function setUserTimeZone(data) {
    return (dispatch) => {
        instance.post('/api/users/setUserTimeZone', data)
            .then((res) => {
                //  console.log(res.data, "setUserTimeZone");
                dispatch(setTimeDifference(res.data.data['diff']));
                dispatch(setUserTimeZoneName(res.data.data['zone']));
            })
            .catch((e) => {
                console.log('error - setUserTimeZone', e);
            });
    };
}

export const resetUserData = createAction(RESET_USER_DATA);

export function userAuthLogin(data) {
    return (dispatch) => {
        instance.post('/api/auth/signIn', data)
            .then((res) => {
                dispatch(setUserConfigs(res.data.data));

                dispatch(createAction(GLOBAL_AUTH_TRUE));
                setAuthToken(res.data.data['user_token']);
                dispatch(setTimeDifference(calculateTimeDifference(res.data.data['moment'])));
                dispatch(setUserTimeZoneName(res.data.data['zone']));
                dispatch(setGlobalPlayer(res.data.data['preferred_ids'] === 'provider' ? 'Energaming' : 'Ajarabet'));
                instance.defaults.headers.common['authorization'] = getToken();
            })
            .catch((e) => {
                dispatch(createAction(GLOBAL_AUTH_FALSE));
                dispatch(setUserErrorMessage(e.response ? e.response.data.message : ''));
            });
    };
}

export function getUserIpAddress() {
    return fetch('https://api.ipify.org')
        .then((res) => {
            return res.text();
        })
        .catch((e) => {
            console.log(e, 'getUserIpAddress');
        });

}

export function getUserConfigs() {
    return (dispatch) => {
        instance.defaults.headers.common['authorization'] = getToken();
        instance.post('/api/acl/getConfigs')
            .then((res) => {
                dispatch(setUserConfigs(res.data.data));
                dispatch(createAction(GLOBAL_AUTH_TRUE));
                dispatch(setTimeDifference(res.data.data['diff']));
                dispatch(setUserTimeZoneName(res.data.data['zone']));
                dispatch(setGlobalPlayer(res.data.data['preferred_ids'] === 'provider' ? 'Energaming' : 'Ajarabet'));
                getToken();
            })
            .catch((e) => {
                removeAuthToken();
                dispatch(createAction(GLOBAL_AUTH_FALSE));
                dispatch(createAction(RESET_USER_DATA));
            })
            .finally(() => {
                dispatch(createAction(GLOBAL_LOADER_HIDE));
            });
    };
}

export function updateOtherUsers(data) {
    return (dispatch) => {
        instance.post('/api/users/updateOthers', data)
            .then((res) => {
                //console.log( res, "updateOthers")
                dispatch({ type: REQUEST_MESSAGE, value: res.data });
            })
            .catch((e) => {
                dispatch(createAction(GLOBAL_AUTH_FALSE));
                dispatch(setUserErrorMessage(e.response ? e.response.data.message : ''));
            });
    };
}

export function createNewUser(data) {
    return (dispatch) => {
        instance.post('/api/users/create', data)
            .then((res) => {
                //console.log( res, "updateOthers")
                dispatch({ type: REQUEST_MESSAGE, value: res.data });
            })
            .catch((e) => {
                dispatch(createAction(GLOBAL_AUTH_FALSE));
                dispatch(setUserErrorMessage(e.response ? e.response.data.message : ''));
            });
    };
}

export function updateUserProfile(data) {
    return (dispatch) => {
        instance.post('/api/users/update', data)
            .then((res) => {
                //console.log( res, "updateUserProfile")
                dispatch({ type: REQUEST_MESSAGE, value: { [data['type']]: res.data } });
            })
            .catch((e) => {
                console.log('updateUserProfile', e);
            });
    };
}

export function deleteUser(data) {
    return (dispatch) => {
        instance.post('/api/users/deleteUser', data)
            .then((res) => {
                // console.log( res, "deleteUser")
                dispatch({ type: REQUEST_MESSAGE, value: res.data });
            })
            .catch((e) => {
                console.log('deleteUser', e);
            });
    };
}

export function setUserConfigs(value) {
    return { type: SET_USER_DATA, value };
}

function calculateTimeDifference(time) {
    // console.log(time,"calculateTimeDifference")
    const nowTZ = moment.tz(moment(), 'Asia/Yerevan').format('DD-MM-YYYY HH:mm:ss');

    // const nowTZ= moment().format("HH:mm:ss");
    // let server = moment(nowTZ.split(':'), "HH:mm:ss")
    // let client = moment(time.split(':'), "HH:mm:ss")
    // let diff= server.diff(client, 'hours')
    // console.log(diff,"diff")
    return moment(nowTZ, 'DD-MM-YYYY HH:mm:ss').diff(moment(time, 'DD-MM-YYYY HH:mm:ss'), 'hours');

}

function setUserErrorMessage(value) {
    return { type: ERROR_MESSAGE_USER, value };
}

export function setUserTimeZoneName(value) {
    return { type: SET_USER_TIMEZONE_NAME, value };
}


//
// export function updateUserReducer(value) {
//     return {type: SET_USER_DATA, value}
// }

export function logOut() {
    return (dispatch) => {
        removeAuthToken();
        dispatch(createAction(GLOBAL_AUTH_FALSE));
        dispatch(createAction(RESET_USER_DATA));
    };
}

