export const tabNames = {
    'HOME': 'Home',
    'PLAYERS': 'Players',
    'TICKETS': 'Tickets',
    'GAMES': 'Games',
    'TOURNAMENT LIST': 'Tournament list',
    'CREATE': 'Create tournament',
    'T-TEMPLATE': 'Tournament template',
    'P-TEMPLATE': 'Prize template',
    'SPINREPORT': 'Spin report',
    'CMS': 'CMS',
    'MANAGMENT': 'ACL management',
    'USERS': 'Backoffice users',
    'SETTING': 'Settings',
    'PROFILE': 'Profile',
}
