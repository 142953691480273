import { createReducer } from '../../helpers/general';
import { GAME_TYPES } from '../actionTypes';

const { SET_GAMES, SET_ONE_GAMES, SET_GAME_LOGS, RESET_GAME_LOGS } = GAME_TYPES;

export const gameReducer = createReducer([], (state, { value }) => {
    return {
        [SET_GAMES]: value ? value : state,
    };
});
export const oneGameReducer = createReducer([], (state, { value }) => {
    return {
        [SET_ONE_GAMES]: value  ? value.data : state,
    };
});
export const gameLogsReducer = createReducer([], (state, { value }) => {
    return {
        [SET_GAME_LOGS]: value?  value: state,
        [RESET_GAME_LOGS]: []
    };
});

export default {
    gameReducer,
    oneGameReducer,
    gameLogsReducer,
};
