import React from 'react';
import {connect} from 'react-redux';
import SpinTabs from "../../components/SpinTabs";


const OpenCurrentSpin = ({ match
                     }) => {
    return (
        <div className="spin-info-content">
            <div className="page-title">
                <h2> Spin info with ID - {match.params.id}</h2>
            </div>
            <SpinTabs  id={match.params.id}/>
        </div>
    );
};

const mapStateToProps = () => ({

});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(OpenCurrentSpin);