import React, { useState, useEffect } from 'react';
import './profile.css';
import timezone from '../../helpers/timezones';
import { connect } from 'react-redux';
import {
    updateUserProfile,
    setUserConfigs,
    setUserTimeZone,
    setUserTimeZoneName,
} from '../../redux/actions/usersActions';
import { resetRequestMessage } from '../../redux/actions/otherActions';
import { setGlobalPlayer } from '../../redux/actions/generalAction';
import { Button, Checkbox, Dropdown, Input, Select } from 'semantic-ui-react';
import p from '../../permissionsConstants';
import * as moment from 'moment-timezone';
import TimeZone from '../../components/TimeZone';

const { profileUpdate } = p;


function Profile({
                     user, requestMessage, updateUserProfile,
                     setUserTimeZone, zone, setUserTimeZoneName,
                     setUserConfigs, resetRequestMessage, permissions, setGlobalPlayer, toggleType,
                 }) {
    const languageOptions = [
        { key: 'en', text: 'English', value: 'en' },
        { key: 'ru', text: 'Russian', value: 'ru' },
    ];

    const [newPassword, setNewPassword] = useState('');
    const [error, setError] = useState({ info: null, password: null });
    const [confirmPassword, setConfirmPassword] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [username, setUserName] = useState(user.username);
    const [locale, setLocale] = useState(user.locale);
    const [selectedTimezone, setSelectedTimezone] = useState({});
    const [checked, setChecked] = useState(user.preferred_ids === 'provider' );

    let timeoutIdInfo, timeoutIdPass;
    useEffect(() => {
        // console.log(zone, "zone")
        // console.log(timezone, "timezone")
        // console.log(selectedTimezone, "selectedTimezone")
    }, [selectedTimezone]);

    useEffect(() => {
        let timeoutId,
            time = 1500;
        // if (
        //     !!requestMessage &&
        //     ((requestMessage['info'] && !requestMessage['info'].error) ||
        //         (requestMessage['password'] && !requestMessage['password'].error))
        // ) {
        //     setUserConfigs({
        //         ...user,
        //         username,
        //         locale,
        //     });
        // } else {
        //     time = 2000;
        // }
        timeoutId = setTimeout(() => {
            resetRequestMessage('');
        }, time);
        return () => {
            clearTimeout(timeoutId);
            clearTimeout(timeoutIdPass);
            clearTimeout(timeoutIdInfo);
        };
    }, [requestMessage]);

    // const getLocale = (locale) => {
    //     const lang = languageOptions.filter((el) => el.key === locale)[0];
    //     return lang ? lang.text : 'Select Language';
    // };
    const getPassword = (type, e) => {
        const val = e.target.value;
        type === 'New'
            ? setNewPassword(val)
            : type === 'Confirm'
            ? setConfirmPassword(val)
            : setOldPassword(val);
    };

    const getUserName = (e) => {
        const val = e.target.value;
        setUserName(val);
    };
    const getTimeZone = (e, data) => {
        const val = data.value;
        if (val) {
            const userTime = moment.tz(moment(), val).format('HH:mm:ss');
            const now = moment().format('DD-MM-YYYY HH:mm:ss');
            const userTimeFull = moment.tz(moment(), val).format('DD-MM-YYYY HH:mm:ss');
            // console.log(val, "getTimeZone")
            // console.log(userTime, "userTime")
            let diff = moment(userTimeFull, 'DD-MM-YYYY HH:mm:ss').diff(moment(now, 'DD-MM-YYYY HH:mm:ss'), 'hours');
            // console.log(userTimeFull > now, "userTimeFull")
            // console.log(now, "now")
            // console.log(userTimeFull, "userTimeFull")
            // console.log(diff, "now")

            setUserTimeZone({ userTime: userTimeFull, id: user['id'], timeZoneName: val });
            setUserTimeZoneName(val);
        }

    };
    const getLang = (e, data) => {
        setLocale(data.value);
    };

    const save = (type) => {
        let params = {};
        if (type === 'password') {
            if (newPassword.length && confirmPassword.length && oldPassword.length) {
                updateUserProfile({
                    type: 'password',
                    newPassword,
                    confirmPassword,
                    oldPassword,
                });
            } else {
                setError({ ...error, password: 'Nothing was changed ...' });
                timeoutIdInfo = setTimeout(() => {
                    setError({
                        info: null,
                        password: null,
                    });
                }, 1500);
            }
        } else {
            if (username.length && username !== user.username) {
                params['username'] = username;
            }
            if (locale && locale.length && locale !== user.locale) {
                params['locale'] = locale;
            }
            params['preferred_ids'] = checked ? 'provider' : 'operator';
            if (Object.keys(params).length) {
                updateUserProfile({
                    type: 'info',
                    params,
                });
            } else {
                setError({ ...error, info: 'Nothing was changed ...' });
                timeoutIdPass = setTimeout(() => {
                    setError({
                        info: null,
                        password: null,
                    });
                }, 1500);
            }
        }
    };

    const checkboxHandle = () => {
        const type = !checked ? 'Energaming' : 'Ajarabet';
        setChecked(!checked);
        setGlobalPlayer(type);

    };

    return (
        <div className="profile-content">
            <h2>Hello {user.username}!</h2>
            <TimeZone/>
            <div className='title'>Here you can edit your personal data.</div>
            <hr/>
            <div className="input-box-setting margin-12">

                <div className="check-box time-zone-own">
                    <div className="check-box m-left p-left">
                        <div className='label'>User Timezone:</div>
                        <Dropdown
                            onChange={getTimeZone}
                            placeholder={timezone.filter((el) => el.value === zone)[0]['text']}
                            fluid
                            selection
                            clearable={true}
                            options={timezone}
                        />
                    </div>
                </div>
                <div className="check-box ">
                    <div className="margin-12 m-left">
                        <div className='label'>Username:</div>
                        <div>
                            <Input
                                disabled
                                value={username}
                                placeholder="Enter your new username..."
                                onChange={getUserName}
                            />
                        </div>
                    </div>
                </div>
                <div className="check-box">
                    <div className='label'>User language:</div>
                    <Select
                        selectOnBlur={false}
                        placeholder={locale === 'ru' ? 'Russian' : 'English'}
                        options={languageOptions}
                        onChange={getLang}
                    />
                </div>
                <div className="margin-l">
                    <div className="label center-toggle"> Choose Player ID:</div>
                    <div className="gl-toggle">
                        <span
                            className={toggleType === 'Ajarabet' ? 'highlight toggle-title' : 'toggle-title'}> Ajarabet</span>
                        <Checkbox
                            toggle
                            checked={ toggleType !== 'Ajarabet'}
                            onChange={checkboxHandle}
                        />
                        <span
                            className={toggleType === 'Energaming' ? 'highlight toggle-title' : 'toggle-title'}> Energaming</span>
                    </div>
                </div>

                <div
                    className={
                        (requestMessage &&
                            requestMessage['info'] &&
                            requestMessage['info'].error) ||
                        error.info
                            ? 'req-mess err'
                            : 'req-mess'
                    }
                >
                    {error.info
                        ? error.info
                        : requestMessage && requestMessage['info']
                            ? requestMessage['info'].message
                            : null}
                </div>
                <div className="action-btn">
                    <Button
                        positive
                        icon="checkmark"
                        labelPosition="right"
                        content="Save"
                        onClick={() => save('info')}
                    />
                </div>
            </div>

            <br/>

            {permissions && permissions[profileUpdate] ?
                <>
                    <hr/>
                    <div className="input-box-setting margin-12">
                        <div className="margin-12">
                            <div className='label'>Old Password:</div>
                            <div>
                                <Input
                                    placeholder="Old Password"
                                    onChange={(e) => getPassword('Old', e)}
                                    type="password"
                                />
                            </div>
                        </div>
                        <div className="margin-12">
                            <div className='label'>New Password:</div>
                            <div>
                                <Input
                                    placeholder="New Password"
                                    onChange={(e) => getPassword('New', e)}
                                    type="password"
                                />
                            </div>
                        </div>
                        <div className="margin-12">
                            <div className='label'>Confirm Password:</div>
                            <div>
                                <Input
                                    placeholder="Confirm Password"
                                    onChange={(e) => getPassword('Confirm', e)}
                                    type="password"
                                />
                            </div>
                        </div>
                        <div
                            className={
                                (requestMessage &&
                                    requestMessage['password'] &&
                                    requestMessage['password'].error) ||
                                error.password
                                    ? 'req-mess err'
                                    : 'req-mess'
                            }
                        >
                            {error.password
                                ? error.password
                                : requestMessage && requestMessage['password']
                                    ? requestMessage['password'].message
                                    : null}{' '}
                        </div>

                        <div className="action-btn">
                            <Button
                                positive
                                icon="checkmark"
                                labelPosition="right"
                                content="Save"
                                onClick={() => save('password')}
                            />
                        </div>
                    </div>
                </> : null}
        </div>
    );
}

const mapStateToProps = ({ userReducer, requestMessage, userTimeZoneReducer, globalPlayerReducer }) => ({
    user: userReducer,
    zone: userTimeZoneReducer,
    requestMessage,
    permissions: userReducer.permissions,
    toggleType: globalPlayerReducer,

});
const mapDispatchToProps = {
    updateUserProfile,
    setUserConfigs,
    resetRequestMessage,
    setUserTimeZone,
    setUserTimeZoneName,
    setGlobalPlayer,
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
