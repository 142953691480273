import React, { useEffect } from 'react';
import Tabs from '../../components/Tabs';
import {
    getUsersForAclPage,
    getGroupsForAclPage,
    getAllResources,
    getAllUsers,
    getAllGroups,
    editResource,
    changeUserGroupConnection,
} from '../../redux/actions/aclmanagmentAction';
import { resetRequestMessage } from '../../redux/actions/otherActions';
import { connect } from 'react-redux';
import { Button } from 'semantic-ui-react';
import EditResource from '../../components/EditResource';
import ModalComponentContent from '../../components/ModalComponentContent';
import AddResource from '../../components/Add';
import AddGroup from '../../components/Add';
import './acl.css';

function Acl({ ...redux }) {
    const {
        changeUserGroupConnection,
        getUsersForAclPage,
        aclUsersReducer,
        aclGroupsReducer,
        aclResourcesReducer,
        res,
        getGroupsForAclPage,
        getAllResources,
        getAllUsers,
        getAllGroups,
        requestMessage,
        editResource,
        resetRequestMessage,
    } = redux;

    useEffect(() => {
        getUsersForAclPage();
        getGroupsForAclPage();
        getAllUsers();
        getAllGroups();
        let timeoutId, timeoutId1;
        if (!!requestMessage && !requestMessage.error) {
            timeoutId = setTimeout(() => {
                editResource({ open: false, name: null });
                timeoutId1 = setTimeout(() => {
                    resetRequestMessage('');
                }, 500);
            }, 1000);
        } else {
            getAllResources();
        }
        return () => {
            clearTimeout(timeoutId);
            clearTimeout(timeoutId1);
        };
    }, [requestMessage]);

    const add = (type) => {
        editResource({ open: type, name: null });
    };
    const openModal = (id) => {
        editResource({ open: 'add-resource-to-current-group', name: id });
    };
    const close = (id) => {
        editResource({ open: '', name: null });
    };
    const userGroupConnection = (type, userId, groupId) => {
        changeUserGroupConnection({
            type,
            userId,
            groupId,
        });
    };

    return (
        <div className="managment-content">
          {res.open === 'edit' && (
            <EditResource
              title="Edit Resource"
              name={res.name}
              resources={aclResourcesReducer[res.name]}
            />
          )}
          {res.open === 'add-group' && <AddGroup title="Add Group" />}
          {res.open === 'add-resource' && <AddResource title="Add Resource" />}
          {res.open === 'add-resource-to-current-group' &&
          <ModalComponentContent componentName={'Add-Resource'}
                                 title="Add"
                                 name="Resource"
                                 resData={aclResourcesReducer}
                                 groupId={res.name}
                                 close={close}
                                 groups={aclGroupsReducer[res.name]}
          />}
            <div className="page-title">
                <h2>ACL Managment</h2>
                <div>
                    <Button primary className="create-user btn belote-green" onClick={() => add('add-group')}>
                        Add Group
                    </Button>
                </div>
            </div>

            <Tabs
                groups={aclGroupsReducer}
                resources={aclResourcesReducer}
                users={aclUsersReducer}
                userGroupConnection={userGroupConnection}
                openModal={openModal}
            />
        </div>
    );
}

const mapStateToProps = ({
    aclUsersReducer,
    aclGroupsReducer,
    aclResourcesReducer,
    resourceEditorModalControl,
    requestMessage,
}) => ({
    aclUsersReducer,
    aclGroupsReducer,
    aclResourcesReducer,
    res: resourceEditorModalControl,
    requestMessage,
});

const mapDispatchToProps = {
    getUsersForAclPage,
    getGroupsForAclPage,
    getAllResources,
    getAllUsers,
    getAllGroups,
    editResource,
    resetRequestMessage,
    changeUserGroupConnection,
};

export default connect(mapStateToProps, mapDispatchToProps)(Acl);
