import React, { useEffect } from 'react';
import { createNewTournament, openTournament } from '../redux/actions/tournamentsAction';
import { Table } from 'semantic-ui-react';
import { connect } from 'react-redux';


const gameType = {
    "ALLCOMBS": "All combinations",
    "NO4NO100": "Without 4 cards and 100",
    "ONLYBR": "Belote / Rebelote only",
};
function InfoTable({id, ...redux }) {
    const { tournamentData, openTournament } = redux;

    useEffect(()=>{
        openTournament({ id: id });
    },[])

    return (
        <>{
            tournamentData && +tournamentData.id ?
                <Table sortable celled fixed size="small">
                <Table.Header>
                    <Table.Row>
                        <Table.Cell>
                            <h5 style={{ margin: '12px 6px' }}>Tournament info </h5>
                        </Table.Cell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>ID:</Table.Cell>
                        <Table.Cell> &nbsp;{tournamentData.id}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Name:</Table.Cell>
                        <Table.Cell> &nbsp;{tournamentData.name}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Create Date: </Table.Cell>
                        <Table.Cell> &nbsp;{tournamentData.createdAt}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Start Date: </Table.Cell>
                        <Table.Cell> &nbsp;{tournamentData.startDate}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Announce Date: </Table.Cell>
                        <Table.Cell> &nbsp;{tournamentData.announceDate}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Registered Date: </Table.Cell>
                        <Table.Cell> &nbsp;{tournamentData.playerRegDate}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>End  Date: </Table.Cell>
                        <Table.Cell> &nbsp;{tournamentData.finishedAt}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Game Type: </Table.Cell>
                        <Table.Cell> &nbsp;{tournamentData.gameType}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Status: </Table.Cell>
                        <Table.Cell> &nbsp;{tournamentData.state}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Min. Players:</Table.Cell>
                        <Table.Cell> &nbsp;{tournamentData.minPlayers}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Max. Players:</Table.Cell>
                        <Table.Cell> &nbsp;{tournamentData.maxPlayers}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>BuyIn:</Table.Cell>
                        <Table.Cell> &nbsp;{tournamentData.buyIn}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>PrizePool: </Table.Cell>
                        <Table.Cell> &nbsp;{tournamentData.prizePool}</Table.Cell>
                    </Table.Row>

                    <Table.Row>
                        <Table.Cell>Priority In Lobby:</Table.Cell>
                        <Table.Cell> &nbsp;{tournamentData.priorityInLobby}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Highlighted:</Table.Cell>
                        <Table.Cell> &nbsp;{tournamentData.isHighlighted? 'on' :  'off'}</Table.Cell>
                    </Table.Row>

                    <Table.Row>
                        <Table.Cell>Game Points: </Table.Cell>
                        <Table.Cell> &nbsp;{tournamentData.gamePoints}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Game Combs: </Table.Cell>
                        <Table.Cell> &nbsp;{gameType[tournamentData.gameCombs]}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Game Time: </Table.Cell>
                        <Table.Cell> &nbsp;{tournamentData.gameTime}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Disconnect Timer: </Table.Cell>
                        <Table.Cell> &nbsp;{tournamentData.disconnectTimer}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Game No Trump: </Table.Cell>
                        <Table.Cell> &nbsp;{tournamentData.gameNoTrump}</Table.Cell>
                    </Table.Row>


                </Table.Body>
            </Table>
                :<h4> Loading ...</h4>

        }
        </>
    );
}

const mapStateToProps = ({ requestMessage, oneTournamentReducer }) => ({
    requestMessage,
    tournamentData: oneTournamentReducer,
});

const mapDispatchToProps = {
    createNewTournament,
    openTournament
};

export default connect(mapStateToProps, mapDispatchToProps)(InfoTable);
