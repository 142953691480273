import { createReducer } from '../../helpers/general';
import { BAZAR_BLOT_TYPES } from '../actionTypes';

const { SET_BAZAR, SET_CURRENT_BAZAR, SET_BAZAR_LOGS, RESET_BAZAR_LOGS } = BAZAR_BLOT_TYPES;

export const bazarBlotReducer = createReducer([], (state, { value }) => {
    return {
        [SET_BAZAR]: value ? value : state,
    };
});

export const bazarBlotCurrentReducer = (state = null, action) => {
    switch (action.type) {
        case SET_CURRENT_BAZAR:
            return action.payload;
        default:
            return state;
    }
};

export const bazarBlotLogsReducer = createReducer([], (state, { value }) => {
    return {
        [SET_BAZAR_LOGS]: value ? value : state,
        [RESET_BAZAR_LOGS]: [],
    };
});

export default {
    bazarBlotReducer,
    bazarBlotCurrentReducer,
    bazarBlotLogsReducer,
};
