import instance from '../../helpers/Api';
import {MAIN_TYPES, OTHER_TYPES, TICKETS_TYPE, USERS_TYPES} from '../actionTypes';
import { createAction } from '../../helpers/general';
const { SET_TICKETS } = TICKETS_TYPE;
const {REQUEST_MESSAGE} = USERS_TYPES;
const {LOADER_HIDE} = MAIN_TYPES;
const { GET_TOTAL_PAGES_COUNT_NEW } = OTHER_TYPES;


export function getTickets(data) {
    return (dispatch) => {
        instance.post('/api/tickets/get', data)
            .then((res) => {
                // console.log(res, "resss")
                if (!res.data.error) {
                    dispatch({type: SET_TICKETS, value:  res.data.data});
                    dispatch({type: GET_TOTAL_PAGES_COUNT_NEW, value: res.data.data});
                } else {
                    dispatch({type: REQUEST_MESSAGE, value: res.data});
                }
                dispatch(createAction(LOADER_HIDE));
            })
            .catch((e) => {
                console.log('getTickets', e);
            });
    };
}

// export const resetGameLogs = createAction(RESET_GAME_LOGS);
