import React from 'react';
import { Table } from 'semantic-ui-react';
import '../../spin.css'

const SpinCoefficientInfoTable = ({ data, tableInfo }) => {

    return (
        <Table celled fixed size="small" className={'blacklist black'}>
            <Table.Header>
                <Table.Row>
                    <Table.Cell colSpan={9} className="t-title">
                        {tableInfo.title}
                    </Table.Cell>
                </Table.Row>
                <Table.Row className={'tr-spin-bg-color'}>
                    <Table.Cell>
                        Prize Pool
                    </Table.Cell>
                    {data.length ? data.map((item, key) => {
                        return (
                            <Table.Cell key={key}>
                                {`X ${item.x}`}
                            </Table.Cell>
                        )
                    }) : null
                    }
                </Table.Row>
            </Table.Header>
            <Table.Body>
                <Table.Row>
                    <Table.Cell>
                        {data.length && data[0] ? data[0]['prizePool'] : null}
                    </Table.Cell>
                    {data ? data.map((item, key) => {
                        return (
                            <Table.Cell key={key}>
                                {item.ratio}
                            </Table.Cell>
                        )
                    }) : null
                    }
                </Table.Row>
            </Table.Body>
        </Table>
    )
}

export default SpinCoefficientInfoTable