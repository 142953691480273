import React,{useState} from 'react';
import { List } from 'semantic-ui-react';
import { connect } from 'react-redux';
import {Checkbox} from 'semantic-ui-react';
import {saveResourceChanginq} from '../../redux/actions/aclmanagmentAction'

const ResList = ({ data, resNames, ownResources ,...redux}) => {
  const {saveResourceChanginq}=redux
  const [checked, setChecked]=useState(false)

  const change=(e,id)=>{
    const obj = {
      users: {
        toDelete: [],
        toAdd: []
      },
      groups: {toDelete: [], toAdd: []}, resourceId: id
    }
    if(e.target.checked) {
      obj['groups']={
        ... obj['groups'],
        toAdd:[ownResources.id]
      }
      setChecked(id)
    }
    else{
      obj['groups']={
        ... obj['groups'],
        toDelete:[ownResources.id]
      }
      setChecked(e.target.checked)
    }
    saveResourceChanginq(obj)
     }

  return (
    <List celled>
      {resNames
        ? resNames.map((item, key) => {
          return (
            <List.Item key={key}>
              <List.Content className="own">
                {item}
                <div className="resource-edit margin-12-acl" >
                <input type="checkbox"  checked={checked === data[item].id || ownResources['resources'].includes(item)} onChange={(e)=>change(e,data[item].id)}/>
                </div>
              </List.Content>
            </List.Item>
          );
        })
        : null}
    </List>
  );
};

const mapStateToProps = ({ }) => ({})

const mapDispatchToProps = {
  saveResourceChanginq

};
export default connect(mapStateToProps, mapDispatchToProps)(ResList);
