import React, { Component, useEffect, useRef } from 'react';
import { Input, Button, Modal, Select } from 'semantic-ui-react';
import { createNewTournament } from '../redux/actions/tournamentsAction';
import { connect } from 'react-redux';
import { TimeInput } from '@trendmicro/react-datepicker';

import '@trendmicro/react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import DatePick from './Datapiker/DataPicker';

const gameObj = [
    { key: '11', text: 'CLASSIC', value: 'CLASSIC' },
    { key: '22', text: 'OPEN', value: 'OPEN' },
];

const gameType = [
    { key: '1', text: 'Created', value: 'Created' },
    { key: '2', text: 'Scheduled', value: 'Scheduled' },
    { key: '3', text: 'Started', value: 'Started' },
    { key: '4', text: 'InReg', value: 'InReg' },
    { key: '5', text: 'RegClosed', value: 'RegClosed' },
    { key: '6', text: 'Finished', value: 'Finished' },
    { key: '7', text: 'Cancelled', value: 'Cancelled' },
    { key: '8', text: 'Announced', value: 'Announced' },
    { key: '9', text: 'Busy', value: 'Busy' },
    { key: '10', text: 'Crushed', value: 'Crushed' },
];
const gameTipe = {
    'CLASSIC': 1,
    'OPEN': 2,
};
const gameState = {
    'Created': 1,
    'Scheduled': 2,
    'Started': 3,
    'InReg': 4,
    'RegClosed': 5,
    'Finished': 6,
    'Cancelled': 7,
    'Announced': 8,
    'Busy': 9,
    'Crushed': 10,

};

class FilterTournament extends Component {
    constructor(props) {
        super(props);
        this.refTo = React.createRef();
        this.refFrom = React.createRef();
    }

    state = {
        minPlayers: '',
        maxPlayers: '',
        buyInTo: '',
        buyInFrom: '',
        PrizePoolFrom: '',
        PrizePoolTo: '',
        selectedGame: 0,
        selected: 0,
        status: '',

        open: true,
        dimmer: 'blurring',

        createDateFrom: moment().format('00-00-0000'),
        createTimeFrom: moment().format('00:00:00'),

        createDateTo: moment().format('00-00-0000'),
        createTimeTo: moment().format('00:00:00'),

        scheduleDateFrom: moment().format('00-00-0000'),
        scheduleTimeFrom: moment().format('00:00:00'),

        scheduleDateTo: moment().format('00-00-0000'),
        scheduleTimeTo: moment().format('00:00:00'),

        regDateFrom: moment().format('00-00-0000'),
        regTimeFrom: moment().format('00:00:00'),

        regDateTo: moment().format('00-00-0000'),
        regTimeTo: moment().format('00:00:00'),

        anounceDateFrom: moment().format('00-00-0000'),
        anounceTimeFrom: moment().format('00:00:00'),

        anounceDateTo: moment().format('00-00-0000'),
        anounceTimeTo: moment().format('00:00:00'),

        endDateFrom: moment().format('00-00-0000'),
        endTimeFrom: moment().format('00:00:00'),

        endDateTo: moment().format('00-00-0000'),
        endTimeTo: moment().format('00:00:00'),

        error: { errName: '', errPass: '' },
        tournamentName: '',
        tournamentId: '',

    };


    search = () => {
        const {
            createDateFrom,
            createTimeFrom,
            createDateTo,
            createTimeTo,
            scheduleDateFrom,
            scheduleDateTo,
            scheduleTimeFrom,
            scheduleTimeTo,
            tournamentName,
            tournamentId,
            buyInTo,
            buyInFrom,
            PrizePoolFrom,
            PrizePoolTo,
            regDateFrom,
            regTimeFrom,
            regDateTo,
            regTimeTo,
            anounceDateFrom,
            anounceTimeFrom,
            anounceDateTo,
            anounceTimeTo,
            endDateFrom,
            endTimeFrom,
            endDateTo,
            endTimeTo,
            game,
            status,
        } = this.state;

        const { makeSearch } = this.props;

        const data = {
            createdAtFrom:
                createDateFrom === '00-00-0000' ? '' : createDateFrom + ' ' + createTimeFrom,
            createdAtTo: createDateTo === '00-00-0000' ? '' : createDateTo + ' ' + createTimeTo,
            scheduledAtFrom:
                scheduleDateFrom === '00-00-0000' ? '' : scheduleDateFrom + ' ' + scheduleTimeFrom,
            scheduledAtTo:
                scheduleDateTo === '00-00-0000' ? '' : scheduleDateTo + ' ' + scheduleTimeTo,

            regFrom:
                regDateFrom === '00-00-0000' ? '' : regDateFrom + ' ' + regTimeFrom,
            regTo:
                regDateTo === '00-00-0000' ? '' : regDateTo + ' ' + regTimeTo,
            announceOpenFrom:
                anounceDateFrom === '00-00-0000' ? '' : anounceDateFrom + ' ' + anounceTimeFrom,
            announceOpenTo:
                anounceDateTo === '00-00-0000' ? '' : anounceDateTo + ' ' + anounceTimeTo,
            endFrom:
                endDateFrom === '00-00-0000' ? '' : endDateFrom + ' ' + endTimeFrom,
            endTo:
                endDateTo === '00-00-0000' ? '' : endDateTo + ' ' + endTimeTo,

            // minPlayers: minPlayers ? +minPlayers : '',
            // maxPlayers: maxPlayers ? +maxPlayers : '',
            showPicker: '',
            name: tournamentName,
            id: tournamentId,
            buyInTo,
            buyInFrom,
            PrizePoolFrom,
            PrizePoolTo,
            kind: game ? gameTipe[game] : '',
            state: status ? gameState[status] : '',
        };

        makeSearch(data);
    };

    resetAll = () => {
        const { reset } = this.props;
        this.setState({
            selectedGame: 0,
            selected: 0,
            tournamentName: '',
            tournamentId: '',
            createDateFrom: moment().format('00-00-0000'),
            createTimeFrom: moment().format('00:00:00'),

            createDateTo: moment().format('00-00-0000'),
            createTimeTo: moment().format('00:00:00'),

            scheduleDateFrom: moment().format('00-00-0000'),
            scheduleTimeFrom: moment().format('00:00:00'),

            scheduleDateTo: moment().format('00-00-0000'),
            scheduleTimeTo: moment().format('00:00:00'),

            regDateFrom: moment().format('00-00-0000'),
            regTimeFrom: moment().format('00:00:00'),

            regDateTo: moment().format('00-00-0000'),
            regTimeTo: moment().format('00:00:00'),

            anounceDateFrom: moment().format('00-00-0000'),
            anounceTimeFrom: moment().format('00:00:00'),

            anounceDateTo: moment().format('00-00-0000'),
            anounceTimeTo: moment().format('00:00:00'),

            endDateFrom: moment().format('00-00-0000'),
            endTimeFrom: moment().format('00:00:00'),

            endDateTo: moment().format('00-00-0000'),
            endTimeTo: moment().format('00:00:00'),

            showPicker: '',
            name: '',
            id: '',
            buyInTo: '',
            buyInFrom: '',
            PrizePoolFrom: '',
            PrizePoolTo: '',
            kind: '',
            state: '',
        });

        reset();
    };

    getSelectValue = (name, data) => {
        if (data.value) {
            if (name === 'game') {
                this.setState({ game: data.value });
                this.setState({ selectedGame: 1 });

            } else {
                this.setState({ status: data.value });
                this.setState({ selected: 1 });
            }
        } else {
            if (name === 'game') {
                this.setState({ game: '' });
                this.setState({ selectedGame: 0 });
            } else {
                this.setState({ selected: 0 });
                this.setState({ status: '' });
            }
        }
    };


    getTime = (type, value, label) => {
        let prop = type + 'Time' + label;
        this.setState({
            [prop]: value,
        });
    };
    openPicker = (e) => {
        this.setState({
            showPicker: e,
        });
    };
    getState = (e, stateName) => {
        this.setState({
            [stateName]: e.target.value,
        });
    };
    closeFromDatePicker = (type, value, label) => {
        let prop = type + 'Date' + label;
        this.setState({
            [prop]: value,
            showPicker: '',
        });
    };


    componentDidMount() {
        document.addEventListener('click', this.handleClick);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClick);
    }

    render() {
        const {
            selectedGame,
            game,
            createDateFrom,
            createTimeFrom,
            createDateTo,
            createTimeTo,
            scheduleDateFrom,
            scheduleDateTo,
            scheduleTimeFrom,
            scheduleTimeTo,
            tournamentName,
            tournamentId,
            showPicker,
            buyInTo,
            buyInFrom,
            PrizePoolFrom,
            PrizePoolTo,
            regDateFrom,
            regTimeFrom,
            regDateTo,
            regTimeTo,
            anounceDateFrom,
            anounceTimeFrom,
            anounceDateTo,
            anounceTimeTo,
            endDateFrom,
            endTimeFrom,
            endDateTo,
            endTimeTo,
            selected,
            status,
        } = this.state;

        const { requestMessage } = this.props;

        return (
            <>
                <div className="filter-inputs input-box-setting flex-wrap margin-top-20">

                    <div className="input-box">
                        <div>
                            <Input
                                label="ID:"
                                value={tournamentId}
                                onChange={(e) => this.getState(e, 'tournamentId')}
                                type="number"
                            />
                        </div>
                        <div>
                            <Input
                                label="Name:"
                                value={tournamentName}
                                onChange={(e) => this.getState(e, 'tournamentName')}
                                type="text"
                            />
                        </div>
                    </div>


                    <div className="input-box-search-tour">
                        <div className="margin-12">
                            <label className='label'>Created At:</label>
                            <div className='label'>
                                From:
                                <div className="date-from">
                                    <div className="ui left icon input date">
                                        <input
                                            ref={this.refFrom}
                                            type="text"
                                            size={10}
                                            value={createDateFrom}
                                            onClick={() => this.openPicker('from')}
                                            onChange={() => this.openPicker('from')}
                                        />
                                        <i aria-hidden="true" className="calendar icon"/>
                                    </div>
                                    {showPicker === 'from' && (
                                        <DatePick
                                            close={this.closeFromDatePicker}
                                            value={createDateFrom}
                                            type="create"
                                            label="From"
                                        />
                                    )}
                                    <div className="left">
                                        <TimeInput
                                            value={createTimeFrom}
                                            onChange={(value) =>
                                                this.getTime('create', value, 'From')
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='label'>
                                To:
                                <div className="date-from">
                                    <div className="ui left icon input date">
                                        <input
                                            ref={this.refTo}
                                            type="text"
                                            size={10}
                                            value={createDateTo}
                                            onClick={() => this.openPicker('to')}
                                            onChange={() => this.openPicker('to')}
                                        />
                                        <i aria-hidden="true" className="calendar icon"/>
                                    </div>
                                    {showPicker === 'to' && (
                                        <DatePick
                                            close={this.closeFromDatePicker}
                                            value={createDateTo}
                                            type="create"
                                            label="To"
                                        />
                                    )}
                                    <div className="left">
                                        <TimeInput
                                            value={createTimeTo}
                                            onChange={(value) =>
                                                this.getTime('create', value, 'To')
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="margin-12">
                            <label className='label'>Start date:</label>
                            <div className='label'>
                                From:
                                <div className="date-from">
                                    <div className="ui left icon input date">
                                        <input
                                            ref={this.refFrom}
                                            type="text"
                                            size={10}
                                            value={scheduleDateFrom}
                                            onClick={() => this.openPicker('schedule-from')}
                                            onChange={() => this.openPicker('schedule-from')}
                                        />
                                        <i aria-hidden="true" className="calendar icon"/>
                                    </div>
                                    {showPicker === 'schedule-from' && (
                                        <DatePick
                                            close={this.closeFromDatePicker}
                                            value={scheduleDateFrom}
                                            type="schedule"
                                            label="From"
                                        />
                                    )}
                                    <div className="left">
                                        <TimeInput
                                            value={scheduleTimeFrom}
                                            onChange={(value) =>
                                                this.getTime('schedule', value, 'From')
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='label'>
                                To:
                                <div className="date-from">
                                    <div className="ui left icon input date">
                                        <input
                                            ref={this.refTo}
                                            type="text"
                                            size={10}
                                            value={scheduleDateTo}
                                            onClick={() => this.openPicker('schedule-to')}
                                            onChange={() => this.openPicker('schedule-to')}
                                        />
                                        <i aria-hidden="true" className="calendar icon"/>
                                    </div>
                                    {showPicker === 'schedule-to' && (
                                        <DatePick
                                            close={this.closeFromDatePicker}
                                            value={scheduleDateTo}
                                            type="schedule"
                                            label="To"
                                        />
                                    )}
                                    <div className="left">
                                        <TimeInput
                                            value={scheduleTimeTo}
                                            onChange={(value) =>
                                                this.getTime('schedule', value, 'To')
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="margin-12">
                            <label className='label'>Anouncement Date:</label>
                            <div className='label'>
                                From:
                                <div className="date-from">
                                    <div className="ui left icon input date">
                                        <input
                                            ref={this.refFrom}
                                            type="text"
                                            size={10}
                                            value={anounceDateFrom}
                                            onClick={() => this.openPicker('anounceDateFrom')}
                                            onChange={() => this.openPicker('anounceDateFrom')}
                                        />
                                        <i aria-hidden="true" className="calendar icon"/>
                                    </div>
                                    {showPicker === 'anounceDateFrom' && (
                                        <DatePick
                                            close={this.closeFromDatePicker}
                                            value={anounceDateFrom}
                                            type="anounce"
                                            label="From"
                                        />
                                    )}
                                    <div className="left">
                                        <TimeInput
                                            value={anounceTimeFrom}
                                            onChange={(value) =>
                                                this.getTime('anounce', value, 'From')
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='label'>
                                To:
                                <div className="date-from">
                                    <div className="ui left icon input date">
                                        <input
                                            ref={this.refTo}
                                            type="text"
                                            size={10}
                                            value={anounceDateTo}
                                            onClick={() => this.openPicker('anounceDateTo')}
                                            onChange={() => this.openPicker('anounceDateTo')}
                                        />
                                        <i aria-hidden="true" className="calendar icon"/>
                                    </div>
                                    {showPicker === 'anounceDateTo' && (
                                        <DatePick
                                            close={this.closeFromDatePicker}
                                            value={anounceDateTo}
                                            type="anounce"
                                            label="To"
                                        />
                                    )}
                                    <div className="left">
                                        <TimeInput
                                            value={anounceTimeTo}
                                            onChange={(value) =>
                                                this.getTime('anounce', value, 'To')
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="margin-12">
                            <label className="label">Registration Date:</label>
                            <div className="label">
                                From:
                                <div className="date-from">
                                    <div className="ui left icon input date">
                                        <input
                                            ref={this.refFrom}
                                            type="text"
                                            placeholder="Choose date..."
                                            size={10}
                                            value={regDateFrom}
                                            onClick={() => this.openPicker('regFromDate')}
                                            onChange={() => this.openPicker('regFromDate')}

                                        />
                                        <i aria-hidden="true" className="calendar icon"/>
                                    </div>
                                    {showPicker === 'regFromDate' && (
                                        <DatePick
                                            close={this.closeFromDatePicker}
                                            value={regDateFrom}
                                            type="reg"
                                            label="From"
                                        />
                                    )}

                                    <div className='left'>
                                        <TimeInput
                                            value={regTimeFrom}
                                            onChange={(value) =>
                                                this.getTime('reg', value, 'From')
                                            }

                                        />
                                    </div>
                                </div>

                                <div className="label">
                                    To:
                                    <div className="date-to">
                                        <div className="ui left icon input date">
                                            <input
                                                ref={this.refTo}
                                                type="text"
                                                placeholder="Choose date..."
                                                size={10}
                                                value={regDateTo}
                                                onClick={() => this.openPicker('regDateTo')}
                                                onChange={() => this.openPicker('regDateTo')}
                                            />
                                            <i aria-hidden="true" className="calendar icon"/>
                                        </div>
                                        {showPicker === 'regDateTo' && (
                                            <DatePick
                                                close={this.closeFromDatePicker}
                                                type="reg"
                                                label="To"
                                                value={regDateTo}/>
                                        )}
                                        <div className='left'>
                                            <TimeInput value={regTimeTo}
                                                       onChange={(value) =>
                                                           this.getTime('reg', value, 'To')
                                                       }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="margin-12">
                            <label className="label">End Date:</label>
                            <div className="label">
                                From:
                                <div className="date-from">
                                    <div className="ui left icon input date">
                                        <input
                                            ref={this.refFrom}
                                            type="text"
                                            placeholder="Choose date..."
                                            size={10}
                                            value={endDateFrom}
                                            onClick={() => this.openPicker('endDateFrom')}
                                            onChange={() => this.openPicker('endDateFrom')}

                                        />
                                        <i aria-hidden="true" className="calendar icon"/>
                                    </div>
                                    {showPicker === 'endDateFrom' && (
                                        <DatePick
                                            close={this.closeFromDatePicker}
                                            value={endDateFrom}
                                            type="end"
                                            label="From"
                                        />
                                    )}

                                    <div className='left'>
                                        <TimeInput
                                            value={endTimeFrom}
                                            onChange={(value) =>
                                                this.getTime('end', value, 'From')
                                            }

                                        />
                                    </div>
                                </div>

                                <div className="label">
                                    To:
                                    <div className="date-to">
                                        <div className="ui left icon input date">
                                            <input
                                                ref={this.refTo}
                                                type="text"
                                                placeholder="Choose date..."
                                                size={10}
                                                value={endDateTo}
                                                onClick={() => this.openPicker('endDateTo')}
                                                onChange={() => this.openPicker('endDateTo')}
                                            />
                                            <i aria-hidden="true" className="calendar icon"/>
                                        </div>
                                        {showPicker === 'endDateTo' && (
                                            <DatePick
                                                close={this.closeFromDatePicker}
                                                type="end"
                                                label="To"
                                                value={endDateTo}/>
                                        )}
                                        <div className='left'>
                                            <TimeInput value={endTimeTo}
                                                       onChange={(value) =>
                                                           this.getTime('end', value, 'To')
                                                       }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="check-box">
                            <div className='fl-left '>
                                <div className="label">Select game:</div>
                                {!selectedGame ? <>
                                    {game ? null : null}
                                    <Select
                                        selectOnBlur={false}
                                        clearable
                                        placeholder={'Not selected'}
                                        options={gameObj}
                                        onChange={(e, data) => this.getSelectValue('game', data)}
                                    /></> : <Select
                                    selectOnBlur={false}
                                    clearable
                                    placeholder={game}
                                    options={gameObj}
                                    onChange={(e, data) => this.getSelectValue('game', data)}
                                />
                                }
                            </div>
                            <div className='fl-left margin-l-20'>
                                <div className="label">Status:</div>
                                {!selected ? <>
                                    {status ? null : null}
                                    <Select
                                        selectOnBlur={false}
                                        clearable
                                        placeholder={'Choose Status'}
                                        options={gameType}
                                        onChange={(e, data) => this.getSelectValue('gameType', data)}
                                    /></> : <Select
                                    selectOnBlur={false}
                                    clearable
                                    placeholder={status}
                                    options={gameType}
                                    onChange={(e, data) => this.getSelectValue('gameType', data)}
                                />
                                }
                            </div>

                        </div>

                    </div>
                    <div className="check-box">
                        <div>
                            <div className="label">Buy In:</div>
                            <div className="item">
                                <Input
                                    label="From:"
                                    value={buyInFrom}
                                    onChange={(e) => this.getState(e, 'buyInFrom')}
                                    type="number"
                                    min={1}
                                    size={'mini'}
                                />
                                <Input
                                    label="To:"
                                    value={buyInTo}
                                    onChange={(e) => this.getState(e, 'buyInTo')}
                                    type="number"
                                    min={1}
                                    size={'mini'}
                                />
                            </div>
                        </div>
                        <div>
                            <div className="label">PrizePool:</div>
                            <div className="item">
                                <Input
                                    label="From:"
                                    value={PrizePoolFrom}
                                    onChange={(e) => this.getState(e, 'PrizePoolFrom')}
                                    type="number"
                                    min={1}
                                    size={'mini'}
                                />
                                <Input
                                    label="To:"
                                    value={PrizePoolTo}
                                    onChange={(e) => this.getState(e, 'PrizePoolTo')}
                                    type="number"
                                    min={1}
                                    size={'mini'}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="filter-div">
                        <Button primary className="filter-btn belote-green" onClick={this.search}>
                            Filter
                        </Button>

                        <Button className="filter-btn" onClick={this.resetAll}>
                            Reset All
                        </Button>
                    </div>
                    <div style={{ minHeight: '20px' }}>
                        <div className={requestMessage.error ? 'req-mess err' : 'req-mess'}>
                            {requestMessage.message}
                        </div>
                    </div>

                </div>
            </>
        );
    }
}

const mapStateToProps = ({ requestMessage }) => ({
    requestMessage,
});

const mapDispatchToProps = {
    createNewTournament,
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterTournament);
