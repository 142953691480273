import './user.css';
import React, { useState, useEffect } from 'react';
import SortableUseraTable from '../../components/SortableUsersTable';
import {Button, Confirm, Loader, Pagination, Segment, Select} from 'semantic-ui-react';
import CreateUser from '../../components/CreateUser';
import EditUser from '../../components/EditUser';
import { getAllUsers, deleteUser } from '../../redux/actions/usersActions';
import { resetRequestMessage } from '../../redux/actions/otherActions';
import { getAllGroups } from '../../redux/actions/aclmanagmentAction';
import { connect } from 'react-redux';
import p from "../../permissionsConstants";
import {loaderReducer} from "../../redux/reducers/mainReducer";
const {createUserBtn} = p;

const rowItems = [
  // { key: '10', text: '10 rows', value: '10' },
  // { key: '30', text: '20 rows', value: '20' },
  { key: '50', text: '50 rows', value: '50' },
  { key: '100', text: '100 rows', value: '100' },
];


function User({ getAllUsers, usersDataReducer, ...redux }) {
    const { totalPagesReducer, resetRequestMessage, loggedInUserId, requestMessage, deleteUser,getAllGroups,permissions,loader } = redux;
    const [showCreateUserModal, setCreateUserModal] = useState(false);
    const [showEditUserModal, setEditUserModal] = useState(false);
    const [sortedField, setSortedField] = useState('');
    const [direction, setDirection] = useState('DESC');
    const [activePage, setActivePage] = useState('');
    const [rowCount, setRowCount] = useState(0);
    const [currentUser, setCurrentUser] = useState(null);
    const [userId, setUserId] = useState(null);
    const [limit, setLimit] = useState(50);
    const [offset, setOffset] = useState(0);
    const [filerDateForSorting, setFilerDateForSorting] = useState({});
    const [confirmModal, setOpenConfirmModal] = useState(false);

    const data = {
        title: { create: 'Create New User', edit: 'Edit User' },
    };
    const openModal = () => {
        setCreateUserModal(true);
    };
    const openEditModal = (id) => {
        setEditUserModal(true);
        let data = usersDataReducer.filter((el) => el.id === id);
        setCurrentUser(data[0]);
    };
    const openConfirmModal = (id) => {
        setUserId(id);
        setOpenConfirmModal(true)
         };
  const deleteCurrentUser = () => {
        deleteUser({id:userId});
       setOpenConfirmModal(false)
    };

    const closeModal = () => {
        if (!!requestMessage) resetRequestMessage('');
        setEditUserModal(false);
    };
    const closeCreateModal = (isEdit) => {
        if (!!requestMessage) resetRequestMessage('');
        setCreateUserModal(false);
    };

    useEffect(() => {
        let timeoutId;
        getAllUsers({ limit, offset, ...filerDateForSorting });
        getAllGroups();
        if (!!requestMessage && !requestMessage.error) {
            timeoutId = setTimeout(() => {
                showEditUserModal ? setEditUserModal(false) : setCreateUserModal(false);
                resetRequestMessage('');
            }, 1500);
        }
        return () => {
            clearTimeout(timeoutId);
        };
    }, [requestMessage]);

    const onPageChange = (e, data) => {
        getAllUsers({
          offset: (data.activePage - 1) * limit,
          orderBy: sortedField,
          order: direction,
          ...filerDateForSorting,
          limit,
        });
        setOffset((data.activePage - 1) * limit);
        setActivePage(data.activePage);
        setRowCount(() => data.activePage * limit - limit);
    };

    const sorting = (field, direction) => {
        getAllUsers({
          orderBy: field,
          order: direction,
          ...filerDateForSorting,
          limit,
          offset: 0
        });
        setSortedField(field);
        setActivePage(1);
        setDirection(direction);
        setRowCount(0);
    };
      const getRowCount = (e, data) => {
        let activePage = Math.floor(offset / limit) + 1;
        setLimit(+data.value);
        getAllUsers({
          limit: +data.value,
          offset: data.value > rowCount ? 0 : +data.value,
          ...filerDateForSorting,
        });
        setRowCount(0);
        setActivePage(activePage);
      };
    return (
        <div className="user-content">
            {confirmModal ?
                <Confirm
                    className={'own-confirm'}
                    open={confirmModal}
                    content='Are you sure, you want to delete this user?'
                    onCancel={()=>setOpenConfirmModal(false)}
                    onConfirm={deleteCurrentUser}
                    confirmButton={'Delete'}
                />: null}
            <div className="page-title">
                <h2>Users List</h2>
              <div className={requestMessage.error ? 'req-mess err' : 'req-mess'}>
                {requestMessage.message}
              </div>
                {permissions && permissions[createUserBtn] ?
                    <Button primary className="create-user btn belote-green" onClick={() => openModal('create')}>
                        Create User
                    </Button>:null
                }
                    </div>
            {showCreateUserModal ? (
                <CreateUser close={closeCreateModal} title={data.title.create} />
            ) : null}
            {showEditUserModal ? (
                <EditUser close={closeModal} title={data.title.edit} user={currentUser} />
            ) : null}
            {!loader?<>
            <div className="row-count">
                <div className="label">Rows count per page</div>
                <div>
                    <Select
                        selectOnBlur={false}
                      className="own"
                      placeholder={`${limit} rows`}
                      options={rowItems}
                      onChange={getRowCount}
                    />
                </div>
            </div>
            <div className='table-and-pg'>
            <SortableUseraTable
                data={usersDataReducer.filter((el) => el.id !== loggedInUserId)}
                sorting={sorting}
                count={rowCount}
                openEditModal={openEditModal}
                deleteCurrentUser={openConfirmModal}
            />
            <Pagination
                activePage={activePage}
                totalPages={totalPagesReducer}
                className="pagi-own"
                onPageChange={totalPagesReducer > 1 ? onPageChange : null}
            />
            </div>
            </>: <Loader active> Loading users list ... </Loader>}
        </div>
    );
}

const mapStateToProps = ({ userReducer, usersDataReducer, totalPagesReducer, requestMessage, loaderReducer }) => ({
    usersDataReducer,
    totalPagesReducer,
    loggedInUserId: userReducer.id,
    requestMessage,
    permissions:userReducer.permissions,
    loader: loaderReducer,
});

const mapDispatchToProps = {
    getAllUsers,
    resetRequestMessage,
    deleteUser,
    getAllGroups
};

export default connect(mapStateToProps, mapDispatchToProps)(User);
