import React, { Component, Suspense } from 'react';
import '../styles/global.scss';
import '../styles/desktop.scss';
import { Tab } from 'semantic-ui-react';
import Logs from '../components/LogsTable';
import GameHistory from './GameHistory/GameHistory';
import Replay from './GameHistory/components/Replay/Replay';
import CurrentGame from '../pages/game/CurrentGame';
import CurrentBazar from '../pages/bazarBlot/CurrentBazar';
import LogsTableBazar from './LogsTableBazar';

// const CurrentGame = React.lazy(() => import("../pages/game/CurrentGame"));

class GameTabs extends Component {
    constructor(props) {
        super(props);
        this.wrapperRef = React.createRef().current;
        this.forCenterGameRef = React.createRef().current;
        this.state = {
            color: 'blue',
            games: [],
            isGame: props.isGame,
        };
        this.panes = [
            {
                menuItem: 'Info',
                render: () => (
                    <Tab.Pane attached={false}>
                        {this.state.isGame ? (
                            <CurrentGame
                                id={this.props.id}
                                gameData={this.props}
                                gameProps={this.state.games}
                            />
                        ) : (
                            <CurrentBazar id={this.props.id} gameData={this.props.oneGame} />
                        )}
                    </Tab.Pane>
                ),
            },
            {
                menuItem: 'Logs',
                render: () => (
                    <Tab.Pane attached={false}>
                        {this.state.isGame ? (
                            <Logs gameId={props.id} logFor={'game'} db={'tableId'} />
                        ) : (
                            <></>
                            // <LogsTableBazar gameId={props.id} logFor={'bazar'} db={'tableId'} />
                        )}
                    </Tab.Pane>
                ),
            },
            this.state.isGame && {
                menuItem: 'Replay',
                render: () => (
                    <Tab.Pane attached={false}>
                        <div className={`for-center-game`} ref={this.forCenterGameRef}>
                            <div ref={this.wrapperRef} className="wrapper--blot-field">
                                <GameHistory
                                    isActive={true}
                                    gameId={props.id}
                                    gameData={this.state.games}
                                />
                            </div>
                        </div>
                    </Tab.Pane>
                ),
            },
            // {
            //     menuItem: 'Rounds',
            //     render: () => (
            //         <Tab.Pane attached={false}>
            //             -
            //             {/*<ShowAllRounds tournamentId={props.id}/>*/}
            //         </Tab.Pane>
            //     ),
            // },
        ];
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.oneGame !== this.props.oneGame) {
            this.setState({ games: [...this.props.oneGame] });
        }
    }

    render() {
        const { color } = this.state;
        return (
            <Tab
                menu={{ color, inverted: true, attached: false, tabular: false }}
                panes={this.panes}
                style={{ marginTop: '3%' }}
            />
        );
    }
}

export default GameTabs;
