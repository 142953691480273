import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { setClass } from '../../../../../helpers/game';
import { emitSpectateGame } from '../../../../../api/emitters';

function InfoPlayers({ players, onCloseClick, userId, isInGameScene }) {
    const { registrated } = players;
    const { t } = useTranslation();
    const [playerList, setPlayerList] = useState([]);
    const [filteredPlayerList, setFilteredPlayerList] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const inputRef = useRef(null);
    const [inGamePlayers, setInGamePlayers] = useState([]);

    useEffect(() => {
        if (inputRef.current && isSearchOpen) {
            inputRef.current.focus();
        }
    }, [inputRef, isSearchOpen]);

    useEffect(() => {
        const { registratedPlayers, details } = players;
        const visiblePlayers = !!details.length ? details : registratedPlayers;
        setPlayerList(visiblePlayers);
    }, [players]);

    useEffect(() => {
        if (playerList.length) {
            const filteredPlayers = playerList.filter((player) => player.remoteId.toString().includes(inputValue));
            setFilteredPlayerList(filteredPlayers);
            const notLostPlayers = playerList.filter((player) => player.lost === false && !!player.roomId);
            setInGamePlayers(notLostPlayers);
        }
    }, [inputValue, playerList]);

    return (
        <div className="players-information players-information--special">
            {/*<h2 className="players-information__title">{t('Players')}</h2>*/}
            <ul className="players-information__statistics-list">
                <li className="players-information__statistics-item">
                    <span className="players-information__statistics-key">{t('Registered')}</span>
                    <span className="players-information__statistics-value">{registrated}</span>
                </li>
                <li className="players-information__statistics-item">
                    <span className="players-information__statistics-key">{t('Now Playing')}</span>
                    <span className="players-information__statistics-value">{inGamePlayers.length}</span>
                </li>
            </ul>
            <ul className="players-information__list">
                <li className="players-information__item players-information__item--header">
                    <span className="player-condition__number">{t('Number')}</span>
                    <span className="player-condition__id">
                        <input
                            onBlur={() => setIsSearchOpen(false)}
                            type="text"
                            ref={inputRef}
                            className="player-condition__id-search-container-input"
                            onChange={(e) => setInputValue(e.target.value)}
                            style={{ display: isSearchOpen ? 'block' : 'none' }}
                        />
                        {!isSearchOpen && (
                            <button
                                type="button"
                                className="player-condition__id-search-container-button"
                                onClick={() => setIsSearchOpen(true)}
                            >
                                {t('Player')}
                            </button>
                        )}
                    </span>
                    <span className="player-condition__status">{t('Status')}</span>
                </li>
                {filteredPlayerList.map(({ remoteId, id, status, lost, roomId }, i) => {
                    const isInGame = lost === false;
                    const isHighlighted = id === userId;
                    return (
                        <PlayerItem
                            roomId={roomId}
                            visualId={remoteId}
                            num={i + 1}
                            status={getStatusText(status, lost, t)}
                            t={t}
                            isInGame={isInGame}
                            userId={userId}
                            isInGameScene={isInGameScene}
                            isHighlighted={isHighlighted}
                        />
                    );
                })}
            </ul>
            {!!onCloseClick && (
                <button type="button" className="players-information__close-button" onClick={onCloseClick}>
                    <span className="visually-hidden">close player information container</span>
                </button>
            )}
        </div>
    );
}

function getStatusText(status, lost, t) {
    let statusText = '';
    if (lost) statusText = t('Place');
    if (!status) statusText = t('Registered');
    if (!!status) {
        let temp = status;
        if (status === '1/1') {
            temp = t('Final');
        }
        statusText = statusText + ' ' + temp;
    }

    return statusText;
}

function PlayerItem({ status, visualId, roomId, num, t, isInGame, isHighlighted, isInGameScene }) {
    const canSpectate = !!roomId && !isHighlighted && !isInGameScene;
    const isButtonVisible = isInGame && !isInGameScene && !isHighlighted;

    return (
        <li
            className={`players-information__item ${setClass(
                isButtonVisible,
                'players-information__item--with-button'
            )} ${setClass(isHighlighted, 'players-information__item--green')}`}
        >
            <span className="player-condition__number">{num}</span>
            <span className="player-condition__id">
                <span className="player-condition__id-search-container">{visualId}</span>
            </span>
            <span className="player-condition__status">
                <span className="player-condition__status-text">{status}</span>
                {
                    <button
                        type="button"
                        className="player-condition__status-button"
                        onClick={handleViewButton}
                        disabled={!roomId}
                    >
                        {!!roomId ? t('View') : t('Waiting')}
                    </button>
                }
            </span>
        </li>
    );

    function handleViewButton() {
        if (canSpectate) {
            emitSpectateGame({ gameId: roomId.toString() });
        }
    }
}

export default InfoPlayers;
