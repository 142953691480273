import React from 'react';
import { useTranslation } from 'react-i18next';

function InfoStatusKnock({ status }) {
    const { t } = useTranslation();

    return (
        <div className="tour-info__info-row">
            <div className="tour-info__info-cell tour-info__info-cell--left">{t('Status')}</div>
            <div className="tour-info__info-cell tour-info__info-cell--right">
                <span className={`game-connection-status game-connection-status--${status}`}>
                    <span className="game-connection-status__span game-connection-status__span--none">-</span>
                    <span className="game-connection-status__span game-connection-status__span--registration">
                        {t('Registration')}
                    </span>
                    <span className="game-connection-status__span game-connection-status__span--finished">
                        {t('Finished')}
                    </span>
                    <span className="game-connection-status__span game-connection-status__span--started">
                        {t('Started')}
                    </span>
                    <span className="game-connection-status__span game-connection-status__span--joined">
                        {t('Joined')}
                    </span>
                </span>
            </div>
        </div>
    );
}

export default InfoStatusKnock;
