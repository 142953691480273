import React, {useEffect, useRef} from 'react';
import DatePick from '../../../components/Datapiker/DataPicker';
import { TimeInput } from '@trendmicro/react-datepicker';
import moment from 'moment';
import { setDateAndTime } from '../../../redux/actions/tournamentsAction';
import { connect } from 'react-redux';
import {arrClass} from "../../../components/Datapiker/general";

function Date({ index, title, ...redux }) {
    const { setDateAndTime, dateObj } = redux;
    const [showPicker, setShowPicker] = React.useState(false);
    const [date, setDate] = React.useState(moment().format('DD-MM-YYYY'));
    const [time, setTime] = React.useState(moment().format('HH:mm:ss'));
    let refFrom = useRef(null);

    const handleClick = (e) => {
        if (showPicker && refFrom.current &&  !arrClass.includes(e.target.className)) {
            setShowPicker('')
        }

    };

    useEffect(() => {
        document.addEventListener('click', handleClick);
        return () => {
            document.removeEventListener('click', handleClick);
        };
    });
    const openPicker = () => {
        setShowPicker(true);
    };
    const getValueDate = (value) => {
        if (index !== null) {
            const newArr = [...dateObj.scheduleAt];
            newArr[index] = {
                ...newArr[index],
                date: value,
            };
            setDateAndTime({
                ...dateObj,
                scheduleAt: newArr,
            });
        } else {
            setDateAndTime({
                ...dateObj,
                sequence: {
                    ...dateObj['sequence'],
                    date: value,
                },
            });
        }
        setDate(value);
        setShowPicker(false);
    };

    const getValueTime = (value) => {
        if (index !== null) {
            const newArr = [...dateObj.scheduleAt];
            newArr[index] = {
                ...newArr[index],
                time: value,
            };
            setDateAndTime({
                ...dateObj,
                scheduleAt: newArr,
            });
        } else {
            setDateAndTime({
                ...dateObj,
                sequence: {
                    ...dateObj['sequence'],
                    time: value,
                },
            });
        }
        setTime(value);
        setShowPicker(false);
    };

    return (
        <div className="width-50 float margin-l date-flex v-align ff">
            <div className="date-flex max-height float width-60 between">
                <div className={title==='Start Date:'?"min-width-100 ":"label min-width-50 fl-left"}>{title}</div>
                <div className="date-from">
                    <div className="ui left icon input date  width-60">
                        <input
                            ref={refFrom}
                            type="text"
                            size={10}
                            value={date}
                            onClick={openPicker}
                            onChange={openPicker}
                        />
                        <i aria-hidden="true" className="calendar icon" />
                    </div>
                    {showPicker && <DatePick close={getValueDate} />}
                    <div className="left">
                        <TimeInput value={time} onChange={getValueTime} />
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = ({ tournamentDateReducer }) => ({
    dateObj: tournamentDateReducer,
});

const mapDispatchToProps = {
    setDateAndTime,
};

export default connect(mapStateToProps, mapDispatchToProps)(Date);
