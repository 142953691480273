import { GAME_SCENE_TYPES } from '../../../constants/general';
import { GAME_FIELD_APPEARANCE_CLASSES } from '../../../constants/game/classes';
import SpinPrize from '../SpinPrize';
import { connect } from 'react-redux';
import React, { useEffect, useRef } from 'react';
import Knockout from '../Knokout/Knockout';
import { setGlobalRef } from '../../../redux/ducks/globalRefsDucks';
import { isSpinPreGameStage } from '../../../helpers/game';

function RoomRootContainer({ children, scene, sceneData, ...redux }) {
    const { fieldColorId, setGlobalRef, currentGame, tournamentList } = redux;
    const isSpinBlot = scene === GAME_SCENE_TYPES.SPIN;
    const isTournament = scene === GAME_SCENE_TYPES.TOURNAMENT;
    const blotFieldRef = useRef(null);

    useEffect(() => {
        setGlobalRef({ blotFieldRef });
    }, [blotFieldRef]);

    return (
        <main ref={blotFieldRef} className={`blot-field ${GAME_FIELD_APPEARANCE_CLASSES[fieldColorId]}`}>
            <div className="blot-field__wrapper">{children}</div>
            {isSpinBlot && !!sceneData && (
                <SpinPrize sceneData={sceneData} isSpinPreGameStage={isSpinPreGameStage(currentGame)} />
            )}
            {isTournament && !!tournamentList[sceneData.id] && (
                <Knockout currentTournament={tournamentList[sceneData.id]} />
            )}
        </main>
    );
}

const mapStateToProps = ({
    currentGame,
    tournamentList,
    player: {
        settings: { fieldColorId },
    },
}) => ({
    fieldColorId,
    currentGame,
    tournamentList,
});

const mapDispatchToProps = {
    setGlobalRef,
};

export default connect(mapStateToProps, mapDispatchToProps)(RoomRootContainer);
