import _ from 'lodash';
import React, {PureComponent} from 'react';
import {Icon, Table} from 'semantic-ui-react'
import {Link} from 'react-router-dom';

const playersTbCols = {
    cols: ['Ticket ID', 'Amount', 'Created At', 'Expired At', 'Bet Type'],
    keys: ['ticketId', 'betAmount', 'startDate', 'endDate',  'betKind'],
};
const betKind = {
    0:'CASH',
    1:'SPIN',
    2:'TOURNAMENT'
};
export default class SortableTicketsTable extends PureComponent {
    state = {
        column: null,
        data: this.props.data,
        toggleType: this.props.toggleType,
        direction: 'descending',
    };
    handleSort = (clickedColumn) => () => {
        const {column, direction} = this.state;
        const {sorting} = this.props;
        if (column !== clickedColumn) {
            this.setState({
                column: clickedColumn,
                //	data: _.sortBy(data, [clickedColumn]),
                direction: 'ascending',
            });
        }

        this.setState({
            direction: direction === 'ascending' ? 'descending' : 'ascending',
        });

        sorting(clickedColumn, direction === 'descending' ? 'ASC' : 'DESC');
    };

    render() {
        const {column, direction, toggleType} = this.state;
        const {data, count} = this.props;
        let i = count;
        return (
            <Table sortable celled size="small">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>No:</Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === (toggleType === 'Ajarabet' ? 'remoteId' : 'id') ? direction : null}
                            onClick={this.handleSort((toggleType === 'Ajarabet' ? 'remoteId' : 'id'))}
                        >
                            Player ID
                        </Table.HeaderCell>
                        {
                            playersTbCols.cols.map((item, index) => {
                                return (
                                    <Table.HeaderCell key={index}
                                                      sorted={column === playersTbCols.keys[index] ? direction : null}
                                                      onClick={this.handleSort(playersTbCols.keys[index])}
                                    >
                                        {item}
                                    </Table.HeaderCell>
                                )
                            })
                        }
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {_.map(
                        data,
                        ({remoteId, op_trx_id, value, created_at, expired_at, op_player_id, type, ref_id}, key) => {
                            return (
                                <Table.Row key={key}>
                                    <Table.Cell>{++i}</Table.Cell>
                                    <Table.Cell>
                                        <Link className="id-hover"
                                              to={`/admin/players/${toggleType === 'Ajarabet' ? op_player_id : remoteId}`}>
                                            {toggleType === 'Ajarabet' ? op_player_id : remoteId}</Link>
                                    </Table.Cell>
                                    <Table.Cell>
                                        {op_trx_id}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {value}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {created_at}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {expired_at}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {betKind[type]} { ref_id ? ` (${ref_id})`: null}
                                    </Table.Cell>

                                </Table.Row>
                            );
                        }
                    )}
                </Table.Body>
            </Table>
        );
    }
}
