import i18next from '../../configs/i18n';
import React, { useEffect, useState } from 'react';
import { setClass } from '../../helpers/game';
import axios from 'axios';
import { GLOBAL_URL } from '../../configs/api';
import TableGameHistory from './components/TableGameHistory';
import Replay from './components/Replay/Replay';
import { useTranslation } from 'react-i18next';

function GameHistory({ isActive, gameId, gameData }) {
    const [historyList, setHistoryList] = useState([]);
    const [activeGame, setActiveGame] = useState({});
    const { t } = useTranslation();
    const [isFetching, setIsFetching] = useState(true);

    useEffect(() => {
        if (isActive) {
            // axios(`${GLOBAL_URL}/c2c/bo/history/${gameId}`, {
            axios(`${GLOBAL_URL}/history/content`, {
                method: 'POST',
                data: {
                    token:'tokenForBo',
                    id:gameId,
                    timeZone: (-1 * new Date().getTimezoneOffset()) / 60,
                },
            }).then((res) => {
            console.log(JSON.parse(res.data.data.history) ,"GameHistory");
            if (!res.data.error){
                setHistoryList([res.data.data]);
                setActiveGame(JSON.parse(res.data.data.history));
            }
                setIsFetching(false);

            }).catch((e)=>{
                console.log("error->", e );
            });
        }
    }, [isActive]);

    return (
        <div className={`history ${setClass(isActive, 'history--active')}`}>
            <div className="history__columns">
                {isFetching ? (
                    <div style={{ fontSize: '32px', position: 'absolute', top: '50%', left: '44%' }}>Loading...</div>
                ) : historyList.length ?
                        !!activeGame.tableId && <Replay game={activeGame} id={gameData[0]['player_id_host']} isHistoryActive={isActive} />
               : (
                    <div className="history__column history__column--tables" style={{ width: '100%' }}>
                        {/*<TableGameHistory date={t('Date')} />*/}
                        <span style={{ position: ' absolute', left: '45%', top: '50%', fontSize: 'large' }}>
                            Your history is empty
                        </span>
                    </div>
                )}
            </div>
        </div>
    );

}

export default GameHistory;
