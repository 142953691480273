import React from 'react';
import { List, Icon } from 'semantic-ui-react';
import { editResource } from '../redux/actions/aclmanagmentAction';
import { connect } from 'react-redux';

const ListExampleCelled = ({ data, names, ...redux }) => {
    const { editResource } = redux;
    const edit = (name) => {
        editResource({ open: 'edit', name });
    };
    return (
        <List celled>
            {names
                ? names.map((item, key) => {
                      return (
                          <List.Item key={key}>
                              <List.Content className="own">
                                  <List.Header>ID: {data[item].id}</List.Header>
                                  {item}
                                  <div className="resource-edit pointer" onClick={() => edit(item)}>
                                      <Icon name="edit" />
                                      edit
                                  </div>
                              </List.Content>
                          </List.Item>
                      );
                  })
                : null}
        </List>
    );
};

const mapStateToProps = ({ resourceEditorModalControl }) => ({
    resourceEditorModalControl,
});
const mapDispatchToProps = {
    editResource,
};
export default connect(mapStateToProps, mapDispatchToProps)(ListExampleCelled);
