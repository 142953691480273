import React, { Component } from 'react';
import { Select, Input, Checkbox, Button, Modal } from 'semantic-ui-react';
import { updateOtherUsers } from '../redux/actions/usersActions';
import { changeUserGroupConnection } from '../redux/actions/aclmanagmentAction';
import SingleSelect from './SelectAutoComplete';
import { connect } from 'react-redux';

class ModalExampleDimmer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: null,
            confirmPassword: null,
            username: this.props.user.username,
            isActive: false,
            open: true,
            dimmer: 'blurring',
            language: 'en',
            addGroup: [],
            userId:null,
            groupId:null,
            languageOptions: [
                { key: 'en', text: 'English', value: 'en' },
                { key: 'ru', text: 'Russian', value: 'ru' },
            ],
            error: { errName: '', errPass: '' },
        };
    }

    getLocale = (locale) => {
        const { languageOptions } = this.state;
        const lang = languageOptions.filter((el) => el.key === locale)[0];
        return lang ? lang.text : 'Select Language';
    };
    saveChanges = () => {
        const { username, isActive, language, error, password, confirmPassword} = this.state;
        const { user } = this.props;

        let errName = '',
            errPass = '';
        if (username.length <= 7 || !username.length) {
            errName = 'Username must be at least 8 characters';
        }
        // console.log(password,"password")
        if (password !== null && password.length <= 7) {
            errPass = 'Password must be at least 8 characters';
        }
        if (password !== confirmPassword) {
             errPass = 'Password and confirm password not the same';
         }

        if (errName.length || errPass.length) {
            this.setState({
                error: { ...error, errName, errPass },
            });
        } else {
            let data = {
                id: user.id,
                username,
                password,
                confirmPassword,
                isActive: isActive === Boolean(user.is_active) ? '0' : '1',
                language,
            };
            this.props.updateOtherUsers(data);
        }
    };

    getValue = (e) => {
        this.setState({
            error: { ...this.state.error, errName: '', errPass: '' },
            [e.target.name]: e.target.value,
        });
    };

    getStatus = () => {
        this.setState({
            isActive: !this.state.isActive,
        });
    };
    getLang = (e, data) => {
        this.setState({
            language: data.value,
        });
    };
    close = (e) => {
        const { close } = this.props;
        close(e);
    };

      addToGroup = (type, userId, groupId) => {
       this.setState({
        userId,
        groupId,
       })
      };
      add =()=>{
        const { changeUserGroupConnection } = this.props;
        const { userId, groupId } = this.state;
        const type='connect';
         changeUserGroupConnection({
          type,
          userId,
          groupId,
        });
      }

    render() {
        const { open, dimmer, languageOptions, error, username, password, isActive, confirmPassword, addGroup } = this.state;
        const { title, user, requestMessage } = this.props;
        return (
            <div>
                <Modal
                    dimmer={dimmer}
                    open={open}
                    onClose={() => {
                        this.close(false);
                    }}
                >
                    <Modal.Header>
                        {title} with id - {user.id}
                        <div className="check-box auto-complete">
                            <SingleSelect
                              type="groups"
                              addToGroup={this.addToGroup}
                              resId={null}
                              name={'users'}
                              addGroup={addGroup}
                              userGroupIds={[]}
                              userId={user.id}
                              addUserToGroup={true}
                            />
                            <Button
                              color="green"
                              onClick={this.add}
                              size={'mini'}
                              className={'margin-l'}
                            >
                                Add
                            </Button>
                        </div>

                    </Modal.Header>
                    <Modal.Content>
                        <div className="input-box">
                            <div>
                                <div className="label">Edit username:</div>
                                <Input
                                    value={username ? username : user.username}
                                    name={'username'}
                                    onChange={(e) => this.getValue(e)}
                                />
                                <span className="error-mes">{error.errName}</span>
                            </div>
                            <div>
                                <div className="label">Reset password:</div>
                                <Input
                                    value={password !== null ? password : ''}
                                    name={'password'}
                                    onChange={(e) => this.getValue(e)}
                                    type="password"
                                />

                            </div>
                            <div>
                                <div className="label">Confirm password:</div>
                                <Input
                                    value={confirmPassword !== null ? confirmPassword : ''}
                                    name={'confirmPassword'}
                                    onChange={(e) => this.getValue(e)}
                                    type="password"
                                />
                                <span className="error-mes">{error.errPass}</span>
                            </div>

                        </div>
                        <div className="check-box">
                            <div> Is-Active</div>
                            <Checkbox
                                toggle
                                checked={
                                    isActive
                                        ? !Boolean(user.is_active)
                                            ? isActive
                                            : !isActive
                                        : Boolean(user.is_active)
                                }
                                onChange={this.getStatus}
                            />
                        </div>
                        <div className="check-box">
                            <Select
                                selectOnBlur={false}
                                placeholder={this.getLocale(user.locale)}
                                options={languageOptions}
                                onChange={this.getLang}
                            />

                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <div className={requestMessage.error ? 'req-mess err' : 'req-mess'}>
                            {' '}
                            {requestMessage.message}
                        </div>
                        <Button
                            color="black"
                            onClick={() => {
                                this.close(false);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            positive
                            icon="checkmark"
                            labelPosition="right"
                            content="Save"
                            onClick={this.saveChanges}
                        />
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = ({ requestMessage }) => ({
    requestMessage,
});

const mapDispatchToProps = {
    updateOtherUsers,
    changeUserGroupConnection
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalExampleDimmer);
