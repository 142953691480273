import { BAZAR_BLOT_TYPES, MAIN_TYPES, OTHER_TYPES, USERS_TYPES } from '../actionTypes';
import instance from '../../helpers/Api';
import moment from 'moment';
import { createAction } from '../../helpers/general';

const { SET_BAZAR, SET_CURRENT_BAZAR, RESET_BAZAR_LOGS, SET_BAZAR_LOGS } = BAZAR_BLOT_TYPES;
const { REQUEST_MESSAGE } = USERS_TYPES;
const { GET_TOTAL_PAGES_COUNT_NEW } = OTHER_TYPES;
const { LOADER_HIDE, LOADER_SHOW } = MAIN_TYPES;

export function getAllGames(data, sorting = false) {
    const da = {
        from: data.dateFrom,
        to: data.dateTo,
        limit: data.limit,
        offset: data.offset,
        minBet: data.minBet,
        maxBet: data.maxBet,
        betType: data.betType,
        custom: data.custom,
        state: data.state,
        tableId: data.tableId,
        winnerId: data.winnerId,
        locale: 'en',
    };

    return (dispatch) => {
        if (!sorting) {
            dispatch(createAction(LOADER_SHOW));
        }
        instance
            .post('/api/bazar/get', da)
            .then((res) => {
                if (res.data.error) {
                    dispatch({ type: REQUEST_MESSAGE, value: res.data.data });
                } else {
                    dispatch({ type: SET_BAZAR, value: res.data.data });
                    dispatch({ type: GET_TOTAL_PAGES_COUNT_NEW, value: res.data.data });
                    dispatch(createAction(LOADER_HIDE));
                }
            })
            .catch((e) => {
                dispatch(createAction(LOADER_SHOW));
                console.log('getGames', e);
            });
    };
}

export function sortingByDate(arr) {
    return arr.sort((a, b) => {
        const d1 = new Date(a['TR_PREFIX']['date']);
        const d2 = new Date(b['TR_PREFIX']['date']);
        return d1 - d2;
    });
}

export function killOneGame(data) {
    return (dispatch) => {
        instance
            .post('/api/games/kill', data)
            .then((res) => {
                dispatch({ type: REQUEST_MESSAGE, value: res.data });
            })
            .catch((e) => {
                console.log('gameKill', e);
            });
    };
}

export function resetBazarTable() {
    return (dispatch) => {
        dispatch({ type: SET_BAZAR, value: [] });
    };
}

export const setCurrentBazar = (state) => {
    return {
        type: SET_CURRENT_BAZAR,
        payload: state,
    };
};

export function getBazarLogs(data) {
    return (dispatch) => {
        // instance
        //     .post('/api/games/getOne', data)
        //     .then((res) => {
        //         if (!res.data.error) {
        // dispatch({ type: SET_BAZAR_LOGS, value: sortingByDate(res.data.data) });
        dispatch({
            type: SET_BAZAR_LOGS,
            value: sortingByDate([
                {
                    TR_PREFIX: {
                        date: '2023-06-14T10:30:00',
                        action: 'FRONT_ACTION',
                        property1: 'Value 1',
                        property2: 'Value 2',
                        // Add more properties as needed...
                    },
                    // Other log data...
                },
                {
                    TR_PREFIX: {
                        date: '2023-06-14T11:45:00',
                        action: 'BACK_ACTION',
                        property1: 'Value 3',
                        property2: 'Value 4',
                        // Add more properties as needed...
                    },
                    // Other log data...
                },
                // Add more log objects as needed...
            ]),
        });

        //     } else {
        //         dispatch({ type: REQUEST_MESSAGE, value: res.data });
        //     }
        // })
        // .catch((e) => {
        //     console.log('getBazarLogs', e);
        // });
    };
}

export const resetBazarLogs = createAction(RESET_BAZAR_LOGS);

export function getSortedBazar(data, sorting = false) {
    return (dispatch) => {
        if (!sorting) {
            dispatch(createAction(LOADER_SHOW));
        }
        instance
            .post('/api/bazar/get', data)
            .then((res) => {
                console.log(res, 'aper1');
                if (res.data.error) {
                    dispatch({ type: REQUEST_MESSAGE, value: res.data.data });
                } else {
                    dispatch({ type: SET_BAZAR, value: res.data.data });
                    dispatch({ type: GET_TOTAL_PAGES_COUNT_NEW, value: res.data.data });
                    dispatch(createAction(LOADER_HIDE));
                }
            })
            .catch((e) => {
                dispatch(createAction(LOADER_SHOW));
                console.log('getGames', e);
            });
    };
}
