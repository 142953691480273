export const BAT_CARDS_OFFSETS = {
    FROM_LIST_OFFSET_X: 100,
    FROM_LIST_OFFSET_Y: 100,
    FROM_CARD_OFFSET_X: 35,
    FROM_CARD_OFFSET_Y: 35,
};

export const CARD_INTERACT_CLASSES = {
    PLAYER_HAND_CARD: 'PLAYER_HAND_CARD',
    OPPONENT_HAND_CARD: 'OPPONENT_HAND_CARD',
    PLAYER_TABLE_CARD: 'PLAYER_TABLE_CARD',
    OPPONENT_TABLE_CARD: 'OPPONENT_TABLE_CARD',
    PLAYER_BAT_CARD: 'PLAYER_BAT_CARD',
    OPPONENT_BAT_CARD: 'OPPONENT_BAT_CARD',
    PLAYER_COMBINATION: 'PLAYER_COMBINATION',
    OPPONENT_COMBINATION: 'OPPONENT_COMBINATION',
};
