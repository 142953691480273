import moment from "moment-timezone";
import React from "react";
import {connect} from "react-redux";


function TimeZone({zone}){
    return (
        <div className='user-timezone'>
            <div className='name'> {zone} </div>
            <div> {moment.tz(moment(), zone).format('DD-MM-YYYY HH:mm')}</div></div>
    )
}

const mapStateToProps = ({userTimeZoneReducer}) => ({
    zone: userTimeZoneReducer,
});

export default connect(mapStateToProps, null)(TimeZone);
