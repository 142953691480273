import { useEffect, useState } from 'react';
import { cardToCardId } from '../../../helpers/helpers/gameplay';

export function useDefineLastHandCardsData(lastHand, bottomPlayerId) {
    const [lastHandCardsData, setLastHandCardsData] = useState({});

    useEffect(() => {
        const playerCardData = [];
        const opponentCardData = [];
        if (!!lastHand.length) {
            lastHand.forEach((el) => {
                if (!!el.playerId) {
                    const { withAnim, playerId, cards: cardsData } = el;
                    const { playerCard, opponentCard } = getCardsFromLastHandData(cardsData, bottomPlayerId);
                    playerCardData.push({
                        lastHandCardId: playerCard.cardId,
                        batOwnerId: playerId,
                        withAnim,
                        zIndex: playerCard.zIndex,
                    });
                    opponentCardData.push({
                        lastHandCardId: opponentCard.cardId,
                        batOwnerId: playerId,
                        withAnim,
                        zIndex: opponentCard.zIndex,
                    });
                }
            });
            setLastHandCardsData({ playerCardData, opponentCardData });
        } else {
            setLastHandCardsData({ playerCardData: [{}], opponentCardData: [{}] });
        }
    }, [lastHand]);

    return lastHandCardsData;
}

function getCardsFromLastHandData(cards, bottomPlayerId) {
    const [player1CardData, player2CardData] = cards;
    const player1Data = { ...player1CardData, zIndex: 0 };
    const player2Data = { ...player2CardData, zIndex: 1 };
    const isMyCardData = bottomPlayerId === player1CardData.player.id;
    const playerFoldCardsData = isMyCardData ? player1Data : player2Data;
    const opponentFoldCardsData = !isMyCardData ? player1Data : player2Data;

    return {
        playerCard: { cardId: cardToCardId(playerFoldCardsData.card), zIndex: playerFoldCardsData.zIndex },
        opponentCard: { cardId: cardToCardId(opponentFoldCardsData.card), zIndex: opponentFoldCardsData.zIndex },
    };
}
