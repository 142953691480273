import _ from 'lodash';
import React, {PureComponent} from 'react';
import {Table} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import store from '../redux/store';
import p from "../permissionsConstants";
import moment from "moment-timezone";

const {gamesKIll} = p;


export default class SortableGamesTable extends PureComponent {
    state = {
        column: null,
        data: this.props.data,
        toggleType: this.props.toggleType,
        direction: 'descending',
        permissions: store.getState().userReducer['permissions'],
        liveStatus: false,
        diffTime: store.getState().diffTimeReducer,
        zone: store.getState().userTimeZoneReducer
    };
    handleSort = (clickedColumn) => () => {
        const {column, direction} = this.state;
        const {sorting} = this.props;

        if (column !== clickedColumn) {
            this.setState({
                column: clickedColumn,
                direction: 'ascending',
            });
        }

        this.setState({
            direction: direction === 'ascending' ? 'descending' : 'ascending',
        });

        sorting(clickedColumn, direction === 'descending' ? 'ASC' : 'DESC');
    };
    isHighLighted = (date, id) => {
        if (date) {
            const nowTZ = moment.tz(moment(), this.state.zone).format('DD-MM-YYYY HH:mm:ss');
            const end = date.split(' ');
            const now = nowTZ.split(' ');
            const now_data = now[0].split('-');
            const now_time = now[1].split(':');
            const end_data = end[0].split('-');
            const end_time = end[1].split(':');
            let now_final = new Date(+now_data[2], +now_data[1], +now_data[0], +now_time[0], +now_time[1], +now_time[2]);
            let end_final = new Date(+end_data[2], +end_data[1], +end_data[0], +end_time[0], +end_time[1], +end_time[2]);
          let diffMin = moment(now_final).diff(moment(end_final), 'minutes');
            return diffMin >= 60
        }
        return false
    };

    componentDidUpdate(nextProps) {
        if (nextProps.liveStatus !== this.state.liveStatus) {
            this.setState({liveStatus: nextProps.liveStatus})
        }

    }

    componentDidMount() {
        this.setState({liveStatus: !this.state.liveStatus})
    }

    render() {
        const {column, direction, permissions, liveStatus, diffTime, toggleType} = this.state;
        const {data, count, kill} = this.props;
        let i = count;
        return (
            <Table sortable celled size="small">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell className="rows-number">No:</Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === 'id' ? direction : null}
                            onClick={this.handleSort('id')}
                        >
                            Table ID
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === (toggleType ==='Ajarabet'?'remoute_hostId':'hostId') ? direction : null}
                            onClick={this.handleSort(toggleType ==='Ajarabet'?'remoute_hostId':'hostId')}
                        >
                            Player ID Host
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === (toggleType ==='Ajarabet'?'remoute_guestId':'guestId') ? direction : null}
                            onClick={this.handleSort(toggleType ==='Ajarabet'?'remoute_guestId':'guestId')}
                        >
                            Player ID Guest
                        </Table.HeaderCell>

                        <Table.HeaderCell
                            sorted={column === (toggleType ==='Ajarabet'?'remoute_winnerId':'winnerId') ? direction : null}
                            onClick={this.handleSort(toggleType ==='Ajarabet'?'remoute_winnerId':'winnerId')}
                        >
                            Winner ID
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === 'props_amount' ? direction : null}
                            onClick={this.handleSort('props_amount')}
                        >
                            Bet Amount
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === 'win_amount' ? direction : null}
                            onClick={this.handleSort('win_amount')}
                        >
                            Win Amount
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === 'kind' ? direction : null}
                            onClick={this.handleSort('kind')}
                        >
                            Game Type
                        </Table.HeaderCell><Table.HeaderCell
                        sorted={column === 'kind' ? direction : null}
                        onClick={this.handleSort('kind')}
                    >
                        Game Kind
                    </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === 'created_at' ? direction : null}
                            onClick={this.handleSort('created_at')}
                        >
                            Created At
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === 'started_at' ? direction : null}
                            onClick={this.handleSort('started_at')}
                        >
                            Start Date
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === 'finished_at' ? direction : null}
                            onClick={this.handleSort('finished_at')}
                        >
                            End Date
                        </Table.HeaderCell>
                        {permissions && permissions[gamesKIll] && (data[0].kind === 'CLASSIC' || data[0].kind === 'OPEN')
                        && (data[0].state === 1 || data[0].state === 2) ?
                            <Table.HeaderCell>
                                Action
                            </Table.HeaderCell> : null}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {_.map(data, ({
                                      id, player_id_guest, player_id_host, remote_id_host,
                                      remote_id_guest, created_at, player_id_winner, props_amount, win_amount,
                                      scene_title, state, started_at, finished_at, kind, winner_remote_id
                                  }) => {
                        return (
                            <Table.Row key={id} className={
                                this.isHighLighted(started_at, id) &&
                                liveStatus ? 'lighted-yes' : null}>
                                <Table.Cell>{++i}</Table.Cell>
                                <Table.Cell>
                                    <Link className='id-hover' to={`/admin/games/${id}`}>{id}</Link>
                                </Table.Cell>
                                <Table.Cell>{toggleType ==='Ajarabet'?remote_id_host: player_id_host}</Table.Cell>
                                <Table.Cell>{toggleType ==='Ajarabet'?remote_id_guest:player_id_guest}</Table.Cell>
                                <Table.Cell>{toggleType ==='Ajarabet'? winner_remote_id:player_id_winner}</Table.Cell>
                                <Table.Cell>{props_amount}</Table.Cell>
                                <Table.Cell>{win_amount}</Table.Cell>
                                <Table.Cell>{scene_title === 'SINGLE' ? 'CASH' : scene_title}</Table.Cell>
                                <Table.Cell>{kind}</Table.Cell>
                                <Table.Cell>{created_at}
                                    </Table.Cell>
                                <Table.Cell>{started_at}</Table.Cell>
                                <Table.Cell>{finished_at}</Table.Cell>
                                {permissions && permissions[gamesKIll] && (kind === 'CLASSIC' || kind === 'OPEN')
                                && (state === 1 || state === 2) ?
                                    <Table.Cell className="pointer" onClick={() => kill(id)}>Delete</Table.Cell> : null}
                            </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>
        );
    }
}
