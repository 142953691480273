import { createAction, createReducer } from '../../helpers/helpers/redux';
import { tabNames } from '../../constants/tabNames';

const { HOME } = tabNames;

const SET_TAB = 'SET_TAB';

export const setTab = createAction(SET_TAB);

const initialValue = {
    currentTab: sessionStorage.getItem('currentTab') || HOME,
}

export const tabReducer = createReducer(initialValue, (state, { value }) => ({
    [SET_TAB]: () => {
        sessionStorage.setItem('currentTab', value);
        return {
            ...state, currentTab: value
        };
    }
}));
