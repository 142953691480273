import React from 'react';
import { setClass } from '../../../../../helpers/helpers/game';

function HistoryPlayerButton({ onClick, isDisabled, classPrefix, ariaLabel }) {
    const className =
        ariaLabel === 'play'
            ? 'history__player-button history__player-button--play'
            : `history__player-button history__player-button--rewind history__player-button--rewind-${classPrefix} ${setClass(
                  isDisabled,
                  'history__player-button--inactive'
              )}`;

    return <button onClick={onClick} type="button" className={className} aria-label={ariaLabel} />;
}

export default HistoryPlayerButton;
