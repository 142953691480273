import {createReducer} from '../../helpers/general';

import {TOURNAMENTS_TYPES} from '../actionTypes';
import moment from 'moment';

const {
    SET_TOURNAMENTS,
    SET_DATE_OR_TIME,
    SET_All_PRIZE_RULES,
    SET_ONE_PRIZE_RULE,
    SET_SELECTED_TOURNAMENT_DATA,
    SET_DATE_OR_TIME_FROM_SELECTED_TOURNAMENT,
    RESET_DATE_OR_TIME,
    SET_TOURNAMENTS_TEMPLATES,
    SET_TOURNAMENT_PLAYERS,
    SET_TRANSACTIONS,
    RESET_TABS_INFO,
    SET_TOURNAMENTS_TEMPLATES_LIST,
    SET_All_PRIZE_RULES_LIST,
    RESET_ONE_PRIZE_RULE,
    SET_TOURNAMENT_ROUNDS,
    SET_WINNERS_TRANSACTIONS,
    SET_ADMIN_ADDED_PLAYERS_LIST,
    UPDATE_ADMIN_ADDED_PLAYERS_LIST
} = TOURNAMENTS_TYPES;

const start = {
    startDate: moment().format('DD-MM-YYYY'),
    startTime: moment().format('HH:mm:ss'),
};
const registrationDate = {
    register: {
        day: 0,
        hour: 0,
        min: 0,
    },
    announcementStart: {
        day: 0,
        hour: 0,
        min: 0,
    },
    announcementEnd: {
        day: 0,
        hour: 0,
        min: 5,
    },

};
const register = {
    registerDate: start.startDate,
    registerTime: start.startTime,
};
const announce = {
    announcementStartDate: moment().format('DD-MM-YYYY'),
    announcementStartTime: moment().format('HH:mm:ss'),
    announcementEndDate: moment().format('DD-MM-YYYY'),
    announcementEndTime: moment().format('HH:mm:ss'),
};
const sequence = {
    date: moment().format('DD-MM-YYYY'),
    time: moment().format('HH:mm:ss'),
};
const initial = {
    ...start,
    ...registrationDate,
    ...register,
    ...announce,
    scheduleAt: [],
    sequence,
    // finishedAt: {
    //     min: 0,
    // },
};
export const places = [
    {place: '1-st place', amount: 0, amountPercent: 0, amountTotal: 0, percentTotal: 0, count: 1},
    {place: '2-nd place', amount: 0, amountPercent: 0, amountTotal: 0, percentTotal: 0, count: 1},
    {place: '3-4 places', amount: 0, amountPercent: 0, amountTotal: 0, percentTotal: 0, count: 2},
    {place: '5-8 places', amount: 0, amountPercent: 0, amountTotal: 0, percentTotal: 0, count: 4},
    {
        place: '9-16 places',
        amount: 0,
        amountPercent: 0,
        amountTotal: 0,
        percentTotal: 0,
        count: 8,
    },
    {
        place: '17-32 places',
        amount: 0,
        amountPercent: 0,
        amountTotal: 0,
        percentTotal: 0,
        count: 16,
    },
    {
        place: '33-64 places',
        amount: 0,
        amountPercent: 0,
        amountTotal: 0,
        percentTotal: 0,
        count: 32,
    },
];
export const tournamentReducer = createReducer({}, (state, {value}) => {
    return {
        [SET_TOURNAMENTS]: value ? value : state,
    };
});
export const tournamentTemplatesReducer = createReducer([], (state, {value}) => {
    return {
        [SET_TOURNAMENTS_TEMPLATES]: value ? setTournamentsTemplates(value.data) : state,
    };
});

export const tournamentTemplatesListReducer = createReducer([], (state, {value}) => {
    return {
        [SET_TOURNAMENTS_TEMPLATES_LIST]: value ? value : state,
    };
});
export const roundsReducer = createReducer({}, (state, {value}) => {
    return {
        [SET_TOURNAMENT_ROUNDS]: value ? value.data : state,
    };
});

export const tournamentTabsReducer = createReducer([], (state, {value}) => {
    return {
        [SET_TOURNAMENT_PLAYERS]: value ? value : state,
        [SET_TRANSACTIONS]: value ? value : state,
        [SET_WINNERS_TRANSACTIONS]: value ? value : state,
        [RESET_TABS_INFO]: value ? value : state,
    };
});

export const tournamentDateReducer = createReducer(initial, (state, {value}) => {
    return {
        [SET_DATE_OR_TIME]: value ? value : state,
        [SET_DATE_OR_TIME_FROM_SELECTED_TOURNAMENT]: value ? {...initial, ...value} : state,
        [RESET_DATE_OR_TIME]: value ? {...initial, ...value} : state,
    };
});
export const tournamentPrizeReducer = createReducer([], (state, {value}) => {
    return {
        [SET_All_PRIZE_RULES]: value ? setData(value.data) : state,
    };
});
export const onePrizeRuleReducer = createReducer({}, (state, {value}) => {
    return {
        [SET_ONE_PRIZE_RULE]: value ? onePrize(value.data) : state,
        [RESET_ONE_PRIZE_RULE]: value === null ? onePrize(value) : state,
    };
});
export const allPrizeRulesListReducer = createReducer({}, (state, {value}) => {
    return {
        [SET_All_PRIZE_RULES_LIST]: value ? value : state,
    };
});
export const adminAddedPlayers = createReducer([], (state, {value}) => {
    return {
        [SET_ADMIN_ADDED_PLAYERS_LIST]: value ? value.data : state,
        [UPDATE_ADMIN_ADDED_PLAYERS_LIST]: value ? value.data : state,
    };
});

export const oneTournamentReducer = createReducer({}, (state, {value}) => {
    return {
        [SET_SELECTED_TOURNAMENT_DATA]: value ? {...value} : state,
    };
});

function setData(val) {
    const allPrizeTemplates = [];
    for (let i in val) {
        allPrizeTemplates.push({key: +i + 1 + '', text: val[i].title, value: +i + 1});
    }
    return allPrizeTemplates;
}

function setTournamentsTemplates(val) {
    const arr = [];
    for (let i in val) {
        arr.push({
            key: i + '',
            text: val[i].name,
            value: val[i].name,
        });
    }
    return arr;
}

function onePrize(val) {
    if (val) {
        const prizeData = [];
        for (let i in val) {
            let c = places[i] ? places[i].count : 1;
            prizeData.push({
                ...places[i],
                amountPercent: val[i].percent / c,
                percentTotal: val[i].percent,
                amountTotal: (val[i].amount * val[i].percent) / 100,
            });
        }
        return {
            winners: val.length ? Math.pow(2, val.length - 1) : '',
            prizeRuleName: val[0] ? val[0].title : '',
            prizeDistribution: prizeData,
            amount: val[0] ? val[0].amount : 0
        };
    }
//return {}
    return {
        winners: '',
        prizeRuleName: '',
        prizeDistribution: [],
        amount: ''
    };
}

export default {
    tournamentReducer,
    tournamentDateReducer,
    tournamentPrizeReducer,
    onePrizeRuleReducer,
    oneTournamentReducer,
    tournamentTemplatesReducer,
    tournamentTabsReducer,
    tournamentTemplatesListReducer,
    allPrizeRulesListReducer,
    roundsReducer,
    adminAddedPlayers,
};
