import {getToken} from '../../configs/server';
import {CMS_TYPES, USERS_TYPES} from '../actionTypes';
import instance from '../../helpers/Api';

const {
    SET_ALL_BANNERS,
    SET_BANNERS_DATA,
    SET_ALL_PLACEHOLDERS,
    EDIT_PLACEHOLDER
} = CMS_TYPES;
const {REQUEST_MESSAGE} = USERS_TYPES;


export function getAllBanners() {
    return (dispatch) => {
        instance.post('/api/cms/allBanners')
            .then((res) => {
              // console.log(res, "res-getCms")
                dispatch({type: SET_ALL_BANNERS, value: res.data.data});
                dispatch({type: SET_BANNERS_DATA, value: prepareData(res.data.data)});

            })
            .catch((e) => {
                console.log('getAllBanners', e);
            });
    };
}
export function getPlaceHolderInfo(data) {

    return (dispatch) => {
        instance.post('/api/cms/getPlaceHolderInfo', data)
            .then((res) => {
               // console.log(res, "res-getPlaceHoldersInfo")
                dispatch({type: SET_ALL_PLACEHOLDERS, value: {[data.placeHolderId]:res.data.data}});
            })
            .catch((e) => {
                console.log('getPlaceHoldersInfo', e);
            });
    };
}
export function updatePlaceHolder(data) {
    return (dispatch) => {
        instance.post('/api/cms/updatePlaceHolder', data)
            .then((res) => {
               // console.log(res, "res-updatePlaceHolder")
              dispatch({type: REQUEST_MESSAGE, value: {...res.data, id:data.placeholderId}});
            })
            .catch((e) => {
                console.log('updatePlaceHolder', e);
            });
    };
}

export function uploadBannerOrHtml(type, data, header,id) {
    return (dispatch) => {
        instance.post(`/api/cms/${type}`, data, {
            headers: {
                'Content-Type': header, authorization: getToken()
            }
        })
            .then((res) => {
                //console.log(res,"uploadBannerOrHtml")
                dispatch({type: REQUEST_MESSAGE, value: {...res.data, id}});
            })
            .catch((e) => {
                console.log(e, 'err-uploadBannerOrHtml');
            });
    };
}
export function deleteMedia(data,id) {
    return (dispatch) => {
        instance.post(`/api/cms/deleteMedia`, data)
            .then((res) => {
            // console.log(res,"deleteMedia")
                dispatch({type: REQUEST_MESSAGE, value: {...res.data, id}});
            })
            .catch((e) => {
                console.log(e, 'err-deleteMedia');
            });
    };
}

function prepareData(val){
    const newObj={}
    for (let el of val){
        newObj[el.filename]={
            priority:'',
            content:'',
            checked:false
        }
    }
    return newObj
}

export function updateBannersData(value) {
    return {type: SET_BANNERS_DATA, value: value};
}

export function editPlaceHolder(value) {
    return {type: EDIT_PLACEHOLDER, value: value};
}
