import React, { useState } from 'react';
import { numWithCommas } from '../../helpers/helpers/general';
import { getCurrencySymbol, setClass } from '../../helpers/game';
import { useTranslation } from 'react-i18next';

function SpinPrize({ sceneData, isSpinPreGameStage }) {
    const [isKnockoutOpen, setIsKnockoutOpen] = useState(false);
    const { t } = useTranslation();
    const { prize, winningPlaces } = sceneData;

    return (
        <>
            <div className="spin-blot" onClick={() => setIsKnockoutOpen(true)}>
                <div className="spin-blot__text">{t('Spin')}</div>
                <div className="spin-blot__prize">
                    Prize{' '}
                    <span className="spin-blot__prize-amount">
                        {!isSpinPreGameStage && numWithCommas(prize) + getCurrencySymbol()}
                    </span>
                </div>
            </div>
            <div className="knockout-container">
                <div className={`knockout ${setClass(isKnockoutOpen, 'active')}`}>
                    <button
                        type="button"
                        className="players-information__close-button"
                        onClick={() => setIsKnockoutOpen(false)}
                    >
                        <span className="visually-hidden">close player information container</span>
                    </button>
                    <section className="tour-info">
                        <header className="tour-info__header">
                            <h2 className="tour-info__title" />
                        </header>
                        <div className="tour-info__body">
                            <div className="tour-info__inner-tabs-container">
                                <ul className="tour-info__inner-tabs">
                                    <li className="tour-info__inner-tab tour-info__inner-tab--prizepool tour-info__inner-tab--active">
                                        <PrizePoolSpin winningPlaces={winningPlaces} />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    );
}

function PrizePoolSpin({ winningPlaces }) {
    const { t } = useTranslation();

    return (
        <div className="prizepool">
            <div className="prizepool__title">{t('Winning Places')}</div>
            <div className="prizepool__table">
                {Object.entries(winningPlaces).map(([place, winAmount]) => (
                    <div className="prizepool__row">
                        <div className="prizepool__cell prizepool__cell--position">{place}</div>
                        <div className="prizepool__cell prizepool__cell--percent">{}</div>
                        <div className="prizepool__cell prizepool__cell--amount">{getCurrencySymbol() + winAmount}</div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default SpinPrize;
