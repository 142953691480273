import React, {Component} from 'react';
import {Icon, Menu, Dropdown} from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import {logOut} from '../../redux/actions/usersActions';
import {connect} from 'react-redux';
import store from '../../redux/store';
import p from '../../permissionsConstants';
import {leftDropDownMenuControl, resetRequestMessage, resetLoader} from '../../redux/actions/otherActions';
import {setSelectedTournamentData, resetDateAndTime, resetPrizeRule} from '../../redux/actions/tournamentsAction';
import {reset} from "../../pages/tournament/createTournament/reset";
import { tabNames } from "../../constants/tabNames";
import { setTab } from '../../redux/ducks/tabDuck';

const {
    home, users, players, games, aclGroups, aclResources,
    aclUsers, cms, spin, profile, settings, spinList,
    tournamentPrizeTemplates, tournamentTemplates, createTournament, tournamentList, tickets,bazarBlot
} = p;

class SideBarMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            droopDownName:'',
            activeItem: '',
            permission: store.getState().userReducer['permissions']
        };
        this.dropRef = React.createRef();
    }

    handleItemClick = (e) => {
        if(this.props.currentTab === tabNames[e.target.name.toUpperCase()]) return;
        this.props.setTab(tabNames[e.target.name.toUpperCase()]);
        this.props.leftDropDownMenuControl(false);
        this.props.setSelectedTournamentData(reset);
        this.props.resetDateAndTime({});
        this.props.resetRequestMessage('');
        this.props.resetPrizeRule(null);
        this.props.resetLoader();
        this.setState({activeItem: e.target.name});
    };
    openDropDown = (e) => {
        const {status} = this.props;
        this.setState({droopDownName:e})
        this.props.leftDropDownMenuControl(e === status ? false : e);

    };

    logOut = () => {
        this.props.logOut();
    };


    componentDidMount() {
        let div = this.dropRef.current;
        if (div) {
            div.ref.current.setAttribute('aria-expanded', this.props.status)
        }
    }

    render() {
        const {activeItem, open, permission,droopDownName} = this.state;
        const {status} = this.props;
        return (
            <Menu vertical>
                {permission && permission[home] ?
                    <NavLink
                        to={`/admin`}
                        name="home"
                        className={activeItem === 'home' ? 'item active' : 'item'}
                        onClick={this.handleItemClick}
                        replace
                    >
                        <Icon name="home"/>
                        Home
                    </NavLink> : null
                }
                {permission && permission[players] ?
                    <NavLink
                        to={`/admin/players`}
                        name="players"
                        className={activeItem === 'players' ? 'item active' : 'item'}
                        onClick={this.handleItemClick}
                        replace
                    >
                        Players
                        <Icon name="user secret"/>
                    </NavLink> : null
                } {permission && permission[tickets] ?
                    <NavLink
                        to={`/admin/tickets`}
                        name="tickets"
                        className={activeItem === 'tickets' ? 'item active' : 'item'}
                        onClick={this.handleItemClick}
                        replace
                    >
                        Tickets
                        <Icon name="user secret"/>
                    </NavLink> : null
                }
                {permission && permission[games] ?
                    <NavLink
                        to={`/admin/games`}
                        name="games"
                        className={activeItem === 'games' ? 'item active' : 'item'}
                        onClick={this.handleItemClick}
                        replace
                    >
                        Games
                        <Icon name="game"/>
                    </NavLink> : null}
                        {permission && permission[games] ?
                    <NavLink
                        to={`/admin/bazar-blot`}
                        name="bazarBlot"
                        className={activeItem === 'bazarBlot' ? 'item active' : 'item'}
                        onClick={this.handleItemClick}
                        replace
                    >
                        Bazar Blot
                        <Icon name="game"/>
                    </NavLink> : null}
                {permission && (permission[tournamentList] || permission[createTournament] || permission[tournamentTemplates] || permission[tournamentPrizeTemplates]) ?

                    <Dropdown
                        ref={this.dropRef}
                        item
                        defaultOpen={open}
                        text="Tournament"
                        name="tournaments"
                       className={status ? `visible item active ${droopDownName==='tournament'?'own-tournament':'not-active'}`: 'item '}
                        onClick={() => this.openDropDown('tournament')}
                    >
                        <Dropdown.Menu
                            className={
                                activeItem === 'tournaments' ||
                                activeItem === 'tournament list' ||
                                activeItem === 'create' ||
                                activeItem === 't-template' ||
                                activeItem === 'p-template'
                                    ? 'own own-tournament-item'
                                    : status === 'tournament' ? 'visible item active own': ''
                            }
                        >
                            {permission && permission[tournamentList] ?
                                <NavLink
                                    to={`/admin/tournaments`}
                                    name="tournament list"
                                    className={
                                        activeItem === 'tournament list' ? 'item active own' : 'item own'
                                    }
                                    onClick={this.handleItemClick}
                                    replace
                                >
                                    Tournament list{' '}
                                </NavLink> : null}
                            {permission && permission[createTournament] ?
                                <NavLink
                                    to={`/admin/create-tournament`}
                                    name="create"
                                    className={activeItem === 'create' ? 'item active own' : 'item own'}
                                    onClick={this.handleItemClick}
                                    replace
                                >
                                    Create a tournament{' '}
                                </NavLink> : null}
                            {permission && permission[tournamentTemplates] ?

                                <NavLink
                                    to={`/admin/tournament-template`}
                                    name="t-template"
                                    className={activeItem === 't-template' ? 'item active own' : 'item own'}
                                    onClick={this.handleItemClick}
                                    replace
                                >
                                    Tournament template{' '}
                                </NavLink> : null}
                            {permission && permission[tournamentPrizeTemplates] ?

                                <NavLink
                                    to={`/admin/all-prize-rules`}
                                    name="p-template"
                                    className={activeItem === 'p-template' ? 'item active own' : 'item own'}
                                    onClick={this.handleItemClick}
                                    replace
                                >
                                    Prize template{' '}
                                </NavLink> : null}
                        </Dropdown.Menu>
                    </Dropdown> : null}
                <Dropdown
                    //ref={this.dropRefSpin}
                    item
                    defaultOpen={open}
                    text="Spin"
                    name="spin"
                    className={status ? `visible item active ${droopDownName==='spin'?'own-spin':'not-active'}` : 'own'}
                    onClick={() => this.openDropDown('spin')}
                >
                    <Dropdown.Menu
                        className={
                            activeItem === 'spin' ||
                            activeItem === 'spinReport' ||
                            activeItem === 'spinList'
                                ? 'own own-spin-item'
                                : status === 'spin' ? 'visible item active own' : ''
                        }
                    >
                        {permission && permission[spin] ?
                            <NavLink
                                to={`/admin/spinReport`}
                                name="spinReport"
                                className={activeItem === 'spinReport' ? 'item active own' : 'item own'}
                                onClick={this.handleItemClick}
                                replace
                            >
                                Spin Report

                            </NavLink> : null}
                        {permission && permission[spinList] ?
                            <NavLink
                                to={`/admin/spinList`}
                                name="spinList"
                                className={activeItem === 'spinList' ? 'item active own' : 'item own'}
                                onClick={this.handleItemClick}
                                replace
                            >
                                Spin List

                            </NavLink> : null}
                    </Dropdown.Menu>
                </Dropdown>
                {permission && permission[cms] ?
                    <NavLink
                        to={`/admin/cms`}
                        name="cms"
                        className={activeItem === 'cms' ? 'item active' : 'item'}
                        onClick={this.handleItemClick}
                        replace
                    >
                        CMS
                        <Icon name="universal access"/>
                    </NavLink> : null}

                {permission && (permission[aclGroups] || permission[aclResources] || permission[aclUsers]) ?
                    <NavLink
                        to={`/admin/managment`}
                        name="managment"
                        className={activeItem === 'managment' ? 'item active' : 'item'}
                        onClick={this.handleItemClick}
                        replace
                    >
                        ACL Managment
                        <Icon name="book"/>
                    </NavLink> : null
                }
                {permission && permission[users] ?
                    <NavLink
                        to={`/admin/users`}
                        name="users"
                        className={activeItem === 'users' ? 'item active' : 'item'}
                        onClick={this.handleItemClick}
                        replace
                    >
                        Backoffice Users
                        <Icon name="users"/>
                    </NavLink> : null
                }


                {permission && permission[settings] ?
                    <NavLink
                        to={`/admin/settings`}
                        name="setting"
                        className={activeItem === 'setting' ? 'item active' : 'item'}
                        onClick={this.handleItemClick}
                        replace
                    >
                        Settings
                        <Icon name="settings"/>
                    </NavLink> : null}
                {permission && permission[profile] ?
                    <NavLink
                        to={`/admin/profile`}
                        name="profile"
                        className={activeItem === 'profile' ? 'item active' : 'item'}
                        onClick={this.handleItemClick}
                        replace
                    >
                        Profile
                        <Icon name="address card"/>
                    </NavLink> : null}

                <Menu.Item
                    name="logout"
                    active={activeItem === 'logout'}
                    onClick={this.logOut}
                    className="log-out-a"
                >
                    <Icon name="sign-out"/>
                    Logout
                </Menu.Item>
            </Menu>
        );
    }
}

const mapStateToProps = ({menuDropDownControlReducer, tabReducer}) => ({
    status: menuDropDownControlReducer,
    currentTab: tabReducer.currentTab,
});
const mapDispatchToProps = {
    logOut,
    leftDropDownMenuControl,
    setSelectedTournamentData,
    resetDateAndTime,
    resetRequestMessage,
    resetPrizeRule,
    resetLoader,
    setTab,
};

export default connect(mapStateToProps, mapDispatchToProps)(SideBarMenu);
