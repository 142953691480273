import React, {PureComponent} from 'react';
import {Icon, Modal} from "semantic-ui-react";

export default function HocModal( MyComponent, data) {
    return class extends PureComponent {
        constructor(props) {
            super(props);
            this.state = {
                open: true,
                dimmer: 'blurring',
                data: data
            };
        }

        render() {
            const {open, dimmer, data} = this.state;
            return (
                <div>
                    <Modal dimmer={dimmer} open={open} onClose={data.close} className={'edit-or-copy'}>
                        {data.title === 'Player Settings'?  <div className='close' onClick={()=>data.close()}><Icon name="delete" style={{padding: '12px'}}/> </div>:null}
                        <Modal.Header>
                            {`${data.title}  ${data.name}`}

                        </Modal.Header>
                        <Modal.Content> <MyComponent  {...data} /></Modal.Content>
                    </Modal>
                </div>

            );
        }
    }
}
