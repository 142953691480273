import React, { useEffect, useState } from 'react';
import {Button, Pagination, Select, Input, Loader, Confirm} from 'semantic-ui-react';
import {
    getAllTournamentsTemplatesList,
    getCurrentTournamentByName,
    deleteTournamentTemplate,
    searchTournamentsTemplates

} from '../../redux/actions/tournamentsAction';

import {  resetRequestMessage, leftDropDownMenuControl } from '../../redux/actions/otherActions';
import { connect } from 'react-redux';
import TemplatesTournamentsTable from '../../components/TournamentsTemplateTable';
import ModalComponentContent from '../../components/ModalComponentContent';
import './tournament.css';


const rowItems = [
    // { key: '10', text: '10 rows', value: '10' },
    // { key: '30', text: '20 rows', value: '20' },
    { key: '50', text: '50 rows', value: '50' },
    { key: '100', text: '100 rows', value: '100' },
];

function TournamentTemplate({...redux }) {
    const {

        templatesData,
        totalPagesReducer,
        getAllTournamentsTemplatesList,
        requestMessage,
        resetRequestMessage,
        getCurrentTournamentByName,
        deleteTournamentTemplate,
        searchTournamentsTemplates,
        leftDropDownMenuControl,
        loader
    } = redux;

    const columns = [
        { key: 1, value: 'id', text: 'id' },
        { key: 2, value: 'name', text: 'Name' },
        { key: 3, value: 'minPlayers', text: 'Min. Players' },
        { key: 4, value: 'maxPlayers', text: 'Max. Players' },
        { key: 5, value: 'prizePool', text: 'Prize Pool' },
    ];

    const [sortedField, setSortedField] = useState('');
    const [direction, setDirection] = useState('DESC');
    const [activePage, setActivePage] = useState('');
    const [rowCount, setRowCount] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const [type, setType] = useState('');
    const [openUniversalModal, setOpenUniversalModal] = useState(false);
    const [tempName, setTempName] = useState(null);
    const [componentName, setComponentName] = useState(false);
    const [filterDateForSorting, setFilterDateForSorting] = useState({});
    const [limit, setLimit] = useState(50);
    const [offset, setOffset] = useState(0);
    const [confirmModal, setOpenConfirmModal] = useState(false);
    const [id, setId] = useState(null);



  useEffect(() => {
    if(tempName && tempName.trim()){
      searchTournamentsTemplates({ name:tempName.trim(), limit, offset });
    }else if(tempName === ''){
      getAllTournamentsTemplatesList({ limit, offset});
    }
    leftDropDownMenuControl('tournament')
  },[tempName])

    useEffect(() => {
        let setTimeOutId;
        if (!openModal) {
            if(tempName && tempName.trim()){
                searchTournamentsTemplates({
                            name:tempName.trim(),
                            offset,
                            orderBy: sortedField,
                            order: direction,
                            ...filterDateForSorting,
                            limit,
                        });
            }
            else{
                getAllTournamentsTemplatesList({ limit, offset});
            }

        }
        if (requestMessage && !requestMessage.error) {
            setTimeOutId = setTimeout(() => {
              if (openUniversalModal) {
                setOpenUniversalModal(false);
              }
                closeCreateModal();
            }, 1000);
        }
        return () => clearTimeout(setTimeOutId);
    }, [requestMessage, limit]);


    const getRowCount = (e, data) => {
        let activePage = Math.floor(offset / limit) + 1;
        setLimit(+data.value);
        setOffset( data.value > rowCount ? 0 : +data.value)
        setRowCount(0);
        setActivePage(activePage);
    };
    const onPageChange = (e, data) => {
        if(tempName && tempName.trim()){
            searchTournamentsTemplates({
                name:tempName.trim(),
                offset: (data.activePage - 1) * limit,
                orderBy: sortedField,
                order: direction,
                ...filterDateForSorting,
                limit,
            });
       }
        else{ getAllTournamentsTemplatesList({
                offset: (data.activePage - 1) * limit,
                orderBy: sortedField,
                order: direction,
                ...filterDateForSorting,
                limit,

            });

        }
        setOffset((data.activePage - 1) * limit);
        setActivePage(data.activePage);
        setRowCount(() => data.activePage * limit - limit);

    };
    const getValue = (e) => {
        setTempName(e.target.value)
        setActivePage(1);
        setOffset(0);

    }


    const closeCreateModal = () => {
      if (!!requestMessage) resetRequestMessage('');
      setOpenModal(false);
      getAllTournamentsTemplatesList({ limit, offset});
    };
    const close = () => {
        //  if (!!requestMessage) resetRequestMessage('');
        setOpenUniversalModal(false);
    };

    const action = (type, name, id) => {
        if (type === 'Edit') {
            getCurrentTournamentByName({ name });
            setOpenUniversalModal(true);
            setType(type);
            setComponentName('EditorCopyTemplateTournament');
        } else if (type === 'Copy') {
            getCurrentTournamentByName({ name });
            setOpenUniversalModal(true);
            setType(type);
            setComponentName('EditorCopyTemplateTournament');
        } else if (type === 'Create') {
            setOpenUniversalModal(true);
            setType(type);
            setComponentName('CreateTemplateTournament');
        } else if (type === 'Delete') {
            setId(id);
            setOpenConfirmModal(true);
        }

    };

    const deleteTemplate=()=>{
        deleteTournamentTemplate({id});
        setOpenConfirmModal(false);
    };
    const sorting = (field, direction) => {
      if(tempName && tempName.trim()){
        searchTournamentsTemplates({
          name:tempName.trim(),
          orderBy: field,
          order: direction,
          ...filterDateForSorting,
          limit,
          offset: 0,
        });
      }
      else{
        getAllTournamentsTemplatesList({
          orderBy: field,
          order: direction,
          ...filterDateForSorting,
          limit,
          offset: 0,
        });
      }
      setSortedField(field);
      setActivePage(1);
      setDirection(direction);
      setRowCount(0);

    };

    return (
        <div className="tournament-content">
            {confirmModal ?
                <Confirm
                    className={'own-confirm'}
                    open={confirmModal}
                    content='Are you sure, you want to delete this template?'
                    onCancel={()=>setOpenConfirmModal(false)}
                    onConfirm={deleteTemplate}
                    confirmButton={'Delete'}
                />: null}
            <div className="page-title min-width">
                {openUniversalModal && (
                    <ModalComponentContent
                        componentName={componentName}
                        title={type}
                        close={close}
                        name={'tournament template'}
                        id={id}
                    />
                )}
                <h2>Tournaments Templates</h2>
              <div
                className={
                  requestMessage && requestMessage.error ? 'req-mess err set' : 'req-mess set'
                }
              >
                {requestMessage && requestMessage.message}
              </div>
                {!loader ?
                <div>
                    <Input
                      label={'Search'}
                            placeholder={'Search by Name...'}
                            value={tempName !== null?tempName:''}
                            onChange={getValue}
                        />

                    <Button
                        primary
                        className="create-user btn belote-green"
                        onClick={() => action('Create')}
                    >
                        Create Template
                    </Button>
                </div>:null }
            </div>

            {!loader ? templatesData && templatesData.length ? (
                <>
                    <div className="row-count">
                        <div className="label">Rows count per page</div>
                        <div>
                            <Select
                                selectOnBlur={false}
                                className="own"
                                placeholder={`${limit} rows`}
                                options={rowItems}
                                onChange={getRowCount}
                            />
                        </div>
                    </div>
                    <div className='table-and-pg'>
                    <TemplatesTournamentsTable
                        data={templatesData}
                        cols={columns}
                        action={action}
                        sorting={sorting}
                        count={rowCount}
                    />
                    <Pagination
                        activePage={activePage}
                        totalPages={totalPagesReducer}
                        className="pagi-own"
                        onPageChange={totalPagesReducer > 1 ? onPageChange : null}
                    />
                    </div>
                </>
            ) : (
                <div className="error-mes date-err">No data was found</div>
            ): <Loader active> Loading ... </Loader>}
        </div>
    );
}

const mapStateToProps = ({ tournamentTemplatesListReducer, totalPagesReducer, requestMessage, loaderReducer}) => ({
    templatesData:tournamentTemplatesListReducer,
    totalPagesReducer,
    requestMessage,
    loader: loaderReducer,
});

const mapDispatchToProps = {
    getAllTournamentsTemplatesList,
    resetRequestMessage,
    deleteTournamentTemplate,
    getCurrentTournamentByName,
    searchTournamentsTemplates,
    leftDropDownMenuControl

};

export default connect(mapStateToProps, mapDispatchToProps)(TournamentTemplate);
