import React, {Component} from 'react';
import {Select, Button,} from 'semantic-ui-react';
import {
    createTournamentTemplate,
    updateTournamentTemplate,
    setDateAndTime,
    getPrizeRules,
    getPrizeRuleName,
    setSelectedTournamentData,
    setPrizeDataFromTournamentTemplate,
    getCurrentTournamentByName,
    resetDateAndTime,
    getAllTournamentsTemplates,
    savePrizeRules,
    updatePrizeTable,
    resetPrizeRule
} from '../redux/actions/tournamentsAction';
import {leftDropDownMenuControl, resetRequestMessage} from '../redux/actions/otherActions';
import {connect} from 'react-redux';
import '@trendmicro/react-datepicker/dist/react-datepicker.css';
import '../pages/tournament/createTournament/createTournament.css';
import Prize from '../pages/tournament/createTournament/prize';
import ModalForMessages from './ModalForMessages';


class OpenPrizeRuleTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: true,
            dimmer: 'blurring',
            prizeTemplateId: null,
            resetTemplate: false,
            prize: {},
            gameType: [
                {key: '11', text: 'CLASSIC', value: 'CLASSIC'},
                {key: '22', text: 'OPEN', value: 'OPEN'},
            ],

            points: [
                {key: '1', text: '51', value: '51'},
                {key: '2', text: '101', value: '101'},
                // {key: '3', text: '1 Round', value: '1Round'},
                // {key: '4', text: '2 Round', value: '2Round'},
                // {key: '5', text: '3 Round', value: '3Round'},
                // {key: '6', text: '4 Round', value: '4Round'},
                // {key: '7', text: '5 Round', value: '5Round'},
                // {key: '8', text: '6 Round', value: '6Round'},
                // {key: '9', text: '7 Round', value: '7Round'},
            ],
            time: [
                {key: '21', text: '20 s', value: '20'},
                {key: '22', text: '30 s', value: '30'},
                {key: '23', text: '40 s', value: '40'},
                {key: '24', text: '50 s', value: '50'},
            ],
            disTime: [
                {key: '1', text: '30 s', value: '30'},
                {key: '2', text: '1 m', value: '60'},
                {key: '3', text: '2 m', value: '120'},
                {key: '4', text: '3 m', value: '180'},
            ],

        };
    }

    componentDidMount() {
        this.props.leftDropDownMenuControl(true);
    }

    // componentDidUpdate() {
    //   const { requestMessage } = this.props;
    //   if (!!requestMessage && !requestMessage.error) {
    //     console.log("componentDidUpdate")
    //   }
    // }

    action = (type) => {
        const {prize, resetTemplate} = this.state;
        const {savePrizeRules, updatePrizeTable, resetPrizeRule} = this.props;
        const changeValuesToInteger = [];
        for (let i in prize.prizeDistribution) {
            changeValuesToInteger.push({
                position: +i + 1,
                percent: +prize.prizeDistribution[i].percentTotal,
            });
        }

        //  console.log(changeValuesToInteger, 'changeValuesToInteger');
        const data = {
            prizePool: +prize.prizePool,
            winners: +prize.winners,
            prizeDistribution: changeValuesToInteger,
            prizeRuleName: prize.prizeRuleName
        };


        if (type === 'Edit') {
            // console.log(data, 'Copy-PrizeRules');
            updatePrizeTable(data);
        } else if (type === 'Copy') {
            // console.log(data, 'Copy-PrizeRules');
            savePrizeRules(data);
        } else if (type === 'Create') {
            console.log(data, 'Create-PrizeRules');
            savePrizeRules(data);
            //resetPrizeRule(null)
            //this.setState({resetTemplate: !resetTemplate})

        }
    };

    getPrizeTemplateId = (e, data) => {
        const {tournamentPrizeReducer, getPrizeRuleName} = this.props;
        if (data.value) {
            let title = '';
            for (let el of tournamentPrizeReducer) {
                if (el.value === data.value) {
                    title = el.text;
                }
            }
            this.setState({
                prizeTemplateId: data.value,
            });
            getPrizeRuleName({title});
        } else {
            this.props.setPrizeDataFromTournamentTemplate({data: []});
            this.setState({
                resetTemplate: true
            })
        }
    };

    setPrizeData = (data) => {

        this.setState({
            ...this.state.prize,
            prize: data,
            resetTemplate: false
        });
    };

    isHighlighted = (e) => {
        this.props.setSelectedTournamentData({...this.props.tournamentData, isHighlighted: !e});
    };

    // shouldComponentUpdate() {
    //     const { requestMessage } = this.props;
    //     return !!requestMessage.error
    // }

    render() {

        const {
            requestMessage,
            resetRequestMessage,
            tournamentPrizeReducer,
            close,
            title,
            disable,
        } = this.props;
        const {
            resetTemplate
        } = this.state;
        return (
            <div style={{width: '100%'}}>


                <div className="create-tournament-content">
                    {!!requestMessage ? (
                        <ModalForMessages
                            error={requestMessage.error}
                            message={requestMessage.message}
                            close={() => resetRequestMessage('')}
                        />
                    ) : null}
                </div>
                <div className="input-box min-height-250 border-bottom ">
                    <Prize setPrizeData={this.setPrizeData} editOrCopy={true} disable={disable}
                           resetTemplate={resetTemplate}/>

                    <div className="check-box">
                        <div className="label">Prize Templates</div>
                        <Select
                            selectOnBlur={false}
                            disabled={disable}
                            clearable
                            placeholder="Templates"
                            options={tournamentPrizeReducer}
                            onChange={this.getPrizeTemplateId}
                        />
                    </div>


                </div>
                <div className="create-tournament-actions prize">
                    {/*<div className={requestMessage.error ? 'req-mess err' : 'req-mess'}>*/}
                    {/*    {requestMessage.message}*/}
                    {/*</div>*/}
                    <Button
                        positive
                        icon="checkmark"
                        labelPosition="right"
                        content={title === 'Edit' ? 'Save' : 'Create'}
                        onClick={() => this.action(title)}
                    />

                    <Button color="black" onClick={close}>
                        Cancel
                    </Button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({
                             requestMessage,
                             tournamentPrizeReducer,
                             tournamentTemplatesReducer,

                         }) => ({
    requestMessage,
    tournamentPrizeReducer,
    tournamentTemplates: tournamentTemplatesReducer,
});

const mapDispatchToProps = {
    setDateAndTime,
    getPrizeRules,
    getPrizeRuleName,
    resetRequestMessage,
    leftDropDownMenuControl,
    setSelectedTournamentData,
    setPrizeDataFromTournamentTemplate,
    getCurrentTournamentByName,
    resetDateAndTime,
    getAllTournamentsTemplates,
    createTournamentTemplate,
    resetPrizeRule,
    updateTournamentTemplate,
    savePrizeRules,
    updatePrizeTable

};

export default connect(mapStateToProps, mapDispatchToProps)(OpenPrizeRuleTemplate);

