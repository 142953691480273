import React from "react";
import { Checkbox, Icon, Input} from "semantic-ui-react";
import {editPlaceHolder, deleteMedia} from '../../redux/actions/cmsAction'
import {connect} from "react-redux";


function MyCard({id, src, name, isIn, placeHolderId, ...redux}) {
    const {allPlaceHolders, editPlaceHolder, deleteMedia} = redux


      const getPriority = (e, name) => {
        const arr = allPlaceHolders[placeHolderId] ? allPlaceHolders[placeHolderId].filter((el) => el.content === name) : []

        if (arr.length) {
            arr[0].priority = +e.target.value
        } else {
            allPlaceHolders[placeHolderId].push({
                priority: +e.target.value,
                content: name,
            })
        }
        editPlaceHolder({...allPlaceHolders})

    }
    const deleteImage= (id) => {
        deleteMedia({id},placeHolderId)
    }
    const checkedStatus = (name) => {
        for (const [i, el] of allPlaceHolders[placeHolderId].entries()) {
            if (el.content === name) {
                allPlaceHolders[placeHolderId].splice(i, 1)
            }
        }
        editPlaceHolder({...allPlaceHolders})

    }

    return (
        <div className={'div-card'}>
            <div className={'align-right'}>
                <Icon name={'delete'} onClick={() => deleteImage(id)}/>
            </div>
            <div className={'img-cms  m-auto'}
                 style={{backgroundImage: `url(${src})`}}/>
            <div className='margin-6-12 pri-cms-inp'>
                <div className="label m-auto">Priority</div>
                <Input
                    className="pri-width m-auto"
                    type="number"
                    size={'mini'}
                    value={isIn ? isIn.priority : ''}
                    onChange={(e) => getPriority(e, name)}
                    min={0}
                />
            </div>
            <div className='margin-6-12'>
                <Checkbox label='Make  image visible' checked={isIn ? Boolean(isIn.priority) : false}
                          onChange={() => checkedStatus(name)}/>
            </div>
        </div>
    )

}


const mapStateToProps = ({cmsBannersDataForUpdate, allPlaceHolders}) => ({
    imgData: cmsBannersDataForUpdate,
    allPlaceHolders

});

const mapDispatchToProps = {
    editPlaceHolder,
    deleteMedia
};

export default connect(mapStateToProps, mapDispatchToProps)(MyCard);



