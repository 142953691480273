import { getTopSidePlayer2 } from '../../../helpers/game';
import Opponent from '../reusables/Opponent/Opponent';
import React from 'react';

export default function OpponentContainerHR({
    trumper,
    trumpSuit,
    cloudText,
    bottomPlayerId,
    owner,
    opponent,
    isPlayersTurn,
    replayDisconnectTimerText,
}) {
    const { avatar: avatarId, id: playerId, remoteId: visualId } = getTopSidePlayer2(bottomPlayerId, owner, opponent);
    const chosenTrumpSuit = playerId === trumper.id && trumpSuit;

    return (
        <Opponent
            {...{
                cloudText,
                chosenTrumpSuit,
                playerId,
                visualId,
                avatarId,
                isPlayersTurn,
                replayDisconnectTimerText,
                withoutTimer: true,
            }}
        />
    );
}
