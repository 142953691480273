import { useState } from 'react';

const useToggle = (initial = false) => {
    const [status, setToggle] = useState(initial);

    const toggle = () => setToggle((prev) => !prev);
    const reset = () => setToggle(initial);

    return [status, toggle, setToggle, reset];
};

export default useToggle;
