import React, {useEffect, useState} from 'react';
import {
    getTournamentPlayers,
    cutPlayerFromTournament,
    getWinners,
    resetTabInfo, getAdminAddedPlayers, updateAdminAddedPlayerList,
} from '../redux/actions/tournamentsAction';
import {resetRequestMessage} from '../redux/actions/otherActions';
import {getRoundsForPlayer, getPlayerTransactions, getPlayerSessions, getPlayerTickets} from '../redux/actions/playersActions';
import {Button, Table, Select, Pagination, Confirm} from 'semantic-ui-react';
import {connect} from 'react-redux';
import GameRoundsFilter from './GameRoundsFilter';
import TransactionsFilter from './TransactionsFilter';
import p from '../permissionsConstants';
import moment from 'moment';
import HocModal from './HOC';
import PlayersList from './PlayersList';
import {Link} from 'react-router-dom';
import TicketFilter from "./TicketFilter";

const {unregistered} = p;

const betKind = {
    0:'CASH',
    1:'SPIN',
    2:'TOURNAMENT'
};
const rowItems = [
    // { key: '10', text: '10 rows', value: '10' },
    // { key: '30', text: '20 rows', value: '20' },
    {key: '50', text: '50 rows', value: '50'},
    {key: '100', text: '100 rows', value: '100'},
];
const tournamentStates = [3, 6, 7, 10];

function SortTable({winnersFor, tournamentId, params, sortable, data, ...redux}) {
    const [confirmModal, setOpenConfirmModal] = useState(false);
    const [error, setError] = useState('');
    const [obj, setObj] = useState({tournamentId: '', playerId: ''});
    const [column, setColumn] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [limit, setLimit] = useState(50);
    const [offset, setOffset] = useState(0);
    const [filerDateForSorting, setFilerDateForSorting] = useState({});
    const [sortedField, setSortedField] = useState('');
    const [activePage, setActivePage] = useState(1);
    const [direction, setDirection] = useState('');
    const [rowCount, setRowCount] = useState(0);
    const {
        getTournamentPlayers,
        dataReducer,
        requestMessage,
        cutPlayerFromTournament,
        resetRequestMessage,
        getWinners,
        resetTabInfo,
        getRoundsForPlayer,
        totalPagesReducer,
        getPlayerTransactions,
        getPlayerSessions,
        permissions,
        getAdminAddedPlayers,
        adminAddedPlayers,
        updateAdminAddedPlayerList,
        toggleType,
        getPlayerTickets
    } = redux;

    const keys = data.keys;
    const remoute_keys = data.remoute_keys;
    const requestType = data.type;

    const getData = (type, obj) => {

        switch (type) {
            case 'regPlayers':
                getTournamentPlayers({...obj});
                break;
            case 'transactions':
                getWinners({...obj, toggleType: toggleType === 'Ajarabet'}, winnersFor);
                break;
            case 'gameTables':
                getRoundsForPlayer({...obj, toggleType: toggleType === 'Ajarabet'});
                break;
            case 'playerTransactions':
                getPlayerTransactions({...obj, toggleType: toggleType === 'Ajarabet'});
                break;
            case 'playerTickets':
                getPlayerTickets({...obj, toggleType: toggleType === 'Ajarabet'});
                break;
            case 'playerSessions':
                getPlayerSessions({...obj});
                break;
            case 'getAdminAddedPlayersList':
                getAdminAddedPlayers({...obj});
                break;
            default:
                return null;
        }
    };

    useEffect(() => {

        getData(requestType, {...params, limit, offset});


    }, [params.playerId]);

    useEffect(() => {
        //resetTabInfo([]);
        setLimit(50);
        setOffset(0);
        getData(requestType, {...params, limit: 50, offset: 0});
        return () => resetTabInfo([]);
    }, [data]);


    useEffect(() => {
        let setTimeOutId;
        if (requestMessage && requestMessage.message !== data.message) {
            if (showModal) {
                setShowModal(false);
                updateAdminAddedPlayerList({data: []});
            }
            setTimeOutId = setTimeout(() => {
                getData(requestType, {...params, limit, offset});
                resetRequestMessage('');
            }, 1000);
        }
        return () => {
            clearTimeout(setTimeOutId);

        };
    }, [requestMessage]);

    const getRowCount = (e, data) => {
        let activePage = Math.floor(offset / limit) + 1;
        setLimit(+data.value);
        getData(requestType,
            {
                ...params,
                limit: +data.value,
                offset: data.value > rowCount ? 0 : +data.value,
                ...filerDateForSorting,
            });
        setRowCount(0);
        setActivePage(activePage);
    };

    const onPageChange = (e, data) => {
        getData(requestType,
            {
                ...params,
                offset: (data.activePage - 1) * limit,
                orderBy: sortedField,
                order: direction,
                ...filerDateForSorting,
                limit,
            });

        setOffset((data.activePage - 1) * limit);
        setActivePage(data.activePage);
        setRowCount(() => data.activePage * limit - limit);

    };
    const getAdminAddedPlayersList = () => {
        getData('getAdminAddedPlayersList', {tournamentId: params.tournamentId});
        setShowModal(true);

    };
    const filterGames = (data) => {
        if (data) {
            getData(requestType,
                {
                    ...params,
                    ...data,
                    limit: 50,
                    offset: 0,
                });
        } else {
            getData(requestType,
                {
                    ...params,
                    limit: 50,
                    offset: 0,
                });
            resetRequestMessage('');
        }
        // setOffset(0);
        // setActivePage(1);
        // setRowCount(0);
        setFilerDateForSorting({...data});
    };


    const unregister = (tournamentId, playerId) => {
        //console.log({tournamentId, playerId}, "{tournamentId, playerId}");
        setOpenConfirmModal(true);
        setError('');
        setObj((obj) => ({
            ...obj,
            tournamentId,
            playerId,
        }));
    };

    const sorting = (field, direction) => {
        getData(requestType, {
            ...params,
            orderBy: field,
            order: direction,
            ...filerDateForSorting,
            limit,
            offset: 0,
        });
        //setSortedField(field);
        setActivePage(1);
        //setDirection(direction);
        setRowCount(0);
    };


    const close = () => {
        setShowModal(false);
    };
    const handleSort = (clickedColumn) => () => {
        if (column !== clickedColumn) {
            setColumn(clickedColumn);
            setDirection('ascending');
        }
        setDirection((direction) => {
            return direction === 'ascending' ? 'descending' : 'ascending';
        });

        sorting(clickedColumn, direction === 'descending' ? 'ASC' : 'DESC');
    };

    const AdminAddedPlayers = HocModal(PlayersList, {
        title: 'Players List',
        close,
        id: params.tournamentId,
        name: '',
        tournamentStates,
    });

    return (
        <>
            {confirmModal ?
                <Confirm
                    className={'own-confirm'}
                    open={confirmModal}
                    content='Are you sure, you want unregister this player?'
                    onCancel={() => setOpenConfirmModal(false)}
                    onConfirm={() => {
                        cutPlayerFromTournament(obj);
                        setOpenConfirmModal(false);
                    }}
                    confirmButton={'Delete'}
                /> : null}
            {showModal && !!adminAddedPlayers.length ?
                <AdminAddedPlayers/> :
                <div
                    className={
                        requestMessage && requestMessage.error ? 'req-mess err set' : 'req-mess set'
                    }
                >
                    {requestMessage && requestMessage.message}
                </div>


            }
            {data.showFilter &&
            data.type === 'gameTables' ? <GameRoundsFilter filterGames={filterGames}/> :
                data.type === 'playerTransactions' ?
                    <TransactionsFilter filterTransactions={filterGames}/> :
                    data.type === 'playerTickets' ?
                        <TicketFilter  filterTickets={filterGames}
                                       // reset={reset} startDate={startDate} startTime={startTime}
                                       // timeTo={timeTo}
                                  isFilterInTab ={true}
                        />:
                        null}
            <div
                className={
                    requestMessage && requestMessage.error ? 'req-mess err set' : 'req-mess set'
                }
            >
                {requestMessage && requestMessage.message}
            </div>
            {dataReducer.length ?
                <>
                    {permissions && permissions[unregistered] && data.type === 'regPlayers' ?
                        <Button onClick={getAdminAddedPlayersList}> Admin's Added Players List </Button> : null}
                    <div className="row-count">
                        <div className="label">Rows count per page</div>
                        <div>
                            <Select
                                selectOnBlur={false}
                                className="own"
                                placeholder={`${limit} rows`}
                                options={rowItems}
                                onChange={getRowCount}
                            />
                        </div>
                    </div>
                    <div className='table-and-pg'>
                        <Table sortable={sortable} celled fixed size="small" className={`${data.classname}`} selectable>
                            <Table.Header>

                                <Table.Row className={'header-own'}>
                                    <Table.HeaderCell className="rows-number">No:</Table.HeaderCell>
                                    {toggleType !== 'Ajarabet' || data.title ? data.cols.map((item, index) => {
                                        return (
                                            <Table.HeaderCell
                                                key={index}
                                                sorted={column === keys[index] ? direction : null}
                                                onClick={sortable ? handleSort(keys[index]) : null}
                                            >
                                                {item}
                                            </Table.HeaderCell>
                                        );
                                    }) : data.cols.map((item, index) => {
                                        return (
                                            <Table.HeaderCell
                                                key={index}
                                                sorted={column === remoute_keys[index] ? direction : null}
                                                onClick={sortable ? handleSort(remoute_keys[index]) : null}
                                            >
                                                {item}
                                            </Table.HeaderCell>
                                        );
                                    })


                                    }
                                    {permissions && permissions[unregistered] && data.type === 'regPlayers' && (
                                        <Table.HeaderCell> Action </Table.HeaderCell>
                                    )}
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {dataReducer.map((item, index) => {

                                    return (
                                        <Table.Row key={index}>
                                            <Table.Cell>{rowCount + ++index}</Table.Cell>
                                            {keys.map((el, key) => {
                                                if (el === 'id') {
                                                    return (
                                                        <Table.Cell key={key}>
                                                            <Link className='id-hover'
                                                                  to={`/admin/games/${item[el]}`}>{item[el]}</Link>
                                                        </Table.Cell>
                                                    );
                                                }
                                                if (el === 'player_id_guest' || el === 'player_id_host') {
                                                    return (
                                                        <Table.Cell key={key}>
                                                            <Link className="id-hover"
                                                                  to={`/admin/players/${item[el]}`}>{item[el]}</Link>
                                                        </Table.Cell>
                                                    );
                                                }
                                                if (el === 'created_at' || el === 'finished_at') {
                                                    return (
                                                        <Table.Cell key={key}>
                                                            &nbsp;{moment(item[el], 'YYYY-MM-DD HH:mm:ss').isValid() ? item[el] : '-'}
                                                        </Table.Cell>
                                                    );
                                                }
                                                if (el === 'scene_title' || el === 'table_type') {
                                                    return (
                                                        <Table.Cell key={key}>
                                                            &nbsp; {item[el] !== 'SINGLE' ? item[el] : 'CASH'}
                                                        </Table.Cell>
                                                    );
                                                }

                                                if (el === 'player_id' || el === 'player_id_remote') {
                                                    if (toggleType === 'Ajarabet' && el === 'player_id_remote') {
                                                        return (
                                                            <Table.Cell key={key}>
                                                                &nbsp; {item['player_id_remote']}
                                                            </Table.Cell>
                                                        );
                                                    } else if (toggleType !== 'Ajarabet' && el === 'player_id') {
                                                        return (
                                                            <Table.Cell key={key}>
                                                                &nbsp; {item['player_id']}
                                                            </Table.Cell>
                                                        );
                                                    }
                                                    return;
                                                }

                                                if (el === 'type') {
                                                    return (
                                                        <Table.Cell key={key}>
                                                            { betKind[item[el]] } {item['ref_id'] ? ` (${item['ref_id']})`: null}
                                                        </Table.Cell>
                                                    );
                                                }
                                                return (
                                                    <Table.Cell key={key}>
                                                        &nbsp;{el === 'ranking' && !item[el] ? 1 : item[el]}
                                                    </Table.Cell>
                                                );
                                            })}
                                            {permissions && permissions[unregistered] && data.type === 'regPlayers'
                                            && !tournamentStates.includes(item['state']) ? (
                                                <Table.Cell>
                                                    &nbsp;
                                                    <Button
                                                        className="margin-12"
                                                        content="Unregister"
                                                        onClick={() => {
                                                            unregister(
                                                                item['tournamentId'],
                                                                item['player_id'],
                                                            );
                                                        }}
                                                    />
                                                </Table.Cell>
                                            ) : null
                                            }
                                        </Table.Row>
                                    );
                                })}
                            </Table.Body>
                        </Table>
                        <Pagination
                            activePage={activePage}
                            totalPages={totalPagesReducer}
                            className="pagi-own"
                            onPageChange={totalPagesReducer > 1 ? onPageChange : null}
                        />
                    </div>
                </> :
                <div className="margin-12-top">
                    {   data.message } </div>
            }
        </>
    );
}


const mapStateToProps = ({
                             requestMessage, oneTournamentReducer, globalPlayerReducer,
                             totalPagesReducer, tournamentTabsReducer, userReducer, adminAddedPlayers,
                         }) => ({
    requestMessage,
    totalPagesReducer,
    tournamentData: oneTournamentReducer,
    dataReducer: tournamentTabsReducer,
    permissions: userReducer.permissions,
    adminAddedPlayers,
    toggleType: globalPlayerReducer,


});

const mapDispatchToProps = {
    resetRequestMessage,
    getTournamentPlayers,
    cutPlayerFromTournament,
    getWinners,
    resetTabInfo,
    getRoundsForPlayer,
    getPlayerTransactions,
    getPlayerSessions,
    getPlayerTickets,
    getAdminAddedPlayers,
    updateAdminAddedPlayerList,
};

export default connect(mapStateToProps, mapDispatchToProps)(SortTable);
