import React, { Component } from 'react';
import { Input, Button, Modal } from 'semantic-ui-react';
import { createNewGroup, createNewResource } from '../redux/actions/aclmanagmentAction';
import { editResource } from '../redux/actions/aclmanagmentAction';
import { connect } from 'react-redux';

class AddResourceOrGroup extends Component {
    state = {
        stateValue: '',
        kind: '',
        open: true,
        dimmer: 'blurring',
        error: '',
    };

    getResOrGroupName = (e) => {
        this.setState({
            stateValue: e.target.value,
            error: '',
        });
    };
    getResKindName = (e) => {
        this.setState({
            kind: e.target.value,
            error: '',
        });
    };
    close = () => {
        this.props.editResource({ open: false, name: null });
    };
    create = (title) => {
        const { stateValue, kind } = this.state;
        const { createNewGroup, createNewResource } = this.props;
        let obj = null;
        if (title === 'Add Resource' && stateValue !== '' && kind !== '') {
            obj = {
                path: stateValue,
                kind,
            };
            createNewResource(obj);
        } else if (title === 'Add Group' && stateValue !== '') {
            obj = {
                title: stateValue,
            };
            createNewGroup(obj);
        } else {
            this.setState({
                error: 'Empty data...',
            });
        }
    };

    render() {
        const { open, dimmer, error, stateValue, kind } = this.state;
        const { title, requestMessage } = this.props;
        return (
            <div>
                <Modal dimmer={dimmer} open={open} onClose={this.close}>
                    <Modal.Header>{title}</Modal.Header>
                    <Modal.Content>
                        <div className="input-box">
                            <div>
                                <Input
                                    placeholder={`Enter ${
                                        title === 'Add Group' ? 'Group Name' : 'Resource'
                                    } ...`}
                                    value={stateValue}
                                    onChange={this.getResOrGroupName}
                                />
                                {title === 'Add Resource' && (
                                    <Input
                                        placeholder={`Enter kind ...`}
                                        value={kind}
                                        onChange={this.getResKindName}
                                    />
                                )}
                                <span className="error-mes acl">{error}</span>
                            </div>
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <div className={requestMessage.error ? 'req-mess err ' : 'req-mess '}>
                            {' '}
                            {requestMessage.message}{' '}
                        </div>
                        <Button color="black" onClick={this.close}>
                            Cancel
                        </Button>
                        <Button
                            positive
                            icon="checkmark"
                            labelPosition="right"
                            content={title}
                            onClick={() => this.create(title)}
                        />
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = ({ requestMessage }) => ({
    requestMessage,
});

const mapDispatchToProps = {
    editResource,
    createNewGroup,
    createNewResource,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddResourceOrGroup);
