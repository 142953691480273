import React, {useState, useEffect, useRef} from 'react';
import {Button, Icon, Input, Select} from 'semantic-ui-react'
import {TimeInput} from '@trendmicro/react-datepicker';
import DatePick from './Datapiker/DataPicker';
import '@trendmicro/react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import {arrClass} from "./Datapiker/general";

const gameType = [
    {key: '11', text: 'CLASSIC', value: 'CLASSIC'},
    {key: '22', text: 'OPEN', value: 'OPEN'},
    {key: '23', text: 'TOURNAMENT', value: 'TOURNAMENT'},
    {key: '24', text: 'FREE ROL', value: 'FREE ROL'},
    {key: '25', text: 'SPIN', value: 'SPIN'},
    {key: '26', text: 'GLOBAL BLOCK', value: 'OPEN'},
];

const labelArr = ['red', 'yellow', 'green', 'blue', 'violet', 'black'];


const FilterPlayersList = ({filterGames, toggleType}) => {
    const [err, setErr] = useState('');
    const [id, setId] = useState('');
    const [selected, setSelected] = useState(0);
    const [opPlayerId, setOpPlayerId] = useState('');
    const [status, setStatus] = useState('');
    const [cashFrom, setCashFrom] = useState('');
    const [cashTo, setCashTo] = useState('');
    const [label, setLabel] = useState('');
    const [tournamentsPlayedFrom, setTournamentsPlayedFrom] = useState('');
    const [tournamentsPlayedTo, setTournamentsPlayedTo] = useState('');
    const [loginCountFrom, setLoginCountFrom] = useState('');
    const [loginCountTo, setloginCountTo] = useState('');
    const [showPicker, setShowPicker] = useState('');
    const [lastLoginFromDate, setLastLoginFromDate] = useState(moment().format('00-00-0000'));
    const [lastLoginFromTime, setLastLoginFromTime] = useState(moment().format('00:00:00'));
    const [lastLoginToTime, setLastLoginToTime] = useState(moment().format('00:00:00'));
    const [lastLoginToDate, setLastLoginToDate] = useState(moment().format('00-00-0000'));
    const [winRateFrom, setWinRateFrom] = useState('');
    const [winRateTo, setWinRateTo] = useState('');
    const [regToTime, setRegToTime] = useState(moment().format('00:00:00'));
    const [regFromTime, setRegFromTime] = useState(moment().format('00:00:00'));
    const [regToDate, setRegToDate] = useState(moment().format('00-00-0000'));
    const [regFromDate, setRegFromDate] = useState(moment().format('00-00-0000'));
    let refTo = useRef(null);
    let refFrom = useRef(null);

    const handleClick = (e) => {
        if ((showPicker === 'regFromDate' || showPicker === 'regToDate' || showPicker === 'lastLoginToDate'
            || showPicker === 'lastLoginFromDate'
        ) && refFrom.current && !arrClass.includes(e.target.className)) {
            setShowPicker('')
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClick);
        return () => {
            document.removeEventListener('click', handleClick);
        };
    });
    useEffect(() => {
        let setTimeoutId;
        if (err) {
            setTimeoutId = setTimeout(() => {
                setErr('')
            }, 1500)
        }
        return (() => clearTimeout(setTimeoutId))
    }, [err]);

    const closeLastLoginDatePicker = (date) => {
        switch (showPicker) {
            case 'lastLoginFromDate':
                setLastLoginFromDate(date);
                break;
            case 'lastLoginToDate':
                setLastLoginToDate(date)
                break;
            case 'regFromDate':
                setRegFromDate(date);
                break;
            case 'regToDate':
                setRegToDate(date);
                break;
            default:
                return null
        }
        setShowPicker('')
    };
    const handleValue = (stateName, e) => {
        let val;
        if (stateName === 'lastLoginFromTime' || stateName === 'lastLoginToTime' ||
            stateName === 'regToTime' || stateName === 'regFromTime') {
            val = e;
        } else {
            val = e.target.value;
        }

        switch (stateName) {
            case 'playerId':
                setId(val);
                break;
            case 'cashFrom':
                setCashFrom(+val);
                break;
            case 'cashTo':
                setCashTo(+val);
                break;
            case 'tournamentsPlayedFrom':
                setTournamentsPlayedFrom(+val);
                break;
            case 'tournamentsPlayedTo':
                setTournamentsPlayedTo(+val);
                break;
            case 'loginCountFrom':
                setLoginCountFrom(+val);
                break;
            case 'loginCountTo':
                setloginCountTo(+val);
                break;
            case 'winRateFrom':
                setWinRateFrom(+val);
                break;
            case 'winRateTo':
                setWinRateTo(+val);
                break;
            case 'lastLoginFromTime':
                setLastLoginFromTime(val);
                break;
            case 'lastLoginToTime':
                setLastLoginToTime(val);
                break;
            case 'regFromTime':
                setRegFromTime(val);
                break;
            case 'regToTime':
                setRegToTime(val);
                break;
            default:
                return null
        }
    };
    const getSelectValue = (name, data) => {
        if (data.value) {
            setStatus(data.value)
            setSelected(1)
        } else {
            setSelected(0)
            setStatus('')
        }
    };

    function isEmpty(obj) {
        for (let key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    const handleFilter = () => {
        if (showPicker) setShowPicker('');
        const dataFilter = {};
        const obj = {
            id: +id,
            regDateFrom: regFromDate + ' ' + regFromTime,
            regDateTo: regToDate + ' ' + regToTime,
            lastLoginFrom: lastLoginFromDate + ' ' + lastLoginFromTime,
            lastLoginTo: lastLoginToDate + ' ' + lastLoginToTime,
            label,
            winRateFrom,
            winRateTo,
            cashGamesPlayedFrom: cashFrom,
            cashGamesPlayedTo: cashTo,
            tournamentsPlayedFrom,
            tournamentsPlayedTo,
            loginsFrom: loginCountFrom,
            loginsTo: loginCountTo,
            status
        };
        if (toggleType === 'Ajarabet') {
            const remoteId = obj['id'];
            delete obj['id'];
            obj['remoteId'] = remoteId
        }

        for (let el in obj) {
            if (obj[el] && obj[el] !== "00-00-0000 00:00:00") {
                dataFilter[el] = obj[el]
            }
        }

        if (isEmpty(dataFilter)) {
            setErr('Empty data')
        } else {
            filterGames(dataFilter)
            setErr('')
        }
    };

    const clearAll = () => {
        setId('');
        setOpPlayerId('');
        setCashFrom('');
        setCashTo('');
        setStatus('');
        setTournamentsPlayedFrom('')
        setTournamentsPlayedTo('');
        setLoginCountFrom('');
        setloginCountTo('');
        setWinRateFrom('');
        setWinRateTo('');
        setLastLoginFromTime(moment().format('00:00:00'));
        setLastLoginToTime(moment().format('00:00:00'));
        setRegFromTime(moment().format('00:00:00'));
        setRegToTime(moment().format('00:00:00'));
        setRegToDate(moment().format('00-00-0000'));
        setRegFromDate(moment().format('00-00-0000'));
        setLastLoginFromDate(moment().format('00-00-0000'));
        setLastLoginToDate(moment().format('00-00-0000'));
        setSelected(0);
        setLabel('');
        filterGames({})
    };
    return (
        <>
            <div className="filter-inputs input-box-setting flex-wrap">
                <div className="margin-l input-box padding-0 flex-wrap">
                    <div className="check-box">
                        <div className="label">Player ID:</div>
                        <Input
                            placeholder="Player ID"
                            value={id}
                            onChange={(e) => handleValue('playerId', e)}
                            type={'number'}
                        />

                    </div>
                    {/*<div className="check-box">*/}
                    {/*    <div className="label">AB Player ID:</div>*/}
                    {/*    <Input*/}
                    {/*        placeholder="AB Player ID"*/}
                    {/*        value={opPlayerId}*/}
                    {/*        onChange={(e) => handleValue('opPlayerId', e)}*/}
                    {/*        type={'number'}*/}
                    {/*    />*/}
                    {/*</div>*/}
                    <div className="check-box">
                        <div className="label">
                            Label:
                            {
                                labelArr.map((item, key) => {
                                    return <Icon key={key} color={item ? item : 'lightgrey'}
                                                 className={label === item ? 'selected-icon pointer' : 'pointer'}
                                                 name='star' size={'large'} onClick={() => setLabel(item)}/>
                                })
                            }
                        </div>

                    </div>
                </div>
                <div className="margin-l input-box padding-0 flex-wrap">
                    <div className="check-box">
                        <div className="label">Block Status:</div>
                        {!selected ? <>
                            {status ? null : null}
                            <Select
                                selectOnBlur={false}
                                clearable
                                placeholder={'Choose Status'}
                                options={gameType}
                                onChange={(e, data) => getSelectValue('gameType', data)}
                            /></> : <Select
                            selectOnBlur={false}
                            clearable
                            placeholder={status}
                            options={gameType}
                            onChange={(e, data) => getSelectValue('gameType', data)}
                        />
                        }
                    </div>
                    <div className="check-box">
                        <div className="label">Cash Games Played:</div>
                        <div className="item">
                            <Input
                                label="From:"
                                value={cashFrom}
                                onChange={(e) => handleValue('cashFrom', e)}
                                type="number"
                                min={1}
                                size={"mini"}
                            />
                            <Input
                                label="To:"
                                value={cashTo}
                                onChange={(e) => handleValue('cashTo', e)}
                                type="number"
                                min={1}
                                size={"mini"}
                            />
                        </div>
                    </div>
                    <div className="check-box">
                        <div className="label">Tournaments Played:</div>
                        <div className="item">
                            <Input
                                label="From:"
                                value={tournamentsPlayedFrom}
                                onChange={(e) => handleValue('tournamentsPlayedFrom', e)}
                                type="number"
                                min={1}
                                size={"mini"}
                            />
                            <Input
                                label="To:"
                                value={tournamentsPlayedTo}
                                onChange={(e) => handleValue('tournamentsPlayedTo', e)}
                                type="number"
                                min={1}
                                size={"mini"}
                            />
                        </div>
                    </div>
                </div>
                <div className="margin-l input-box padding-0 flex-wrap">
                    <div className="check-box">
                        <div className="label">Amount of Logins:</div>
                        <div>
                            <Input
                                label="From:"
                                value={loginCountFrom}
                                onChange={(e) => handleValue('loginCountFrom', e)}
                                type="number"
                                min={1}
                                size={"small"}
                            />
                            <Input
                                label="To:"
                                value={loginCountTo}
                                onChange={(e) => handleValue('loginCountTo', e)}
                                type="number"
                                min={1}
                                size={"small"}
                            />
                        </div>
                    </div>

                    <div className="check-box">
                        <div className="label">Win Rate:</div>
                        <div className="item">
                            <Input
                                label="From: (%)"
                                value={winRateFrom}
                                onChange={(e) => handleValue('winRateFrom', e)}
                                type="number"
                                min={1}
                                size={"small"}
                            />

                            <Input
                                label="To: (%)"
                                value={winRateTo}
                                onChange={(e) => handleValue('winRateTo', e)}
                                type="number"
                                min={1}
                                size={"small"}
                            />
                        </div>
                    </div>
                </div>
                <div className="margin-l input-box padding-0 flex-wrap">
                    <div className="check-box">
                        <div className="label">Registration Date:</div>
                        <div>
                            <label className="fnt-10">From:</label>
                            <div className="date-from">
                                <div className="ui left icon input date">
                                    <input
                                        ref={refFrom}
                                        type="text"
                                        placeholder="Choose date..."
                                        size={10}
                                        value={regFromDate}
                                        onClick={() => setShowPicker('regFromDate')}
                                        onChange={() => setShowPicker('regFromDate')}
                                    />
                                    <i aria-hidden="true" className="calendar icon"/>
                                </div>
                                {showPicker === 'regFromDate' && (
                                    <DatePick close={closeLastLoginDatePicker} value={regFromDate}/>
                                )}
                                <div className='left'>
                                    <TimeInput
                                        value={regFromTime}
                                        onChange={(e) => handleValue('regFromTime', e)}
                                    />
                                </div>
                            </div>

                            <div>
                                <label className="fnt-10">To: </label>
                                <div className="date-to">
                                    <div className="ui left icon input date">
                                        <input
                                            ref={refTo}
                                            type="text"
                                            placeholder="Choose date..."
                                            size={10}
                                            value={regToDate}
                                            onClick={() => setShowPicker('regToDate')}
                                            onChange={() => setShowPicker('regToDate')}
                                        />
                                        <i aria-hidden="true" className="calendar icon"/>
                                    </div>
                                    {showPicker === 'regToDate' && (
                                        <DatePick close={closeLastLoginDatePicker} value={regToDate}/>
                                    )}
                                    <div className='left'>
                                        <TimeInput value={regToTime}
                                                   onChange={(e) => handleValue('regToTime', e)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="check-box">
                        <div className="label">Last Login Date:</div>
                        <div>
                            <label className="fnt-10">From:</label>
                            <div className="date-from">
                                <div className="ui left icon input date">
                                    <input
                                        ref={refFrom}
                                        type="text"
                                        placeholder="Choose date..."
                                        size={10}
                                        value={lastLoginFromDate}
                                        onClick={() => setShowPicker('lastLoginFromDate')}
                                        onChange={() => setShowPicker('lastLoginFromDate')}
                                    />
                                    <i aria-hidden="true" className="calendar icon"/>
                                </div>
                                {showPicker === 'lastLoginFromDate' && (
                                    <DatePick close={closeLastLoginDatePicker} value={lastLoginFromDate}/>
                                )}
                                <div className='left'>
                                    <TimeInput
                                        value={lastLoginFromTime}
                                        onChange={(e) => handleValue('lastLoginFromTime', e)}
                                    />
                                </div>
                            </div>

                            <div>
                                <label className="fnt-10">To: </label>
                                <div className="date-to">
                                    <div className="ui left icon input date">
                                        <input
                                            ref={refTo}
                                            type="text"
                                            placeholder="Choose date..."
                                            size={10}
                                            value={lastLoginToDate}
                                            onClick={() => setShowPicker('lastLoginToDate')}
                                            onChange={() => setShowPicker('lastLoginToDate')}
                                        />
                                        <i aria-hidden="true" className="calendar icon"/>
                                    </div>
                                    {showPicker === 'lastLoginToDate' && (
                                        <DatePick close={closeLastLoginDatePicker} value={lastLoginToDate}/>
                                    )}
                                    <div className='left'>
                                        <TimeInput value={lastLoginToTime}
                                                   onChange={(e) => handleValue('lastLoginToTime', e)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="margin-l-24 filter-div">
                <Button primary className="filter-btn belote-green" onClick={handleFilter}>
                    Filter
                </Button>

                <Button className="filter-btn" onClick={clearAll}>
                    Reset All
                </Button>
            </div>
            <div style={{minHeight: '20px'}}>
                <span className="error-mes date-err"> {err}</span>
            </div>
        </>
    );
};
export default FilterPlayersList;
