import React, { useState } from 'react';
import PrizePoolKnockout from './components/PrizePoolKnockout';
import { setClass } from '../../../helpers/game';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import InfoPlayers from '../../LobbyScene/Tournaments/TournamentsInfo/components/InfoPlayers';
import TabKnockout from './components/TabKnockout';
import InfoKnockout from './components/InfoKnockout/InfoKnockout';

const tabs = [
    {
        id: '1',
        label: 'Info',
    },
    {
        id: '2',
        label: 'Prizepool',
    },
    {
        id: '3',
        label: 'Players',
    },
];

function Knockout({ currentTournament, ...redux }) {
    const { userId } = redux;
    const { winningPlaces, buyIn, prize, startDate, players, name, status, time, gameTypes } = currentTournament;
    const [isKnockoutOpen, setIsKnockoutOpen] = useState(false);
    const [tabId, setTabId] = useState('1');
    const { t } = useTranslation();

    return (
        <div className="knockout-container">
            <div className="knockout-btn__container">
                <div className="knockout-btn__top">
                    <button className="knockout-btn__icon-container">
                        <img className="knockout-btn__icon" src="img/icon-cups.svg" alt="Icon cups" />
                    </button>
                    <div className="knockout-btn__title">{name}</div>
                </div>
                <div className="knockout-btn__more-container">
                    <button className="knockout-btn" onClick={handleKnockoutOpen}>
                        {t('More Info')}
                    </button>
                </div>
            </div>
            <div className={`knockout ${setClass(isKnockoutOpen, 'active')}`}>
                <button type="button" className="players-information__close-button" onClick={handleKnockoutClose}>
                    <span className="visually-hidden">close player information container</span>
                </button>
                <section className="tour-info">
                    <header className="tour-info__header">
                        <h2 className="tour-info__title">{name}</h2>
                    </header>
                    <div className="tour-info__body">
                        <ul className="tour-info__tabs">
                            {tabs.map(({ id, label }) => (
                                <TabKnockout id={id} label={label} onClick={handleTabChange} isActive={id === tabId} />
                            ))}
                        </ul>
                        <div className="tour-info__inner-tabs-container">
                            <ul className="tour-info__inner-tabs">
                                <li
                                    className={`tour-info__inner-tab tour-info__inner-tab--info ${setClass(
                                        tabId === tabs[0].id,
                                        'tour-info__inner-tab--active'
                                    )}`}
                                >
                                    <InfoKnockout
                                        {...{ status, buyIn, prize, settings: gameTypes, time, players, startDate }}
                                    />
                                </li>
                                <li
                                    className={`tour-info__inner-tab tour-info__inner-tab--prizepool ${setClass(
                                        tabId === tabs[1].id,
                                        'tour-info__inner-tab--active'
                                    )} `}
                                >
                                    <PrizePoolKnockout winningPlaces={winningPlaces} prize={prize} />
                                </li>
                                <li
                                    className={`tour-info__inner-tab tour-info__inner-tab--players ${setClass(
                                        tabId === tabs[2].id,
                                        'tour-info__inner-tab--active'
                                    )} `}
                                >
                                    <InfoPlayers isInGameScene={true} players={players} userId={userId} />
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );

    function handleKnockoutOpen() {
        setIsKnockoutOpen(true);
    }

    function handleKnockoutClose() {
        setIsKnockoutOpen(false);
    }

    function handleTabChange({ currentTarget: { id } }) {
        setTabId(id);
    }
}

const mapStateToProps = ({ player: { id: userId } }) => ({
    userId,
});

export default connect(mapStateToProps)(Knockout);
