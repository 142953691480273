import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {getAllSpins} from '../../redux/actions/spinAction';
import {leftDropDownMenuControl} from "../../redux/actions/otherActions";
import './spin.css'
import SpinTable from "../../components/SpinTable";
import {Loader, Pagination, Select} from "semantic-ui-react";

const columns = [
    {key: 1, value: 'id', text: 'ID'},
    {key: 2, value: 'created_at', text: 'Start Date'},
    {key: 3, value: 'buyin', text: 'Buy In'},
    {key: 4, value: 'prizepool', text: 'Prize Pool'},
    {key: 5, value: 'finished_at', text: 'End Date'}
];

const rowItems = [
    // {key: '10', text: '10 rows', value: '10'},
    // {key: '30', text: '20 rows', value: '20'},
    {key: '50', text: '50 rows', value: '50'},
    {key: '100', text: '100 rows', value: '100'},
];

function SpinReport({...redux}) {
    const {getAllSpins, tableData, totalPagesReducer, leftDropDownMenuControl, loader} = redux;
    const [filterDateForSorting, setFilterDateForSorting] = useState({});
    const [limit, setLimit] = useState(50);
    const [offset, setOffset] = useState(0);
    const [sortedField, setSortedField] = useState('');
    const [direction, setDirection] = useState('DESC');
    const [activePage, setActivePage] = useState('');
    const [rowCount, setRowCount] = useState(0);

    useEffect(() => {
        getAllSpins({limit, offset});
        leftDropDownMenuControl('spin');
    }, []);

    const getRowCount = (e, data) => {
        let activePage = Math.floor(offset / limit) + 1;
        setLimit(+data.value);
        getAllSpins({
            limit: +data.value,
            offset: data.value > rowCount ? 0 : +data.value,
            ...filterDateForSorting,
        });
        setRowCount(0);
        setActivePage(activePage);
    };
    const sorting = (field, direction) => {
        getAllSpins({
            orderBy: field,
            order: direction,
            ...filterDateForSorting,
            limit,
            offset: 0,

        });
        setSortedField(field);
        setActivePage(1);
        setDirection(direction);
        setRowCount(0);
    };

    const onPageChange = (e, data) => {
        getAllSpins({
            offset: (data.activePage - 1) * limit,
            orderBy: sortedField,
            order: direction,
            ...filterDateForSorting,
            limit,
        });
        setOffset((data.activePage - 1) * limit);
        setActivePage(data.activePage);
        setRowCount(() => data.activePage * limit - limit);

    };

    return (
        <div className="spin-content">
            <div className="page-title min-width">
                <h2>Spins Report</h2>
            </div>
            {!loader ?
                <>
                    <div className="row-count">
                        <div className="label">Rows count per page</div>
                        <div>
                            <Select
                                selectOnBlur={false}
                                className="own"
                                placeholder={`${limit} rows`}
                                options={rowItems}
                                onChange={getRowCount}
                            />
                        </div>
                    </div>
                    <div className='table-and-pg'>
                    <SpinTable data={tableData}
                               cols={columns}
                               sorting={sorting}
                               count={rowCount}
                               edit={false}
                    />
                    <Pagination
                        activePage={activePage}
                        totalPages={totalPagesReducer}
                        className="pagi-own"
                        onPageChange={totalPagesReducer > 1 ? onPageChange : null}
                    />
                    </div>
                </> : <Loader active> Loading ... </Loader>}
        </div>


    );
}

const mapStateToProps = ({allSpinsReducer, totalPagesReducer, loaderReducer}) => ({
    tableData: allSpinsReducer,
    totalPagesReducer,
    loader: loaderReducer,
});

const mapDispatchToProps = {
    getAllSpins,
    leftDropDownMenuControl
};
export default connect(mapStateToProps, mapDispatchToProps)(SpinReport);
