import React from 'react';
import { TABLE_ROW_GAME_TYPE_CLASSES } from '../../../../../constants/classicLobby/classes';
import PropTypes from 'prop-types';
import { TABLE_ROW_GAME_TYPE_IDS } from '../../../../../constants/classicLobby/ids';
import { GAME_MIN_POINT } from '../../../../../constants/game/other';
import { useTranslation } from 'react-i18next';
TypeRow.propTypes = {
    gameSettings: PropTypes.object,
};
const { ALL_COMBINATIONS, ...availableIconsIds } = TABLE_ROW_GAME_TYPE_IDS;

const times = {
    20: 'twenty',
    30: 'thirty',
    40: 'forty',
    50: 'fifty',
};

function TypeRow({ gameSettings = {}, till, time, dave }) {
    const gameSettingsList = Object.values(availableIconsIds).filter((id) => !!gameSettings[id]);

    return (
        <div className="lobby__table-cell lobby__table-cell--type">
            <ul className="lobby__table-cell-element type-list">
                {(!!time || !!till) && <TimeTillInfo time={time} till={till} />}
                {gameSettingsList.map((type) => {
                    if (type === TABLE_ROW_GAME_TYPE_IDS.DAVE) {
                        return <li key={type} className={`type-item type-item--dave-${dave}-x`} />;
                    } else {
                        return (
                            <li key={type} className={`type-item type-item--${TABLE_ROW_GAME_TYPE_CLASSES[type]}`} />
                        );
                    }
                })}
            </ul>
        </div>
    );
}

export function TimeTillInfo({ time, till }) {
    const { t } = useTranslation();

    const isRound = till < GAME_MIN_POINT;
    return (
        <>
            <div className={`lobby__table-cell-timer lobby__table-cell-timer--${times[time]}`} />
            {till && (
                <>
                    <span className="separator separator--large" />
                    <div className="lobby__table-cell-to">
                        {isRound ? t('Round [count]', { count: till }) : t('To [count]', { count: till })}
                    </div>
                    <span className="separator separator--large" />
                </>
            )}
        </>
    );
}

export default React.memo(TypeRow);
