import React, { useEffect } from 'react';
import { Table } from 'semantic-ui-react';
import './bazarBlot.css';
import { connect } from 'react-redux';
import moment from 'moment';
import { setCurrentBazar } from '../../redux/actions/bazarBlotAction';
import store from '../../redux/store';
import { useHistory } from 'react-router-dom';

const gameCombs = {
    ALLCOMBS: 'All combinations',
    NO4NO100: 'Without 4 cards and 100',
    ONLYBR: 'Belote / Rebelote only',
};

const ROOM_BET_TYPES = {
    1: 'Cash',
    2: 'Coins',
};

const CUSTOMS = {
    1: 'Player',
    0: 'Syustem',
};

const ROOM_MYSQL_STATUS = {
    1: 'CREATED',
    2: 'STARTED',
    3: 'FINISED',
    4: 'CRUSHED',
};

const CurrentBazar = ({ id, ...redux }) => {
    const { bazarBlotCurrentReducer } = redux;
    const history = useHistory();

    let playersObj = {};
    let playerKeys = [];

    try {
        playersObj = JSON.parse(bazarBlotCurrentReducer.players || '{}');
        playerKeys = Object.keys(playersObj);
    } catch (error) {
        console.error('Error parsing players JSON:', error);
    }

    useEffect(() => {
        const isPageRefreshed = performance.navigation.type === 1;

        if (isPageRefreshed) {
            history.push('/admin/bazar-blot');
        }
    }, [history]);

    return (
        <div className="game-info-content">
            {bazarBlotCurrentReducer && (
                <>
                    <div className="page-title">{<h2> Current Bazar Blot ID - {id}</h2>}</div>
                    <Table sortable celled fixed size="small">
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>
                                    Table ID:<span className="bold-data"> &nbsp;{id}</span>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    Player 1 ID :
                                    <span className="bold-data">&nbsp;{playerKeys[0]}</span>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    Player 2 ID:
                                    <span className="bold-data">&nbsp;{playerKeys[1]}</span>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    Player 3 ID:
                                    <span className="bold-data">&nbsp;{playerKeys[2]}</span>
                                </Table.Cell>
                            </Table.Row>{' '}
                            <Table.Row>
                                <Table.Cell>
                                    Player 4 ID:
                                    <span className="bold-data">&nbsp;{playerKeys[3]}</span>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    Bet Amount:
                                    <span className="bold-data">
                                        &nbsp;
                                        {bazarBlotCurrentReducer.bet}
                                    </span>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    Bet Type :
                                    <span className="bold-data">
                                        &nbsp;
                                        {ROOM_BET_TYPES[bazarBlotCurrentReducer.bet_type]}
                                    </span>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    Custom :
                                    <span className="bold-data">
                                        &nbsp;
                                        {CUSTOMS[bazarBlotCurrentReducer.custom]}
                                    </span>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    State :
                                    <span className="bold-data">
                                        &nbsp;
                                        {ROOM_MYSQL_STATUS[bazarBlotCurrentReducer.state]}
                                    </span>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    Points :
                                    <span className="bold-data">
                                        &nbsp;
                                        {bazarBlotCurrentReducer.points}
                                    </span>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    Winner 1 :
                                    <span className="bold-data">
                                        &nbsp;
                                        {bazarBlotCurrentReducer.winner_1}
                                    </span>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    Winner 2 :
                                    <span className="bold-data">
                                        &nbsp;
                                        {bazarBlotCurrentReducer.winner_2}
                                    </span>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    Winner Amount :
                                    <span className="bold-data">
                                        &nbsp;
                                        {bazarBlotCurrentReducer.win_amount}
                                    </span>
                                </Table.Cell>
                            </Table.Row>
                            {bazarBlotCurrentReducer.template_id && (
                                <Table.Row>
                                    <Table.Cell>
                                        Template :
                                        <span className="bold-data">
                                            &nbsp;
                                            {bazarBlotCurrentReducer.template_id}
                                        </span>
                                    </Table.Cell>
                                </Table.Row>
                            )}
                            <Table.Row>
                                <Table.Cell>
                                    Created At :
                                    <span className="bold-data">
                                        &nbsp;
                                        {moment(
                                            bazarBlotCurrentReducer.created_at,
                                            'YYYY-MM-DD HH:mm:ss'
                                        ).isValid()
                                            ? bazarBlotCurrentReducer.created_at
                                            : '-'}
                                    </span>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    Started At :
                                    <span className="bold-data">
                                        &nbsp;
                                        {moment(
                                            bazarBlotCurrentReducer.started_at,
                                            'YYYY-MM-DD HH:mm:ss'
                                        ).isValid()
                                            ? bazarBlotCurrentReducer.started_at
                                            : '-'}
                                    </span>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    Finished At :
                                    <span className="bold-data">
                                        &nbsp;
                                        {moment(
                                            bazarBlotCurrentReducer.finished_at,
                                            'YYYY-MM-DD HH:mm:ss'
                                        ).isValid()
                                            ? bazarBlotCurrentReducer.finished_at
                                            : '-'}
                                    </span>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    Update At :
                                    <span className="bold-data">
                                        &nbsp;
                                        {moment(
                                            bazarBlotCurrentReducer.updated_at,
                                            'YYYY-MM-DD HH:mm:ss'
                                        ).isValid()
                                            ? bazarBlotCurrentReducer.updated_at
                                            : '-'}
                                    </span>
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </>
            )}
        </div>
    );
};

const mapStateToProps = ({ bazarBlotCurrentReducer }) => ({
    bazarBlotCurrentReducer,
});

export default connect(mapStateToProps)(CurrentBazar);
