export const arrClass = ['picky-date-time__calendar', 'picky-date-time__breaker ',
    'picky-date-time__close picky-date-time-highlight_off',
    'picky-date-time-calendar__icon picky-date-time-first_page',
    'picky-date-time-calendar__icon picky-date-time-last_page',
    'picky-date-time-calendar__body-container xs',
    'picky-date-time-calendar__icon picky-date-time-keyboard_arrow_left',
    'picky-date-time-calendar__icon picky-date-time-keyboard_arrow_right',
    'picky-date-time visible', 'picky-date-time-calendar__inline-span',
    'picky-date-time-calendar__table-caption picky-date-time-calendar__table-cel no-border xs',
    'picky-date-time-calendar__button picky-date-time-calendar__today',
    'picky-date-time-calendar__inline-span picky-date-time-calendar__icon picky-date-time-refresh'];