import React, {PureComponent} from 'react';
import {Table} from 'semantic-ui-react';


export default class DataTable extends PureComponent {

    render() {
        const {data} = this.props;
        return (
            <div className='games-data-home'>
                <Table celled  size="small" className={'margin-l'}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell colSpan='3'>Cash Games</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>Game Count: <span className='bold-data'> &nbsp;{data.games[0] ? data.games[0].gamesCount : ''} </span> </Table.Cell>
                            <Table.Cell>Unique
                                Players:  <span className='bold-data'> &nbsp; {data.games[0] ? data.games[0].uniquePlayers : ''} </span> </Table.Cell>
                            <Table.Cell>Rake: <span className='bold-data'>  &nbsp; {data.games[0] ? data.games[0].rake : ''}</span> </Table.Cell>

                        </Table.Row>
                    </Table.Body>
                </Table>
                <Table celled size="small" className={'margin-l'}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell colSpan='4'>Tournaments</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <Table.Row>

                            <Table.Cell>Tournaments
                                Count: <span className='bold-data'> &nbsp;{data.tournament[0] ? data.tournament[0].tournamentsCount : ''}</span> </Table.Cell>
                            <Table.Cell>Tournament Unique
                                Players:  <span className='bold-data'> &nbsp; {data.tournament[0] ? data.tournament[0].tournamentUniques : ''}</span></Table.Cell>
                            <Table.Cell>Buyin
                                Sum:  <span className='bold-data'> &nbsp; {data.tournament[0] ? data.tournament[0].buyinsSum : ''}</span> </Table.Cell>
                            <Table.Cell>Prize Pool
                                Sum:  <span className='bold-data'> &nbsp;{data.tournament[0] ? data.tournament[0].prizePoolSum : ''}</span> </Table.Cell>

                        </Table.Row>
                    </Table.Body>
                </Table>
                <Table celled size="small" className={'margin-l'}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell colSpan='4'>Spin</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>Spin
                                Count: <span className='bold-data'> &nbsp;{data.spin[0] ? data.spin[0].tournamentsCount : ''}</span> </Table.Cell>
                            <Table.Cell>Spin Unique
                                Players: <span className='bold-data'> &nbsp; {data.spin[0] ? data.spin[0].tournamentUniques : ''}</span> </Table.Cell>
                            <Table.Cell>Buyin Sum: <span className='bold-data'> &nbsp; {data.spin[0] ? data.spin[0].buyinsSum : ''}</span> </Table.Cell>
                            <Table.Cell>Prize Pool
                                Sum: <span className='bold-data'> &nbsp;{data.spin[0] ? data.spin[0].prizePoolSum : ''} </span></Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </div>
        );
    }
}
