import * as axios from 'axios';
import { uri } from '../configs/server';


const instance = axios.create({
    baseURL: uri,
    headers: { 'Content-Type': 'application/json'},
})


export default instance