import { CARD_SUIT_IDS, CARD_VALUE_IDS, COMBINATIONS_IDS } from './ids';

const { BELOTE, FIFTY, FOR_CARDS, HUNDRED, REBELOTE, TERZ } = COMBINATIONS_IDS;
const { ACE, JACK, KING, QUEEN, NINE, TEN } = CARD_VALUE_IDS;
const { CLUB, DIAMOND, HEART, NO_TRUMP, SPADE } = CARD_SUIT_IDS;

export const COMBINATION_NAMES = {
    [TERZ]: 'Terz',
    [FIFTY]: '50',
    [HUNDRED]: '100',
    [FOR_CARDS]: '4 Cards',
    [BELOTE]: 'Belote',
    [REBELOTE]: 'Rebelote',
};

export const COMBINATION_VALUE_NAMES = {
    [ACE]: 'A!',
    [KING]: 'K!',
    [QUEEN]: 'Q!',
    [JACK]: 'J!',
    [NINE]: '9!',
    [TEN]: '10!',
};

export const CARD_SUIT_NAMES = {
    [CLUB]: 'Club',
    [DIAMOND]: 'Diamond',
    [HEART]: 'Heart',
    [NO_TRUMP]: 'No Trump',
    [SPADE]: 'Spade',
};
