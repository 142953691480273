import React from 'react';
import { useTranslation } from 'react-i18next';
import ItemKnockoutPrizePool from './ItemKnockoutPrizePool';

function PrizePoolKnockout({ winningPlaces, prize }) {
    const { t } = useTranslation();

    return (
        <div className="prizepool">
            <div className="prizepool__title">{t('Winning Places')}</div>
            <div className="prizepool__table">
                {Object.entries(winningPlaces).map(([place, percent]) => {
                    const winAmount = (prize * Number(percent.slice(0, -1))) / 100;
                    return <ItemKnockoutPrizePool place={place} percent={percent} winAmount={winAmount} />;
                })}
            </div>
        </div>
    );
}

export default PrizePoolKnockout;
